import { Fragment } from "react";
import { TableRowActionDef, TableRowsProps, TableRowType } from "../types";
import { styles } from "../styles";
import { defaultLookupId } from "../../utils";
import { useColumns } from "../hooks";
import TableRowActionsFlyout from "./RowActionsFlyout";
import { useRowActionDefs } from "../hooks/useRowActionDefs";
import Checkbox from "../../Checkbox";
import Icon from "../../Icon";
import Button from "../../Button";
import TableRows from "./Rows";

const TableRowsBase = <
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>({
  columns,
  data,
  children,
  rowActions,
  selectableRows,
  lookupRowId = defaultLookupId,
  rowTestId,
  isNested,
  nestedRows
}: TableRowsProps<Row, Column, Action> & { isNested?: boolean }) => {
  const { orderedKeys, definitions } = useColumns({
    columns,
    rowSample: data[0],
    children
  });

  const rowActionDefs = useRowActionDefs(rowActions, children);
  const hasRowActions = !!rowActionDefs?.length || !!rowActions?.render;
  const rowActionsTestId = rowActions?.testId || "row-actions-cell";

  const nestedRowsProps =
    nestedRows && typeof nestedRows === "object" ? nestedRows.props : undefined;

  return (
    <>
      {data.map(row => {
        const nestedData = nestedRowsProps?.data?.(row);
        const isNestedExpanded = nestedRowsProps?.isExpanded?.(row);
        return (
          <Fragment key={lookupRowId(row)}>
            <tr
              className={`subKit-TableRow ${
                isNested ? "kit-table-nested-row" : ""
              }`}
              css={[styles.tr]}
              data-testid={rowTestId}
            >
              {orderedKeys.map((columnKey: Column, columnIndex) => {
                const column = definitions[columnKey];
                return (
                  <Fragment key={`${column.key}-${lookupRowId(row)}`}>
                    <td css={[styles.td]} data-testid={column.testId}>
                      {columnIndex === 0 &&
                        (!!selectableRows || !!nestedData || isNested) && (
                          <div css={[styles.cellPrefix]}>
                            {selectableRows && (
                              <Checkbox
                                checked={
                                  selectableRows.isSelected(row) === true
                                }
                                indeterminate={
                                  selectableRows.isSelected(row) ===
                                  "indeterminate"
                                }
                                onChange={event =>
                                  selectableRows.onToggle(
                                    row,
                                    event.currentTarget.checked
                                  )
                                }
                              />
                            )}
                            {nestedData?.length && (
                              <Button
                                className="kit-table-nested-toggle"
                                variant="ghost"
                                onClick={e => {
                                  e.stopPropagation();
                                  nestedRowsProps?.onToggle!(
                                    row,
                                    !isNestedExpanded
                                  );
                                }}
                                aria-expanded={!!isNestedExpanded}
                                css={[styles.nestedToggle]}
                              >
                                <Icon
                                  name="Caret"
                                  fill="var(--color-border-primary)"
                                  direction={
                                    isNestedExpanded ? "down" : "right"
                                  }
                                />
                              </Button>
                            )}
                            {!nestedData?.length &&
                              (!!isNested || !!nestedRowsProps) && (
                                <div css={styles.nestedToggle} />
                              )}
                          </div>
                        )}
                      {column.render(row) ?? "--"}
                    </td>

                    {/* row actions */}
                    {hasRowActions &&
                      columnIndex === orderedKeys.length - 1 && (
                        <td
                          css={[styles.td, styles.actionsCell]}
                          data-testid={rowActionsTestId}
                        >
                          <TableRowActionsFlyout
                            {...rowActions}
                            row={row}
                            actions={rowActionDefs}
                            testId={rowActionsTestId}
                          />
                        </td>
                      )}
                  </Fragment>
                );
              })}
            </tr>
            {isNestedExpanded && (
              <TableRows
                {...nestedRowsProps}
                isNested
                rowActions={nestedRowsProps?.rowActions || rowActions}
                data={nestedData || []}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default TableRowsBase;
