import { FilterTypes } from "@madhive/mad-sdk";
import { useCallback } from "react";
import { toObservable } from "../../rxjs";
import { useSingularMadHooksUtils } from "../singularUtils";
/**
 * Loads a single piece of data by id
 * @param collection Collection the data belongs to
 * @param id the id of the data
 * @returns loadFunction: () => void;
 */
export const useLoadSingleData = (collection, id, noData) => {
    const { observer, handler } = useSingularMadHooksUtils(collection, id);
    /**
     * @param reloadOptions Options for reloading data
     * @param reloadOptions.resetChanges if true, will clear any pending changes - defaults to false
     * @param reloadOptions.clearCache if true, will clear the given data from the sdk's cache - defaults to false
     */
    const loadData = useCallback((loadOptions = {}) => {
        const { resetChanges = false, clearCache = false } = loadOptions;
        if (resetChanges) {
            // removing changes on deltas
            Object.keys(observer.value.deltas).forEach((key) => {
                delete observer.value.deltas[key];
            });
            observer.value.validationErrors?.clear();
        }
        if (clearCache) {
            handler.reconcileCache("delete", observer.value.data);
        }
        if (!id || noData) {
            return observer.next({
                ...observer.value,
                isInitializing: false
            });
        }
        observer.next({
            ...observer.value,
            isLoading: true,
            isLoaded: false,
            isInitializing: false
        });
        const filters = {
            where: [
                {
                    field: handler.getIdentifierKey(),
                    type: FilterTypes.EQ,
                    value: id
                }
            ]
        };
        // @ts-expect-error - TODO: determine if this is a ts issue or needs to be figured out
        return toObservable(handler.find_once(filters)).subscribe({
            next: (data) => observer.next({
                ...observer.value,
                data,
                pendingChanges: new Proxy(observer.value.deltas, {
                    get(deltasObj, key) {
                        return key in deltasObj ? deltasObj[key] : observer.value.data?.[key];
                    }
                }),
                isLoading: true,
                isLoaded: false,
                isDeleted: false,
                isSaving: false,
                isInitializing: false
            }),
            complete: () => observer.next({
                ...observer.value,
                isLoading: false,
                isLoaded: true,
                isSaving: false
            }),
            error: (error) => observer.next({
                ...observer.value,
                isLoading: false,
                isLoaded: false,
                isDeleted: false,
                isSaving: false,
                isInitializing: false,
                error
            })
        });
    }, [id, observer, handler]);
    return loadData;
};
