import { useRef, useState } from "react";
import { css } from "@emotion/react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { kitShadow } from "../../styles";
import { OverflowShadowsProps } from "./types";
import { styles } from "./styles";

/**
 * Wrap any content that needs to apply shadows (when `root` scroll overflows) with `OverflowShadows`.
 * Using intersection observers, it applies css variables to control the value of a given shadow — when the scroll area overflows.
 * > A common use case is the sticky headers and columns on `Table`.
 *
 * ### How does it work?
 * - The component will include invisible elements that will be observed for intersection (`top`, `left`, `right`):
 * ```
 * |               top                   |
 * ———————————————————————————————————————
 * | left |      children        | right |
 * ```
 * - The shadows are applied to the scroll area and not the content itself.
 * - To apply shadows to the content, set the box-shadow to the relevant shadow variable:
 *   - top: `box-shadow: var(--scroll-shadow-top)`
 *   - left: `box-shadow: var(--scroll-shadow-left)`
 *   - right: `box-shadow: var(--scroll-shadow-right)`
 *   - Apply opposite direction clip-path to the content to clip the shadow:
 *     - top: `clip-path: ${kitShadow.overflow.values.clipPath.bottom};`
 *     - left: `clip-path: ${kitShadow.overflow.values.clipPath.right};`
 *     - right: `clip-path: ${kitShadow.overflow.values.clipPath.left};`
 *
 * #### Usage:
 * - The `root` prop is the element to observe for intersection.
 * - If `root` prop not provided:
 *    - if `overflow` is set to `auto` | `scroll` it will default to the component itself.
 *    - if `overflow` is set to `hidden` | `visible` it will default to `document.body`.
 *
 * ```tsx
 * const rootRef = useRef<HTMLDivElement>(null);
 * <div root={rootRef}>
 *  <OverflowShadows root={rootRef.current}>
 *   <div css={[styles.stickyElem, styles.sticky.top]}>
 *    Sticky Top
 *   </div>
 *
 *   <div css={[styles.stickyElem, styles.sticky.left]}>
 *    Sticky Left
 *   <div>
 *
 *   <div>...</div>
 *  </OverflowShadows>
 * </div>
 * ```
 *
 * #### Applying styles:
 * ```tsx
 * const styles = {
 *  stickyElem: css`
 *    position: sticky;
 *    z-index: 1;
 *  `,
 *  sticky: {
 *   top: css`
 *    box-shadow: var(--scroll-shadow-top);
 *    clip-path: ${kitShadow.overflow.values.clipPath.bottom};
 *    top: 0px;
 *   `,
 *   left: css`
 *    box-shadow: var(--scroll-shadow-left);
 *    clip-path: ${kitShadow.overflow.values.clipPath.right};
 *    left: 0px;
 *   `
 *  }
 * };
 * ```
 */
const OverflowShadows = ({
  root: _root,
  containerRef,
  children,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  overflow = "visible",
  overflowShadows = ["left", "right", "top"],
  onScroll,
  fillHeight
}: OverflowShadowsProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const overflowX = typeof overflow === "object" ? overflow.x : overflow;
  const overflowY = typeof overflow === "object" ? overflow.y : overflow;
  const [overflowedShadows, setOverflowedShadows] = useState<
    Record<"left" | "right" | "top", boolean>
  >({
    left: false,
    top: false,
    right: false
  });

  const isScrollableOverflow =
    ["auto", "scroll"].includes(overflowX!) ||
    ["auto", "scroll"].includes(overflowY!);

  const root = _root || isScrollableOverflow ? ref : undefined;

  const setLeftScrollTarget = useIntersectionObserver({
    root,
    threshold: 0.01,
    rootMargin: "100% 0px 100% 0px",
    disabled: !overflowShadows.includes("left"),
    onIntersection: ([entry]) => {
      setOverflowedShadows(current =>
        current.left === !entry.isIntersecting
          ? current
          : {
              ...current,
              left: !entry.isIntersecting
            }
      );
    }
  });
  const setRightScrollTarget = useIntersectionObserver({
    root,
    threshold: 0.01,
    rootMargin: "100% 0px 100% 0px",
    disabled: !overflowShadows.includes("right"),
    onIntersection: ([entry]) => {
      setOverflowedShadows(current =>
        current.right === !entry.isIntersecting
          ? current
          : {
              ...current,
              right: !entry.isIntersecting
            }
      );
    }
  });
  const setTopScrollTarget = useIntersectionObserver({
    root,
    threshold: 0.01,
    disabled: !overflowShadows.includes("top"),
    onIntersection: ([entry]) => {
      setOverflowedShadows(current =>
        current.top === !entry.isIntersecting
          ? current
          : {
              ...current,
              top: !entry.isIntersecting
            }
      );
    }
  });
  return (
    <div
      ref={current => {
        ref.current = current;
        containerRef?.(current);
      }}
      className="kit-OverflowShadows"
      onScroll={isScrollableOverflow ? onScroll : undefined}
      css={[
        styles.columnContainer,

        css`
          overflow-x: ${overflowX};
          overflow-y: ${overflowY};
        `,
        fillHeight &&
          css`
            flex-grow: 1;
          `,
        isScrollableOverflow
          ? {
              maxWidth,
              maxHeight,
              minHeight
            }
          : css`
              min-width: fit-content;
            `,
        overflowedShadows.left &&
          css`
            --scroll-shadow-left: ${kitShadow.overflow.values.shadow};
          `,
        overflowedShadows.right &&
          css`
            --scroll-shadow-right: ${kitShadow.overflow.values.shadow};
          `,
        overflowedShadows.top &&
          css`
            --scroll-shadow-top: ${kitShadow.overflow.values.shadow};
          `
      ]}
    >
      <div css={[styles.scrollTargetContainer]}>
        <div ref={setTopScrollTarget} css={styles.topScrollTarget} />
        <div ref={setLeftScrollTarget} />

        <div
          css={[
            styles.columnContainer,
            {
              minWidth,
              minHeight,
              // offset by 1px avoid scrollbar when no overflow
              width: "calc(100% - 1px)"
            }
          ]}
        >
          {children}
        </div>

        <div ref={setRightScrollTarget} css={styles.rightScrollTarget} />
      </div>
    </div>
  );
};

export default OverflowShadows;
export * from "./types";
