import { makeDateRange } from "lib/utils/date";
import {
  DailyOverlapFormatted,
  DataPoint,
  PixelReportFormatted,
  ProviderOverlapFormatted
} from "pixel-types";

const formatDateForOverlap = (date: string) =>
  date.replace(
    /(\d{4})-(\d{1,2})-(\d{1,2})/,
    (match, y, m, d) => `${m}/${d}/${y}`
  );

const formatDateRange = (date: string) =>
  date.replace(
    /(\d{4})(\d{1,2})(\d{1,2})/,
    (match, y, m, d) => `${m}-${d}-${y}`
  );

export const calculateOverlapTableData = (reportData: PixelReportFormatted) => {
  const resultSet = reportData.allOverlapData;

  // get provider overlap values
  const providerOverlap: ProviderOverlapFormatted[] =
    resultSet.providerOverlap.map(
      (elem: ProviderOverlapFormatted): ProviderOverlapFormatted => {
        const impressionsTotal = Number(resultSet.impressions.total);
        const providerImps = Number(elem.impressions);

        /* eslint-disable no-restricted-globals */
        const distribution =
          !isNaN(impressionsTotal) &&
          !isNaN(providerImps) &&
          impressionsTotal !== 0
            ? (providerImps / impressionsTotal) * 100
            : 0;
        /* eslint-enable no-restricted-globals */

        const percent = Number(elem.pixelPercentage) || 0;

        return {
          ...elem,
          campaignDistribution: distribution,
          performance: percent - distribution,
          overlapPercent: percent
        };
      }
    );

  const dailyOverlapSortedByDate = resultSet.dailyOverlap.sort(
    (a: { date: string }, b: { date: any }) => a.date.localeCompare(b.date)
  );

  const getCumulativeNumber = (index: number, curr: number, acc: number) =>
    index === 0 ? curr : acc + curr;

  let cumulativeImps = 0;
  let cumulativePixelFires = 0;
  let cumulativePixelFiresPercent = 0;

  const dailyOverlap: DailyOverlapFormatted[] = [];

  dailyOverlapSortedByDate.forEach(
    (
      elem: {
        pixelOverlap: number;
        date: string;
        impressions: any;
        overlap: string | number;
        dedupedOverlap: any;
      },
      index: number
    ) => {
      /* eslint-disable no-restricted-globals */
      const overlapPercent =
        !isNaN(elem.pixelOverlap) &&
        !isNaN(resultSet.allOverlap.total) && // total is now total pixel overlap
        resultSet.allOverlap.total > 0
          ? (elem.pixelOverlap / resultSet.allOverlap.total) * 100
          : 0;
      /* eslint-enable no-restricted-globals */

      const currentIdx = dailyOverlapSortedByDate[index];

      cumulativeImps = getCumulativeNumber(
        index,
        currentIdx.impressions,
        cumulativeImps
      );

      cumulativePixelFires = getCumulativeNumber(
        index,
        currentIdx.pixelOverlap,
        cumulativePixelFires
      );

      cumulativePixelFiresPercent = getCumulativeNumber(
        index,
        overlapPercent,
        cumulativePixelFiresPercent
      );

      dailyOverlap.push({
        date: formatDateForOverlap(elem.date),
        impressions: elem.impressions,
        overlap: +elem.overlap,
        pixelOverlap: +elem.pixelOverlap,
        dedupedOverlap: elem.dedupedOverlap
      });
    }
  );

  return { providerOverlap, dailyOverlap };
};

const fillInMissingDatesWithBlankData = (
  impsOverlapData: DataPoint[],
  startDate: string,
  endDate: string
) => {
  const dateRange = makeDateRange(new Date(startDate), new Date(endDate));
  const impsOverlapDataCopy = [...impsOverlapData];
  const impsOverlapDataSet = new Set(impsOverlapData.map(el => el.date));
  dateRange.forEach(date => {
    if (!impsOverlapDataSet.has(formatDateRange(date))) {
      impsOverlapDataCopy.push({
        date: formatDateRange(date),
        impressions: 0,
        overlap: 0,
        allOverlap: 0
      });
    }
  });
  return impsOverlapDataCopy;
};

export const shapeDataForMultiLineChart = (
  reportData: DataPoint[],
  overlap: string,
  startDate: string,
  endDate: string
) => {
  const dataPoints = fillInMissingDatesWithBlankData(
    reportData,
    startDate,
    endDate
  )
    .map(elem => ({
      label: elem.date,
      primary: elem.impressions,
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      secondary: elem[overlap]
    }))
    .sort((a, b) => new Date(a.label).valueOf() - new Date(b.label).valueOf());

  return dataPoints;
};
