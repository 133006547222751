import { KnownProjectIds, User as MadSDKUser } from "@madhive/mad-sdk";
import type { IListener, IUser } from "@madhive/mad-tracker";
import { firebaseProjectId } from "lib/sdk";

import { Injector } from "../../injector";

import { SprigProjectID } from "./constants";

export class Sprig extends Injector implements IListener<MadSDKUser> {
  user = {
    set: (user: IUser<MadSDKUser>) => {
      if (!user.traits || !this.instance) {
        return;
      }

      const {
        id,
        email,
        name,
        type,
        userType,
        isAdmin,
        isDev,
        affiliations,
        viewIds,
        primaryOrganizationId,
        primaryOrganizationName,
        brandingId,
        advertiserId,
        agencyId,
        teamId
      } = user.traits;

      this.instance("setUserId", user.id);
      this.instance("setAttributes", {
        id,
        name,
        email,
        type,
        userType,
        isAdmin,
        isDev,
        affiliations,
        viewIds,
        primaryOrganizationId,
        primaryOrganizationName,
        brandingId,
        advertiserId,
        agencyId,
        teamId
      });
    },
    reset: () => {
      this.instance?.("logoutUser");
    }
  };

  protected get instance() {
    // eslint-disable-next-line dot-notation
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    return window.Sprig;
  }

  get projectId() {
    switch (firebaseProjectId) {
      case KnownProjectIds.MAD_MASTER:
        return SprigProjectID.PRODUCTION;
      case KnownProjectIds.MAD_STAGING:
        return SprigProjectID.DEVELOPMENT;
      // there is no sprig project for any other env
      default:
        return undefined;
    }
  }

  protected get source() {
    return `
      (function(l,e,a,p) {
          window.Sprig = function(){S._queue.push(arguments)}
          var S = window.Sprig;S.appId = a;S._queue = [];window.UserLeap=S;
          a=l.createElement('script');
          a.async=1;a.src=e+'?id='+S.appId;
          p=l.getElementsByTagName('script')[0];
          p.parentNode.insertBefore(a, p);
        })(document, 'https://cdn.sprig.com/shim.js', '${this.projectId}');
      `;
  }
}
