import { isValidObject } from "lib/utils/validation";

import { FirestoreDate, dateFromFirestoreDate } from "@madhive/mad-sdk";

import { formatNumberWithPadding } from "./number";

export type YYYYMMDD = string;

export const MILLI_MINUTE = 1000 * 60;
export const MILLI_HOUR = 1000 * 60 * 60;
export const MILLI_DAY = 1000 * 60 * 60 * 24;
export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export enum WeekDay {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

export const isValidDate = (dateObj: any): dateObj is Date => {
  const date = new Date(dateObj);
  return !!dateObj && !Number.isNaN(date.valueOf());
};

export const isValidFirestoreDate = (
  dateObj: any
): dateObj is FirestoreDate => {
  if (typeof dateObj !== "object") {
    return false;
  }

  return (
    typeof dateObj.seconds === "number" &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(dateObj.seconds) &&
    typeof dateObj.toDate === "function" &&
    isValidDate(dateObj.toDate())
  );
};

export const isCurrentTimeZoneET = ["EST", "EDT"].includes(
  new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2]
);

/**
 * Compares the time between two dates, to determine if they are equal.
 * @param date1: The first date to compare.
 * @param date2: The second date to compare.
 * @returns true if they are equal, false otherwise.
 */
export const isTimeEqual = (
  date1: Date | undefined,
  date2: Date | undefined
) => {
  if (!date1 || !date2) return false;

  return (
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes() &&
    date1.getSeconds() === date2.getSeconds()
  );
};

export const prettyPrintDate = (date: Date | string | undefined) => {
  if (!isValidDate(date)) {
    return "Invalid Date";
  }
  if (new Date(date).getTime() === 0) {
    return "--";
  }

  return new Date(date).toLocaleTimeString("en", {
    weekday: "long",
    month: "long",
    year: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "America/New_York",
    timeZoneName: "long"
  });
};

export const prettifyDateWithTimezone = (
  date: Date | string | undefined | null
) => {
  if (!isValidDate(date)) {
    return "Invalid Date";
  }
  if (new Date(date).getTime() === 0) {
    return "--";
  }

  return new Date(date).toLocaleTimeString("en", {
    hour: "2-digit",
    minute: "2-digit",
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    timeZone: "America/New_York",
    timeZoneName: "short"
  });
};

export const prettifyDateWithShortTimezoneAndSeconds = (
  date: Date | string | undefined
) => {
  if (!isValidDate(date)) {
    return "Invalid Date";
  }
  if (new Date(date).getTime() === 0) {
    return "--";
  }

  return new Date(date).toLocaleTimeString("en", {
    hour: "2-digit",
    minute: "2-digit",
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    second: "2-digit",
    timeZoneName: "short"
  });
};

export const prettifyDateWithTimezoneAndSeconds = (
  date: Date | string | undefined
) => {
  if (!isValidDate(date)) {
    return "Invalid Date";
  }
  if (new Date(date).getTime() === 0) {
    return "--";
  }

  return `${new Date(date)
    .toLocaleTimeString("en", {
      hour: "2-digit",
      minute: "2-digit",
      month: "numeric",
      day: "numeric",
      year: "2-digit",
      second: "2-digit",
      timeZone: "America/New_York"
    })
    .replace(",", "")} (ET)`;
};

export const datePicker = (value: Date) =>
  // Formatted for datepickers: From Date to YYYY-MM-DD
  // TODO: Update mrhat globals with this version as previous version was using UTC as base; Need to use local time
  `${value.getFullYear()}-${formatNumberWithPadding(
    value.getMonth() + 1,
    2
  )}-${formatNumberWithPadding(value.getDate(), 2)}`;

export const formatAsFirestoreDate = (value: Date) =>
  // Formatted for firestore: From Date to YYYYMMDD
  datePicker(value).replace(new RegExp("-", "g"), "");

export const dateFromFirestoreIndex = (str: string) => {
  // From YYYYMMDD to Date
  const n = parseFloat(str);
  // eslint-disable-next-line no-restricted-globals
  if (typeof n !== "number" || isNaN(n) || n < 10000000) {
    throw new Error("string should be provided in YYYYMMDD format ...");
  }

  const nString = n.toString();
  return new Date(
    `${nString.substr(4, 2)}/${nString.substr(6, 2)}/${nString.substr(0, 4)}`
  );
};

export const displayDate = (value: Date | undefined) => {
  // From Date to MM/DD/YY
  if (!isValidDate(value)) {
    return "--";
  }
  const year = `${value.getFullYear()}`.substring(2);
  return `${formatNumberWithPadding(
    value.getMonth() + 1
  )}/${formatNumberWithPadding(value.getDate())}/${year}`;
};

export const monthDayDisplay = (value: Date) => {
  // From Date to MM/DD
  if (!isValidDate(value)) {
    return "--";
  }
  return `${formatNumberWithPadding(
    value.getMonth() + 1
  )}/${formatNumberWithPadding(value.getDate())}`;
};
export const dateFullDisplay = (value: Date) => {
  // From Date to MM/DD/YYYY
  if (!isValidDate(value)) {
    return "--";
  }
  return `${formatNumberWithPadding(
    value.getMonth() + 1
  )}/${formatNumberWithPadding(value.getDate())}/${value.getFullYear()}`;
};
export const dateFullDisplayWithTime = (
  value: Date | null | undefined,
  s = "/",
  allowSeconds: boolean = false
) => {
  // From Date to MM/DD/YYYY 07:22 AM
  if (!isValidDate(value)) {
    return "--";
  }

  const hours = formatNumberWithPadding(((value.getHours() + 11) % 12) + 1);
  const mins = formatNumberWithPadding(value.getMinutes());
  const seconds = formatNumberWithPadding(value.getSeconds());
  const suffix = value.getHours() >= 12 ? "PM" : "AM";

  return `${formatNumberWithPadding(
    value.getMonth() + 1
  )}${s}${formatNumberWithPadding(
    value.getDate()
  )}${s}${value.getFullYear()} ${hours}:${mins}${
    allowSeconds ? `:${seconds}` : ""
  } ${suffix}`;
};

/**
 * Creates a formatted date string from a firestore date
 * @param date firestore date
 * @returns formatted date string in the following format MM/DD/YYYY
 */
export const formatFilterDatesForDisplay = (
  date: FirestoreDate | undefined
): string => {
  if (!isValidObject(date)) {
    return "--";
  }

  const formattedDate = dateFromFirestoreDate(date);
  if (!isValidDate(formattedDate)) {
    return "--";
  }

  return formattedDate.toLocaleString("en-US", {
    timeZone: "UTC", // ! firestore date is stored in utc
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  });
};

export const dateDisplayTime = (value: Date) => {
  const hours = formatNumberWithPadding(((value.getHours() + 11) % 12) + 1);
  const mins = formatNumberWithPadding(value.getMinutes());
  const suffix = value.getHours() >= 12 ? "PM" : "AM";

  return `${hours}:${mins} ${suffix}`;
};

export const createCleanDate = (date: Date | number) => {
  const dateCopy = new Date(date);

  dateCopy.setHours(0);
  dateCopy.setMinutes(0);
  dateCopy.setSeconds(0);
  dateCopy.setMilliseconds(0);
  return dateCopy;
};

export const addOneDayToDate = (date: Date): Date => {
  const dateCopy = new Date(date);

  dateCopy.setDate(dateCopy.getDate() + 1);

  return dateCopy;
};

export const makeDateRange = (startDate: Date, endDate: Date): YYYYMMDD[] => {
  // returns array of YYYYMMDD dates between start and end date (inclusive)
  if (startDate.valueOf() > endDate.valueOf()) {
    return [];
  }

  let mutableCurrentDate = createCleanDate(startDate);
  const endDateInMilliseconds = endDate.valueOf();

  const dateRange: YYYYMMDD[] = [];

  while (mutableCurrentDate.valueOf() <= endDateInMilliseconds) {
    dateRange.push(formatAsFirestoreDate(mutableCurrentDate));

    mutableCurrentDate = addOneDayToDate(mutableCurrentDate);
  }

  return dateRange;
};

export const formatRemaining = (millis: number) => {
  // TODO: Set local of Date.now() to EST
  const milliDifference = Math.abs(millis);
  const suffix = millis < 0 ? " ago" : "";

  let difference = milliDifference / MILLI_DAY;

  if (difference < 1) {
    difference = milliDifference / MILLI_HOUR;

    let formatted = difference >= 1 ? `${Math.floor(difference)}h ` : "";
    const minutes = (milliDifference % MILLI_HOUR) / MILLI_MINUTE;

    if (minutes >= 1) {
      formatted += `${Math.floor(minutes)}m `;
    }
    if (formatted === "") {
      formatted = "< 1m";
    } else {
      formatted += suffix;
    }
    return formatted;
  }
  const hours = (milliDifference % MILLI_DAY) / MILLI_HOUR;

  let formatted = `${Math.floor(difference)}d `;

  if (hours >= 1) {
    formatted += `${Math.floor(hours)}h `;
  }

  return (formatted += suffix);
};

export const getLatestDate = (dates: Array<Date | undefined>): Date => {
  let largestDate: Date | undefined;
  dates.forEach(date => {
    if (date) {
      const realDate = typeof date === "string" ? new Date(date) : date;
      if (!largestDate) {
        largestDate = date;
      } else if (largestDate.getTime() < realDate.getTime()) {
        largestDate = realDate;
      }
    }
  });
  if (largestDate) {
    return largestDate;
  }
  throw Error("Invalid array of dates given to getLatestDate");
};

export const getEarliestDate = (dates: Array<Date | undefined>): Date => {
  let smallestDate: Date | undefined;
  dates.forEach(date => {
    if (date) {
      const realDate = typeof date === "string" ? new Date(date) : date;
      if (!smallestDate) {
        smallestDate = date;
      } else if (smallestDate.getTime() > realDate.getTime()) {
        smallestDate = realDate;
      }
    }
  });
  if (smallestDate) {
    return smallestDate;
  }
  throw Error("Invalid array of dates given to getEarliestDate");
};

/**
 * Creates a copy of the passed-in date, and sets its time to midnight.
 * @param date: The date you want to update the time for.
 * @returns A copy of the date, with the time updated.
 */
export const setDateToMidnight = (date: Date): Date => {
  const dateCopy = new Date(date);

  dateCopy.setHours(0, 0, 0, 0);

  return dateCopy;
};

/**
 * Creates a copy of the passed-in date, and sets its time to one second before midnight.
 * @param date: The date you want to update the time for.
 * @returns A copy of the date, with the time updated.
 */
export const setDateToBeforeMidnight = (date: Date): Date => {
  const dateCopy = new Date(date);

  dateCopy.setHours(23, 59, 59, 0);

  return dateCopy;
};

export const formatToHermesDate = (date: Date): string =>
  // PK: 60000 is to transform the timezone offset to milliseconds.
  // This will ensure that new Date("01-02-2021 19:00").toISOString() in Eastern time doesn't return the next day (due to zero UTC offset)
  new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];

export const setDateToLastMillisecondOfDay = (date: Date): Date => {
  const dateCopy = new Date(date);

  dateCopy.setHours(23, 59, 59, 999);

  return dateCopy;
};

export const setDateToLastSecondOfDay = (date: Date): Date => {
  const dateCopy = new Date(date);

  dateCopy.setHours(23, 59, 59);

  return dateCopy;
};

/** Careful DRYing these functions, their names make the intent in the parts of the code where they're used very clear. */
export const addOneMonthToDate = (date: Date): Date => {
  const dateCopy = new Date(date);

  dateCopy.setMonth(dateCopy.getMonth() + 1);

  return dateCopy;
};

export const addMonthsToDate = (date: Date, monthsToAdd: number): Date => {
  const dateCopy = new Date(date);

  dateCopy.setMonth(dateCopy.getMonth() + monthsToAdd);

  return dateCopy;
};

export const subtractXMonthsToDate = (x: number, date: Date): Date => {
  const dateCopy = new Date(date);
  dateCopy.setMonth(dateCopy.getMonth() - x);

  return dateCopy;
};

export const addYearsToDate = (date: Date, yearsToAdd: number): Date => {
  const dateCopy = new Date(date);

  dateCopy.setFullYear(dateCopy.getFullYear() + yearsToAdd);

  return dateCopy;
};

const MILLISECONDS_IN_A_WEEK = 7 * 24 * 60 * 60 * 1000;

export const addOneWeekToDate = (date: Date): Date => {
  const dateCopy = new Date(date);

  dateCopy.setTime(dateCopy.getTime() + MILLISECONDS_IN_A_WEEK);

  return dateCopy;
};

export const addDaysToDate = (date: Date, numberOfDaysToAdd: number): Date => {
  const dateCopy = new Date(date);

  dateCopy.setDate(dateCopy.getDate() + numberOfDaysToAdd);

  return dateCopy;
};

export const addHoursToDate = (date: Date, numberOfHoursToAdd: number) => {
  const dateCopy = new Date(date);

  dateCopy.setTime(dateCopy.getTime() + numberOfHoursToAdd * 60 * 60 * 1000);

  return dateCopy;
};

export const addMinutesToDate = (date: Date, numberOfMinutesToAdd: number) => {
  const dateCopy = new Date(date);

  dateCopy.setTime(dateCopy.getTime() + numberOfMinutesToAdd * 60 * 1000);

  return dateCopy;
};

export const addSecondsToDate = (date: Date, numberOfSecondsToAdd: number) => {
  const dateCopy = new Date(date);

  dateCopy.setTime(dateCopy.getTime() + numberOfSecondsToAdd * 1000);

  return dateCopy;
};

export const removeOneWeekFromDate = (date: Date): Date => {
  const dateCopy = new Date(date);

  dateCopy.setTime(dateCopy.getTime() - MILLISECONDS_IN_A_WEEK);

  return dateCopy;
};

export enum DateOption {
  ENDED_YESTERDAY = "Ended Yesterday",
  ENDED_THIS_WEEK = "Ended This Week",
  ENDED_LAST_WEEK = "Ended Last Week",
  ENDED_THIS_MONTH = "Ended This Month",
  ENDED_LAST_MONTH = "Ended Last Month",
  STARTS_TOMORROW = "Starts Tomorrow",
  STARTS_THIS_WEEK = "Starts This Week",
  STARTS_NEXT_WEEK = "Starts Next Week",
  STARTS_THIS_MONTH = "Starts This Month",
  STARTS_NEXT_MONTH = "Starts Next Month"
}

/**
 * Returns a Date object for the next day of a given index. For example calling
 * this with 0 as the second argument will return the date of the next Sunday.
 *
 * If the passed in day (e.g. Wednesday) is the same as the next day you're
 * looking for (also Wednesday), it'll return the Wednesday seven days from now.
 *
 * @param {string|number|Date} now A valid argument to a date constructor
 * @param {number} dayIndex a number representing the day index to get the next day of. 0 is Sunday, for example
 */
export const nextDay = (now = new Date(), dayIndex = 0) => {
  const dateCopy = createCleanDate(new Date(now));

  if (now.getDay() === dayIndex) {
    dateCopy.setDate(dateCopy.getDate() + 7);
  } else {
    dateCopy.setDate(
      dateCopy.getDate() + ((dayIndex + (7 - dateCopy.getDay())) % 7)
    );
  }

  return dateCopy;
};

// Proportional days between two date ranges -> 11/1 00:00 - 11/1 03:00 gives 0.125 day
export const getNumberOfProportionalDaysBetween = (d1: Date, d2: Date) => {
  const date1 = new Date(d1.getTime());
  const date2 = new Date(d2.getTime());

  return (date2.getTime() - date1.getTime()) / MILLI_DAY;
};

export const getNumberOfDaysBetween = (d1: Date, d2: Date) => {
  const date1 = createCleanDate(new Date(d1.getTime()));
  const date2 = createCleanDate(new Date(d2.getTime()));

  return Math.round((date2.getTime() - date1.getTime()) / MILLI_DAY);
};

/**
 * Handy for when saving dates to backend since certain properties need to be converted to seconds (takes care of decimal values when deriving seconds)
 * Also useful when unit testing new Date() === new Date() since this would return false sometimes due to discrepancy in milliseconds .456 vs. .458 (probaby a delay when running test)
 * Shovon also brought up an alternate fix to the problem above, which would be to reference the new Date() that was called and pass it down
 * */
export const stripMillisecondsFromDate = (dateInput: Date): Date =>
  new Date(new Date(dateInput).setMilliseconds(0));

export const convertTimeToUnixTimestamp = (
  date: Date | number | null
): number => {
  // If date is null return current date in unix timestamp
  if (!date) {
    return Math.round(new Date().getTime() / 1000);
  }
  return Math.round(new Date(date).getTime() / 1000);
};

export const getDateXYearsFromNow = (x: number) => {
  const dateOneYearFromNow = new Date();
  return dateOneYearFromNow.setFullYear(dateOneYearFromNow.getFullYear() + x);
};

export const displayHermesDate = (date: string | number) => {
  const [year, month, day] = formatToHermesDate(new Date(date)).split("-");

  return `${month}/${day}/${year}`;
};

// PK: This takes "YYYY-MM-DD" and shows as "MM/DD/YYYY".
// This is the date string format we get back from Hermes
export const displayHyphenDateAsSlashDate = (date: string) => {
  const [y, m, d] = date.split("-");

  return `${m}/${d}/${y}`;
};

// PK: Takes a value 1609459200000 and returns 2021-01-01 rather than 2020-12-31
export const displayTimestampAsDateWithoutOffset = (
  date: number | string | Date
) => new Date(date).toISOString().split("T")[0];

export const dateStringToMillisecond = (dateString: string) =>
  new Date(dateString).getTime();

type ConstraintType = "min" | "max";

export const combineOrgAndUserLevelDateConstraint = (
  constraintType: ConstraintType,
  orgLevelConstraint: Date | undefined,
  userLevelConstraint: Date | undefined
): Date | undefined => {
  if (!orgLevelConstraint) {
    return userLevelConstraint;
  }

  if (!userLevelConstraint) {
    return orgLevelConstraint;
  }

  if (constraintType === "min") {
    /** In this case, dont use the user level date constraint if it's earlier than the org level date constraint */
    return getLatestDate([orgLevelConstraint, userLevelConstraint]);
  }

  if (constraintType === "max") {
    /** In this case, dont use the user level date constraint if it's later than the org level date constraint */
    return getEarliestDate([orgLevelConstraint, userLevelConstraint]);
  }

  throw new Error(`Invalid date constraint type: ${constraintType}`);
};

export const isDatePassed = (date: Date | undefined) =>
  Boolean(date && date < new Date());

export const getTenMinutesFromNow = (date = new Date()) =>
  new Date(date.getTime() + 10 * MILLI_MINUTE);

export const copyHoursAndMinutes = (date: Date, source: Date) => {
  date.setHours(source.getHours());
  date.setMinutes(source.getMinutes());
  date.setSeconds(source.getSeconds());
  return date;
};

/**
 * Determines if all of the passed-in dates are set to the same day.
 * @param { ...Date } dates: A variable number of Date objects to compare. Can be zero or more.
 * @returns True if all of the passed-in dates are the same day, false otherwise.
 */
export const isSameDay = (...dates: Date[]) => {
  if (!dates.length) return true;

  const dateFns = [
    (date: Date) => date.getDate(),
    (date: Date) => date.getMonth(),
    (date: Date) => date.getFullYear()
  ];

  return dates.every(date => dateFns.every(fn => fn(dates[0]) === fn(date)));
};

// expects date string in the format of YYYY-MM-DD
export const parseDateString = (date: string): Date => {
  // YYYY-MM-DD
  const dateRegex = /\d{4}-\d{2}-\d{2}$/;
  if (dateRegex.test(date)) {
    const [year, month, day] = date.split("-").map(Number);

    return new Date(year, month - 1, day, 0, 0, 0, 0);
  }

  return new Date(date);
};

/**
 * If the day for a date is changing, reset the time to the specified time.
 * @param oldDate: The previously date.
 * @param newDate The new date.
 * @param timeToResetTo: The time to reset to if the day is changing.
 * @returns A copy of the new date, with the time reset if the day is being changed.
 */
export const resetTimeIfChangingDay = (
  oldDate: Date | undefined,
  newDate: Date | undefined,
  dateWithTimeReset: Date
) => {
  const isChangingDay = !oldDate || (newDate && !isSameDay(oldDate, newDate));

  if (isChangingDay) {
    return dateWithTimeReset;
  }

  return newDate;
};
