import { sanitizeUserInput } from "@madhive/mad-sdk";
import readXlsxFile from "read-excel-file";
import { BulkCreativeAssetType, BulkXlsRow } from "./constants";
import { bulkGenerateCreativeIds, getIabCategoryIdFromCell } from "./utils";

enum XlsxErrorTypes {
  INVALID_COL_NAME = "invalidColumnName",
  REQUIRED_VALUE_MISSING = "requiredValueMissing",
  BAD_CELL_VALUE_TYPE = "badCellValueType"
}

interface XlsxError {
  rowNumber: number;
  columnNumber: number;
  type: XlsxErrorTypes;
}

const XlsColumnHeadingNames = {
  ASSET_FILE_NAME: "Asset File Name*",
  CDN_VAST_URL: "CDN Link / VAST Tag*",
  // Update this for linear VAST/CDN header title in the event it changes. Really need to clean up template discrepancy, see one line above and see one line below
  LINEAR_CDN_VAST_URL: "VAST Tag or CDN Link",
  CREATIVE_ID: "Creative ID*",
  CREATIVE_NAME: "Creative Name*",
  ISCI_CODE: "ISCI Code",
  IMPRESSION_PIXEL: "Third Party Tracking Pixel - Impression",
  CLICKTHROUGH_URL: "Clickthrough URL*",
  VIDEO_START_PIXEL: "Third Party Tracking Pixel - Start",
  FIRST_QUARTILE_PIXEL: "Third Party Tracking Pixel - Q1",
  MIDPOINT_PIXEL: "Third Party Tracking Pixel - Q2 (Midpoint)",
  THIRD_QUARTILE_PIXEL: "Third Party Tracking Pixel - Q3",
  VIDEO_COMPLETE_PIXEL: "Third Party Tracking Pixel - Q4 (Complete)",
  IAB_CATEGORY: "Category*"
};

const XlsColumnHeadingVariableNames = {
  CREATIVE_ID: "creativeId",
  ASSET_FILE_NAME: "assetFileName",
  CREATIVE_NAME: "creativeName",
  ISCI_CODE: "isciCode",
  IMPRESSION_PIXEL: "impressionPixel",
  CLICKTHROUGH_URL: "clickthroughUrl",
  VIDEO_START_PIXEL: "videoStartPixel",
  FIRST_QUARTILE_PIXEL: "firstQuartilePixel",
  MIDPOINT_PIXEL: "midpointPixel",
  THIRD_QUARTILE_PIXEL: "thirdQuartilePixel",
  VIDEO_COMPLETE_PIXEL: "videoCompletePixel",
  IAB_CATEGORY: "iabCategoryId"
};

const RequiredXlsFields: Record<string, string> = {
  CREATIVE_ID: XlsColumnHeadingVariableNames.CREATIVE_ID,
  ASSET_FILE_NAME: XlsColumnHeadingVariableNames.ASSET_FILE_NAME,
  CREATIVE_NAME: XlsColumnHeadingVariableNames.CREATIVE_NAME,
  CLICKTHROUGH_URL: XlsColumnHeadingVariableNames.CLICKTHROUGH_URL,
  IAB_CATEGORY: XlsColumnHeadingVariableNames.IAB_CATEGORY
};

const COLUMN_HEADING_TO_VARIABLE_NAME = {
  [XlsColumnHeadingNames.CREATIVE_ID]:
    XlsColumnHeadingVariableNames.CREATIVE_ID,
  [XlsColumnHeadingNames.CREATIVE_NAME]:
    XlsColumnHeadingVariableNames.CREATIVE_NAME,
  [XlsColumnHeadingNames.ISCI_CODE]: XlsColumnHeadingVariableNames.ISCI_CODE,
  [XlsColumnHeadingNames.IMPRESSION_PIXEL]:
    XlsColumnHeadingVariableNames.IMPRESSION_PIXEL,
  [XlsColumnHeadingNames.CLICKTHROUGH_URL]:
    XlsColumnHeadingVariableNames.CLICKTHROUGH_URL,
  [XlsColumnHeadingNames.VIDEO_START_PIXEL]:
    XlsColumnHeadingVariableNames.VIDEO_START_PIXEL,
  [XlsColumnHeadingNames.FIRST_QUARTILE_PIXEL]:
    XlsColumnHeadingVariableNames.FIRST_QUARTILE_PIXEL,
  [XlsColumnHeadingNames.MIDPOINT_PIXEL]:
    XlsColumnHeadingVariableNames.MIDPOINT_PIXEL,
  [XlsColumnHeadingNames.THIRD_QUARTILE_PIXEL]:
    XlsColumnHeadingVariableNames.THIRD_QUARTILE_PIXEL,
  [XlsColumnHeadingNames.VIDEO_COMPLETE_PIXEL]:
    XlsColumnHeadingVariableNames.VIDEO_COMPLETE_PIXEL
};

const VAST_CDN_COLUMN_HEADING_TO_VARIABLE_NAME = {
  ...COLUMN_HEADING_TO_VARIABLE_NAME,
  // to make things simpler, I'm coercing different column names (CDN Link / VAST Tag and Asset File Name) into one interface
  [XlsColumnHeadingNames.CDN_VAST_URL]:
    XlsColumnHeadingVariableNames.ASSET_FILE_NAME,
  [XlsColumnHeadingNames.IAB_CATEGORY]:
    XlsColumnHeadingVariableNames.IAB_CATEGORY
};

const DESKTOP_COLUMN_HEADING_TO_VARIABLE_NAME = {
  ...COLUMN_HEADING_TO_VARIABLE_NAME,
  [XlsColumnHeadingNames.ASSET_FILE_NAME]:
    XlsColumnHeadingVariableNames.ASSET_FILE_NAME,
  [XlsColumnHeadingNames.IAB_CATEGORY]:
    XlsColumnHeadingVariableNames.IAB_CATEGORY
};

const REQUIRED_VAST_CDN_FIELDS = new Set([
  XlsColumnHeadingVariableNames.CREATIVE_NAME,
  XlsColumnHeadingVariableNames.CLICKTHROUGH_URL,
  XlsColumnHeadingVariableNames.ASSET_FILE_NAME,
  XlsColumnHeadingVariableNames.IAB_CATEGORY
]);

const REQUIRED_DESKTOP_FIELDS = new Set([
  XlsColumnHeadingVariableNames.CREATIVE_NAME,
  XlsColumnHeadingVariableNames.CLICKTHROUGH_URL,
  XlsColumnHeadingVariableNames.ASSET_FILE_NAME,
  XlsColumnHeadingVariableNames.CREATIVE_ID,
  XlsColumnHeadingVariableNames.IAB_CATEGORY
]);

const XlsxErrorTypeToErrorMessage: Record<XlsxErrorTypes, string> = {
  [XlsxErrorTypes.INVALID_COL_NAME]:
    "The following cells have invalid column names",
  [XlsxErrorTypes.REQUIRED_VALUE_MISSING]:
    "The following cells cannot be empty",
  [XlsxErrorTypes.BAD_CELL_VALUE_TYPE]:
    "The following cells are not of type 'string'"
};

const isValidXlsParserResponse = (obj: any): obj is any[][] => {
  if (!Array.isArray(obj)) {
    return false;
  }
  let isValid = true;
  obj.forEach(row => {
    if (!Array.isArray(row)) {
      isValid = false;
    }
  });
  return isValid;
};

const isValidBulkXlsRow = (obj: any): obj is BulkXlsRow => {
  if (obj === null || typeof obj !== "object") {
    return false;
  }
  let valid = true;
  const requiredFields = RequiredXlsFields;
  Object.keys(requiredFields).forEach(xlsField => {
    if (
      !obj[requiredFields[xlsField]] ||
      typeof obj[requiredFields[xlsField]] !== "string"
    ) {
      valid = false;
    }
  });
  return valid;
};

//* * e.g. column 28 => 'AB' */
export const getExcelColumnNameFromNumber = (columnNumber: number) => {
  const charCodes = [];

  while (columnNumber > 0) {
    const letterCode = columnNumber % 26;
    charCodes.unshift(letterCode === 0 ? 26 : letterCode);
    // eslint-disable-next-line no-param-reassign
    columnNumber = Math.floor(
      columnNumber % 26 === 0 ? columnNumber / 26 - 1 : columnNumber / 26
    );
  }

  return charCodes.map(num => String.fromCharCode(num + 64)).join("");
};

//* * The purpose of this fn is to group all errors that have been generated during parsing and display them in a concise format. [errorCategory]: <list of cells with that error> */
const generateXlsxError = (errors: XlsxError[]) => {
  const groupedErrors = errors.reduce<
    Record<XlsxErrorTypes, Array<Omit<XlsxError, "type">>>
  >(
    (acc, err) => {
      const { rowNumber, columnNumber } = err;

      acc[err.type].push({ rowNumber, columnNumber });

      return acc;
    },
    {
      [XlsxErrorTypes.INVALID_COL_NAME]: [],
      [XlsxErrorTypes.REQUIRED_VALUE_MISSING]: [],
      [XlsxErrorTypes.BAD_CELL_VALUE_TYPE]: []
    }
  );
  return (
    Object.keys(groupedErrors)
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      .filter(errorType => !!groupedErrors[errorType].length)
      .reduce(
        (errorString, errorType) =>
          `${errorString} ${
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            XlsxErrorTypeToErrorMessage[errorType]
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          }: ${groupedErrors[errorType]
            .map(
              (el: Omit<XlsxError, "type">) =>
                `${getExcelColumnNameFromNumber(el.columnNumber)}${
                  el.rowNumber
                }`
            )
            .join(", ")}.`,
        ""
      )
      .trim()
  );
};

//* * We take the raw header row from the xls parser (any[]) and attempt to transform it to a mapping of the following type: { column index: column name }; so it's Record<number,string>. In a later func, we can use that column heading object to map all of the cells in the spreadsheet to the correct column heading using their row index. */
const makeRowHeadings = (
  row: any[],
  assetUploadType: BulkCreativeAssetType
) => {
  const seenColumnNames = new Set();
  const headingErrors: XlsxError[] = [];

  const columnNamesToIndexes = row.reduce<Record<number, string>>(
    (acc, el: any, idx) => {
      const error: XlsxError = {
        rowNumber: 1,
        columnNumber: idx + 1,
        type: XlsxErrorTypes.INVALID_COL_NAME
      };

      if (seenColumnNames.has(el)) {
        headingErrors.push(error);
        return acc;
      }

      seenColumnNames.add(el);

      let colName;

      if (assetUploadType === BulkCreativeAssetType.DESKTOP) {
        colName = DESKTOP_COLUMN_HEADING_TO_VARIABLE_NAME[el];
      } else {
        colName = VAST_CDN_COLUMN_HEADING_TO_VARIABLE_NAME[el];
      }

      if (!colName) {
        headingErrors.push(error);
        return acc;
      }
      if (typeof colName !== "string") {
        headingErrors.push({
          ...error,
          type: XlsxErrorTypes.BAD_CELL_VALUE_TYPE
        });
        return acc;
      }

      acc[idx] = colName;

      return acc;
    },
    {}
  );

  return { rowHeadings: columnNamesToIndexes, headingErrors };
};

//* * As stated above, here we use the column headings object (Record<columnIndexNumber,columnName>) to map the cells in each row to their proper column name. This results in a return type of (Record<columnName, columnValue>)[]. We leave that return type as type any[], and perform the validation (isValidBulkXlsRow) in the master parsing func, parseXlsRows. We also return an array of row errors so the user will be able to see all of their mistakes at once instead of piecemeal. */
const mapRowsToColumnHeadings = async (
  rows: any[][],
  headings: Record<number, string>,
  requiredFields: Set<string>,
  startRow: number
) => {
  const rowErrors: XlsxError[] = [];

  const parsedRows = await Promise.all(
    rows.map(async (row, rowIdx) =>
      row.reduce(async (newRow, cellValue, colIdx) => {
        const error: XlsxError = {
          rowNumber: rowIdx + startRow + 1,
          columnNumber: colIdx + 1,
          type: XlsxErrorTypes.REQUIRED_VALUE_MISSING
        };

        // n.b. since an async function returns a promise
        // ... we need to await the result before we can use it in subsequent iterations
        // eslint-disable-next-line no-param-reassign
        newRow = await newRow;

        if (!cellValue) {
          if (requiredFields.has(headings[colIdx])) {
            rowErrors.push(error);
          }
          return newRow;
        }

        if (typeof cellValue === "number") {
          // eslint-disable-next-line no-param-reassign
          cellValue = cellValue.toString();
        }

        if (typeof cellValue !== "string") {
          rowErrors.push({
            ...error,
            type: XlsxErrorTypes.BAD_CELL_VALUE_TYPE
          });
          return newRow;
        }

        // eslint-disable-next-line no-param-reassign
        newRow[headings[colIdx]] = sanitizeUserInput(cellValue);

        const isIabColumn =
          headings[colIdx] === XlsColumnHeadingVariableNames.IAB_CATEGORY;

        if (isIabColumn) {
          // eslint-disable-next-line no-param-reassign
          newRow[headings[colIdx]] = await getIabCategoryIdFromCell(
            newRow[headings[colIdx]]
          );
        }

        return newRow;
      }, {})
    )
  );

  return {
    parsedRows,
    rowErrors
  };
};

export const makeRows = async (
  rows: any[][],
  headerIndex: number,
  assetUploadType: BulkCreativeAssetType
) => {
  if (!rows[headerIndex]) {
    throw "Xlsx has invalid column headings";
  }

  const { rowHeadings, headingErrors } = makeRowHeadings(
    rows[headerIndex],
    assetUploadType
  );

  if (headingErrors.length) {
    return {
      parsedRows: [],
      errors: headingErrors
    };
  }

  // Is desktop upload OR is CDN/VAST
  const requiredFields =
    assetUploadType === BulkCreativeAssetType.DESKTOP
      ? REQUIRED_DESKTOP_FIELDS
      : REQUIRED_VAST_CDN_FIELDS;

  const { parsedRows, rowErrors } = await mapRowsToColumnHeadings(
    // start parsing from the row below the header row
    rows.slice(headerIndex + 1, rows.length),
    rowHeadings,
    requiredFields,
    headerIndex + 1
  );

  let finalizedRows;

  if (assetUploadType === BulkCreativeAssetType.CDN_VAST) {
    // Add creative ids to Vast/Cdn xlsx rows, which do not have them automatically populated by the encoder endpoint
    const creativeIds = await bulkGenerateCreativeIds(parsedRows.length);

    finalizedRows = parsedRows.map((row, idx) => {
      // eslint-disable-next-line no-param-reassign
      row[XlsColumnHeadingVariableNames.CREATIVE_ID] = creativeIds[idx];
      return row;
    });
  } else {
    finalizedRows = parsedRows;
  }

  return {
    parsedRows: finalizedRows,
    errors: rowErrors
  };
};

export const parseResponse = async (
  res: any,
  uploadType: BulkCreativeAssetType
) => {
  if (isValidXlsParserResponse(res)) {
    if (res.length === 0) {
      throw {
        parsingError: "Spreadsheet is empty"
      };
    }

    const { parsedRows, errors } = await makeRows(res, 1, uploadType);

    if (errors.length) {
      throw { parsingError: generateXlsxError(errors) };
    }

    if (!parsedRows.length) {
      throw {
        parsingError: "Spreadsheet is empty"
      };
    }

    const validatedRows = parsedRows.map((r, idx) => {
      if (!isValidBulkXlsRow(r)) {
        throw {
          parsingError: `row ${idx} of the spreadsheet is invalid`
        };
      }
      return r;
    });

    return validatedRows;
  }
  //* * Unable to give the export from 'read-excel-file' a type. If its return type is, for some reason, different from what we'd expect (any[][]), it's a black box problem.  */
  throw "unknown error";
};

export const parseXlsRows = async (
  xls: File,
  uploadType: BulkCreativeAssetType
): Promise<BulkXlsRow[]> =>
  readXlsxFile(xls)
    .then(async (res: any) => await parseResponse(res, uploadType))
    .catch((e: any) => {
      if (e.parsingError) {
        throw e.parsingError;
      } else {
        throw "Unknown error encountered parsing spreadsheet";
      }
    });
