import { css } from "@emotion/react";

export const styles = {
  wrapper: css`
    display: inline-flex;
    appearance: none;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: transparent solid 2px;
    outline-offset: 2px;
    line-height: var(--spacing-16);
    border-radius: var(--border-radii-base);
    transition: background-color 200ms;
    padding: var(--spacing-8) var(--spacing-16);
    border: 0px solid white;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-8);
    width: auto;
    background-color: var(--color-splash-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    color: var(--color-contrast);
    --icon-fill-color: var(--color-contrast);
    &:focus:not(:disabled) {
      background-color: var(--color-splash-tertiary);
    }
    &:hover:not(:disabled) {
      background-color: var(--color-splash-secondary);
    }
    &:disabled {
      background-color: var(--color-background-tertiary);
      pointer-events: none;
      color: var(--color-tertiary);
      --icon-fill-color: var(--color-tertiary);
    }
  `,
  loading: css`
    background-color: var(--color-background-tertiary);
    &:focus:not(:disabled) {
      background-color: var(--color-background-tertiary);
    }
    pointer-events: none;
    color: transparent;
    --icon-fill-color: transparent;
  `,
  loading_indicator: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--color-contrast);
    fill: var(--color-contrast);
    --icon-fill-color: var(--color-contrast);
  `,
  fill: css`
    width: 100%;
  `
};

// ghost styles are reused in ghostLink
const ghost = css`
  color: var(--color-splash-primary);
  --icon-fill-color: var(--color-splash-primary);
  background-color: transparent;
  padding: 0;
  &:focus:not(:disabled) {
    color: var(--color-splash-tertiary);
    --icon-fill-color: var(--color-splash-tertiary);
    background-color: transparent;
  }
  &:hover:not(:disabled) {
    color: var(--color-splash-secondary);
    --icon-fill-color: var(--color-splash-secondary);
    background-color: transparent;
  }
  &:disabled {
    color: var(--color-tertiary);
    --icon-fill-color: var(--color-tertiary);
    background-color: transparent;
  }
`;

/**
 * Base styles keyed by variant
 */
export const variantBase = {
  /* --- Variants --- */
  primary: css``,
  outline: css`
    padding-left: calc(var(--spacing-16) - 2px);
    padding-right: calc(var(--spacing-16) - 2px);
    padding-top: calc(var(--spacing-8) - 2px);
    padding-bottom: calc(var(--spacing-8) - 2px);
    border: 2px solid var(--color-splash-primary);
    color: var(--color-splash-primary);
    --icon-fill-color: var(--color-splash-primary);
    background-color: var(--color-background-primary);
    &:focus:not(:disabled) {
      color: var(--color-splash-primary);
      --icon-fill-color: var(--color-splash-primary);
      border-color: var(--color-splash-tertiary);
      background-color: var(--color-background-primary);
    }
    &:hover:not(:disabled) {
      background-color: var(--color-splash-secondary);
      color: var(--color-contrast);
      --icon-fill-color: var(--color-contrast);
    }
    &:disabled {
      color: var(--color-tertiary);
      --icon-fill-color: var(--color-tertiary);
      border-color: var(--color-border-secondary);
      background-color: var(--color-background-primary);
      pointer-events: none;
    }
  `,

  basic: css`
    padding-left: calc(var(--spacing-16) - 1px);
    padding-right: calc(var(--spacing-16) - 1px);
    padding-top: calc(var(--spacing-8) - 1px);
    padding-bottom: calc(var(--spacing-8) - 1px);
    border: 1px solid var(--color-border-primary);
    color: var(--color-primary);
    --icon-fill-color: var(--color-primary);
    background-color: var(--color-background-primary);
    &:focus:not(:disabled) {
      border-color: var(--color-border-primary);
      background-color: var(--color-background-primary);
    }
    &:hover:not(:disabled) {
      background-color: var(--color-background-tertiary);
    }
    &:disabled {
      border-color: var(--color-border-secondary);
      background-color: var(--color-background-tertiary);
    }
  `,
  ghost,

  ghostLink: css`
    ${ghost}
    font-weight: inherit;
    color: inherit;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    border-width: 1px 0 1px 0;
    border-color: transparent;
    &:hover:not(:disabled) {
      border-bottom-color: var(--color-splash-secondary);
    }
    &:focus:not(:disabled) {
      border-bottom-color: var(--color-splash-tertiary);
    }
  `
};

/**
 * Loading styles keyed by variant
 */
export const variantLoading = {
  primary: css``,
  basic: css`
    background-color: var(--color-background-tertiary);
    border-color: var(--color-border-secondary);
    &:focus:not(:disabled) {
      background-color: var(--color-background-tertiary);
      border-color: var(--color-border-secondary);
    }
  `,
  outline: css`
    background-color: var(--color-background-primary);
    border-color: var(--color-border-secondary);
    &:focus:not(:disabled) {
      background-color: var(--color-background-primary);
      border-color: var(--color-border-secondary);
    }
  `,
  ghostLink: css`
    color: inherit;
    &:hover {
      border-bottom: 1px solid var(--color-splash-primary);
      color: var(--color-splash-primary);
    }
  `,
  ghost: css`
    background-color: transparent;
    &:focus:not(:disabled) {
      background-color: transparent;
    }
  `
};
