import {
  ConditionallyIncludedResource,
  GeographicSelections,
  LogicalOperator,
  GeographicEntityTypes,
  GeoTargetsKeys
} from "@madhive/mad-sdk";
import { GeoTargets } from "campaign-types";
import { CountryToRegionToZipCodes } from "appReducers/geographyReducer";
import {
  IndividualRegion,
  ZipMappedToAllGeoIds,
  ZipMappedToAllGeos
} from "appReducers/geographyReducer/types";
import { cloneDeep, omit, capitalize } from "lodash";
import { NnyGeoObjectRaw } from "newnewyork";

export { GeographicEntityTypes, GeoTargetsKeys };

export const NO_DATA = "No Data";

export type FormattedGeoInfo = Record<
  GeographicEntityTypes,
  {
    availableEntities: IndividualRegion[];
    idToName: Record<string, string>;
  }
>;

//* * Keeping this deprecated enum because there are forecasts in firestore that use these keys */
export enum DeprecatedGeographicEntityTypes {
  COUNTRY = "Country",
  STATE = "State",
  DMA = "DMA",
  ZIP_CODE = "Zip Code"
}

export const GEO_ENTITY_TO_DISPLAY_NAME: Record<GeographicEntityTypes, string> =
  {
    [GeographicEntityTypes.COUNTRY]: "Country",
    [GeographicEntityTypes.STATE]: "State",
    [GeographicEntityTypes.DISTRICT]: "Congressional District",
    [GeographicEntityTypes.DMA]: "Metro",
    [GeographicEntityTypes.ZIP_CODE]: "Zip Code"
  };

export const GEO_LINEITEM_KEY_TO_GEO_ENTITY_KEY: Record<
  GeoTargetsKeys,
  GeographicEntityTypes
> = {
  [GeoTargetsKeys.COUNTRY]: GeographicEntityTypes.COUNTRY,
  [GeoTargetsKeys.STATE]: GeographicEntityTypes.STATE,
  [GeoTargetsKeys.DISTRICT]: GeographicEntityTypes.DISTRICT,
  [GeoTargetsKeys.DMA]: GeographicEntityTypes.DMA,
  [GeoTargetsKeys.ZIP_CODE]: GeographicEntityTypes.ZIP_CODE
};

export const GEO_ENTITY_KEY_TO_GEO_LINEITEM_KEY: Record<
  GeographicEntityTypes,
  GeoTargetsKeys
> = {
  [GeographicEntityTypes.COUNTRY]: GeoTargetsKeys.COUNTRY,
  [GeographicEntityTypes.STATE]: GeoTargetsKeys.STATE,
  [GeographicEntityTypes.DISTRICT]: GeoTargetsKeys.DISTRICT,
  [GeographicEntityTypes.DMA]: GeoTargetsKeys.DMA,
  [GeographicEntityTypes.ZIP_CODE]: GeoTargetsKeys.ZIP_CODE
};

export const GEO_ENTITY_TO_POLYGON_ENDPOINT: Record<
  GeographicEntityTypes,
  string
> = {
  [GeographicEntityTypes.COUNTRY]: "geo_us_border",
  [GeographicEntityTypes.STATE]: "geo_states",
  [GeographicEntityTypes.DISTRICT]: "geo_congress_district",
  [GeographicEntityTypes.DMA]: "geo_dmas",
  [GeographicEntityTypes.ZIP_CODE]: "geo_zips"
};

export const GEO_ENTITY_TO_POSTALS_ENDPOINT_KEY: Record<
  GeographicEntityTypes,
  string
> = {
  [GeographicEntityTypes.COUNTRY]: "country",
  [GeographicEntityTypes.STATE]: "regions",
  [GeographicEntityTypes.DISTRICT]: "congressional_districts",
  [GeographicEntityTypes.DMA]: "dmas",
  [GeographicEntityTypes.ZIP_CODE]: "postal_code"
};

// this is for the tolerance param of the polygons endpoint. Tolerance can be between 0 and 100 inclusive; 100 = maximum payload size reduction, 0 = no reduction
export const GEO_ENTITY_TO_TOLERANCE_FLOOR: Record<
  GeographicEntityTypes,
  number
> = {
  [GeographicEntityTypes.COUNTRY]: 20,
  [GeographicEntityTypes.STATE]: 70,
  [GeographicEntityTypes.DISTRICT]: 99,
  [GeographicEntityTypes.DMA]: 20,
  [GeographicEntityTypes.ZIP_CODE]: 99
};

export const emptyGeographyObject = <T>(
  valueType: T
): Record<GeographicEntityTypes, T> => ({
  [GeographicEntityTypes.COUNTRY]: cloneDeep(valueType),
  [GeographicEntityTypes.STATE]: cloneDeep(valueType),
  [GeographicEntityTypes.DISTRICT]: cloneDeep(valueType),
  [GeographicEntityTypes.DMA]: cloneDeep(valueType),
  [GeographicEntityTypes.ZIP_CODE]: cloneDeep(valueType)
});

export const emptyNnyGeographyObject = (): GeoTargets => ({
  [GeoTargetsKeys.COUNTRY]: "",
  [GeoTargetsKeys.STATE]: [],
  [GeoTargetsKeys.DISTRICT]: [],
  [GeoTargetsKeys.DMA]: [],
  [GeoTargetsKeys.ZIP_CODE]: []
});

export const KNOWN_COUNTRY_CODES = {
  US: "US",
  CA: "CA",
  IT: "IT"
};

export const KNOWN_COUNTRY_CODES_ARRAY = Object.keys(KNOWN_COUNTRY_CODES);

export const filterIncompletegeoData = (data: NnyGeoObjectRaw) =>
  data.country_code &&
  data.postal_code &&
  /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
  KNOWN_COUNTRY_CODES[data.country_code];

export const transformGeoData = (
  data: NnyGeoObjectRaw[]
): ZipMappedToAllGeos[] =>
  data.map(datum =>
    Object.values(GeographicEntityTypes).reduce<ZipMappedToAllGeos>(
      (acc, geoType: GeographicEntityTypes) => {
        switch (geoType) {
          case GeographicEntityTypes.COUNTRY:
            acc[GeographicEntityTypes.COUNTRY] = [
              {
                name: datum.country_name,
                id: datum.country_code
              }
            ];
            break;
          case GeographicEntityTypes.STATE:
            acc[GeographicEntityTypes.STATE] = datum.regions.length
              ? datum.regions.map(reg => ({
                  name: reg.region_name,
                  id: reg.region_code
                }))
              : [{ name: NO_DATA, id: NO_DATA }];
            break;
          case GeographicEntityTypes.DMA:
            acc[GeographicEntityTypes.DMA] = datum.dmas.length
              ? datum.dmas.map(reg => ({
                  name: reg.dma_name,
                  id: reg.dma_code.toString()
                }))
              : [{ name: NO_DATA, id: NO_DATA }];
            break;
          case GeographicEntityTypes.DISTRICT:
            acc[GeographicEntityTypes.DISTRICT] = datum.congressional_districts
              .length
              ? datum.congressional_districts.map(reg => ({
                  name: [
                    reg.state_code || "Unknown Region",
                    reg.district_name || "Unknown District"
                  ].join(" "),
                  id: reg.district_id
                }))
              : [{ name: NO_DATA, id: NO_DATA }];
            break;
          case GeographicEntityTypes.ZIP_CODE:
            acc[GeographicEntityTypes.ZIP_CODE] = [
              {
                name: datum.postal_code,
                id: datum.postal_code
              }
            ];
            break;
          default:
            break;
        }
        return acc;
      },
      emptyGeographyObject([])
    )
  );

export const formatGeographyForGeoMultiSelect = (
  flatGeoData: ZipMappedToAllGeos[],
  includedCountryCodes?: string[],
  excludedCountryCodesForTopLevel: string[] = []
): FormattedGeoInfo => {
  const seenIds = emptyGeographyObject(new Set<string>());

  const countriesAvailableAsCountryOnly = omit(
    KNOWN_COUNTRY_CODES,
    excludedCountryCodesForTopLevel
  );

  return flatGeoData.reduce<FormattedGeoInfo>(
    (acc, geosAssociatedWithZip) => {
      const country = geosAssociatedWithZip.country[0];
      const countryCode = country.id;

      const shouldExcludeContainedGeoEntities =
        includedCountryCodes &&
        includedCountryCodes.length &&
        !includedCountryCodes.includes(countryCode);

      if (shouldExcludeContainedGeoEntities) {
        if (
          !seenIds.country.has(countryCode) &&
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          countriesAvailableAsCountryOnly[countryCode]
        ) {
          // TB: If we want to maintain all countries at the top level of our formatted data (e.g. returnvalue.country.availableEntities) despite their enclosed regions being excluded, add them here:
          acc.country.availableEntities.push(country);
          acc.country.idToName[country.id] = country.name;
          seenIds.country.add(countryCode);
        }
        return acc;
      }

      Object.keys(geosAssociatedWithZip).forEach(
        (geoType: GeographicEntityTypes) => {
          const geoEntities = geosAssociatedWithZip[geoType];

          geoEntities.forEach(geo => {
            if (!seenIds[geoType].has(geo.id)) {
              acc[geoType].availableEntities.push(geo);
              acc[geoType].idToName[geo.id] = geo.name;
              seenIds[geoType].add(geo.id);
            }
          });
        }
      );

      return acc;
    },
    emptyGeographyObject({ availableEntities: [], idToName: {} })
  );
};

export const formatGeographyForGeoSelectionComponent = (
  flatGeoData: ZipMappedToAllGeos[]
): Record<string, FormattedGeoInfo> => {
  const seenIds: Record<
    string,
    Record<GeographicEntityTypes, Set<string>>
  > = {};

  return flatGeoData.reduce<Record<string, FormattedGeoInfo>>(
    (acc, geosAssociatedWithZip) => {
      const country = geosAssociatedWithZip.country[0];
      const countryCode = country.id;

      if (!acc[countryCode]) {
        acc[countryCode] = emptyGeographyObject({
          availableEntities: [],
          idToName: {}
        });
      }

      if (!seenIds[countryCode]) {
        seenIds[countryCode] = emptyGeographyObject(new Set<string>());
      }

      Object.keys(geosAssociatedWithZip).forEach(
        (geoType: GeographicEntityTypes) => {
          const geoEntities = geosAssociatedWithZip[geoType];

          geoEntities.forEach(geo => {
            if (!seenIds[countryCode][geoType].has(geo.id)) {
              acc[countryCode][geoType].availableEntities.push(geo);
              acc[countryCode][geoType].idToName[geo.id] = geo.name;
              seenIds[countryCode][geoType].add(geo.id);
            }
          });
        }
      );

      return acc;
    },
    {}
  );
};

export const formatGeoAsRegionToZipCodes = (
  flatGeoData: ZipMappedToAllGeos[]
): CountryToRegionToZipCodes =>
  flatGeoData.reduce<CountryToRegionToZipCodes>((mappings, geoGroup) => {
    const country = geoGroup.country[0];
    const countryCode = country.id;

    if (!mappings[countryCode]) {
      // eslint-disable-next-line no-param-reassign
      mappings[countryCode] = emptyGeographyObject({});
    }

    Object.keys(geoGroup).forEach((geoType: GeographicEntityTypes) => {
      // the zipCode property of this object will always be an array of length 1
      const zipCode = geoGroup.zipCode[0].id;
      const geoEntities = geoGroup[geoType];

      geoEntities.forEach(geo => {
        if (mappings[countryCode][geoType][geo.id]) {
          mappings[countryCode][geoType][geo.id].push(zipCode);
        } else {
          // eslint-disable-next-line no-param-reassign
          mappings[countryCode][geoType][geo.id] = [zipCode];
        }
      });
    });

    return mappings;
  }, {});

export const formatZipToAllGeoCodes = (
  flatGeoData: ZipMappedToAllGeos[]
): ZipMappedToAllGeoIds =>
  flatGeoData.reduce<ZipMappedToAllGeoIds>((acc, geoGroup) => {
    const zipCode = geoGroup.zipCode[0].id;

    acc[zipCode] = Object.keys(geoGroup).reduce((group, geoType) => {
      /* eslint-disable no-param-reassign */
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      group[geoType] = geoGroup[geoType].map((geo: IndividualRegion) => geo.id);
      /* eslint-enable no-param-reassign */
      return group;
    }, emptyGeographyObject([]));

    return acc;
  }, {});

export const deriveIsCountryTargeted = (
  geoSelections: Partial<GeographicSelections>
) =>
  Object.keys(geoSelections).reduce((isCountryTargeted, geoEntityType) => {
    if (geoEntityType === GeographicEntityTypes.COUNTRY)
      return isCountryTargeted;

    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    const geoSelection = geoSelections[geoEntityType];
    const isEntityIncluded = !!(
      geoSelection?.values.length &&
      geoSelection?.logicalOperator === LogicalOperator.INCLUDE
    );

    if (!isCountryTargeted) return false;

    return !isEntityIncluded;
  }, true);

export const generateGeoNames = (
  geoSelections: GeographicSelections,
  geoMetadata: Record<string, FormattedGeoInfo>
): Record<LogicalOperator, string> => {
  const boundingCountry = geoSelections.country.values[0]
    ? geoSelections.country.values[0].id
    : KNOWN_COUNTRY_CODES.US;

  return Object.keys(geoSelections).reduce(
    (acc, geoEntityType: GeographicEntityTypes) => {
      if (
        geoEntityType === GeographicEntityTypes.COUNTRY &&
        !deriveIsCountryTargeted(geoSelections)
      ) {
        return acc;
      }

      const selectionForType: ConditionallyIncludedResource<{
        id: string;
        name: string | null;
      }> = geoSelections[geoEntityType];

      if (!selectionForType) return acc;

      if (!selectionForType.values.length) return acc;

      const amountToShow = selectionForType.values.length === 3 ? 3 : 2;

      const extraToShow = selectionForType.values.slice(amountToShow).length;

      const placeNames: string[] = selectionForType.values
        .slice(0, amountToShow)
        .map(
          ({ id }) => geoMetadata[boundingCountry][geoEntityType].idToName[id]
        );

      const shouldAddToString =
        !!(
          acc[selectionForType.logicalOperator] &&
          acc[selectionForType.logicalOperator].length
        ) || false;

      acc[selectionForType.logicalOperator] += `${
        shouldAddToString ? ", " : ""
      }${capitalize(
        GEO_ENTITY_TO_DISPLAY_NAME[geoEntityType].toLocaleLowerCase()
      )}: ${placeNames.join(", ")}${
        extraToShow ? ` and ${extraToShow} more` : ""
      }`;

      return acc;
    },
    {
      [LogicalOperator.INCLUDE]: "",
      [LogicalOperator.EXCLUDE]: ""
    }
  );
};

export const idsToSimpleIncludeTargeting = (
  geoIds: string[],
  geoType: GeographicEntityTypes
): Partial<GeographicSelections> => ({
  [geoType]: {
    values: geoIds.map(id => ({
      id,
      name: null
    })),
    logicalOperator: LogicalOperator.INCLUDE
  }
});

export const isGeoSelectionEmpty = (selections: GeographicSelections) =>
  !Object.keys(selections).some(
    (geoType: GeographicEntityTypes) =>
      selections[geoType].logicalOperator === LogicalOperator.INCLUDE &&
      selections[geoType].values.length > 0
  );

export const makeEmptyGeoSelection = (): GeographicSelections =>
  Object.values(GeographicEntityTypes).reduce(
    (empty, geoType: GeographicEntityTypes) => {
      // eslint-disable-next-line no-param-reassign
      empty[geoType] = {
        values: [],
        logicalOperator: LogicalOperator.INCLUDE
      };

      return empty;
    },
    {} as GeographicSelections
  );

export const isMissingCountryFromTargeting = (
  selections: Partial<GeographicSelections>
) =>
  !selections?.country?.values.length &&
  !!(selections?.state?.values.length || selections?.zipCode?.values.length);
