export const CLASS_NAME = {
  DROP_ZONE: "kit-ddr-DropZone",
  DROP_ZONE_HOVER: "kit-ddr-DropZone-Hover",
  DRAGGABLE: "kit-ddr-Draggable",
  DRAGGABLE_DRAGGING: "kit-ddr-Draggable-Dragging",
  CONTAINS_DRAGGING: "kit-ddr-Contains-Dragging"
};

export const CSS_VAR = {
  GAP: "--kit-ddr-gap"
};

export const DRAG_INDEX_NAME = "dragIndex";

export enum DROP_POSITION {
  TOP,
  BOTTOM
}
