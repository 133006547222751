import { FILLED_DAYPARTS } from "components/SmithersDayparter/SmithersDayparter";
import { pivotMetricsOptions } from "features/OOH/Planning/constants";
import {
  DaypartMetrics,
  InventoryDimension,
  InventoryMetrics
} from "features/OOH/Planning/generated-types";
import { LogicalOperator } from "lib/constants/misc";
import {
  GET_OOH_FOOTTRAFFIC_PENDING,
  GET_OOH_FOOTTRAFFIC_SUCCESS,
  GET_OOH_FOOTTRAFFIC_FAILURE,
  OohState,
  OohActionTypes,
  RESET_PLAN_CONSTRAINTS,
  SET_ACTIVE_SCENARIO,
  SET_PLAN_CONSTRAINT,
  SET_LIST_FILTER,
  SET_PLAN_FILTER
} from "./types";

const initialConstraintsState = {
  maxCPM: null,
  venues: [],
  publishers: [],
  poi: [],
  poiRadius: 0.25,
  bundles: [],
  sizes: [],

  venuesOperator: LogicalOperator.INCLUDE,
  publishersOperator: LogicalOperator.INCLUDE,
  poiOperator: LogicalOperator.INCLUDE,
  bundlesOperator: LogicalOperator.INCLUDE,
  sizesOperator: LogicalOperator.INCLUDE,

  showDayparts: false,
  dayparts: FILLED_DAYPARTS,
  daypartsOperator: LogicalOperator.INCLUDE
};
export const initialState: OohState = {
  isLoading: false,
  error: null,

  footTraffic: undefined,
  footTrafficIsLoading: false,
  footTrafficError: null,

  activeScenarioId: null,
  constraints: initialConstraintsState,

  listFilters: {
    selectedDateRange: {
      startDate: null,
      endDate: null
    },
    audiences: [],
    statuses: new Set(),
    search: ""
  },

  planFilters: {
    datatable: {
      dimension: undefined,
      metrics: pivotMetricsOptions.map(option => option.id)
    },
    heatmap: {
      showAudienceConcentration: false,
      showInventory: true,
      poiCategories: []
    },
    barchart: {
      dimension: InventoryDimension.Venue,
      metric: InventoryMetrics.TotalImpressions
    },
    bubblechart: {
      metric: DaypartMetrics.Impressions
    },
    scatterplot: {
      dimension: InventoryDimension.Venue,
      x: InventoryMetrics.TotalImpressions,
      y: InventoryMetrics.AudienceComposition,
      z: InventoryMetrics.TotalAdUnits
    }
  }
};

export const oohReducer = (
  state = initialState,
  action: OohActionTypes
): OohState => {
  switch (action.type) {
    case GET_OOH_FOOTTRAFFIC_PENDING:
      return {
        ...state,
        footTrafficIsLoading: true
      };
    case GET_OOH_FOOTTRAFFIC_SUCCESS: {
      return {
        ...state,
        footTraffic: action.payload,
        footTrafficIsLoading: false,
        footTrafficError: null
      };
    }
    case GET_OOH_FOOTTRAFFIC_FAILURE:
      return {
        ...state,
        footTrafficIsLoading: false,
        footTrafficError: action.meta.error.message
      };

    case SET_PLAN_CONSTRAINT:
      return {
        ...state,
        constraints: {
          ...state.constraints,
          [action.prop]: action.value
        }
      };
    case SET_ACTIVE_SCENARIO:
      return {
        ...state,
        activeScenarioId: action.activeScenarioId,
        constraints: {
          ...initialConstraintsState,
          ...action.constraints
        }
      };
    case RESET_PLAN_CONSTRAINTS:
      return {
        ...state,
        activeScenarioId: null,
        constraints: initialConstraintsState
      };
    case SET_LIST_FILTER:
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          [action.prop]: action.value
        }
      };
    case SET_PLAN_FILTER:
      return {
        ...state,
        planFilters: {
          ...state.planFilters,
          [action.card]: {
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            ...state.planFilters[action.card],
            [action.prop]: action.value
          }
        }
      };

    default:
      return state;
  }
};
