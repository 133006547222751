import { FC, ReactElement, useMemo, useState } from "react";
import { TableRowType, TableRowsProps, NestedRowsOnTable } from "../..";
import { TableRowActionDef } from "../../types";
import { defaultLookupId, lookupElementsByDisplayName } from "../../../utils";
import { IdType, DoNotCare } from "../../../types";
import { TableNestedRows } from "../../CompositionalProps";

export const isPropsWithNestedRows = (
  props: Pick<TableRowsProps<DoNotCare, string>, "nestedRows" | "children">
) =>
  !!props.nestedRows ||
  !!lookupElementsByDisplayName("Table.NestedRows", props.children)?.length;

/**
 * Used to lookup props `nestedRows` on Table.
 * 1. looks in `children` prop
 * 2. if no `children` override
 *    - look in `nestedRows` prop
 * 3. if nested rows props found
 *    - return with defaults
 */
const getNestedRowsProps = <Row extends TableRowType>({
  nestedRows,
  children,
  columns: _columns,
  rowTestId: _rowTestId
}: TableRowsProps<Row, DoNotCare, DoNotCare>):
  | NestedRowsOnTable<Row, DoNotCare, DoNotCare>
  | undefined => {
  let nestedRowsComponent: ReactElement<
    NestedRowsOnTable<TableRowType, DoNotCare, DoNotCare>
  > = lookupElementsByDisplayName("Table.NestedRows", children)[0];
  if (!nestedRowsComponent && nestedRows && typeof nestedRows === "object") {
    nestedRowsComponent = nestedRows;
  }
  if (!nestedRowsComponent) {
    return undefined;
  }

  const {
    props: {
      rowTestId = `nested-${_rowTestId}`,
      columns = _columns,
      ...nestedRowsProps
    }
  } = nestedRowsComponent;

  const columnDefinitions = {
    ...(_columns?.definitions || {}),
    ...(columns?.definitions || {})
  };

  return {
    rowTestId,
    columns: {
      orderedKeys: _columns?.orderedKeys,
      definitions: columnDefinitions
    } as NestedRowsOnTable<Row, DoNotCare, DoNotCare>["columns"],
    ...nestedRowsProps
  };
};

/**
 * Higher Order Component that adds support for handling nested rows.
 * @param WrappedTableRows The kit Table component to wrap.
 * @returns A Table component that manages local state of nested rows,
 * and derives values from `nestedRows` prop and `Table.NestedRows` in `children`
 */
export function withNestedRows<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTableRows: FC<TableRowsProps<Row, Column, Action>>) {
  /**
   * TableRows that provides support for handling nested rows
   * - locally manages sate of expanded rows
   * - derives state of nested rows from provided props and local state
   * - passes derived results for `nestedRows` to `WrappedTable`
   */
  return function TableRows(props: TableRowsProps<Row, Column, Action>) {
    const [expandedRowIds, setExpandedRowIds] = useState<Set<IdType>>(
      new Set()
    );
    const {
      nestedRows: _nestedRows,
      children,
      lookupRowId = defaultLookupId
    } = props;

    const nestedRowsProps = useMemo(
      () => getNestedRowsProps(props),
      [_nestedRows, children, expandedRowIds]
    );

    if (!nestedRowsProps) {
      return <WrappedTableRows {...props} />;
    }

    const isExpanded = (row: Row) => {
      const isExpanded = nestedRowsProps.isExpanded?.(row);
      if (typeof isExpanded === "boolean") {
        return isExpanded;
      }
      return expandedRowIds.has(lookupRowId(row));
    };

    const onToggle = (row: Row) => {
      const rowId = lookupRowId(row);
      const isExpanded = expandedRowIds.has(rowId);
      if (isExpanded) {
        expandedRowIds.delete(rowId);
      } else {
        expandedRowIds.add(rowId);
      }
      setExpandedRowIds(new Set([...expandedRowIds]));
      nestedRowsProps.onToggle?.(row, !isExpanded);
    };

    return (
      <WrappedTableRows
        {...props}
        nestedRows={
          <TableNestedRows
            {...nestedRowsProps}
            isExpanded={isExpanded}
            onToggle={onToggle}
          />
        }
      />
    );
  };
}
