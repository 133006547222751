import {
  CreativeLegacy,
  UploadedFileResultsLegacy,
  MediaType,
  MIME_TO_GCP_EXTENSION_LEGACY,
  ServiceStatus,
  Advertiser,
  ClientDeviceNames,
  AssetSpecificationFailuresLegacy,
  KnownCreativeAssetSpecificationId
} from "@madhive/mad-sdk";
import { CREATIVE_ASSET_FOLDER_BASE_URL } from "api/constants";
import { AssetDurationThresholds, TrackerType } from "lib/constants/creatives";
import { ArchiveFilter } from "lib/constants/filters";
import { FormattedDataWithCategory, ServingStatus } from "types";
import {
  AudioStandardsStatus,
  CreativeArchiveStatus,
  CreativeLibraryDurationOperators,
  creativeLibraryDurationValidation,
  CreativeLibraryFilterCategory,
  InscapeStatus,
  ServiceMediaTypeLegacy,
  VideoStandardsStatus
} from "./constants";
import { CreativeLibraryTabId } from "./ExistingCreativesScreen/constants";
import { CreativeTypeOptions } from "./ExistingCreativesScreen/FiltersDrawer";

export const getVideoDimensions = async (
  assetSrc: string
): Promise<{ videoHeight: number; videoWidth: number; duration: number }> => {
  const video = document.createElement("video");
  video.src = assetSrc;

  return new Promise((res, rej) => {
    video.addEventListener("loadedmetadata", ev => {
      if (
        ev.target &&
        (ev.target as HTMLVideoElement).videoHeight &&
        (ev.target as HTMLVideoElement).videoWidth
      ) {
        const { videoHeight, videoWidth, duration } =
          ev.target as HTMLVideoElement;
        return res({ videoHeight, videoWidth, duration });
      }
      rej("Failed to get video dimensions");
    });
  });
};

export enum TrackerInputs {
  IMPRESSION = "Impression",
  CLICK_THROUGH = "Click Through",
  CLICK_TRACKER = "Click Tracker",
  VIDEO_START = "Video Start",
  AUDIO_START = "Audio Start",
  FIRST_QUARTILE = "First Quartile",
  MIDPOINT = "Midpoint",
  THIRD_QUARTILE = "Third Quartile",
  VIDEO_COMPLETE = "Video Complete",
  AUDIO_COMPLETE = "Audio Complete"
}

const COMMON_TRACKERS = {
  [TrackerType.IMPRESSION]: TrackerInputs.IMPRESSION,
  [TrackerType.START]: TrackerInputs.VIDEO_START,
  [TrackerType.Q1]: TrackerInputs.FIRST_QUARTILE,
  [TrackerType.Q2]: TrackerInputs.MIDPOINT,
  [TrackerType.Q3]: TrackerInputs.THIRD_QUARTILE,
  [TrackerType.Q4]: TrackerInputs.VIDEO_COMPLETE
};

export const TRACKER_TYPE_TO_VIDEO_LABEL_MAP = {
  ...COMMON_TRACKERS,
  [TrackerType.CLICK_TRACKER]: TrackerInputs.CLICK_TRACKER,
  [TrackerType.CLICK_THROUGH]: TrackerInputs.CLICK_THROUGH
};

export const TRACKER_TYPE_TO_AUDIO_LABEL_MAP = {
  ...COMMON_TRACKERS,
  [TrackerType.START]: TrackerInputs.AUDIO_START,
  [TrackerType.Q4]: TrackerInputs.AUDIO_COMPLETE
};

// PK: Seems like we were incorrectly setting the asset url for VAST
// TODO: Figure out if we should pull the asset from XML to show (currently not in design, and if so which quality)
// If not would a blank url suffice so we don't get more 403s?
export const deriveCreativeAssetUrlFromCreative = (
  creative: CreativeLegacy
) => {
  const extension =
    MIME_TO_GCP_EXTENSION_LEGACY[
      creative.validationDetails?.assetMetadata.mime || 0
    ];
  const isVast = !!creative.vastUrl;
  const derivedUrl = isVast
    ? ""
    : `${CREATIVE_ASSET_FOLDER_BASE_URL}/${creative.id}/master${extension}`;

  return derivedUrl;
};

export const calculateAppliedCreativeLibraryFilters = (
  appliedFilters: FormattedDataWithCategory[]
) => {
  const filters: Record<
    CreativeLibraryFilterCategory,
    Array<FormattedDataWithCategory>
  > = {
    [CreativeLibraryFilterCategory.ADVERTISER]: [],
    [CreativeLibraryFilterCategory.CATEGORY]: [],
    [CreativeLibraryFilterCategory.DURATION]: [],
    [CreativeLibraryFilterCategory.SECONDARY_DURATION]: [],
    [CreativeLibraryFilterCategory.INSCAPE_STATUS]: [],
    [CreativeLibraryFilterCategory.CREATIVE_TYPE]: [],
    [CreativeLibraryFilterCategory.ARCHIVED_STATUS]: [],
    [CreativeLibraryFilterCategory.VIDEO_STANDARD]: [],
    [CreativeLibraryFilterCategory.PUBLISHER]: [],
    [CreativeLibraryFilterCategory.PUBLISHER_STATUS]: []
  };

  appliedFilters.forEach(filter => {
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    if (filters[filter.category]) {
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      filters[filter.category].push(filter);
    }
  });

  return {
    selectedAdvertisers: filters[CreativeLibraryFilterCategory.ADVERTISER],
    selectedCategories: filters[CreativeLibraryFilterCategory.CATEGORY],
    selectedDuration: filters[CreativeLibraryFilterCategory.DURATION],
    selectedSecondaryDuration:
      filters[CreativeLibraryFilterCategory.SECONDARY_DURATION],
    selectedInscapeStatus:
      filters[CreativeLibraryFilterCategory.INSCAPE_STATUS],
    selectedCreativeType: filters[CreativeLibraryFilterCategory.CREATIVE_TYPE],
    selectedArchivedStatus:
      filters[CreativeLibraryFilterCategory.ARCHIVED_STATUS],
    selectedVideoStandards:
      filters[CreativeLibraryFilterCategory.VIDEO_STANDARD],
    selectedPublisher: filters[CreativeLibraryFilterCategory.PUBLISHER],
    selectedPublisherStatus:
      filters[CreativeLibraryFilterCategory.PUBLISHER_STATUS]
  };
};

export const groupFilters = (
  appliedFilters: FormattedDataWithCategory[]
): Array<[string, Set<string>]> =>
  Object.entries(
    appliedFilters.reduce((acc, filter) => {
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      acc[filter.category]
        ? /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          acc[filter.category].add(filter.value)
        : /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          (acc[filter.category] = new Set([filter.value]));
      return acc;
    }, {})
  );

export const applyFiltersToCreatives = (
  unfilteredCreatives: CreativeLegacy[],
  filtersGrouped: Array<[string, Set<string>]>,
  durationOperator: CreativeLibraryDurationOperators | undefined,
  category?: CreativeLibraryFilterCategory
) =>
  unfilteredCreatives.filter(creative => {
    const shouldBeAvailable = !filtersGrouped.some(
      ([filterCategory, filterValues]) => {
        if (!category || filterCategory !== category) {
          switch (filterCategory) {
            case CreativeLibraryFilterCategory.ARCHIVED_STATUS:
              if (
                filterValues.has(ArchiveFilter.ARCHIVED) &&
                creative.status !== ServiceStatus.ARCHIVED
              )
                return true;
              if (
                filterValues.has(ArchiveFilter.ACTIVE) &&
                creative.status === ServiceStatus.ARCHIVED
              )
                return true;
              break;
            case CreativeLibraryFilterCategory.ADVERTISER:
              if (!filterValues.has(creative.advertiserId)) {
                return true;
              }
              break;
            case CreativeLibraryFilterCategory.DURATION:
              if (
                durationOperator &&
                !creativeLibraryDurationValidation[durationOperator](
                  creative.validationDetails?.assetMetadata.durationInSeconds ||
                    0,
                  [...filterValues][0]
                )
              ) {
                return true;
              }
              break;
            case CreativeLibraryFilterCategory.SECONDARY_DURATION:
              if (
                durationOperator ===
                  CreativeLibraryDurationOperators.IS_BETWEEN &&
                parseInt([...filterValues][0], 10) <
                  (creative.validationDetails?.assetMetadata
                    .durationInSeconds || 0)
              ) {
                return true;
              }
              break;
            case CreativeLibraryFilterCategory.CREATIVE_TYPE:
              const creativeType =
                creative.inscapeStatus === InscapeStatus.SUCCESS
                  ? CreativeTypeOptions.VIDEO_AND_LINEAR
                  : CreativeTypeOptions.VIDEO;
              // Check to see if creative has this type
              if (!filterValues.has(creativeType)) {
                return true;
              }
              break;
            case CreativeLibraryFilterCategory.INSCAPE_STATUS:
              if (
                creative.inscapeStatus &&
                !filterValues.has(creative.inscapeStatus)
              ) {
                return true;
              }
              break;
            default:
              return false;
          }
          return false;
        }
        return false;
      }
    );

    return shouldBeAvailable;
  });

//* * Duration thresholds are fetched from the /spec endpoint, and are almost always going to 15s, 30s, and 60s. This function returns which of these thresholds a video has violated. Only call this function on videos that have failed validateAssetDurationInSeconds. */
export const getDurationThresholdExceeded = (
  durationSpecifications: number[],
  creativeWithDurationOutofBounds: CreativeLegacy | UploadedFileResultsLegacy
): number => {
  const durationExceeded: number | undefined = durationSpecifications
    .sort((a, b) => (a < b ? 1 : -1))
    .find(acceptableDuration => {
      const seconds =
        creativeWithDurationOutofBounds.validationDetails?.assetMetadata
          .durationInSeconds || 0;
      return seconds > acceptableDuration + AssetDurationThresholds.UPPER;
    });

  return durationExceeded || 0;
};

export const hasError = (errors: AssetSpecificationFailuresLegacy) => {
  return Object.values(errors).some(error => error === false);
};

export const getSpecErrors = (
  madErrors?: AssetSpecificationFailuresLegacy,
  orgSpec?: KnownCreativeAssetSpecificationId,
  orgErrors?: AssetSpecificationFailuresLegacy
) => {
  return {
    ...{
      [KnownCreativeAssetSpecificationId.BASIC]: madErrors // madhive spec errors
        ? hasError(madErrors)
        : undefined
    },
    ...(orgErrors && {
      [orgSpec as KnownCreativeAssetSpecificationId]: orgErrors
        ? hasError(orgErrors)
        : undefined
    })
  };
};

export const getAllErrors = (
  madErrors?: AssetSpecificationFailuresLegacy,
  orgSpec?: KnownCreativeAssetSpecificationId,
  orgErrors?: AssetSpecificationFailuresLegacy
) => {
  // gets a boolean if there are spec errors
  const errors = getSpecErrors(madErrors, orgSpec, orgErrors);

  return { errors };
};

export const getVideoStandardsStatus = (
  madErrors: AssetSpecificationFailuresLegacy,
  orgErrors: AssetSpecificationFailuresLegacy
) => {
  if (!orgErrors.acceptableExactDurationsInSeconds) {
    return VideoStandardsStatus.FAILED_DURATION;
  }

  if (hasError(orgErrors) || hasError(madErrors)) {
    return VideoStandardsStatus.FAILED_NON_DURATION_SPEC;
  }

  return VideoStandardsStatus.PASSED;
};

export const getAudioStandardsStatus = (
  madErrors: AssetSpecificationFailuresLegacy,
  orgErrors: AssetSpecificationFailuresLegacy
): AudioStandardsStatus => {
  if (!orgErrors.acceptableExactDurationsInSeconds) {
    return AudioStandardsStatus.FAILED_DURATION;
  }

  if (hasError(orgErrors) || hasError(madErrors)) {
    return AudioStandardsStatus.FAILED_NON_DURATION_SPEC;
  }

  return AudioStandardsStatus.PASSED;
};

export const getAudioStandardsString = (
  audioStandardsStatus: AudioStandardsStatus,
  durationSpecifications: number[],
  creative: CreativeLegacy | UploadedFileResultsLegacy
): string => {
  if (audioStandardsStatus === AudioStandardsStatus.FAILED_DURATION) {
    const thresholdExceeded = getDurationThresholdExceeded(
      durationSpecifications,
      creative
    );

    return thresholdExceeded > 0
      ? `Exceeds ${thresholdExceeded}s`
      : `Under ${Math.min(...durationSpecifications)}s`;
  }

  return audioStandardsStatus;
};

export const getVideoStandardsString = (
  videoStandardsStatus: VideoStandardsStatus,
  durationSpecifications: number[],
  creative: CreativeLegacy | UploadedFileResultsLegacy
): string => {
  if (videoStandardsStatus === VideoStandardsStatus.FAILED_DURATION) {
    const thresholdExceeded = getDurationThresholdExceeded(
      durationSpecifications,
      creative
    );

    return thresholdExceeded > 0
      ? `Exceeds ${thresholdExceeded}s`
      : `Under ${Math.min(...durationSpecifications)}s`;
  }

  return videoStandardsStatus;
};

export const getCreativeArchiveStatus = (
  appliedFilters: FormattedDataWithCategory[]
) => {
  const statusFilter = appliedFilters?.find(
    filter => filter.category === CreativeLibraryFilterCategory.ARCHIVED_STATUS
  )?.value;

  if (statusFilter === ArchiveFilter.BOTH) {
    return CreativeArchiveStatus.BOTH;
  }
  if (statusFilter === ArchiveFilter.ARCHIVED) {
    return CreativeArchiveStatus.ARCHIVED;
  }
  return CreativeArchiveStatus.ACTIVE;
};

export const isCreativeDetailsPage = () => {
  const pathArray = window.location.pathname.split("/");
  return (
    pathArray[1] === "creative-library" &&
    pathArray[2] === "creatives" &&
    pathArray.length > 3
  );
};

export const isLineItemDetailsPage = () => {
  const pathArray = window.location.pathname.split("/");
  return (
    pathArray[1] === "manage-campaigns" &&
    pathArray[2] === "lineItems" &&
    pathArray.length > 3
  );
};

export const CREATIVE_LIBRARY_TAB_ID_TO_SERVICE_MEDIA_TYPE = {
  [CreativeLibraryTabId.VIDEO]: ServiceMediaTypeLegacy.VIDEO,
  [CreativeLibraryTabId.DISPLAY]: ServiceMediaTypeLegacy.DISPLAY,
  [CreativeLibraryTabId.AUDIO]: ServiceMediaTypeLegacy.AUDIO
};

export const MEDIA_TYPE_TO_LABEL = {
  [MediaType.VIDEO]: "Video",
  [MediaType.AUDIO]: "Audio",
  [MediaType.DISPLAY]: "Display"
};

export const getSupportedDeviceNamesByMediaType = (
  mediaType: MediaType | string | undefined
) => {
  const clientDeviceNames = Object.values(ClientDeviceNames);
  switch (mediaType) {
    case MEDIA_TYPE_TO_LABEL[MediaType.AUDIO]:
    case MEDIA_TYPE_TO_LABEL[MediaType.DISPLAY]:
    case MediaType.AUDIO:
    case MediaType.DISPLAY:
      return clientDeviceNames.filter(n => n !== ClientDeviceNames.CTV);
    default:
      return clientDeviceNames;
  }
};

export const filterOutArchivedAdvertisers = (advertisers: Advertiser[]) =>
  advertisers.reduce((acc: Advertiser[], advertiser: Advertiser) => {
    if (advertiser.status !== (ServingStatus.ARCHIVED as number)) {
      acc.push({
        id: advertiser.id,
        name: advertiser.name,
        status: advertiser.status
      } as Advertiser);
    }
    return acc;
  }, []);
