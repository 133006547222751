import {
  GET_ALL_DMPS_SUCCESS,
  GET_ALL_DMPS_FAILURE,
  GET_ALL_DMPS_PENDING,
  DmpsState,
  DmpActionTypes
} from "./types";

export const initialState: DmpsState = {
  byId: {},
  isAllLoading: false,
  receivedAllAt: null
};

export const dmpsReducer = (
  state = initialState,
  action: DmpActionTypes
): DmpsState => {
  switch (action.type) {
    case GET_ALL_DMPS_PENDING:
      return {
        ...state,
        isAllLoading: true
      };
    case GET_ALL_DMPS_FAILURE:
      return {
        ...state,
        isAllLoading: false
      };
    case GET_ALL_DMPS_SUCCESS:
      return {
        ...state,
        byId: action.payload.reduce((byId, rawDmp) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[rawDmp.id] = rawDmp;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp
      };
    default:
      return state;
  }
};
