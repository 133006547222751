import { RemoteConfigProperties, ViewId } from "@madhive/mad-sdk";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { getUserPermissions } from "components/Auth/utils";
import { shallowEqual, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectProductionViews
} from "appReducers/authReducer";

export function useManageDealsView(): boolean {
  const user = useSelector(selectCurrentUser, shallowEqual);
  const manageDealsFeatureFlag: boolean = useFeatureFlag(
    RemoteConfigProperties.INTERNAL_VIEW_MANAGE_DEALS
  );

  const clientFacingDealsViewFeatureFlag: boolean = useFeatureFlag(
    RemoteConfigProperties.ENABLE_CLIENT_FACING_DEALS_VIEW
  );

  const orgProductionViews = useSelector(selectProductionViews);
  const { canView: canViewManageDeals } = getUserPermissions(
    ViewId.MANAGE_DEALS,
    user?.abilities ?? []
  );

  const filteredViews =
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    user?.viewIds.filter(view => orgProductionViews[view]) ?? [];

  const orgHasManageDeals = filteredViews.some(
    view => view === ViewId.MANAGE_DEALS
  );

  if (!manageDealsFeatureFlag) {
    return false;
  }

  return (
    !!user?.isDev ||
    (orgHasManageDeals &&
      canViewManageDeals &&
      clientFacingDealsViewFeatureFlag)
  );
}
