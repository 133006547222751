import {
  GET_AVAILABLE_STATIONS_PENDING,
  GET_AVAILABLE_STATIONS_SUCCESS,
  GET_AVAILABLE_STATIONS_FAILURE,
  CREATE_STATION_PENDING,
  CREATE_STATION_SUCCESS,
  CREATE_STATION_FAILURE,
  UPDATE_STATION_PENDING,
  UPDATE_STATION_SUCCESS,
  UPDATE_STATION_FAILURE,
  ABORT_STATION_REQUEST,
  StationsActionTypes,
  StationState
} from "./types";

export const initialState: StationState = {
  byId: {},
  isLoading: false,
  isSaving: false,
  /* boolean indicating if ever loaded -- useful for some loading screen logic */
  isLoaded: false,
  updatingIds: [],
  error: null
};

export const stationsReducer = (
  state = initialState,
  action: StationsActionTypes
): StationState => {
  switch (action.type) {
    case GET_AVAILABLE_STATIONS_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case GET_AVAILABLE_STATIONS_SUCCESS: {
      return {
        ...state,
        byId: action.payload.reduce((byId, station) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[station.id] = station;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        isLoading: false,
        isLoaded: true,
        error: null
      };
    }
    case GET_AVAILABLE_STATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error
      };
    case CREATE_STATION_PENDING:
      return {
        ...state,
        isSaving: true
      };
    case CREATE_STATION_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        isSaving: false,
        error: null
      };
    case CREATE_STATION_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.meta.error
      };
    case UPDATE_STATION_PENDING:
      return {
        ...state,
        updatingIds: [action.meta.id],
        isSaving: true
      };
    case UPDATE_STATION_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        isSaving: false,
        updatingIds: [],
        error: null
      };
    case UPDATE_STATION_FAILURE:
      return {
        ...state,
        isSaving: false,
        updatingIds: [],
        error: action.meta.error
      };
    case ABORT_STATION_REQUEST:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        updatingIds: []
      };
    default:
      return state;
  }
};
