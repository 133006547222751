import { ChangeEvent } from "react";
import { ObjType, TrackerObject } from "@madhive/mad-sdk";
import { TrackerType } from "lib/constants/creatives";
import { getIabCategoryIdByName } from "lib/utils/categories";
import { madSDK } from "lib/sdk";
import { downloadBlob } from "frontier/lib/utils/files";
import { getAcceptedMimeTypes } from "../CreativeUploader/utils";
import {
  AssetGroupDetails,
  AssetRowError,
  BulkCreativeAssetType,
  BulkXlsRow,
  FileMappedToMetadata,
  FlattenedAsset,
  UploadedAssetGroup,
  XlsToUploadedAssetMap
} from "./constants";
import { parseXlsRows } from "./XlxsParsing";

export const bulkGenerateCreativeIds = async (amount: number) => {
  const gatherKeys: Array<Promise<string | undefined>> = [];
  for (let i = 0; i < amount; i++) {
    gatherKeys.push(madSDK.cryptography.mintKey(ObjType.CREATIVE_VID));
  }

  const ids = await Promise.all(gatherKeys);

  const filteredIds = ids.reduce<string[]>(
    (acc, id) => (id ? acc.concat([id]) : acc),
    []
  );

  if (filteredIds.length !== amount) {
    throw "Unable to mint key";
  }

  return filteredIds;
};

export const fileListToArray = (files: FileList | null) => {
  if (!files) {
    return [];
  }
  const fileArray = [];
  for (let i = 0; i < files.length; i++) {
    fileArray.push(files[i]);
  }
  return fileArray;
};

const flattenAssetGroups = (assetGroups: UploadedAssetGroup[]) =>
  assetGroups.reduce<FlattenedAsset[]>(
    (acc, el, idx) =>
      acc.concat(
        el.files.map((f, idx2) => ({
          file: f,
          innerIdx: idx2,
          groupIdx: idx,
          timestamp: el.timeStamp
        }))
      ),
    []
  );

// Returns a record of asset file name duplicates, id duplicates, and asset duplicates
const getDuplicateXlsxRowsAndUploadedAssets = (
  xlsRows: BulkXlsRow[],
  flattenedAssets: FlattenedAsset[]
) => ({
  xlsFilenameDups: xlsRows.reduce((acc, r) => {
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    acc[r.assetFileName]
      ? /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        (acc[r.assetFileName] += 1)
      : /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        (acc[r.assetFileName] = 1);
    return acc;
  }, {}),
  xlsCreativeIdDups: xlsRows.reduce((acc, r) => {
    // If there's no creative id, it was setting undefined: 1 which will falsely say that there are creative ID dups
    if (!r.creativeId) {
      return acc;
    }
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    acc[r.creativeId] ? (acc[r.creativeId] += 1) : (acc[r.creativeId] = 1);
    return acc;
  }, {}),
  assetDups: flattenedAssets.reduce((acc, a) => {
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    acc[a.file.name] ? (acc[a.file.name] += 1) : (acc[a.file.name] = 1);
    return acc;
  }, {})
});

export const isFileTypeInvalid = (
  { type }: File,
  isDisplayActivation: boolean = false,
  isAudioActivation: boolean = false
) => !getAcceptedMimeTypes(isDisplayActivation, isAudioActivation).has(type);

export const joinXlsxRowsToUploadedAssets = (
  xlsRows: BulkXlsRow[],
  assetGroups: UploadedAssetGroup[],
  isDisplayActivation: boolean = false,
  isAudioActivation: boolean = false
): XlsToUploadedAssetMap[] => {
  const flattenedAssetGroups = flattenAssetGroups(assetGroups);

  const { xlsFilenameDups, xlsCreativeIdDups, assetDups } =
    getDuplicateXlsxRowsAndUploadedAssets(xlsRows, flattenedAssetGroups);

  const xlsRowNames = new Set(xlsRows.map(row => row.assetFileName));

  const joined: XlsToUploadedAssetMap[] = xlsRows.map(row => {
    let assetGroupDetails: AssetGroupDetails | undefined;
    const assetFileName = row.assetFileName;
    const creativeId = row.creativeId;
    const matchingAsset = flattenedAssetGroups.filter(
      a => a.file.name === assetFileName
    );
    if (matchingAsset.length > 0) {
      assetGroupDetails = {
        timestamp: matchingAsset[0].timestamp,
        fileIdx: matchingAsset[0].innerIdx,
        groupIdx: matchingAsset[0].groupIdx
      };
    }

    return {
      assetFileName,
      assetGroupDetails,
      creativeId,
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      isXlsRowDuplicate: xlsFilenameDups[row.assetFileName] > 1,
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      isCreativeIdDuplicate: xlsCreativeIdDups[row.creativeId] > 1,
      isAssetDuplicate: matchingAsset.length > 1,
      isAssetCorrupted:
        matchingAsset.length > 0 &&
        matchingAsset.some(({ file }) =>
          isFileTypeInvalid(file, isDisplayActivation, isAudioActivation)
        )
    };
  });

  return flattenedAssetGroups
    .reduce<XlsToUploadedAssetMap[]>((acc, group) => {
      if (!xlsRowNames.has(group.file.name)) {
        acc.push({
          assetFileName: group.file.name,
          assetGroupDetails: {
            timestamp: group.timestamp,
            fileIdx: group.innerIdx,
            groupIdx: group.groupIdx
          },
          isCreativeIdDuplicate: false,
          isXlsRowDuplicate: false,
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          isAssetDuplicate: assetDups[group.file.name] > 1,
          isAssetCorrupted: isFileTypeInvalid(
            group.file,
            isDisplayActivation,
            isAudioActivation
          )
        });
        return acc;
      }
      return acc;
    }, [])
    .concat(joined);
};

export const areVideoAssetsAndXlsxValid = (
  xlsRows: BulkXlsRow[],
  assetGroups: UploadedAssetGroup[],
  isDisplayActivation: boolean = false,
  isAudioActivation: boolean = false
) => {
  let areValid = true;

  const flattenedAssetGroups = flattenAssetGroups(assetGroups);

  const { xlsFilenameDups, xlsCreativeIdDups, assetDups } =
    getDuplicateXlsxRowsAndUploadedAssets(xlsRows, flattenedAssetGroups);

  if (
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    Object.keys(xlsFilenameDups).some(filename => xlsFilenameDups[filename] > 1)
  ) {
    return false;
  }

  if (
    Object.keys(xlsCreativeIdDups).some(
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      creativeId => xlsCreativeIdDups[creativeId] > 1
    )
  ) {
    return false;
  }

  /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
  if (Object.keys(assetDups).some(filename => assetDups[filename] > 1)) {
    return false;
  }

  if (
    flattenedAssetGroups.some(({ file }) =>
      isFileTypeInvalid(file, isDisplayActivation, isAudioActivation)
    )
  ) {
    return false;
  }

  const xlsRowNameSet = new Set(xlsRows.map(r => r.assetFileName));

  if (xlsRowNameSet.size !== flattenedAssetGroups.length) {
    return false;
  }

  flattenedAssetGroups.forEach(gr => {
    if (!xlsRowNameSet.has(gr.file.name)) {
      areValid = false;
    }
  });
  return areValid;
};

export const generateMp4XlsxUnionErrors = (obj: XlsToUploadedAssetMap) => {
  const errors = [];
  if (!obj.assetGroupDetails) {
    errors.push(AssetRowError.MISSING_FROM_UPLOADED_ASSETS);
  }
  if (!obj.creativeId) {
    errors.push(AssetRowError.MISSING_FROM_XLS);
  }
  if (obj.isAssetCorrupted) {
    errors.push(AssetRowError.CORRUPTED);
  }
  if (obj.isAssetDuplicate) {
    errors.push(AssetRowError.DUPLICATE);
  }
  if (obj.isXlsRowDuplicate) {
    errors.push(AssetRowError.DUPLICATE);
  }
  if (obj.isCreativeIdDuplicate) {
    errors.push(AssetRowError.DUPLICATE_ID);
  }
  return errors.length ? errors : undefined;
};

export const finalizeXlsxRows = (
  rows: BulkXlsRow[],
  files: UploadedAssetGroup[]
) => {
  const fileMap = files.reduce<Record<string, File>>((acc, fGroup) => {
    fGroup.files.forEach(f => (acc[f.name] = f));
    return acc;
  }, {});

  return rows.map<FileMappedToMetadata>(row => ({
    file: fileMap[row.assetFileName],
    metadata: row
  }));
};

const XLS_TO_TRACKER = {
  impressionPixel: TrackerType.IMPRESSION,
  videoStartPixel: TrackerType.START,
  firstQuartilePixel: TrackerType.Q1,
  midpointPixel: TrackerType.Q2,
  thirdQuartilePixel: TrackerType.Q3,
  videoCompletePixel: TrackerType.Q4,
  clickthroughUrl: TrackerType.CLICK_THROUGH
};

export const generateTrackers = (xlsRow: BulkXlsRow) =>
  Object.keys(xlsRow).reduce<TrackerObject[] | undefined>((acc, key) => {
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    if (XLS_TO_TRACKER[key]) {
      if (!acc) {
        // eslint-disable-next-line no-param-reassign
        acc = [];
      }
      return acc.concat({
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        type: XLS_TO_TRACKER[key],
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        urls: xlsRow[key].split(",")
      });
    }
    return acc;
  }, undefined);

export const handleXlsxUpload = async (
  ev: ChangeEvent<HTMLInputElement>,
  setXls: (value: File | undefined) => void,
  setParsedXlsRows: (value: BulkXlsRow[] | undefined) => void,
  uploadType: BulkCreativeAssetType
) => {
  if (!ev.target.files) {
    return;
  }
  const xls = ev.target.files[0];
  // TODO: Figure out why we are changing the target.value
  // eslint-disable-next-line no-param-reassign
  ev.target.value = "";
  try {
    const assetNamesFromXls = await parseXlsRows(xls, uploadType);
    setXls(xls);
    setParsedXlsRows(assetNamesFromXls);
  } catch (e) {
    setXls(undefined);
    if (Array.isArray(e)) {
      throw e.join(", ");
    } else {
      throw e;
    }
  }
};

export const downloadXlsx = (arrayBuffer: ArrayBuffer) => {
  const blob = new Blob([arrayBuffer], {
    type: "application/xml"
  });
  try {
    downloadBlob(blob, "Bulk Creative Upload Template.xlsx");
  } catch (e) {
    console.error(e);
  }
};

/**
 * Gets the category ID associated with the value selected in the XLS file.
 * @param xlsCategoryValue The value inside the IAB category column.
 * @returns The ID of the subcategory or category, or an empty string if not found.
 *
 * @example
 *   getIabCategoryIdFromCell("Arts & Entertainment > Books & Literature") => "IAB1-1"
 */
export const getIabCategoryIdFromCell = async (xlsCategoryValue: string) => {
  const resolvedCategory = xlsCategoryValue.split(" > ").pop();

  return getIabCategoryIdByName(resolvedCategory!);
};
