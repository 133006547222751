import { css } from "@emotion/react";
import { useMadState } from "@madhive/mad-hooks";
import { FC, useState } from "react";
import { Button, Checkbox, Icons, Modal, Toggle } from "frontier/lib/kit";
import {
  HINTS_DISABLED_CACHE_KEY,
  HINTS_DISCLAIMER_DISABLED_CACHE_KEY
} from "../constants";

const styles = {
  button: css`
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    font-weight: inherit;
    justify-content: space-between;
    padding: 0;
    width: 100%;
  `,
  modal: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    line-height: normal;
  `,
  modalTitle: css`
    align-items: center;
    color: var(--text-color);
    display: flex;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  `,
  modalTitleIcon: css`
    display: flex;
    margin-right: 0.5rem;
  `,
  modalDisable: css`
    margin-top: 1rem;
  `,
  modalFooter: css`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  `
};

export const HintToggle: FC = () => {
  const [hintsDisabled, setHintsDisabled] = useMadState<boolean>(
    HINTS_DISABLED_CACHE_KEY,
    !!localStorage.getItem(HINTS_DISABLED_CACHE_KEY)
  );

  const [disclaimerDisabled, setDisclaimerDisabled] = useState<boolean>(
    !!localStorage.getItem(HINTS_DISCLAIMER_DISABLED_CACHE_KEY)
  );

  const [disclaimerDisplayed, setDisclaimerDisplayed] =
    useState<boolean>(false);

  const toggleHints = () => {
    const isBeingDisabled = !hintsDisabled;

    setHintsDisabled(isBeingDisabled);

    if (isBeingDisabled) {
      localStorage.setItem(HINTS_DISABLED_CACHE_KEY, "true");
    } else {
      localStorage.removeItem(HINTS_DISABLED_CACHE_KEY);
    }
  };

  const onToggleClick = () => {
    const isDisablingHintsAndNeedsDisclaimer =
      !hintsDisabled && !disclaimerDisabled;

    if (isDisablingHintsAndNeedsDisclaimer) {
      setDisclaimerDisplayed(true);
    } else {
      toggleHints();
    }
  };

  const onDisableDisclaimerClick = () => {
    const isBeingDisabled = !disclaimerDisabled;

    setDisclaimerDisabled(isBeingDisabled);

    if (isBeingDisabled) {
      localStorage.setItem(HINTS_DISCLAIMER_DISABLED_CACHE_KEY, "true");
    } else {
      localStorage.removeItem(HINTS_DISCLAIMER_DISABLED_CACHE_KEY);
    }
  };

  return (
    <>
      <button type="button" css={styles.button} onClick={onToggleClick}>
        Enable Hints
        <Toggle checked={!hintsDisabled} onClick={onToggleClick} />
      </button>

      <Modal
        intent="info"
        isOpen={disclaimerDisplayed}
        onClose={() => setDisclaimerDisplayed(false)}
      >
        <section css={styles.modal}>
          <h3 css={styles.modalTitle}>
            <div css={styles.modalTitleIcon}>
              <Icons.Display size="medium">
                <Icons.Circled.Question fill="var(--color-splash-primary)" />
              </Icons.Display>
            </div>
            Hints
          </h3>

          <p>
            In order to assist you while you work through your flows, hints give
            you access to hover over key areas so you can read additional
            information. Are you sure you want to disable them?
          </p>

          <div css={styles.modalDisable}>
            <Checkbox
              onChange={onDisableDisclaimerClick}
              checked={disclaimerDisabled}
            >
              Please do not show this again
            </Checkbox>
          </div>

          <footer css={styles.modalFooter}>
            <Button
              onClick={() => {
                setDisclaimerDisplayed(false);
                toggleHints();
              }}
            >
              <>
                <Icons.Display size="small">
                  <Icons.Check fill="var(--color-contrast)" />
                </Icons.Display>
                OK
              </>
            </Button>
          </footer>
        </section>
      </Modal>
    </>
  );
};
