import { TableRowType, TableProps } from "..";
import { TableRowActionDef } from "../types";
import { WrappedTableComponent, WrappedTableProps } from "./types";
import EmptyState from "../../EmptyState";
import Icon from "../../Icon";

export const isTablePropsWithEmptyState = (props: TableProps<TableRowType>) =>
  !!props.emptyState;

const DEFAULT_EMPTY_STATE_PROPS = {
  title: "There are no items to view",
  children: "Try narrowing your search term or filters.",
  icon: <Icon name="Search" size="large" fill="var(--color-secondary)" />
};

export function tableWithEmptyState<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: WrappedTableComponent<Row, Column, Action>) {
  /**
   * Table that provides the EmptyState element to the table.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const { emptyState: _emptyState = {} } = props as WrappedTableProps<
      Row,
      Column,
      Action
    >;

    const emptyState =
      "props" in _emptyState ? (
        _emptyState
      ) : (
        <EmptyState {...DEFAULT_EMPTY_STATE_PROPS} {..._emptyState} />
      );

    return <WrappedTable {...props} INTERNAL_emptyState={emptyState} />;
  };
}
