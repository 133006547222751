import { KnownOrganizationIds } from "@madhive/mad-sdk";
import { KnownHostNames } from "./config";

export const ORG_ID_TO_WHITE_LIST_LABEL_NAME: Partial<
  Record<KnownOrganizationIds, string>
> = {
  [KnownOrganizationIds.PREMION]: "Product",
  [KnownOrganizationIds.SCRIPPS]: "Product",
  [KnownOrganizationIds.FOX]: "Product",
  [KnownOrganizationIds.BOBMILLS]: "Publisher Group",
  [KnownOrganizationIds.MADHIVE_MEDIA_GROUP]: "Publisher Group",
  [KnownOrganizationIds.NEWSY]: "Publisher",
  [KnownOrganizationIds.FOSTERS_LAB]: "Publisher"
};
export const HostNamesDefaults = [
  KnownHostNames.PROD_MADHIVE_DEFAULT,
  KnownHostNames.STAGING_DEFAULT,
  KnownHostNames.INTERNAL_QA,
  KnownHostNames.INTERNAL_HACK,
  KnownHostNames.TESTING_MADHIVE_DEFAULT
];
export const ORG_ID_TO_HOSTNAMES: Record<KnownOrganizationIds, string[]> = {
  [KnownOrganizationIds.MADHIVE_DEFAULT]: [...HostNamesDefaults],
  [KnownOrganizationIds.MADHIVE_INTEGRATION_TESTING_ORG]: [
    ...HostNamesDefaults
  ],
  [KnownOrganizationIds.MADHIVE_DEMO]: [
    ...HostNamesDefaults,
    KnownHostNames.DEMO_DEFAULT,
    KnownHostNames.DEMO_LEGACY
  ],
  [KnownOrganizationIds.MADHIVE_MEDIA_GROUP]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_MADHIVE_MEDIA_GROUP
  ],
  [KnownOrganizationIds.PREMION]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_PREMION_LEGACY,
    KnownHostNames.PROD_PREMION_CONSOLE,
    KnownHostNames.STAGING_PREMION
  ],
  [KnownOrganizationIds.SCRIPPS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_SCRIPPS_LEGACY,
    KnownHostNames.PROD_SCRIPPS_OCTANE,
    KnownHostNames.PROD_SCRIPPS_OCTANE_BETA,
    KnownHostNames.STAGING_SCRIPPS
  ],
  [KnownOrganizationIds.BOBMILLS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BOBMILLS
  ],
  [KnownOrganizationIds.MEDIAMATH]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_MEDIAMATH,
    KnownHostNames.PROD_MEDIAMATH_TV_PLATFORM
  ],
  [KnownOrganizationIds.FUTURESMEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_FUTURESMEDIA
  ],
  [KnownOrganizationIds.RUNNINGMATE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_RUNNINGMATE_LEGACY,
    KnownHostNames.PROD_RUNNINGMATE_PLATFORM
  ],
  [KnownOrganizationIds.BEACHFRONT]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BEACHFRONT
  ],
  [KnownOrganizationIds.BIG_SANDY_PROMOTIONS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_DEFAULT,
    KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_OCTILLION
  ],
  [KnownOrganizationIds.ASSOCIATED_VOLUME_BUYERS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_DEFAULT,
    KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_OCTILLION
  ],
  [KnownOrganizationIds.BTC_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BTC_MEDIA
  ],
  [KnownOrganizationIds.UAT_MEDIA]: [...HostNamesDefaults],
  [KnownOrganizationIds.FOX]: [...HostNamesDefaults, KnownHostNames.PROD_FOX],
  [KnownOrganizationIds.NEWSY]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_NEWSY,
    KnownHostNames.PROD_NEWSY_WITH_PORTAL_PREFIX
  ],
  [KnownOrganizationIds.RESIDENT_HOME]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_RESIDENT_HOME
  ],
  [KnownOrganizationIds.VOICE_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_VOICE_MEDIA
  ],
  [KnownOrganizationIds.LEGAL_ACTION_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_LEGAL_ACTION_MEDIA
  ],
  [KnownOrganizationIds.FOSTERS_LAB]: [...HostNamesDefaults],
  [KnownOrganizationIds.MODUS_DIRECT]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_MODUS_DIRECT
  ],
  [KnownOrganizationIds.TEAM_LEWIS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_TEAM_LEWIS
  ],
  [KnownOrganizationIds.KING]: [...HostNamesDefaults, KnownHostNames.PROD_KING],
  [KnownOrganizationIds.DX_AGENCY]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_DX_AGENCY
  ],
  [KnownOrganizationIds.LINCOLN_DIGITAL_GROUP]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_LINCOLN_DIGITAL_GROUP
  ],
  [KnownOrganizationIds.NMPI]: [...HostNamesDefaults, KnownHostNames.PROD_NMPI],
  [KnownOrganizationIds.PATHFINDER_INTERACTIVE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_PATHFINDER_INTERACTIVE
  ],
  [KnownOrganizationIds.CTV_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_CTV_MEDIA,
    KnownHostNames.PROD_CTV_MEDIA_DASHBOARD
  ],
  [KnownOrganizationIds.NEWS_PRESS_GAZETTE]: [...HostNamesDefaults],
  [KnownOrganizationIds.TALON]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_TALON
  ],
  [KnownOrganizationIds.IHEART]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_IHEART
  ],
  [KnownOrganizationIds.OMNICOM]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_OMNICOM
  ],
  [KnownOrganizationIds.CBC]: [...HostNamesDefaults, KnownHostNames.PROD_CBC],
  [KnownOrganizationIds.GROUNDTRUTH]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_GROUNDTRUTH
  ],
  [KnownOrganizationIds.COX]: [...HostNamesDefaults, KnownHostNames.PROD_COX],
  [KnownOrganizationIds.LOCALFACTOR]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_LOCALFACTOR
  ],
  [KnownOrganizationIds.ALLENMEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_ALLENMEDIA
  ],
  [KnownOrganizationIds.GRIFFIN]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_GRIFFIN
  ],
  [KnownOrganizationIds.HEARST]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_HEARST
  ],
  [KnownOrganizationIds.CAPITOL_BROADCASTING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_CAPITOL_BROADCASTING
  ],
  [KnownOrganizationIds.MCAFEE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_MCAFEE
  ],
  [KnownOrganizationIds.JUICE_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_JUICE_MEDIA
  ],
  [KnownOrganizationIds.GOOD_KARMA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_GOOD_KARMA
  ],
  [KnownOrganizationIds.UNIVISION]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_UNIVISION,
    KnownHostNames.PROD_UNIVISION_ANALYTICS
  ],
  [KnownOrganizationIds.GYDIGITAL]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_GYDIGITAL
  ],
  [KnownOrganizationIds.KATZ_DIGITAL]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_KATZDIGITAL
  ],
  [KnownOrganizationIds.SPARKLIGHT]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_SPARKLIGHT
  ],
  [KnownOrganizationIds.HP]: [...HostNamesDefaults, KnownHostNames.PROD_HP],
  [KnownOrganizationIds.NOM]: [...HostNamesDefaults, KnownHostNames.PROD_NOM],
  [KnownOrganizationIds.BEN_HER]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BEN_HER
  ],
  [KnownOrganizationIds.THE_REAL_REAL]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_THE_REAL_REAL
  ],
  [KnownOrganizationIds.BRANT_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BRANT_MEDIA
  ],
  [KnownOrganizationIds.GAMUT]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_GAMUT
  ],
  [KnownOrganizationIds.EVERGREEN]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_EVERGREEN
  ],
  [KnownOrganizationIds.BAHAKEL]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BAHAKEL,
    KnownHostNames.PROD_BAHAKEL_DIGITAL_MEDIA
  ],
  [KnownOrganizationIds.ANALYTICS_OWL]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_ANALYTICS_OWL
  ],
  [KnownOrganizationIds.DLM_AGENCY]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_DLM_AGENCY
  ],
  [KnownOrganizationIds.MEDICX]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_MEDICX
  ],
  [KnownOrganizationIds.TOWNSQUARE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_TOWNSQUARE
  ],
  [KnownOrganizationIds.DIGITAL_ANALYTICS_PRO]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_DIGITAL_ANALYTICS_PRO
  ],
  [KnownOrganizationIds.BLOCK_COMMUNICATIONS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BLOCK_COMMUNICATIONS
  ],
  [KnownOrganizationIds.HOMESLICE_GROUP]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_HOMESLICE_GROUP
  ],
  [KnownOrganizationIds.TURNER_SPORTS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_TURNER_SPORTS
  ],
  [KnownOrganizationIds.MONDAY]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_MONDAY
  ],
  [KnownOrganizationIds.WIN_DIGITAL]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_WIN_DIGITAL
  ],
  [KnownOrganizationIds.CHARLTON_MARKETING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_CHARLTON_MARKETING
  ],
  [KnownOrganizationIds.DIGILANT_ARCHIVED]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_DIGILANT_ARCHIVED,
    KnownHostNames.PROD_MEDIAMATH,
    KnownHostNames.PROD_MEDIAMATH_TV_PLATFORM
  ],
  [KnownOrganizationIds.TALMONT]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_TALMONT
  ],
  [KnownOrganizationIds.FIVE_STAR_MARKETING_ADVISORS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_FIVE_STAR_MARKETING_ADVISORS
  ],
  [KnownOrganizationIds.EVIDNT]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_EVIDNT
  ],
  [KnownOrganizationIds.DISH_SLING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_DISH_SLING
  ],
  [KnownOrganizationIds.T_MOBILE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_T_MOBILE
  ],
  [KnownOrganizationIds.AI_DIGITAL]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_AI_DIGITAL
  ],
  [KnownOrganizationIds.TRIP_ADVISOR]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_TRIP_ADVISOR
  ],
  [KnownOrganizationIds.LIFTOFF]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_LIFTOFF
  ],
  [KnownOrganizationIds.MiQ]: [...HostNamesDefaults, KnownHostNames.PROD_MiQ],
  [KnownOrganizationIds.OMG]: [...HostNamesDefaults, KnownHostNames.PROD_OMG],
  [KnownOrganizationIds.OTTERA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_OTTERA
  ],
  [KnownOrganizationIds.VOGLIO]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_VOGLIO
  ],
  [KnownOrganizationIds.ARKADAS_GROUP]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_ARKADAS_GROUP
  ],
  [KnownOrganizationIds.SAKS_OFF_FIFTH]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_SAKS_OFF_FIFTH
  ],
  [KnownOrganizationIds.BUCKEYE_BROADBAND]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BUCKEYE_BROADBAND
  ],
  [KnownOrganizationIds.LIFEBRANDS_D2C]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_LIFEBRANDS_D2C
  ],
  [KnownOrganizationIds.KILL_YOUR_COMPETITION]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_KILL_YOUR_COMPETITION
  ],
  [KnownOrganizationIds.SONOBI]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_SONOBI
  ],
  [KnownOrganizationIds.BATEMAN_COLLECTIVE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BATEMAN_COLLECTIVE
  ],
  [KnownOrganizationIds.FEDERATED_DIGITAL_SOLUTIONS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_FEDERATED_DIGITAL_SOLUTIONS
  ],
  [KnownOrganizationIds.KR8_VENTURES]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_KR8_VENTURES
  ],
  [KnownOrganizationIds.ESSENTIALISM_ADVERTISING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_ESSENTIALISM_ADVERTISING
  ],
  [KnownOrganizationIds.RED_VENTURES]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_RED_VENTURES
  ],
  [KnownOrganizationIds.HUDDLED_MASSES]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_HUDDLED_MASSES
  ],
  [KnownOrganizationIds.AUTOMATED_TESTING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_AUTOMATED_TESTING
  ],
  [KnownOrganizationIds.GREEN_AND_WOOD_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_GREEN_AND_WOOD_MEDIA
  ],
  [KnownOrganizationIds.TASTEMADE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_TASTEMADE
  ],
  [KnownOrganizationIds.DIGILANT]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_DIGILANT
  ],
  [KnownOrganizationIds.CHAMPIONSHIP_ADVERTISING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_CHAMPIONSHIP_ADVERTISING
  ],
  [KnownOrganizationIds.CTV_BUYER]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_CTV_BUYER
  ],
  [KnownOrganizationIds.LEV_LANE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_LEV_LANE
  ],
  [KnownOrganizationIds.THE_DIGITAL_RUCKUS]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_THE_DIGITAL_RUCKUS
  ],
  [KnownOrganizationIds.BRAVE]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BRAVE
  ],
  [KnownOrganizationIds.VIDEOHEROES]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_VIDEOHEROES
  ],
  [KnownOrganizationIds.ENTHUSIAST_GAMING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_ENTHUSIAST_GAMING
  ],
  [KnownOrganizationIds.CBS]: [...HostNamesDefaults, KnownHostNames.PROD_CBS],
  [KnownOrganizationIds.NIMBUS99]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_NIMBUS99
  ],
  [KnownOrganizationIds.CONTINUUM_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_CONTINUUM_MEDIA
  ],
  [KnownOrganizationIds.NEXSTAR]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_NEXSTAR
  ],
  [KnownOrganizationIds.BLAM_RETAIL]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_BLAM_RETAIL
  ],
  [KnownOrganizationIds.VICA_AI]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_VICA_AI
  ],
  [KnownOrganizationIds.NIGHTLIFE_TELEVISION]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_NIGHTLIFE_TELEVISION
  ],
  [KnownOrganizationIds.JRR_ADVERTISING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_JRR_ADVERTISING
  ],
  [KnownOrganizationIds.ESTRELLA_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_ESTRELLA_MEDIA
  ],
  [KnownOrganizationIds.PERFORMCB]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_PERFORMCB
  ],
  [KnownOrganizationIds.NEW_ROAD_ADVERTISING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_NEW_ROAD_ADVERTISING
  ],
  [KnownOrganizationIds.QUICKTURN_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_QUICKTURN_MEDIA
  ],
  [KnownOrganizationIds.CONNECTADTV]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_CONNECTADTV
  ],
  [KnownOrganizationIds.NRG_MEDIA]: [...HostNamesDefaults],
  [KnownOrganizationIds.LILLY_BROADCASTING]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_LILLY_BROADCASTING
  ],
  [KnownOrganizationIds.ENTRAVISION]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_ENTRAVISION
  ],
  [KnownOrganizationIds.GUARANTY_MEDIA]: [
    ...HostNamesDefaults,
    KnownHostNames.PROD_GUARANTY_MEDIA
  ]
};
