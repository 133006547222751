import { FC } from "react";
import { CommonIconProps, FourDirection } from "./types";

type Props = CommonIconProps & {
  direction?: FourDirection;
};

const paths = {
  up: "M12 8.09576C11.775 8.09576 11.58 8.20076 11.445 8.35076L6.195 14.3508C6.075 14.4858 6 14.6508 6 14.8458C6 15.2658 6.33 15.5958 6.75 15.5958L17.25 15.5958C17.67 15.5958 18 15.2658 18 14.8458C18 14.6508 17.925 14.4858 17.805 14.3508L12.555 8.35076C12.42 8.20076 12.225 8.09576 12 8.09576Z",
  down: "M12 16.25C12.225 16.25 12.42 16.145 12.555 15.995L17.805 9.995C17.925 9.86 18 9.695 18 9.5C18 9.08 17.67 8.75 17.25 8.75L6.75 8.75C6.33 8.75 6 9.08 6 9.5C6 9.695 6.075 9.86 6.195 9.995L11.445 15.995C11.58 16.145 11.775 16.25 12 16.25Z",
  left: "M6.94153 12.5C6.94153 12.725 7.04653 12.92 7.19653 13.055L13.1965 18.305C13.3315 18.425 13.4965 18.5 13.6915 18.5C14.1115 18.5 14.4415 18.17 14.4415 17.75L14.4415 7.25C14.4415 6.83 14.1115 6.5 13.6915 6.5C13.4965 6.5 13.3315 6.575 13.1965 6.695L7.19653 11.945C7.04653 12.08 6.94153 12.275 6.94153 12.5Z",
  right:
    "M15.75 12.5C15.75 12.275 15.645 12.08 15.495 11.945L9.495 6.695C9.36 6.575 9.195 6.5 9 6.5C8.58 6.5 8.25 6.83 8.25 7.25L8.25 17.75C8.25 18.17 8.58 18.5 9 18.5C9.195 18.5 9.36 18.425 9.495 18.305L15.495 13.055C15.645 12.92 15.75 12.725 15.75 12.5Z"
};

const Caret: FC<Props> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)",
  direction = "down"
}) => {
  return (
    <svg
      className={`icon-caret ${direction}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={paths[direction]} fill={fill} stroke={stroke} />
    </svg>
  );
};

export default Caret;
