import { Button, Icons } from "..";
import { styles } from "./styles";
import { EmptyStateProps } from "./types";

export function EmptyState({
  actionProps = {
    disabled: false,
    variant: "primary"
  },
  children,
  icon,
  secondaryActionProps = {
    disabled: false,
    variant: "ghost"
  },
  title
}: EmptyStateProps) {
  return (
    <div className="kit-EmptyState" css={styles.wrapper}>
      {icon && <Icons.Display size="extra-large">{icon}</Icons.Display>}
      <h4 css={styles.header}>{title}</h4>
      {children}
      {actionProps.label && (
        <Button
          disabled={actionProps.disabled}
          prefix={actionProps.prefix}
          variant={actionProps.variant}
          onClick={actionProps.onClick}
        >
          {actionProps.label}
        </Button>
      )}
      {secondaryActionProps.label && (
        <Button
          disabled={secondaryActionProps.disabled}
          prefix={secondaryActionProps.prefix}
          variant={secondaryActionProps.variant || "ghost"}
          onClick={secondaryActionProps.onClick}
        >
          {secondaryActionProps.label}
        </Button>
      )}
    </div>
  );
}

export default EmptyState;
export * from "./types";
