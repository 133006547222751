import { ReactNode } from "react";
import { objectLookup } from "../../../../utils";
import { formatDateFromAny, lookupElementsByDisplayName } from "../../../utils";
import { ColumnDef, ColumnKeys, TableProps, TableRowType } from "../..";
import { DoNotCare, isReactNode, KeyPaths, WithRequired } from "../../../types";

/**
 * A factory function for creating a columnDef.render method, by providing the key path to the value.
 * returns: (row: Row) => ReactNode
 */
const makeDefaultRender =
  (valuePath: string) =>
  <Row extends TableRowType>(row: Row): ReactNode => {
    const val = objectLookup(row, valuePath);

    if (val instanceof Date) {
      return formatDateFromAny(val);
    }
    if (Array.isArray(val)) {
      return val.join(", ");
    }
    if (isReactNode(val)) {
      return val;
    }
    return JSON.stringify(val);
  };

/**
 * Get the column definitions from Table.Column props w/ matching key
 * @returns column props
 */
export function lookupColumnDefInChildren<Row extends TableRowType>(
  key: string,
  children: DoNotCare
): ColumnDef<Row> {
  const columnComponents = lookupElementsByDisplayName(
    "Table.Column",
    children
  );
  const { children: render, ...props } =
    columnComponents.find(child => child.key === key)?.props || {};

  return { ...props, render };
}

/**
 * Determines whether a column should be excluded, based `hidden` prop provided to column.
 */
export function shouldExcludeColumn(
  columnKey: string,
  children: DoNotCare,
  columnDef: ColumnDef<DoNotCare> | undefined,
  childrenDef = lookupColumnDefInChildren(columnKey, children)
) {
  const { hidden = columnDef?.hidden } = childrenDef;
  return typeof hidden === "function" ? hidden(columnKey) : !!hidden;
}

/**
 * Extends ColumnDef to:
 * - enforce certain props
 * - provide expressed values for lookup methods
 * - add's typed `key`
 */
export type DerivedColumnDef<
  Row extends TableRowType,
  Column extends string
> = Omit<
  WithRequired<ColumnDef<Row>, "isSortable" | "render" | "label">,
  "hidden"
> & {
  key: Column;
  hidden: boolean;
};

export type DerivedColumnDefs<
  Row extends TableRowType = TableRowType,
  Column extends string = string
> = {
  [K in ColumnKeys<Row, Column>]: DerivedColumnDef<Row, Column>;
};

export class DerivedColumnDefinition<
  Row extends TableRowType,
  Column extends string
> implements DerivedColumnDef<Row, Column>
{
  key: Column;

  private incomingDef: ColumnDef<Row>;

  private childrenDef: ColumnDef<Row>;

  constructor(
    columnKey: Column,
    private columnIndex: number,
    private opts: {
      columnDef: ColumnDef<Row> | undefined;
      children: DoNotCare;
      rowSample: Row;
      sorting: TableProps<Row, Column>["sorting"] | undefined;
      someColumnsHaveSortFn?: boolean;
    }
  ) {
    this.key = columnKey;
    this.columnIndex = columnIndex;
    this.incomingDef = this.opts.columnDef || {};
    this.childrenDef = lookupColumnDefInChildren<Row>(
      this.key,
      this.opts.children
    );
  }

  get valuePath() {
    return (
      this.childrenDef.valuePath ||
      this.incomingDef.valuePath ||
      this.deriveValuePath()
    );
  }

  get label() {
    return this.childrenDef.label || this.incomingDef.label || String(this.key);
  }

  get testId() {
    return (
      this.childrenDef.testId || this.incomingDef.testId || `cell-${this.key}`
    );
  }

  get render() {
    return (
      this.childrenDef.render ||
      this.incomingDef.render ||
      makeDefaultRender(String(this.valuePath))
    );
  }

  get hidden() {
    return shouldExcludeColumn(
      this.key,
      this.opts.children,
      this.incomingDef,
      this.childrenDef
    );
  }

  /**
   * Determines whether the column is sortable — based on:
   * - columnDef.isSortable
   * - sorting.isSortable
   * - columnDef.onSort
   * - sorting.onSort + opts.someColumnsHaveSortFn
   */
  get isSortable() {
    const {
      isSortable = this.incomingDef.isSortable,
      onSort = this.incomingDef.onSort
    } = this.childrenDef;

    if (isSortable) {
      return true;
    }

    const { sorting = {}, someColumnsHaveSortFn } = this.opts;

    // if sorting.isSortable is true or a function, return the results.
    if (sorting?.isSortable) {
      if (typeof sorting.isSortable === "function") {
        return sorting.isSortable(this.key);
      }
      return true;
    }

    // column is sortable if it has an onSort prop on def or Table.Column
    if (onSort) {
      return true;
    }

    // column is sortable if the table has sorting.onSort prop defined,
    // and sorting.isSortable prop is not defined
    return (
      !!sorting?.onSort &&
      sorting.isSortable !== false &&
      !someColumnsHaveSortFn
    );
  }

  /** Locate or derive `onSort` fn */
  get onSort() {
    const { sorting = {} } = this.opts;

    let { onSort = this.incomingDef.onSort } = this.childrenDef;

    if (!onSort && sorting?.onSort && this.isSortable) {
      const { onSort: defaultSort } = sorting;
      onSort = (direction: "asc" | "desc") => defaultSort(this.key, direction);
    }
    return onSort;
  }

  /**
   * locate (or derive) the valuePath used in default render method
   */
  private deriveValuePath() {
    const { rowSample } = this.opts;

    // if rowSample is an array, we are dealing with csv-like data
    if (Array.isArray(rowSample)) {
      return this.deriveCsvValuePath(rowSample);
    }

    return String(this.key) as KeyPaths<Row, ReactNode>;
  }

  /**
   * Derive the value path (index of array) for a CSV-like row
   */
  private deriveCsvValuePath(rowSample: string[]) {
    // if we find the key in the header row sample,
    // we use it's index for tje `valuePath`
    let foundIndex = rowSample.findIndex(col => col === this.key);

    if (foundIndex === -1) {
      // if we don't find the key, we set the column's index
      foundIndex = this.columnIndex;
    }
    return foundIndex as KeyPaths<Row, ReactNode>;
  }
}
