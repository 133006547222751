import { Checkbox as BlueprintCheckbox } from "@blueprintjs/core";
import { FC } from "react";
import { v4 as uuid } from "uuid";
import { styles } from "./styles";
import { CheckboxProps } from "./types";
import Icon from "../Icon";

/**
 * `Checkbox` components allow users to make a binary decisions.
 */
const Checkbox: FC<CheckboxProps> = ({
  children,
  checked,
  disabled = false,
  indeterminate = false,
  id = `kit-${uuid()}`,
  ellipses = false,
  icon,
  value,
  onChange,
  onClick
}) => {
  return (
    <BlueprintCheckbox
      css={[styles.wrapper]}
      className="kit-Checkbox"
      id={id}
      checked={checked}
      indeterminate={indeterminate}
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
      value={String(value)}
    >
      {(!!children || !!icon) && (
        <span css={[styles.label, ellipses && styles.ellipses]}>
          {icon && typeof icon !== "string" && icon}
          {icon && typeof icon === "string" && <Icon name={icon} />}
          {children}
        </span>
      )}
    </BlueprintCheckbox>
  );
};

export default Checkbox;
export * from "./types";
