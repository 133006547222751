import {
  CREATE_FOOT_TRAFFIC_REPORT_FAILURE,
  CREATE_FOOT_TRAFFIC_REPORT_PENDING,
  CREATE_FOOT_TRAFFIC_REPORT_SUCCESS,
  ARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS,
  ARCHIVE_FOOT_TRAFFIC_REPORT_PENDING,
  ARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE,
  FootTrafficReportsActionTypes,
  FootTrafficState,
  GET_FOOT_TRAFFIC_REPORTS_FAILURE,
  GET_FOOT_TRAFFIC_REPORTS_PENDING,
  GET_FOOT_TRAFFIC_REPORTS_SUCCESS,
  GET_FOOT_TRAFFIC_REPORT_RESULTS_FAILURE,
  GET_FOOT_TRAFFIC_REPORT_RESULTS_PENDING,
  GET_FOOT_TRAFFIC_REPORT_RESULTS_SUCCESS,
  UNARCHIVE_FOOT_TRAFFIC_REPORT_PENDING,
  UNARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS,
  UNARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE
} from "./types";

export const initialState: FootTrafficState = {
  byId: {},
  isLoading: false,
  isLoaded: false,
  isReportDetailsLoading: false,
  isNewDimensionLoading: false,
  resultsById: {},
  error: null
};

export const footTrafficAttributionReducer = (
  state = initialState,
  action: FootTrafficReportsActionTypes
) => {
  switch (action.type) {
    case GET_FOOT_TRAFFIC_REPORTS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GET_FOOT_TRAFFIC_REPORTS_SUCCESS: {
      return {
        ...state,
        // @ts-ignore To Type once reports are defined
        byId: {
          ...state.byId,
          ...action.payload.reduce((byId, report) => {
            /* eslint-disable no-param-reassign */
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            byId[report.resultKey || ""] = report;
            /* eslint-enable no-param-reassign */
            return byId;
          }, {})
        },
        isLoading: false,
        isLoaded: true,
        error: null
      };
    }
    case GET_FOOT_TRAFFIC_REPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error
      };
    case GET_FOOT_TRAFFIC_REPORT_RESULTS_PENDING:
      return {
        ...state,
        isNewDimensionLoading: true,
        isReportDetailsLoading: action.payload || false
      };
    case GET_FOOT_TRAFFIC_REPORT_RESULTS_SUCCESS:
      // we append newly fetched data (instead of overwriting) to the dimensions that have already been fetched.
      // before we fetched everything at once, now we fetch individual dimensions

      // get all results by id
      const resultsById = state.resultsById || {};

      // get all dimensions by report id
      const currentData = resultsById[action.payload.reportId] || {};

      // combine new dimensions and dimensions already fetched
      const results = { ...currentData, ...action.payload.results };

      return {
        ...state,
        isReportDetailsLoading: false,
        isNewDimensionLoading: false,
        resultsById: {
          ...state.resultsById,
          [action.payload.reportId]: results
        }
      };
    case GET_FOOT_TRAFFIC_REPORT_RESULTS_FAILURE:
      return {
        ...state,
        isReportDetailsLoading: false,
        isNewDimensionLoading: false
      };

    case ARCHIVE_FOOT_TRAFFIC_REPORT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS: {
      const { report } = action.meta;
      return {
        ...state,
        byId: {
          ...state.byId,
          [report.resultKey]: report
        },
        isLoading: false
      };
    }
    case ARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error.message
      };

    case UNARCHIVE_FOOT_TRAFFIC_REPORT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case UNARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS: {
      const { report } = action.meta;
      return {
        ...state,
        byId: {
          ...state.byId,
          [report.resultKey]: report
        },
        isLoading: false
      };
    }
    case UNARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error.message
      };

    case CREATE_FOOT_TRAFFIC_REPORT_PENDING:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case CREATE_FOOT_TRAFFIC_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        byId: {
          ...state.byId,
          [action.payload.resultKey]: action.payload
        }
      };

    case CREATE_FOOT_TRAFFIC_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error.message
      };

    default:
      return state;
  }
};
