import { DoNotCare, IdType } from "../types";
import { lookupElementsByDisplayName } from "../utils";

/**
 * Get keys of additional children that are not in the current set of keys.
 * @param displayName - the displayName of Table component to look for
 * @param current - the current set of keys
 * @param children - the children props of the table
 * @returns the additional keys that are not in the current set
 */
export const getAdditionalChildrenKeys = (
  displayName: string,
  current: IdType[],
  children?: DoNotCare
) => {
  const idSet = new Set(current);
  const additional: string[] = [];

  const childrenComponents = lookupElementsByDisplayName(displayName, children);

  childrenComponents.forEach(child => {
    const key = child.key?.toString();
    if (!key || idSet.has(key)) {
      return;
    }
    idSet.add(key);
    additional.push(key);
  });
  return additional;
};
