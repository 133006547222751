import { css } from "@emotion/react";
import { kitText } from "../../styles";

export const styles = {
  wrapper: css`
    ${kitText.color.secondary}
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem;
  `,
  header: css`
    ${kitText.variant.heading4}
    margin: 0;
  `
};
