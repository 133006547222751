import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { User, ViewId } from "@madhive/mad-sdk";
import {
  AdvancedExportsIcon,
  AttributionDashboardIcon,
  ConversionDashboardIcon,
  AudiencesIcon,
  CreativeLibraryIcon,
  CustomReportingIcon,
  DashboardIcon,
  FootTrafficAttributionIcon,
  ManageAdvertisersIcon,
  ManageAgencyIcon,
  ManageCampaignsIcon,
  ManageProductsIcon,
  ManageStationIcon,
  ManageUsersIcon,
  ManageWhitelistsIcon,
  OOHPlannerIcon,
  PacingIcon,
  PlannerIcon,
  TrackingPixelsIcon,
  SpendDashboardIcon
} from "components/Svg/ViewSelectorDrawerIcons";
import { HOSTNAME_TO_BACKGROUND_CLASSNAME } from "lib/constants/config";
import { Routes } from "lib/constants/routes";
import { viewDisplayName } from "lib/utils/parsing";
import { ProductionViews } from "types";
import { Icon as KitIcon } from "frontier/lib/kit";

export const SORT_INDEX_FOR_UNKNOWN_VIEW = 9999;

/**
 * The corresponding object for a particular viewId
 */
interface MappedView {
  title: string; // The text rendered as the view's name.
  /**
   * // The path of the view, usually a top level, absolute path.
   */
  href: string;
  /**
   * The component to render as the icon. Usually imported from @blueprintjs
   */
  icon: JSX.Element;
  /**
   * The categorical heading the link appears below
   */
  category: ViewCategories;
  /**
   * Used to order some links above others within each respective category
   * (e.g. the dashboard link should always be the first so it has a sortIndex of 0).
   */
  sortIndex: number;
}

export enum ViewCategories {
  UNLABELED_TOP = "",
  PLANNING = "Planning",
  CAMPAIGN_MANAGEMENT = "Campaign Management",
  REPORTING = "Reporting",
  SUPPLY_PATHS = "Supply Paths",
  ADMIN = "Admin",
  UNKNOWN = "Unknown"
}

export const OrderedViewCategories = Object.freeze([
  ViewCategories.UNLABELED_TOP,
  ViewCategories.PLANNING,
  ViewCategories.CAMPAIGN_MANAGEMENT,
  ViewCategories.REPORTING,
  ViewCategories.SUPPLY_PATHS,
  ViewCategories.ADMIN,
  ViewCategories.UNKNOWN
]);

/**
 * Takes a viewId and generates a link component
 */
export const mapViewIdToView = (
  viewId: ViewId,
  orgProductionViews: ProductionViews
): MappedView => {
  switch (viewId) {
    case ViewId.DASHBOARD:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.DASHBOARD,
        icon: <DashboardIcon />,
        category: ViewCategories.UNLABELED_TOP,
        sortIndex: 0
      };
    case ViewId.OOH_PLANNING:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.OOH_PLANNING,
        icon: <OOHPlannerIcon />,
        category: ViewCategories.PLANNING,
        sortIndex: 1
      };
    case ViewId.TRACKING_PIXELS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.TRACKING_PIXELS,
        icon: <TrackingPixelsIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 3
      };
    case ViewId.PACING:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.PACING,
        icon: <PacingIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 0
      };
    case ViewId.PACING_BETA:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.PACING_BETA,
        icon: <PacingIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 0
      };
    case ViewId.MANAGE_CAMPAIGNS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_CAMPAIGNS,
        icon: <ManageCampaignsIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 1
      };
    case ViewId.CREATIVE_LIBRARY:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.CREATIVE_LIBRARY,
        icon: <CreativeLibraryIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 2
      };
    case ViewId.MANAGE_PUBLISHER_GROUPS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_PUBLISHER_GROUPS,
        icon: <ManageWhitelistsIcon />,
        category: ViewCategories.ADMIN,
        sortIndex: 5
      };
    case ViewId.ADMIN_USERS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_USERS,
        icon: <ManageUsersIcon />,
        category: ViewCategories.ADMIN,
        sortIndex: 0
      };
    case ViewId.MANAGE_ADVERTISERS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_ADVERTISERS,
        icon: <ManageAdvertisersIcon />,
        category: ViewCategories.ADMIN,
        sortIndex: 3
      };
    case ViewId.MANAGE_AGENCIES:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_AGENCIES,
        icon: <ManageAgencyIcon />,
        category: ViewCategories.ADMIN,
        sortIndex: 2
      };
    case ViewId.MANAGE_PRODUCTS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_PRODUCTS,
        icon: <ManageProductsIcon />,
        category: ViewCategories.ADMIN,
        sortIndex: 4
      };
    case ViewId.MANAGE_STATIONS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_STATIONS,
        icon: <ManageStationIcon />,
        category: ViewCategories.ADMIN,
        sortIndex: 1
      };
    case ViewId.DELIVERY_REPORT:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.DELIVERY_REPORT,
        icon: <PacingIcon />,
        category: ViewCategories.REPORTING,
        sortIndex: 1
      };
    case ViewId.CUSTOM_REPORTING:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.CUSTOM_REPORTING,
        icon: <CustomReportingIcon />,
        category: ViewCategories.REPORTING,
        sortIndex: 3
      };
    case ViewId.ADVANCED_EXPORTS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.ADVANCED_EXPORTS,
        icon: <AdvancedExportsIcon />,
        category: ViewCategories.REPORTING,
        sortIndex: 4
      };
    case ViewId.FOOT_TRAFFIC_ATTRIBUTION:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.FOOT_TRAFFIC_ATTRIBUTION,
        icon: <FootTrafficAttributionIcon />,
        category: ViewCategories.REPORTING,
        sortIndex: 5
      };
    case ViewId.CONVERSION_DASHBOARD:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.CONVERSION_DASHBOARD,
        icon: <ConversionDashboardIcon />,
        category: ViewCategories.REPORTING,
        sortIndex: 6
      };
    case ViewId.ADBOOK_ERRORS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.ADBOOK_ERRORS,
        icon: <ManageCampaignsIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 3
      };
    case ViewId.WIDE_ORBIT_ERRORS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.WIDE_ORBIT_ERRORS,
        icon: <ManageCampaignsIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 3
      };
    case ViewId.CRYPTO_DASHBOARD:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.CRYPTO_DASHBOARD,
        icon: <DashboardIcon />,
        category: ViewCategories.UNLABELED_TOP,
        sortIndex: 1
      };
    case ViewId.MANAGE_AUDIENCES:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_AUDIENCES,
        icon: <AudiencesIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 1
      };
    case ViewId.PLANNER:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.PLANNER,
        icon: <PlannerIcon />,
        category: ViewCategories.PLANNING,
        sortIndex: 0
      };
    case ViewId.FIRST_PARTY_DATA_ONBOARDING:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.FIRST_PARTY_DATA_ONBOARDING,
        icon: <PlannerIcon />,
        category: ViewCategories.CAMPAIGN_MANAGEMENT,
        sortIndex: 5
      };
    case ViewId.SPEND_DASHBOARD:
      return {
        title: viewDisplayName(viewId, orgProductionViews, "BETA"),
        href: Routes.SPEND_DASHBOARD,
        icon: <SpendDashboardIcon />,
        category: ViewCategories.REPORTING,
        sortIndex: 7
      };
    case ViewId.MANAGE_DEALS:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.MANAGE_DEALS,
        icon: <KitIcon name="Deals" size="fill" />,
        category: ViewCategories.ADMIN,
        sortIndex: 1
      };
    case ViewId.AUDIENCE_DATA_PROVISIONING:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.AUDIENCE_DATA_PROVISIONING,
        icon: <KitIcon name="AudienceDataProvisioning" size="fill" />,
        category: ViewCategories.ADMIN,
        sortIndex: 0
      };
    case ViewId.ATTRIBUTION_DASHBOARD_BETA:
      return {
        title: viewDisplayName(viewId, orgProductionViews),
        href: Routes.ATTRIBUTION,
        icon: <AttributionDashboardIcon />,
        category: ViewCategories.REPORTING,
        sortIndex: 8
      };
    default:
      return {
        title: viewId,
        href: viewId,
        icon: <Icon icon={IconNames.HOME} iconSize={22} />,
        category: ViewCategories.UNKNOWN,
        sortIndex: SORT_INDEX_FOR_UNKNOWN_VIEW
      };
  }
};

/**
 * Retrieve login background color based on the hostname.
 */
export const getLoginBackgroundClassName = (
  hostname: string,
  user: Partial<User> | null
) => {
  if (user) {
    return "";
  }

  return (
    HOSTNAME_TO_BACKGROUND_CLASSNAME[
      hostname as keyof typeof HOSTNAME_TO_BACKGROUND_CLASSNAME
    ] || "Smithers--black-background"
  );
};
