import styled from "@emotion/styled";

export const DEFAULT_ROW_HEIGHT = 40;

export const zIndex = {
  rowSticky: 5,
  rightMenuContentRenderFn: 4,
  headerBulk: 4,
  cellBulk: 3
};

export const colors = {
  main: "var(--primary-color)",
  white: "var(--white)",
  gray: "var(--gray-4)",
  black: "var(--black)",
  blueLight: "var(--blue-light-3)"
};

export const shadow = `4px 0px 6px rgba(186, 199, 213, 0.5)`;

export const leftShadow = `0px 2px 10px rgba(186, 199, 213, 0.5)`;

export const StickyCheckbox = styled.div<{
  checked: boolean;
  inverted?: boolean;
}>`
  width: var(--spacing-8);
  height: var(--spacing-8);
  border-radius: var(--spacing-8);
  background: ${props => (props.checked ? colors.main : "")};
  border: 1px solid
    ${props =>
      props.checked
        ? colors.main
        : props.inverted
          ? colors.black
          : colors.gray};
  cursor: pointer;
`;

export const getRemSize = () => {
  const size = document.getElementsByTagName("html")[0].style.fontSize || "16";
  return parseInt(size.replace("px", ""), 10);
};
