import { Signaller, User } from "./modules";
export * from "./types";
/**
 * Singleton which knows how to identifer users, and report on any and all "actions" taken within a UI.
 * Allows flexible registry of `on:acton`-style listeners via `listeners`.
 * @template {U}: the structure of user traits. Defaults to `Record<string, unknown>`.
 * @template {P}: the structure of event parameters. Defaults to `Record<string, unknown>`.
 */
export class MadTracker {
    constructor() {
        Object.defineProperty(this, "signaller", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "user", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /** Core data sent with every event. */
        Object.defineProperty(this, "core", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "listeners", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        if (MadTracker.instance) {
            return MadTracker.instance;
        }
        this.signaller = new Signaller();
        this.user = new User(this.signaller.signal.bind(this.signaller));
        this.listeners = {
            register: this.signaller.register.bind(this.signaller),
            clear: this.signaller.clear.bind(this.signaller),
            remove: this.signaller.remove.bind(this.signaller)
        };
        MadTracker.instance = this;
    }
    /**
     * @param event: a name for the event being reported.
     * @param properties: additional data describing the event.
     * @return: a promise which resolves once all listeners have been signalled, and have completed their response.
     */
    track(event, properties) {
        return this.signaller.signal("track", {
            event,
            properties,
            core: this.core,
            user: this.user.get()
        });
    }
    /**
     * @param category a optional category for the page
     * @param name a optional name for the page
     * @returns a promise which resolves once all listeners have been signalled, and have completed their response.
     */
    page(category, name) {
        return this.signaller.signal("page", { category, name });
    }
    /**
     * @param event a name for the event being reported
     * @returns a stopwatch object with a `start` method which returns a `stop` method.
     */
    makeStopwatch(event) {
        const start = (properties) => {
            const startTime = Date.now();
            const stop = (additionalProperties) => this.signaller.signal("track", {
                event,
                properties: {
                    ...properties,
                    ...additionalProperties,
                    stopwatchDuration: Date.now() - startTime
                },
                core: this.core,
                user: this.user.get()
            });
            return stop;
        };
        return { start };
    }
}
