import { FC, useMemo } from "react";
import Spinner, { SpinnerSize } from "../Spinner";
import { getIconFromStringOrElement } from "../Icons/utils";
import { ButtonProps } from "./types";
import { styles, variantBase, variantLoading } from "./styles";

/**
 * `Button` is an interactive element activated by a user and can be linked to perform a programmable action.
 */
const Button: FC<ButtonProps> = ({
  fill = false,
  variant = "primary",
  className = "",
  ariaLabel,
  actionType = "button",
  tabIndex = 0,
  prefix,
  suffix,
  loadingIndicator,
  children,
  disabled = false,
  stopPropagation = false,
  loading = false,
  "aria-expanded": ariaExpanded,
  onClick
}) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    if (loading || disabled) return;
    onClick?.(event);
  };

  const PrefixIcon = useMemo(
    () => getIconFromStringOrElement(prefix),
    [prefix]
  );
  const SuffixIcon = useMemo(
    () => getIconFromStringOrElement(suffix),
    [suffix]
  );

  return (
    <button
      css={[
        styles.wrapper,
        variantBase[variant],
        fill && styles.fill,
        loading && !disabled && styles.loading,
        // specific styles for different variants' loading state which cannot be targeted with css
        loading && !disabled && variantLoading[variant]
      ]}
      data-testid="kit-Button"
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      type={actionType}
      onClick={handleClick}
      disabled={disabled}
      className={className}
      tabIndex={tabIndex}
    >
      {PrefixIcon}
      {children}
      {SuffixIcon}
      {loading && !disabled && (
        <span css={[styles.loading_indicator]}>
          {loadingIndicator || <Spinner size={SpinnerSize.SMALL} />}
        </span>
      )}
    </button>
  );
};

export default Button;
export * from "./types";
