import { useMemo } from "react";
import { TableRowType } from "..";
import {
  makeColumnDefinitions,
  MakeColumnsProps,
  makeOrderedColumnKeys
} from "./helpers";

/**
 * Hook to derive column `definitions` and `orderedKeys` for a Table
 * @returns { orderedKeys: Column[]; definitions: UseColumnDef<Row, Column>}
 */
export const useColumns = <Row extends TableRowType, Column extends string>(
  opts: MakeColumnsProps<Row, Column>
) => {
  const { definitions, orderedKeys } = useMemo(
    () => ({
      definitions: makeColumnDefinitions(opts),
      orderedKeys: makeOrderedColumnKeys(opts)
    }),
    [
      opts.columns?.orderedKeys,
      opts.columns?.definitions,
      opts.children,
      opts.rowSample
    ]
  );

  return {
    definitions,
    orderedKeys
  };
};
