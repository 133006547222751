import { useMemo } from "react";
import { TableRowActionDef, TableRowsProps, TableRowType } from "../types";
import TableRowsBase from "./RowsBase";
import { isPropsWithNestedRows, withNestedRows } from "./HOC/withNestedRows";

const TableRows = <
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(
  props: TableRowsProps<Row, Column, Action> & { isNested?: boolean }
) => {
  const TableRows = useMemo(() => {
    let TableRows = TableRowsBase;

    // Nested rows
    if (isPropsWithNestedRows(props)) {
      TableRows = withNestedRows(TableRows);
    }

    return TableRows;
  }, [isPropsWithNestedRows(props)]);

  return <TableRows {...props} />;
};

export default TableRows;
