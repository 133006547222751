import { css } from "@emotion/react";

export const styles = {
  columnContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  `,
  scrollTargetContainer: css`
    display: grid;
    grid-template-columns: 0px 1fr 0px;
    flex-grow: 1;
    grid-template-rows: 0px 1fr 0px;
  `,
  topScrollTarget: css`
    transform: translateY(2px);
    grid-column: 1 / -1;
  `,
  rightScrollTarget: css`
    transform: translateX(-2px);
  `
};
