import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { DateTimeRange } from "../../types";
import { UseCalendarArgs, UseCalendarReturn } from "../Picker/Calendar/types";
import { DatePickerProps } from "../Picker/types";
import { DateRangePresets } from "../constants";
import { dateTimeFromAny, isSameDay } from "../utils";
import { useDateTimeMinMax } from "./useDateTimeMinMax";

export const useCalendar = ({
  selected: incomingSelected,
  selecting,
  range,
  min: incomingMin,
  max: incomingMax,
  time,
  setEndToEndOfDay,
  transitionMs = 200,
  setSelected,
  setSelecting
}: UseCalendarArgs): UseCalendarReturn => {
  /** selected Date(s) as DateTime(s) */
  const selected = useMemo<DateTimeRange>(
    () =>
      incomingSelected
        ?.map(date => (date ? dateTimeFromAny(date) : undefined))
        .slice(0, range ? 2 : 1) || [],
    [incomingSelected]
  );

  const getTime = (
    dateTime: DateTime | undefined,
    isEndDate = false
  ): {
    hour: number;
    minute: number;
    second: number;
  } => {
    const res = {
      hour: isEndDate && setEndToEndOfDay ? 23 : 0,
      minute: isEndDate && setEndToEndOfDay ? 59 : 0,
      second: isEndDate && setEndToEndOfDay ? 59 : 0
    };
    if (time && dateTime?.isValid) {
      const { hour, minute, second } = dateTime;
      // if time is set, we want to keep the selected time
      if (!isEndDate || !setEndToEndOfDay || hour || minute || second) {
        res.hour = dateTime.hour;
        res.minute = dateTime.minute;
        res.second = dateTime.second;
      }
    }
    return res;
  };

  /** state of selected times */
  const [times, setTimes] = useState<
    Array<{ hour: number; minute: number; second: number }>
  >([
    getTime(selected[0], !range && setEndToEndOfDay),
    getTime(selected[1], true)
  ]);

  /**
   * keeps times state in sync with selected state,
   * so that when user selects a date,
   * the time is set to the selected time
   */
  useEffect(() => {
    setTimes(prev => {
      if (!time) {
        return [
          {
            hour: 0,
            minute: 0,
            second: 0
          },
          getTime(selected[1], true)
        ];
      }
      if (!selected[0]?.isValid && !selected[1]?.isValid) {
        return prev;
      }
      const newTimes = [...prev];
      if (selected[0]?.isValid) {
        newTimes[0] = {
          hour: selected[0]?.hour || 0,
          minute: selected[0]?.minute || 0,
          second: selected[0]?.second || 0
        };
      }
      if (selected[1]?.isValid) {
        newTimes[1] = getTime(selected[1], true);
      }
      return newTimes;
    });
  }, [selected[0], selected[1], time]);

  const { min, max } = useDateTimeMinMax({
    min: incomingMin,
    max: incomingMax,
    time,
    setEndToEndOfDay
  });

  const [transitioning, setTransitioning] =
    useState<UseCalendarReturn["transitioning"]>();

  /**
   * state setter for array of timeouts, to control date change & transition/animation
   * we only need the setter method — since we are going to read value via state callback
   */
  const setTransitioningTimeouts = useState<NodeJS.Timeout[]>([])[1];

  /**
   * method for getting the currently viewed month(s)
   * @returns [DateTime]: when range === false
   * @returns [DateTime, DateTime]: when range === true
   */
  const getViewing = (value?: DateTime | undefined) => {
    let dateTime: DateTime | undefined;

    if (value?.isValid) {
      dateTime = value;
    } else if (selected?.[0]?.isValid) {
      dateTime = selected?.[0];
    } else if (selected?.[1]?.isValid) {
      dateTime = selected?.[1];
    } else if (
      (!min || DateTime.now() > min) &&
      (!max || DateTime.now() < max)
    ) {
      dateTime = DateTime.now();
    } else if (min) {
      dateTime = min;
    } else if (max) {
      dateTime = max;
    } else {
      dateTime = DateTime.now();
    }

    const newViewing: UseCalendarReturn["viewing"] = [dateTime];
    if (range) {
      newViewing.push(dateTime.plus({ month: 1 }));
    }
    return newViewing;
  };

  /** the currently viewed month(s) */
  const [viewing, setViewing] =
    useState<UseCalendarReturn["viewing"]>(getViewing());

  const dateIsSelected = (value: DateTime | undefined) => {
    if (!value || selected?.length === 0) {
      return false;
    }
    const [start, end] = selected || [];
    if (isSameDay(start, value) || isSameDay(end, value)) {
      return true;
    }
    return false;
  };

  /**
   * @returns boolean: whether date is greater than max or less than min
   */
  const dateIsDisabled = (value: DateTime | undefined) => {
    if (!value?.get("day")) {
      return false;
    }

    if (
      (min && value.startOf("day") < min.startOf("day")) ||
      (max && value.startOf("day") > max.startOf("day"))
    ) {
      return true;
    }
    return false;
  };

  /**
   * @returns boolean: whether given date falls within selected range
   */
  const dateIsInRange = (value: DateTime | undefined) => {
    if (!value || !range) {
      return false;
    }

    const [start, end] = selected || [];

    if (
      start &&
      end &&
      start.startOf("day") < value.startOf("day") &&
      end.startOf("day") > value.startOf("day")
    ) {
      return true;
    }

    return false;
  };

  const error = useMemo<[boolean, boolean]>(
    () => [
      Boolean(
        (min && selected?.[0] && selected?.[0] < min) ||
          (max && selected?.[0] && selected?.[0] > max)
      ),
      Boolean(
        (min && selected?.[1] && selected?.[1] < min) ||
          (max && selected?.[1] && selected?.[1] > max)
      )
    ],
    [selected?.[0], selected?.[1], min, max]
  );

  const months = useMemo<UseCalendarReturn["months"]>(() => {
    return getViewing(viewing[0]).map(viewingMonth => {
      const date = viewingMonth.startOf("month");
      const fillerDays = Array(date.weekday % 7);
      const days = [...fillerDays];
      for (let i = 0; i < (date?.daysInMonth || 0); i++) {
        days.push(date.plus({ days: i }));
      }
      return new Array(Math.ceil(days.length / 7)).fill(null).map((_, week) =>
        new Array(7).fill(null).map((_, day) => {
          const date = days?.[week * 7 + day];
          const isSelected = dateIsSelected(date);
          const [start, end] = selected || [];
          let error = false;
          if (isSelected) {
            if (
              (start && end && start >= end) ||
              (min && date < min.startOf("day")) ||
              (max && date > max.endOf("day"))
            ) {
              error = true;
            }
          }
          const props = {
            isSelected,
            isInRange: dateIsInRange(date),
            isDisabled: dateIsDisabled(date),
            isEmpty: !date?.get("day"),
            error
          };

          return {
            date,
            ...props
          };
        })
      );
    });
  }, [
    min,
    max,
    selected?.[0],
    selected?.[1],
    viewing?.[0],
    viewing?.[1],
    range,
    dateIsSelected,
    dateIsInRange,
    dateIsDisabled,
    getViewing
  ]);

  const selectDay: UseCalendarReturn["selectDay"] = (
    value: DateTime | undefined
  ) => {
    let [start, end] =
      selected.map((dateTime, i) => dateTime?.set(times[i])) || [];

    /**
     * if picking single date, we only want to select one date,
     * and ensure selecting is set to undefined
     */
    if (!range) {
      setSelected([
        isSameDay(value, start) ? undefined : value?.set(times[0])?.toJSDate()
      ]);
      if (selecting) {
        setSelecting?.(undefined);
      }
      return;
    }

    if (
      selecting === "start" &&
      (isSameDay(start, value) || isSameDay(end, value))
    ) {
      if (isSameDay(start, end)) {
        // if clicking on end date when start and end already equal that date, we want to clear the dates
        setSelected([undefined, undefined]);
        setSelecting?.("start");
        return;
      }
      if (end?.isValid) {
        setSelected([value?.set(times[0]).toJSDate(), end.toJSDate()]);
        setSelecting?.("end");
        return;
      }
    }

    if (
      selecting === "end" &&
      (isSameDay(start, value) || isSameDay(end, value))
    ) {
      // if clicking on start date when start and end already equal that date, we want to clear the dates
      if (isSameDay(start, end)) {
        setSelected([undefined, undefined]);
        setSelecting?.("start");
        return;
      }
      if (start?.isValid) {
        setSelected([start?.toJSDate(), value?.set(times[1]).toJSDate()]);
        setSelecting?.(undefined);
        return;
      }
    }

    /**
     * if user selects start day that is already selected,
     * then we want to clear the start selection for user to re-select
     */
    if (isSameDay(value, start)) {
      setSelected([undefined, end?.set(times[1]).toJSDate()]);
      setSelecting?.("start");
      return;
    }
    /**
     * if user selects end day that is already selected,
     * then we want to clear the end selection for user to re-select
     */
    if (isSameDay(value, end)) {
      setSelected([start?.set(times[0]).toJSDate(), undefined]);
      setSelecting?.("end");
      return;
    }

    let newSelecting: DatePickerProps["selecting"];

    if (selecting === "end") {
      newSelecting = undefined;
      end = value?.set({
        ...times[1],
        millisecond: 0
      });
    } else {
      start = value?.set({
        ...times[0],
        millisecond: 0
      });
      if (selecting === undefined) {
        end = undefined;
      }
      newSelecting = "end";
    }

    /**
     * if user selects start date that comes after end date,
     * or selects a end date without start date,
     * selected date is moved to start position
     */
    if ((start && end && start >= end) || (end && !start)) {
      start = value?.set({
        ...times[0],
        millisecond: 0
      });
      end = undefined;
      newSelecting = "end";
    }

    /**
     * if user selects a date that comes before min or after max,
     * and is the same day as the min or max,
     * then we want to set the min or max as the selected date
     */
    if (min) {
      if (start && start < min && isSameDay(start, min)) {
        start = min;
      }
      if (end && end < min && isSameDay(start, min)) {
        end = min;
      }
    }
    if (max) {
      if (start && start > max && isSameDay(start, max)) {
        start = max;
      }
      if (end && end > max && isSameDay(start, max)) {
        end = max;
      }
    }

    if (
      !range ||
      (min && ((start && start < min) || (end && end < min))) ||
      (max && ((start && start > max) || (end && end > max)))
    ) {
      newSelecting = undefined;
    }

    setSelected([start?.toJSDate(), end?.toJSDate()]);

    if (selecting !== newSelecting) {
      setSelecting?.(newSelecting);
    }
  };

  const handleTimeChange: UseCalendarReturn["handleTimeChange"] = (
    monthIndex,
    value
  ) => {
    setSelected(
      monthIndex === 0
        ? [selected?.[0]?.set(value)?.toJSDate(), selected?.[1]?.toJSDate()]
        : [selected?.[0]?.toJSDate(), selected?.[1]?.set(value)?.toJSDate()]
    );
  };

  /**
   * method handles next/prev month button clicks
   * @param nextPrev: "next" | "prev": whether to go to next or previous month
   */
  const handleNextPrev = (nextPrev: "next" | "prev") => {
    setTransitioningTimeouts(prev => {
      if (prev?.length) {
        prev.forEach(timeout => clearTimeout(timeout));
      }

      // triggers ui animation
      setTransitioning(nextPrev);

      // returning new timeouts to setTransitioningTimeouts
      return [
        // changing the date 1/4 of way through the transition
        // to avoid undesired style transitions
        setTimeout(() => {
          const viewingCount = range ? 2 : 1;
          setViewing(
            getViewing(
              viewing[0]?.plus({
                month: nextPrev === "next" ? viewingCount : viewingCount * -1
              })
            )
          );
        }, transitionMs / 4),
        // transition complete
        setTimeout(() => {
          setTransitioning(undefined);
        }, transitionMs)
      ];
    });
  };

  const prevMonth = useMemo<UseCalendarReturn["prevMonth"]>(() => {
    if (min && viewing[0].startOf("month") < min.endOf("month")) {
      return undefined;
    }
    return () => handleNextPrev("prev");
  }, [min, viewing, handleNextPrev]);

  const nextMonth: UseCalendarReturn["nextMonth"] = useMemo(() => {
    if (
      max &&
      viewing[viewing.length - 1].endOf("month") > max.startOf("month")
    ) {
      return undefined;
    }
    return () => handleNextPrev("next");
  }, [max, viewing, handleNextPrev]);

  /* update viewing when selected[0] changes */
  useEffect(() => {
    // if selected month is already viewable, return early
    if (
      !selected?.[0] ||
      (selected?.[0] &&
        !!viewing.find(
          date =>
            date.month === selected?.[0]?.month &&
            date.year === selected?.[0]?.year
        ))
    ) {
      return;
    }
    setViewing(getViewing(selected?.[0]));
  }, [selected?.[0]?.day, selected?.[0]?.month, selected?.[0]?.year]);

  /* update viewing when selected[1] changes */
  useEffect(() => {
    // if selected month is already viewable, return early
    if (
      !selected?.[1] ||
      (selected?.[1] &&
        !!viewing.find(
          date =>
            date.month === selected?.[1]?.month &&
            date.year === selected?.[1]?.year
        ))
    ) {
      return;
    }
    setViewing(getViewing(selected?.[1]?.minus({ month: 1 })));
  }, [selected?.[1]?.day, selected?.[1]?.month, selected?.[1]?.year]);

  /* update viewing to selected "start" date, when range changes */
  useEffect(
    () => setViewing(getViewing(selected?.[0] || viewing?.[0])),
    [range]
  );

  const [presetRange, setPresetRange] = useState<
    DateRangePresets | undefined
  >();

  const handleSetPresetRange = (
    newPresetRange: DateRangePresets | undefined
  ) => {
    setPresetRange(newPresetRange);
    //  if user unselects the preset date range, then we will clear the selected date range
    if (!newPresetRange) {
      setSelected([undefined, undefined]);
      return;
    }
    switch (newPresetRange) {
      case DateRangePresets.LAST_1_DAY:
        setSelected([
          DateTime.now().minus({ days: 1 }).startOf("day").toJSDate(),
          DateTime.now().endOf("day").toJSDate()
        ]);
        break;
      case DateRangePresets.LAST_3_DAYS:
        setSelected([
          DateTime.now().minus({ days: 3 }).startOf("day").toJSDate(),
          DateTime.now().endOf("day").toJSDate()
        ]);
        break;
      case DateRangePresets.LAST_7_DAYS:
        setSelected([
          DateTime.now().minus({ days: 7 }).startOf("day").toJSDate(),
          DateTime.now().endOf("day").toJSDate()
        ]);
        break;
      case DateRangePresets.LAST_30_DAYS:
        setSelected([
          DateTime.now().minus({ days: 30 }).startOf("day").toJSDate(),
          DateTime.now().endOf("day").toJSDate()
        ]);
        break;
      case DateRangePresets.LAST_90_DAYS:
        setSelected([
          DateTime.now().minus({ days: 90 }).startOf("day").toJSDate(),
          DateTime.now().endOf("day").toJSDate()
        ]);
        break;
      case DateRangePresets.ONE_MONTH_AGO:
        setSelected([
          DateTime.local().minus({ months: 1 }).startOf("month").toJSDate(),
          DateTime.local().minus({ months: 1 }).endOf("month").toJSDate()
        ]);
        break;
      case DateRangePresets.TWO_MONTHS_AGO:
        setSelected([
          DateTime.local().minus({ months: 2 }).startOf("month").toJSDate(),
          DateTime.local().minus({ months: 2 }).endOf("month").toJSDate()
        ]);
        break;
      default:
        break;
    }
  };

  return {
    months,
    viewing,
    transitioning,
    error,
    times,
    presetRange,
    nextMonth,
    prevMonth,
    selectDay,
    handleTimeChange,
    setPresetRange: handleSetPresetRange
  };
};
