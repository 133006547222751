import { Provider as ReduxProvider } from "react-redux";
import configureStore from "configureStore";
import { BulkAssetUploadDataProvider } from "features/CreativeLibrary/BulkCreativeUploader/BulkCreativeUploaderDataProvider";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { lookup } from "lib/redux";
import { BulkEditLineItemsContextProvider } from "frontier/lib/instruction/campaign/lineItem/bulkEdit/BulkEditContext";
import { ActivationContextProvider } from "frontier/lib/plan/activation/ActivationContext";
import { FC } from "react";
import Auth from "./Auth";
import CrashScreen from "./CrashScreen";
import { Footer } from "./Footer/Footer";
import SmithersRouter from "./Router/SmithersRouter";
import ToasterProvider from "./Toaster/Toaster";
import SmithersToolbar from "./Toolbar/SmithersToolbar";
import { ApplicationSettingsProvider } from "./ApplicationSettingsProvider";
import OrganizationSettings from "./OrganizationSettings";

const store = configureStore();
lookup.store = store;

const SmithersBrowserRouter: FC = () => (
  <ReduxProvider store={store}>
    <ToasterProvider>
      <Auth>
        <OrganizationSettings>
          <ApplicationSettingsProvider>
            <BulkEditLineItemsContextProvider>
              <ActivationContextProvider>
                <BulkAssetUploadDataProvider>
                  <SmithersToolbar />
                  <CrashScreen>
                    <SmithersRouter />
                  </CrashScreen>
                </BulkAssetUploadDataProvider>
              </ActivationContextProvider>
            </BulkEditLineItemsContextProvider>
            <Footer />
          </ApplicationSettingsProvider>
        </OrganizationSettings>
      </Auth>
    </ToasterProvider>
  </ReduxProvider>
);

const router = createBrowserRouter([
  { path: "*", element: <SmithersBrowserRouter /> }
]);

const Smithers: FC = () => (
  <RouterProvider
    router={router}
    /**
     * The react-router code that suppresses upgrade warnings looks for `v7_relativeSplatPath`
     * on this object (the typing seems to erroneously omit it though):
     * https://github.com/remix-run/react-router/blob/948d00bbbd82e402b8835eba7e5810ce523a26df/packages/react-router/lib/deprecations.ts#L34
     */
    //@ts-ignore
    future={{ v7_startTransition: false, v7_relativeSplatPath: false }}
  />
);

export default Smithers;
