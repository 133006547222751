import { createElement, PropsWithChildren } from "react";
import styled from "@emotion/styled";

import {
  TypographyProps,
  TextColor,
  TextVariant,
  TEXT_VARIANT_TO_FONT_SIZE
} from "./types";

const BaseComponent = (props: PropsWithChildren<TypographyProps>) => {
  const { children, asElement, className } = props;

  let elementType = "p";

  if (asElement) {
    elementType = asElement;
  } else if (props.variant && !props.variant.includes("p")) {
    elementType = props.variant;
  }

  const element = createElement(
    elementType,
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    { className, ["data-testid"]: props["data-testid"] },
    children
  );

  return element;
};

/** TODO: rethink Typography https://madhive.atlassian.net/browse/BASE-2181 */

export const Typography = styled(BaseComponent)`
  color: ${props => props.color || TextColor.DEFAULT};
  font-size: ${props =>
    props.variant
      ? TEXT_VARIANT_TO_FONT_SIZE[props.variant]
      : TEXT_VARIANT_TO_FONT_SIZE[TextVariant.BODY]};
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  font-style: ${props => (props.italic ? "italic" : "normal")};
  font-weight: ${props =>
    props.bold ? "var(--font-weight-bold)" : "var(--font-weight-normal)"};
  text-transform: ${props =>
    props.uppercase ? "uppercase" : props.capitalize ? "capitalize" : "none"};
  text-overflow: ${props => (props.ellipsize ? "ellipsis" : "inherit")};
  overflow: ${props => (props.ellipsize ? "hidden" : "inherit")};
  white-space: ${props => (props.ellipsize ? "nowrap" : "inherit")};
  margin-bottom: 0;
`;
