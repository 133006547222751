import { css } from "@emotion/react";
import classNames from "classnames";
import { buildMediaQuery } from "lib/utils/styles";
import "./Layout.scss";
import { FC, HTMLProps, memo } from "react";

const classes = {
  common: css`
    & > *:not(:last-child) {
      &:not(.SmithersBreadcrumbs) {
        margin-bottom: 10px;
      }
    }
  `,
  defaultWidth: css`
    ${buildMediaQuery.min("lg")} {
      width: 1560px;
    }
    ${buildMediaQuery.between("md", "xl")} {
      width: 1275px;
    }
    ${buildMediaQuery.between("sm", "lg")} {
      width: 900px;
    }
    ${buildMediaQuery.max("md")} {
      width: unset;
      margin: unset;
    }
    margin: 0px auto;
  `,
  narrowWidth: css`
    ${buildMediaQuery.min("xs")} {
      width: 500px;
    }
    ${buildMediaQuery.max("sm")} {
      width: unset;
      margin: unset;
    }
    margin: 0px auto;
  `,
  layoutFullPage: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  `,
  fullWidth: css`
    margin: var(--spacing-0) var(--spacing-32);
  `,
  noMargin: css`
    margin: var(--spacing-0);
  `
};

interface LayoutProps {
  fillPage?: boolean;
  fullWidth?: boolean;
  narrowWidth?: boolean;
  noMargin?: boolean;
}

const Layout: FC<LayoutProps & HTMLProps<HTMLDivElement>> = ({
  className,
  children,
  fillPage,
  fullWidth,
  narrowWidth,
  noMargin,
  ...restProps
}) => {
  return (
    <div
      className={classNames(!noMargin ? "Smithers-Layout" : "", className)}
      css={css(
        fullWidth ? classes.fullWidth : null,
        noMargin ? classes.noMargin : classes.common,
        narrowWidth ? classes.narrowWidth : null,
        !narrowWidth && !fullWidth && classes.defaultWidth,
        fillPage ? classes.layoutFullPage : null
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default memo(Layout);
