import { Children, FC } from "react";
import { CardProps, CardType } from "./types";
import { styles } from "./styles";

/**
 * `Card` is used to group and set apart information on a page.
 */
const Card: FC<CardProps> = ({
  title,
  children,
  titleSuffix,
  actions,
  dense = false,
  noPadding = false,
  type = CardType.primary
}) => {
  const hasChildren = !!Children.count(children);
  const hasHeader = !!title || !!titleSuffix || !!actions;

  return (
    <section className="kit-Card" css={styles.container}>
      {hasHeader && (
        <div data-testid="card-header-container" css={styles.headerContainer}>
          {(!!title || !!titleSuffix) && (
            <div data-testid="card-title-container" css={styles.headerTitle}>
              {!!title && (
                <h4 data-testid="card-title" css={styles.title}>
                  {title}
                </h4>
              )}
              {!!titleSuffix && titleSuffix}
            </div>
          )}
          {!!actions && (
            <div data-testid="card-actions" css={styles.headerActions}>
              {actions}
            </div>
          )}
        </div>
      )}
      {hasChildren && (
        <div
          data-testid="card-children"
          css={[
            styles.card,
            dense && styles.dense,
            noPadding && styles.noPadding,
            type && styles[type as keyof typeof styles]
          ]}
        >
          {children}
        </div>
      )}
    </section>
  );
};

export default Card;
