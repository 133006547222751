import { ServiceStatus } from "@madhive/mad-sdk";
import { omit } from "lodash";
import {
  ABORT_PIXEL_REQUEST,
  GET_ALL_PIXELS_FAILURE,
  GET_ALL_PIXELS_PENDING,
  GET_ALL_PIXELS_SUCCESS,
  GET_PIXEL_HISTORY_FAILURE,
  GET_PIXEL_HISTORY_PENDING,
  GET_PIXEL_HISTORY_SUCCESS,
  PixelActionTypes,
  SAVE_PIXEL_FAILURE,
  SAVE_PIXEL_PENDING,
  SAVE_PIXEL_SUCCESS,
  TrackingPixelsState
} from "./types";

export const pixelsInitialState: TrackingPixelsState = {
  // pixels
  byId: {},
  availableById: {},
  archivedById: {},
  isPixelsLoading: false,
  isPixelSaving: false,
  updatingIds: [],
  receivedAllAt: null,
  pixelsError: null,
  // pixel history
  pixelHistoryById: {},
  isPixelHistoryLoading: false,
  receivedPixelHistoryAt: null,
  pixelHistoryError: null
};

export const trackingPixelsReducer = (
  state = pixelsInitialState,
  action: PixelActionTypes
): TrackingPixelsState => {
  switch (action.type) {
    case GET_ALL_PIXELS_PENDING:
      return {
        ...state,
        pixelsError: null,
        isPixelsLoading: true
      };
    case GET_ALL_PIXELS_FAILURE:
      return {
        ...state,
        pixelsError: action.meta.error.message,
        isPixelsLoading: false
      };
    case GET_ALL_PIXELS_SUCCESS: {
      return {
        ...state,
        byId: action.payload.reduce((byId, pixel) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[pixel.id] = pixel;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        ...action.payload.reduce(
          (accumulator, pixel) => {
            const { status, id } = pixel;
            const { availableById, archivedById } = accumulator;

            if (status === ServiceStatus.READY) {
              /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
              availableById[id] = pixel;
            }

            if (status === ServiceStatus.ARCHIVED) {
              /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
              archivedById[id] = pixel;
            }

            return accumulator;
          },
          {
            availableById: {},
            archivedById: {}
          }
        ),
        pixelsError: null,
        isPixelsLoading: false,
        receivedAllAt: action.meta.timestamp
      };
    }
    case GET_PIXEL_HISTORY_PENDING:
      return {
        ...state,
        isPixelHistoryLoading: true,
        pixelsError: null
      };
    case GET_PIXEL_HISTORY_FAILURE:
      return {
        ...state,
        isPixelHistoryLoading: false,
        pixelHistoryError: action.meta.error.message
      };
    case GET_PIXEL_HISTORY_SUCCESS: {
      return {
        ...state,
        pixelHistoryById: action.payload.reduce((byId, pixelHistory) => {
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          if (byId[pixelHistory.id]) {
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            byId[pixelHistory.id].push(pixelHistory);
          } else {
            /* eslint-disable no-param-reassign */
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            byId[pixelHistory.id] = [pixelHistory];
            /* eslint-enable no-param-reassign */
          }
          return byId;
        }, {}),
        isPixelHistoryLoading: false,
        receivedPixelHistoryAt: action.meta.timestamp,
        pixelHistoryError: null
      };
    }
    case SAVE_PIXEL_PENDING:
      return {
        ...state,
        updatingIds: action.meta
          ? [...new Set(state.updatingIds.concat(action.meta.pixelId))]
          : state.updatingIds,
        isPixelSaving: true
      };
    case SAVE_PIXEL_SUCCESS: {
      const {
        pixel,
        pixel: { status, id }
      } = action.payload;

      const baseFields = {
        ...state,
        byId: {
          ...state.byId,
          [id]: pixel
        },
        updatingIds: state.updatingIds.filter(updatingId => updatingId !== id),
        isPixelSaving: false
      };

      if (status === ServiceStatus.READY) {
        return {
          ...baseFields,
          availableById: {
            ...state.availableById,
            [id]: pixel
          },
          archivedById: omit(state.archivedById, id)
        };
      }

      if (status === ServiceStatus.ARCHIVED) {
        return {
          ...baseFields,
          availableById: omit(state.availableById, id),
          archivedById: {
            ...state.archivedById,
            [id]: pixel
          }
        };
      }

      return {
        ...baseFields
      };
    }
    case SAVE_PIXEL_FAILURE:
      return {
        ...state,
        updatingIds: action.meta.pixelId
          ? state.updatingIds.filter(id => id !== action.meta.pixelId)
          : state.updatingIds,
        isPixelSaving: false,
        pixelsError: action.meta.error.message
      };
    case ABORT_PIXEL_REQUEST:
      return {
        ...state,
        pixelsError: null,
        pixelHistoryError: null,
        isPixelsLoading: false,
        isPixelSaving: false,
        isPixelHistoryLoading: false
      };
    default:
      return state;
  }
};
