import { Fragment } from "react";
import { css } from "@emotion/react";
import { styles } from "./styles";
import { defaultLookupId, lookupElementsByDisplayName } from "../utils";
import {
  RowActionsOnTable,
  TableProps,
  TableRowActionDef,
  TableRowType
} from "./types";
import { DoNotCare, isIdType } from "../types";
import { useColumns } from "./hooks";
import Icon from "../Icon";
import Checkbox from "../Checkbox";
import TableRows from "./Rows";

const tableHasRowActions = (
  rowActions: RowActionsOnTable<any> | undefined,
  children: DoNotCare
) => {
  if (!rowActions && !children) {
    return false;
  }
  return (
    !!rowActions?.list?.some(row => isIdType(row) || !row?.hidden) ||
    !!lookupElementsByDisplayName("Table.RowAction", children).find(
      child => !child.props.hidden
    ) ||
    !!rowActions?.render
  );
};

/**
 * BaseTable contains all the core functionality of the Table component.
 * All [Prefix]Table components are built on top of this component.
 */
function TableBase<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>({
  columns,
  data = [],
  children,
  sorting,
  rowActions,
  selectableRows,
  rowTestId,
  lookupRowId = defaultLookupId,
  tableRef,
  nestedRows
}: TableProps<Row, Column, Action>) {
  const { orderedKeys, definitions } = useColumns({
    columns,
    rowSample: data[0],
    children,
    sorting
  });
  const hasRowActions = tableHasRowActions(rowActions, children);

  const displaySelectAll = typeof selectableRows?.isAllSelected === "function";
  const isAllSelected = selectableRows?.isAllSelected?.();
  const isAllSelectedIndeterminate = isAllSelected === "indeterminate";

  return (
    <table
      ref={tableRef}
      css={[
        styles.table,
        css`
          --kit-table-column-count: ${orderedKeys.length};
          grid-template-columns:
            repeat(var(--kit-table-column-count), minmax(max-content, auto))
            ${hasRowActions ? "3rem" : ""};
        `
      ]}
    >
      <thead className="subKit-TableHeader" css={[styles.tableHeader]}>
        <tr css={[styles.tr]}>
          {orderedKeys.map((columnKey: Column, columnIndex) => {
            const column = definitions[columnKey];
            return (
              <Fragment key={String(column.key)}>
                <th
                  css={[
                    styles.th,
                    column.isSortable && styles.thSortable,
                    column.key === sorting?.key && styles.thSorted
                  ]}
                  aria-sort={
                    sorting?.key === column.key && sorting?.direction
                      ? (`${sorting?.direction}ending` as const)
                      : undefined
                  }
                  className={column.isSortable ? "sortable" : ""}
                  role={column.isSortable ? "button" : undefined}
                  onClick={
                    column.isSortable
                      ? () => {
                          column.onSort?.(
                            sorting?.key === column.key &&
                              sorting?.direction === "asc"
                              ? "desc"
                              : "asc"
                          );
                        }
                      : undefined
                  }
                >
                  {columnIndex === 0 && !!selectableRows && (
                    <div css={[styles.cellPrefix]}>
                      {displaySelectAll && (
                        <Checkbox
                          checked={isAllSelected === true}
                          indeterminate={isAllSelectedIndeterminate}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          onChange={event => {
                            selectableRows.onToggleAll?.(
                              event.currentTarget.checked
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
                  {column.label}
                  {sorting?.key === column.key && (
                    <Icon
                      name="Chevron"
                      direction={sorting?.direction === "asc" ? "up" : "down"}
                    />
                  )}
                </th>

                {/* row actions */}
                {columnIndex === orderedKeys.length - 1 && hasRowActions && (
                  <th
                    css={[styles.th, styles.actionsCell]}
                    aria-label="actions"
                  >
                    <span />
                  </th>
                )}
              </Fragment>
            );
          })}
        </tr>
      </thead>

      <tbody css={styles.tableBody}>
        <TableRows
          data={data}
          columns={columns}
          lookupRowId={lookupRowId}
          rowTestId={rowTestId}
          rowActions={rowActions}
          selectableRows={selectableRows}
          nestedRows={nestedRows}
        >
          {children}
        </TableRows>
      </tbody>
    </table>
  );
}

export default TableBase;
