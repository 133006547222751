import {
  DeprecatedSegmentsState,
  SegmentsActionTypes,
  GET_ALL_SEGMENTS_PENDING,
  GET_ALL_SEGMENTS_FAILURE,
  GET_ALL_SEGMENTS_SUCCESS,
  ABORT_GET_ALL_SEGMENTS_REQUEST
} from "./types";

export const initialState: DeprecatedSegmentsState = {
  byId: {},
  isAllLoading: false,
  loadAllCancellationSource: null,
  receivedAllAt: null
};

export const deprecatedSegmentsReducer = (
  state = initialState,
  action: SegmentsActionTypes
): DeprecatedSegmentsState => {
  switch (action.type) {
    case GET_ALL_SEGMENTS_PENDING:
      return {
        ...state,
        isAllLoading: true,
        loadAllCancellationSource: action.meta.cancellationSource
      };
    case GET_ALL_SEGMENTS_FAILURE:
      return {
        ...state,
        isAllLoading: false,
        loadAllCancellationSource: null
      };
    case GET_ALL_SEGMENTS_SUCCESS:
      return {
        ...state,
        byId: action.payload.reduce((byId, segment) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[segment.id] = segment;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        isAllLoading: false,
        loadAllCancellationSource: null,
        receivedAllAt: action.meta.timestamp
      };
    case ABORT_GET_ALL_SEGMENTS_REQUEST:
      /**
       * If the cancellation source is not the same as in state,
       * the application is aborting because it dispatched a
       * new GET_ALL_SEGMENTS_PENDING. It should therefore avoid
       * overriding loading indication changes from that newer action.
       */
      return state.loadAllCancellationSource === action.meta.cancellationSource
        ? {
            ...state,
            isAllLoading: false,
            loadAllCancellationSource: null
          }
        : {
            ...state,
            loadAllCancellationSource: state.isAllLoading
              ? action.meta.cancellationSource
              : null
          };
    default:
      return state;
  }
};
