import { useEffect, useRef, useCallback, RefObject } from "react";

export type UseIntersectionObserverProps = {
  /**
   * Callback to be executed when the element is intersect
   * @param entries: Array of IntersectionObserverEntry objects
   */
  onIntersection: (entries: IntersectionObserverEntry[]) => void;
  /**
   * Element that is used as the viewport for checking visibility of the target.
   * @default: the browser viewport
   */
  root?: RefObject<Element | null>;
  /**
   * Margin around the root. Can have values similar to the CSS margin property,
   * e.g. "10px 20px 30px 40px".
   */
  rootMargin?: string;
  /**
   * Either a single number or an array of numbers which indicate at what percentage
   * of the target's visibility the observer's callback should be executed.
   */
  threshold?: number | number[];
  /**
   * If true, the observer will not be created.
   */
  disabled?: boolean;
};

/**
 * Hook to observe an element for intersection.
 */
const useIntersectionObserver = ({
  onIntersection,
  root,
  rootMargin,
  threshold,
  disabled = false
}: UseIntersectionObserverProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const elementRef = useRef<Element | null>(null);

  const setElementRef = useCallback(
    (node: Element | null) => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      if (disabled) {
        return;
      }

      if (node) {
        observerRef.current = new IntersectionObserver(
          args => {
            onIntersection(args);
          },
          {
            root: root?.current || document.querySelector("root"),
            rootMargin,
            threshold
          }
        );
        observerRef.current.observe(node);
      }

      elementRef.current = node;
    },
    [onIntersection, root, rootMargin, threshold, disabled]
  );

  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return setElementRef;
};

export default useIntersectionObserver;
