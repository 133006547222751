import { useMemo } from "react";
import { TableRowType, TableProps } from "..";
import { TableRowActionDef } from "../types";
import { WrappedTableComponent, WrappedTableProps } from "./types";
import Spinner from "../../Spinner";

export const isTablePropsWithLoadingState = (props: TableProps<TableRowType>) =>
  !!props.loadingState;

export function tableWithLoadingState<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: WrappedTableComponent<Row, Column, Action>) {
  /**
   * Table that provides the custom loading state element to the table.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const { loadingState: _loadingState = {} } = props as WrappedTableProps<
      Row,
      Column,
      Action
    >;

    const loadingState = useMemo(
      () =>
        "props" in _loadingState ? (
          _loadingState
        ) : (
          <Spinner {..._loadingState} />
        ),
      [_loadingState]
    );

    return <WrappedTable {...props} INTERNAL_loadingState={loadingState} />;
  };
}
