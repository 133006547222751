import {
  __DEV__,
  HOSTNAME_TO_FIREBASE_PROJECT,
  FIREBASE_PROJECT_TO_GOOGLE_ENDPOINTS
} from "lib/constants/config";
import MadSDK, { KnownProjectIds } from "@madhive/mad-sdk";
import {
  REACT_APP_CI_BASE_URL,
  CI,
  VITE_SMITHERS_VERSION
} from "lib/viteEnvConfig";
import { readFirebaseEnvironmentFromQueryString } from "./utils/firebase";

const defaultProjectID = CI ? KnownProjectIds.MAD_QA : KnownProjectIds.MAD_HACK;

// SET FIREBASE CONFIG BASED ON DEV ENVIRONMENT OR DOMAIN
// need to switch to have projectId pulled from MadSDK
/* eslint-disable-next-line import/no-mutable-exports */
let projectId = defaultProjectID;
if (__DEV__) {
  const maybeTargetFirebaseProject = readFirebaseEnvironmentFromQueryString(
    window.location.search
  );

  if (maybeTargetFirebaseProject) {
    projectId = maybeTargetFirebaseProject;
  }
} else {
  const d = window.location.hostname;
  const previewUrl =
    "^(https://)?pr-[0-9]+---edge-[A-z0-9]{10}-[A-z]{2}.a.run.app$";
  const isPreview = new RegExp(previewUrl).test(REACT_APP_CI_BASE_URL);
  if (isPreview) {
    projectId = KnownProjectIds.MADHIVE_TESTING;
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
  } else if (d && HOSTNAME_TO_FIREBASE_PROJECT[d]) {
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    projectId = HOSTNAME_TO_FIREBASE_PROJECT[d];
  }
}

/* eslint-disable no-console */

// INITIALIZE FIREBASE AND FIRESTORE
if (__DEV__) {
  console.log("=========================================================");
  console.log(
    "DEV ENV: ",
    __DEV__,
    " FIREBASE PROJECT ID: ",
    projectId,
    " COMMIT: ",
    VITE_SMITHERS_VERSION
  );
  console.log("=========================================================");
}

const minimumFetchIntervalMillis =
  __DEV__ && projectId !== KnownProjectIds.MAD_MASTER ? 240000 : 14400000;

const madSDK = new MadSDK({
  firebaseConfig: {
    projectId,
    storageId: KnownProjectIds.MAD_DATA,
    minimumFetchIntervalMillis,
    debug: __DEV__
  },
  googleEndpointProjectId:
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    FIREBASE_PROJECT_TO_GOOGLE_ENDPOINTS[projectId] || defaultProjectID,
  baseCIUrl: REACT_APP_CI_BASE_URL
});
Object.freeze(madSDK);

/**
 * Inject the madSDK into the global scope so we can call
 * madSDK commands in the chrome dev tools / console
 */
if (__DEV__ || projectId !== KnownProjectIds.MAD_MASTER) {
  // @ts-ignore
  global.madSDK = madSDK;
}

export { projectId as firebaseProjectId, madSDK };
