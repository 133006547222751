import React, { useMemo } from "react";
import {
  Dialog as BlueprintDialog,
  DialogBody,
  DialogFooter
} from "@blueprintjs/core";
import Button from "../Button";
import Icon from "../Icon";
import { ModalProps } from "./types";
import { styles } from "./styles";
import { getIconFromStringOrElement } from "../Icons/utils";

const icons = {
  info: "Circled.Info",
  warning: "Warning",
  success: "Circled.Check",
  error: "Circled.Exclamation"
} as const;

/**
 * Modal is used to display a modal dialog. It is a wrapper around Blueprint's Dialog component.
 */
const Modal: React.FC<ModalProps> = ({
  actions,
  leftActions,
  isOpen,
  children,
  onClose,
  title,
  intent,
  showCloseButton = false,
  showTitleBorder = false,
  showFooterBorder = false,
  carousel = false,
  large = false,
  usePortal = true,
  portalContainer = document.body,
  cssOverrideModal
}) => {
  const intentIcon = useMemo(
    () =>
      intent &&
      getIconFromStringOrElement(icons[intent], {
        size: "medium"
      }),
    [intent]
  );

  return (
    <BlueprintDialog
      isOpen={isOpen}
      lazy
      onClose={e => {
        onClose && onClose(e);
      }}
      css={[
        styles.wrapper,
        carousel && styles.carouselWrapper,
        large && styles.largeWrapper,
        carousel && cssOverrideModal
      ]}
      usePortal={usePortal}
      portalContainer={portalContainer}
      canEscapeKeyClose
      data-testid="kit-modal"
    >
      {(!!title || !!showCloseButton) && (
        <span css={[styles.container, showTitleBorder && styles.titleBorder]}>
          {title && (
            <div css={styles.header}>
              {intent && (
                <div css={[styles.icon, styles[intent]]}>{intentIcon}</div>
              )}
              <div css={carousel ? styles.carouselTitle : styles.title}>
                {title}
              </div>
            </div>
          )}
          {showCloseButton && (
            <Button
              variant="ghost"
              onClick={e => {
                onClose && onClose(e);
              }}
              css={styles.closeButton}
              ariaLabel="close modal"
              prefix={
                <Icon
                  name="X"
                  size="medium"
                  fill="var(--color-border-primary)"
                />
              }
            />
          )}
        </span>
      )}
      <DialogBody css={[styles.body, large && styles.bodyLarge]}>
        {children}
      </DialogBody>
      {(leftActions || actions) && (
        <DialogFooter
          css={[styles.footer, showFooterBorder && styles.footerBorder]}
        >
          <div css={styles.actions}>
            <div css={styles.leftActions}>{leftActions}</div>
            {actions}
          </div>
        </DialogFooter>
      )}
    </BlueprintDialog>
  );
};

export default Modal;
