import { DoNotCare } from "@madhive/mad-sdk";
import { lookupElementsByDisplayName } from "../../../utils";
import { TableRowType, ColumnKeys, TableProps } from "../..";
import { ColumnDef } from "../../types";
import { getAdditionalChildrenKeys } from "../../utils";
import {
  DerivedColumnDefinition,
  shouldExcludeColumn,
  DerivedColumnDef
} from "./ColumnDefinition";

/**
 * Properties needed to make column definitions and derive ordered keys.
 */
export type MakeColumnsProps<
  Row extends TableRowType = TableRowType,
  Column extends string = string
> = {
  columns?: TableProps<Row, Column>["columns"];
  sorting?: TableProps<Row, Column>["sorting"];
  children?: DoNotCare;
  rowSample: Row;
};

/**
 * Determines if any column definitions (found in columns or children)
 * define an `onSort` event handler.
 */
function isSortFnDefinedOnAnyColumn({
  columns,
  children
}: MakeColumnsProps<DoNotCare, DoNotCare>) {
  let hasSortFn = Object.values(columns?.definitions || {}).some(
    ({ onSort }: ColumnDef<DoNotCare>) => typeof onSort === "function"
  );
  if (!hasSortFn) {
    hasSortFn = lookupElementsByDisplayName("Table.Column", children).some(
      ({ onSort }: DoNotCare) => typeof onSort === "function"
    );
  }
  return hasSortFn;
}

/**
 * Finds all the column keys based on:
 * - array of keys passed to `columns.orderedKeys`
 * - keys on `columns.definitions`
 * - Table.Column found in `children`
 */
function findAllColumnKeys<Row extends TableRowType, Column extends string>(
  props: MakeColumnsProps<Row, Column>
) {
  const foundKeys = [...(props.columns?.orderedKeys || [])];

  const { rowSample, children, columns: { definitions = {} } = {} } = props;

  // if `columns` are explicity defined, those are the columnKeys
  if (foundKeys.length) {
    return [...foundKeys];
  }

  // check `columns` for keys
  foundKeys.push(
    ...(Object.keys(definitions) as Array<ColumnKeys<Row, Column>>)
  );

  // if no keys in `columns`, we grab from `rowSample`
  if (!foundKeys?.length) {
    foundKeys.push(
      ...((Array.isArray(rowSample)
        ? rowSample
        : Object.keys(rowSample || {})) as Array<ColumnKeys<Row, Column>>)
    );
  }

  // last, we gather any additional keys found on Table.Column in `children`
  const keysInChildren = getAdditionalChildrenKeys(
    "Table.Column",
    foundKeys,
    children
  ) as Array<ColumnKeys<Row, Column>>;

  return [...foundKeys, ...keysInChildren];
}

/**
 * Uses `findAllColumnKeys` to fetch full list,
 * and returns a filtered down list of columns that do not have `hidden: true`
 */
export function makeOrderedColumnKeys<
  Row extends TableRowType,
  Column extends string
>(props: MakeColumnsProps<Row, Column>, allKeys = findAllColumnKeys(props)) {
  const { children, columns: { definitions } = {} } = props;

  return allKeys.filter(
    (key: Column) => !shouldExcludeColumn(key, children, definitions?.[key])
  );
}

/**
 * Derives the columns.definitions object by merging values of:
 * - opts.columns.definitions
 * - props on Table.Column in `children`
 * - derived from other values — in some cases (like sorting)
 */
export function makeColumnDefinitions<
  Row extends TableRowType,
  Column extends string
>(props: MakeColumnsProps<Row, Column>) {
  const { children, sorting = {}, rowSample = {} as Row } = props;
  const someColumnsHaveSortFn = isSortFnDefinedOnAnyColumn(props);

  const allKeys = findAllColumnKeys(props);

  const definitions = {} as {
    [k in Column]: DerivedColumnDef<Row, Column>;
  };

  allKeys.forEach((columnKey: Column, columnIndex) => {
    const def = new DerivedColumnDefinition<Row, Column>(
      columnKey,
      columnIndex,
      {
        children,
        sorting,
        rowSample,
        columnDef: props.columns?.definitions?.[columnKey],
        someColumnsHaveSortFn
      }
    );

    if (!def.hidden) {
      definitions[columnKey] = def;
    }
  });

  return definitions;
}
