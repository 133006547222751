import {
  createContext,
  useContext,
  memo,
  useState,
  useRef,
  useEffect,
  useMemo
} from "react";
import { useResizeObserver } from "hooks/useResizeObserver";
import { debounce, isEqual } from "lodash";
import Paginator from "./paginator";
// not sure if rows will have different heights
import { DEFAULT_ROW_HEIGHT } from "./style";
import TableContent from "./tableContent";
import TableFooter from "./tableFooter";
import TableHeader from "./tableHeader";
import TableWrapper from "./tableWrapper";
import { RenderCell, SortDirection, TableProps, ToggleSelected } from "./types";
import { getIsUnchangedRenderDependencies } from "./utils";

export interface ColumnContextBase {
  columnCount: number;
  canFixRows: boolean;
  renderRow?: (item: any) => JSX.Element[];
  renderSubrow?: (item: any) => JSX.Element[];
  rowHeight: number;
  rowHeights?: number[];
  rightMenuContentRenderFn?: (...args: any) => JSX.Element;
  data: any[];
  lengthOfDataPlusSubRows: number;
  page: number;
  selectable: boolean;
  toggleSelected?: ToggleSelected;
  itemKey?: string;
  selectedIds?: Record<string, boolean>;
  disableSubRowSelection?: boolean;
  hasSubrows: boolean;
  subRowKey?: string;
  openRows: Set<number>;
  toggleSubrows: (parentRowIndex: number) => void;
  stickyRows: number[];
  toggleStickyRow: (rowIndex: number) => void;
  rowClassname?: (item: any) => string | undefined;
  onRowClick?: (item: any) => void;
}

interface CellContext {
  renderCell?: RenderCell;
}

const rowContext = createContext<ColumnContextBase | null>(null);
const cellContext = createContext<CellContext | null>(null);

export const useRowContext = () => {
  const contextValue = useContext(rowContext);
  if (!contextValue) throw new Error("Row context error in table");
  return contextValue;
};
export const useCellContext = () => {
  const contextValue = useContext(cellContext);
  if (!contextValue) throw new Error("Cell context error in table");
  return contextValue;
};

const Table = memo(
  (props: TableProps) => {
    const [doesVanillaListenerExist, setDoesVanillaListenerExist] =
      useState(false);
    const [scrollIndex, setScrollIndex] = useState(0);
    const [leftScrollIndex, setLeftScrollIndex] = useState(0);
    const [parentHeight, setParentHeight] = useState(0);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const getScrollParent = (): Element | null => {
      if (props.scrollParentClassName)
        return (
          wrapperRef?.current?.closest(`.${props.scrollParentClassName}`) ||
          null
        );
      return wrapperRef?.current || null;
    };

    const isScrollParentExist = Boolean(getScrollParent());

    const scrollParentHeight = isScrollParentExist
      ? getScrollParent()?.getBoundingClientRect().height || 0
      : 0;

    const handleScroll = () => {
      setScrollIndex(() => {
        const parent = getScrollParent();
        const newY = parent?.scrollTop || 0;
        const scrollIndexPosition = Math.round(newY / DEFAULT_ROW_HEIGHT);
        return scrollIndexPosition;
      });
    };

    const handleHorizontalScroll = () => {
      const newLeftScrollIndex = getScrollParent()?.scrollLeft;

      if (typeof newLeftScrollIndex !== "number") return;

      const hasSwitchedLeftToRight =
        leftScrollIndex === 0 && newLeftScrollIndex > 0;

      const hasSwitchedRightToLeft =
        leftScrollIndex > 0 && newLeftScrollIndex === 0;

      const shouldReRender = hasSwitchedLeftToRight || hasSwitchedRightToLeft;

      // only one rerender per scroll in one direction
      if (shouldReRender) {
        setLeftScrollIndex(newLeftScrollIndex);
      }
    };

    const calcInitialWidths = (): number[] => {
      if (!wrapperRef?.current) return props.columns.map(c => c.width || 100);
      const totalWidth = props.columns.reduce(
        (acc, curr) => acc + (curr.width || 0),
        0
      );
      const parentWidth = wrapperRef.current.getBoundingClientRect().width;
      if (totalWidth >= parentWidth)
        return props.columns.map(c => c.width || 100);
      return props.columns.map(c => {
        const orig = c.width || 100;
        return (orig * parentWidth) / totalWidth;
      });
    };

    // some part of this is in columns section to avoid duplication
    const [columnWidth, setColumnWidthLocal] = useState(calcInitialWidths());

    const updateColWidthsOnWindowResize = () =>
      setColumnWidthLocal(calcInitialWidths());

    useEffect(() => {
      if (isScrollParentExist) {
        setParentHeight(scrollParentHeight);

        const parent = getScrollParent();

        if (parent && !doesVanillaListenerExist) {
          setDoesVanillaListenerExist(true);
          parent.addEventListener("scroll", debounce(handleScroll, 50));
        }

        updateColWidthsOnWindowResize();
      }
    }, [scrollParentHeight, isScrollParentExist]);

    // adjust column width when user adds or removes columns
    useEffect(() => {
      updateColWidthsOnWindowResize();
    }, [props.columns]);

    // adjust column width when size of parent wrapper changes
    useResizeObserver(wrapperRef, debounce(updateColWidthsOnWindowResize, 500));

    // local pagination
    const [page, setPage] = useState(0);

    // --- columns
    const setColumnWidth = (columnIndex: number, width: number) => {
      const newColumns = [...columnWidth];
      newColumns[columnIndex] = width;
      setColumnWidthLocal(newColumns);
    };

    const [stickyColumns, setStickyColumns] = useState<number[]>(
      props.columns.reduce((acc, curr, index) => {
        if (!curr.sticky) return acc;
        return [...acc, index];
      }, [])
    );

    const setStickyColumn = (columnIndex: number) => {
      if (stickyColumns.includes(columnIndex)) {
        setStickyColumns(
          stickyColumns.filter((s: number) => s !== columnIndex)
        );
        return;
      }
      setStickyColumns([...stickyColumns, columnIndex]);
    };

    const calcStickyColumns = (): { columnIndex: number; left: number }[] => {
      let lastLeft = 0;
      return stickyColumns
        .sort((a: number, b: number) => a - b)
        .map((columnIndex: number) => {
          lastLeft += columnWidth[columnIndex];

          return {
            columnIndex,
            left: lastLeft - columnWidth[columnIndex]
          };
        });
    };

    const [sortColumn, setSortColumnLocal] = useState(
      props.isSortedExternally
        ? -1
        : props.columns.findIndex(col => col.defaultSort) > -1
          ? props.columns.findIndex(col => col.defaultSort)
          : 0
    );

    const [sortDirection, setSortDirection] = useState(SortDirection.ASCENDING);

    const setSortColumn = (columnIndex: number) => {
      if (sortColumn === columnIndex) {
        setSortDirection(prev =>
          prev === SortDirection.DESCENDING
            ? SortDirection.ASCENDING
            : SortDirection.DESCENDING
        );
        return;
      }

      setSortDirection(SortDirection.ASCENDING);
      setSortColumnLocal(columnIndex);
    };

    // --- rows
    const [stickyRows, setStickyRows] = useState<number[]>([]);
    const [openRows, setOpenRows] = useState<Set<number>>(new Set());
    const [numberOfRows, setNumberOfRows] = useState<number>(
      props.numberOfRows || 0
    );

    useEffect(() => {
      setNumberOfRows(prev => props.numberOfRows || prev);
    }, [props.numberOfRows]);

    useEffect(() => {
      setParentHeight(getScrollParent()?.getBoundingClientRect().height || 0);
    }, [numberOfRows]);

    // just to prevent initial call to sort function
    const sortColumnOld = useRef(sortColumn);

    const sortDirectionOld = useRef(SortDirection.ASCENDING);

    useEffect(() => {
      if (
        sortColumnOld.current === sortColumn &&
        sortDirectionOld.current === sortDirection
      )
        return; // prevent init call

      setStickyRows([]);

      setPage(0); // or no?
      // also remove selected rows here? I think it makes no sense
      // setSelectedRows({})
      const column = props.columns[sortColumn];

      if (!column.sortFunction && !props.onSort) return; // assert there is some sorting function

      sortColumnOld.current = sortColumn;

      sortDirectionOld.current = sortDirection;

      if (props.onSort) {
        props.onSort(column.id, sortColumn, sortDirection);

        return;
      }

      column.sortFunction && column.sortFunction(sortDirection);
    }, [sortColumn, sortDirection]);

    const rowContextValue: ColumnContextBase = useMemo(
      () => ({
        columnCount: props.columns.length,
        canFixRows: !!props.canFixRows,
        renderRow: props.renderRow,
        renderSubrow: props.renderSubrow,
        renderFooter: props.renderFooter,
        rowHeight: DEFAULT_ROW_HEIGHT,
        ...(props.isRowHeightDynamic
          ? {
              rowHeights: props.data.map(
                datum => datum.height || DEFAULT_ROW_HEIGHT
              )
            }
          : {}),
        rightMenuContentRenderFn: props.rightMenuContentRenderFn,
        data: props.data,
        lengthOfDataPlusSubRows: props.data.reduce(
          (acc, curr) =>
            acc + 1 + ((props.subRowKey && curr[props.subRowKey]?.length) || 0),
          0
        ),
        page,
        selectable: !!props.toggleSelected,
        toggleSelected: props.toggleSelected,
        disableSubRowSelection: props.disableSubRowSelection,
        itemKey: props.itemKey,
        selectedIds: props.selectedIds,
        hasSubrows:
          Boolean(props.subRowKey) &&
          props.data.some(row => row[props.subRowKey!]) &&
          Boolean(props.renderSubrow),
        subRowKey: props.subRowKey,
        openRows,
        rowClassname: props.rowClassname,
        onRowClick: props.onRowClick,
        toggleSubrows: (parentRowIndex: number) => {
          if (openRows.has(parentRowIndex)) {
            setOpenRows(
              prev =>
                new Set(Array.from(prev).filter(r => r !== parentRowIndex))
            );
            return;
          }
          setOpenRows(prev => new Set([...prev, parentRowIndex]));
        },
        stickyRows,
        toggleStickyRow: (rowIndex: number) => {
          if (stickyRows.includes(rowIndex)) {
            setStickyRows(prev => prev.filter(s => s !== rowIndex));
            return;
          }
          setStickyRows(prev => [...prev, rowIndex].sort((a, b) => a - b));
        }
      }),
      [
        stickyRows,
        openRows,
        props.data,
        page,
        props.selectedIds,
        props.renderDependencies
      ]
    );

    const getOuterRowsOnPage = (): any[] => {
      if (numberOfRows)
        return props.data.slice(page * numberOfRows, (page + 1) * numberOfRows);

      return props.data;
    };

    const getExistingRowCount = () => getOuterRowsOnPage().length;

    const getOpenSubRowCount = (): number =>
      getOuterRowsOnPage().reduce(
        (acc, curr, rowIndex) =>
          acc +
          (openRows.has(rowIndex)
            ? (props.subRowKey && curr[props.subRowKey]?.length) || 0
            : 0),
        0
      );

    // --- cells
    const cellContextValue = useMemo(
      () => ({
        renderCell: props.renderCell
      }),
      [props.renderCell]
    );

    return (
      <rowContext.Provider value={rowContextValue}>
        <cellContext.Provider value={cellContextValue}>
          <TableWrapper
            ref={wrapperRef}
            stickyColumns={calcStickyColumns()}
            widths={columnWidth}
            containContentHeight={!!props.scrollParentClassName}
            leftItemCount={
              [
                !!props.toggleSelected,
                props.subRowKey && props.data.some(row => row[props.subRowKey!])
              ].filter(i => i).length
            }
            maxHeight={props.maxHeightCssString}
            isScrolledRight={leftScrollIndex > 0}
            onScroll={handleHorizontalScroll}
          >
            <TableHeader
              columns={props.columns}
              widths={columnWidth}
              toggleSelected={props.toggleSelected}
              sortColumn={props.isSortedExternally ? -1 : sortColumn}
              sortDirection={sortDirection}
              setSortColumn={setSortColumn}
              canFixColumns={props.canFixColumns}
              stickyColumns={stickyColumns}
              setStickyColumn={setStickyColumn}
              setColumnWidth={setColumnWidth}
              setOpenRows={setOpenRows}
            />
            <TableContent
              rowHeight={props.rowHeight || DEFAULT_ROW_HEIGHT}
              parentHeight={parentHeight}
              rowCount={getExistingRowCount()}
              openSubRowCount={getOpenSubRowCount()}
              scrollIndex={scrollIndex}
              indexAccountingForPage={page * (numberOfRows || 0)}
              subRowKey={props.subRowKey}
              renderDependencies={props.renderDependencies}
            />
            {props.renderFooter && (
              <TableFooter renderFooter={props.renderFooter} />
            )}
          </TableWrapper>
          {!!numberOfRows && (
            <Paginator
              page={page}
              setPage={setPage}
              totalRows={props.data.length}
              rowsPerPage={numberOfRows}
              setRowPerPage={setNumberOfRows}
              possibleNumberOfRows={props.possibleNumberOfRows}
            />
          )}
        </cellContext.Provider>
      </rowContext.Provider>
    );
  },
  (prev, next) =>
    getIsUnchangedRenderDependencies(
      prev.renderDependencies,
      next.renderDependencies
    ) &&
    isEqual(prev.data, next.data) &&
    isEqual(prev.columns, next.columns) &&
    prev.numberOfRows === next.numberOfRows &&
    prev.selectedIds === next.selectedIds
);

export default Table;
