import { css } from "@emotion/react";
import { kitText } from "../../styles";

export const styles = {
  wrapper: css`
    width: 30rem;
    min-height: 12.5rem;
    background-color: var(--color-background-primary);
    margin: var(--spacing-0);
    padding: var(--spacing-24) 0;
    & > * {
      // left & right padding is applied to children to push scroll bar to the edge of container
      padding-left: var(--spacing-24);
      padding-right: var(--spacing-24);
    }
  `,
  carouselWrapper: css`
    width: 47rem;
    height: 28rem;
  `,
  largeWrapper: css`
    width: 85%;
    height: 80%;
    position: absolute;
    padding: var(--spacing-16) 0;
  `,
  container: css`
    display: flex;
    flex: 0 0 auto;
    margin-bottom: var(--spacing-16);
    align-items: start;
    width: 100%;
  `,
  header: css`
    display: flex;
    flex: 1 1 auto;
  `,
  title: css`
    ${kitText.variant.heading3};
  `,
  carouselTitle: css`
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-extrabold);
  `,
  closeButton: css`
    border: 0;
    margin-left: auto;
    bottom: var(--spacing-4);
  `,
  icon: css`
    margin-right: var(--spacing-8);
  `,
  info: css`
    --icon-fill-color: var(--color-info);
  `,
  warning: css`
    --icon-fill-color: var(--color-warning);
  `,
  success: css`
    --icon-fill-color: var(--color-success);
  `,
  error: css`
    --icon-fill-color: var(--color-danger);
  `,
  body: css`
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-0);
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    ${kitText.color.secondary}
    ${kitText.variant.body}
  `,
  bodyLarge: css`
    justify-content: normal;
    gap: var(--spacing-40);
    margin-top: var(--spacing-16);
  `,
  footer: css`
    border: 0;
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-0);
    margin-top: var(--spacing-16);
  `,
  actions: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,
  leftActions: css`
    flex: 1;
  `,
  cancelButton: css`
    padding: var(--spacing-8) 0;
  `,
  carouselHeader: css`
    padding-bottom: var(--spacing-24);
  `,
  titleBorder: css`
    border-bottom: 1px solid var(--color-border-secondary);
    margin-bottom: var(--spacing-16);
    padding-bottom: var(--spacing-16);
  `,
  footerBorder: css`
    border-top: 1px solid var(--color-border-secondary);
    margin-top: var(--spacing-16);
    padding-top: var(--spacing-16);
  `
};
