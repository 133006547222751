import { Divider } from "@blueprintjs/core";
import { ViewId, UserType, KnownOrganizationIds } from "@madhive/mad-sdk";
import { Routes, INTERNAL_VIEWS } from "lib/constants/routes";
import { uniqBy } from "lodash";
import { RemoteConfigProperties, useFeatureFlag } from "hooks/useFeatureFlag";
import {
  selectCurrentUser,
  selectProductionViews
} from "appReducers/authReducer";
import { useSelector } from "react-redux";
import { css } from "@emotion/react";
import { Drawer } from "frontier/lib/components";
import { Icons } from "frontier/lib/kit";
import ListItem from "frontier/lib/components/List/ListItem";
import {
  FC,
  useMemo,
  Fragment,
  PropsWithChildren,
  useState,
  useEffect
} from "react";
import { useLocation } from "react-router-dom";
import useManageDealsView from "hooks/useManageDealsView";
import {
  mapViewIdToView,
  SORT_INDEX_FOR_UNKNOWN_VIEW,
  OrderedViewCategories,
  ViewCategories
} from "./Router/utils";
import { AllowedOnly } from "./Router/WithAuth";
import InternalViewLink from "./InternalViewLink";
import ViewLink from "./ViewLink";

export const style = {
  menuDrawer: css`
    .DrawerCloseContainer {
      min-height: 64px;
      background-color: var(--white);
    }
  `,
  activeStyle: css`
    color: var(--black);
    ::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      border-left: 2px solid var(--color-splash-primary);
      border-radius: var(--spacing-4);
    }
  `,
  listContainer: (active: boolean = false) => css`
    padding: var(--spacing-8) var(--spacing-24);
    color: var(--color-secondary);
    ${active ? style.activeStyle : undefined}
  `,
  fullList: css`
    width: auto;
  `,
  linkText: css`
    padding: 0;
    color: var(--color-secondary);
    text-decoration: none !important;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-12);
  `,
  listItemIcon: css`
    min-width: 40px !important;
    color: var(--semi-dark);
  `,
  close: css`
    font-size: 2em;
  `,
  categoryHeading: css`
    font-size: var(--font-size-12);
  `,
  divider: css`
    margin: 0px;
  `,
  listItemContainer: css`
    padding: var(--spacing-24) var(--spacing-24) var(--spacing-8)
      var(--spacing-24);
  `,
  sectionHeading: css`
    border: none;
    cursor: default;
    color: var(--color-secondary);
  `,
  deepWaterHeading: css`
    font-size: var(--font-size-12);
    margin-top: 1em !important;
    padding: 0 var(--spacing-24);
  `
};

const ViewHeading: FC<PropsWithChildren> = props => {
  const { children } = props;
  // If there is no category, don't render the heading.
  if (!children) {
    return null;
  }
  return (
    <p
      css={[
        style.sectionHeading,
        style.categoryHeading,
        style.listItemContainer
      ]}
    >
      {children}
    </p>
  );
};

interface ViewSelectorDrawerProps {
  isOpen: boolean;
  views: ViewId[];
  width: number;
  onDrawerClose: () => void;
}

const ViewSelectorDrawer: FC<ViewSelectorDrawerProps> = ({
  views,
  isOpen,
  onDrawerClose,
  width
}) => {
  const user = useSelector(selectCurrentUser);
  const { primaryOrganizationId } = user!;

  const orgProductionViews = useSelector(selectProductionViews);

  const canViewManageDeals = useManageDealsView();

  const isFiveMinSeparationEnabled: boolean = useFeatureFlag(
    RemoteConfigProperties.INTERNAL_FIVE_MIN_SEPARATION
  );
  const isPublisherMangerEnabled: boolean = useFeatureFlag(
    RemoteConfigProperties.PUBLISHER_MANAGER
  );

  const isAudienceDataProvisioningEnabled: boolean = useFeatureFlag(
    RemoteConfigProperties.ENABLE_AUDIENCE_DATA_PROVISIONING
  );

  // Only Madhive admin users can access Audience Data Provisioning
  const canViewAudienceDataProvisioning =
    primaryOrganizationId === KnownOrganizationIds.MADHIVE_DEFAULT &&
    user?.isAdmin;

  // Called once here so useLocation doesn't get called twice and violate hook rules
  const currentPath = useLocation().pathname;

  const [activeItem, setActiveItem] = useState(currentPath);

  // Changes in ADOPS-1115 causes the location href to change to "/dev" on first logins only.
  // This path doesn't represent the current page so doesn't set the correct active menu item.
  // This useEffect assists with first logins, otherwise the default useState handles navigation and page refreshes.
  useEffect(() => {
    if (isOpen && activeItem === Routes.LOGIN_LOCAL) {
      setActiveItem(currentPath);
    }
  }, [isOpen]);

  /**
   * Note @MaximeHeckel:
   * Users might have views enabled that are no longer active / enabled at the org level.
   * If a view is turned off at the org level, it should be turned off as well at the user level.
   *
   * Hence, we need to filter out any user views that are not present in the org view list.
   */
  /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
  const filteredViews = views.filter(view => orgProductionViews[view]);
  /** Deduplicate any views that lead to the same URL. */
  const hydratedViews = useMemo(
    () =>
      uniqBy(
        filteredViews.map(viewId => ({
          viewId,
          ...mapViewIdToView(viewId, orgProductionViews)
        })),
        view => view.href
      )
        .filter(view => view.sortIndex !== SORT_INDEX_FOR_UNKNOWN_VIEW)
        .filter(view => view.category !== ViewCategories.UNKNOWN),
    [filteredViews, orgProductionViews]
  );
  /** Generate a mapping of ordered categories to its view links */
  const categoriesToViews = useMemo(
    () =>
      OrderedViewCategories.reduce((mapping, category) => {
        const orderedViewsInCategory = hydratedViews
          .filter(view => view.category === category)
          .sort((viewA, viewB) => viewA.sortIndex - viewB.sortIndex);
        if (orderedViewsInCategory.length) {
          return mapping.set(category, orderedViewsInCategory);
        }
        return mapping;
      }, new Map()),
    [hydratedViews]
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onDrawerClose}
      position="left"
      size="315px"
      css={style.menuDrawer}
    >
      <div
        css={css`
          width: ${width};
        `}
      >
        <ListItem
          button
          tabIndex={0}
          onClick={onDrawerClose}
          className="DrawerCloseContainer"
          css={style.listItemContainer}
        >
          <Icons.Display size="small" fill="var(--color-secondary)">
            <Icons.Arrow direction="left" />
          </Icons.Display>
        </ListItem>
        <Divider css={style.divider} />
        {[...categoriesToViews].map(
          ([category, categoryViews]: [
            string,
            Array<{
              href: string;
              viewId: string;
              icon: JSX.Element;
              title: string;
            }>
          ]) => (
            <Fragment key={category}>
              <ViewHeading>{category}</ViewHeading>
              {categoryViews.map(view => (
                <ViewLink
                  key={view.viewId}
                  activeItem={activeItem}
                  onClick={() => {
                    setActiveItem(view.href);
                    onDrawerClose();
                  }}
                  {...view}
                />
              ))}
            </Fragment>
          )
        )}
        <AllowedOnly userType={UserType.DEV}>
          <ViewHeading>Internal Tools</ViewHeading>
          {INTERNAL_VIEWS.filter(view => {
            if (view.route === Routes.MANAGE_DEALS) {
              return canViewManageDeals;
            }
            if (view.route === Routes.PUBLISHER_MANAGER) {
              return isPublisherMangerEnabled;
            }
            if (view.route === Routes.FIVE_MIN_SEPARATION) {
              return isFiveMinSeparationEnabled;
            }
            if (view.route === Routes.AUDIENCE_DATA_PROVISIONING) {
              return (
                isAudienceDataProvisioningEnabled &&
                canViewAudienceDataProvisioning
              );
            }
            return true;
          }).map(view => (
            <InternalViewLink
              key={view.route}
              to={view.route}
              text={view.name}
              onClick={() => {
                setActiveItem("");
                onDrawerClose();
              }}
            />
          ))}
        </AllowedOnly>
      </div>
    </Drawer>
  );
};

export default ViewSelectorDrawer;
