import { FilterTypes, ObservableBuilder, isPage } from "@madhive/mad-sdk";
// RSJ: feels like we shouldn't have the types in dependencies vs devDependencies so the eslint-disable-ing here unless there's a better solution
// eslint-disable-next-line import/no-extraneous-dependencies
import deepEqual from "deep-equal";
import { useState, useEffect, useCallback } from "react";
import { cleanupCollection, cleanupSubscribers, getObservableFromData, isObservable } from "../rxjs";
import { useDeepCompareEffect } from "../utils";
import { useListMadHooksUtils } from "./listUtils";
import { useMadHooksCacheSubscription } from "./useMadHooksCacheSubscription";
import { usePagination } from "./usePagination";
import { getSingularDataDefaults } from "./singularUtils";
import { peerChangesHandler } from "../modules";
/**
 * Loads list data by collection and optional filter/sort
 * @param collection Collection the data belongs to
 * @param options Optional 'where'/'pagination' filters and sort to apply to madSDK
 * @returns ListData
 */
export const useLoadListData = (collection, options) => {
    const { filters, sort, pagination, setSort, setPageMetadata, onStalePage: setToPageZero } = usePagination(options);
    const { observer, handler } = useListMadHooksUtils(collection, { filters, sort });
    const [listData, setListData] = useState(observer.value);
    const [madHooksCacheSubscriptions, setMadHooksCacheSubscriptions] = useState(new Map());
    const [count, setCount] = useState(0);
    const refresh = () => setCount((prevCount) => prevCount + 1);
    // ensures that pagination filters are updated when a new behavior subject is subscribed to.
    useEffect(() => {
        if (observer.value.pageMetadata) {
            setPageMetadata(observer.value.pageMetadata);
        }
    }, [observer]);
    useEffect(() => {
        if (!observer.value.isLoaded)
            return undefined;
        const idKey = handler.getIdentifierKey();
        const resourceId = observer.value.data?.length && observer.value.data[0]?.[idKey];
        if (!resourceId) {
            return undefined;
        }
        let subscription;
        peerChangesHandler
            .startListeningList(resourceId, handler.id, (saved) => {
            if (saved.heardAs === "new" ||
                observer.value.data?.some((item) => item[idKey] === saved.resourceId)) {
                observer.next({
                    ...observer.value,
                    isStale: true
                });
                return true;
            }
            return false;
        })
            .then((sub) => {
            subscription = sub;
        });
        return () => {
            subscription?.unsubscribe();
            peerChangesHandler.stopListening(resourceId);
        };
    }, [observer.value.isLoading, observer.value.isLoaded]);
    useMadHooksCacheSubscription(observer, (value) => {
        if (value.isStale && !value.isLoading) {
            refresh();
            if (options.paginate) {
                // any stale data means that current api pagination is also stale and must be reset to page 0
                setToPageZero();
            }
        }
        // it is a rare occurance but there are chances a filter with
        // a single id might arrive in a list request which causes the
        // observable cache to return a non-array value
        let data = value.data ? [...value.data] : [];
        if (value.data !== undefined && !Array.isArray(value.data)) {
            data = [value.data];
        }
        // create up byId map
        const byId = data?.reduce((acc, next) => {
            acc.set(handler.getIdentifier(next), next);
            return acc;
        }, new Map());
        setListData({ ...value, byId, data });
    }, [filters, sort]);
    let madSDKSubscription;
    // cleans up subscriptions to singular data objects
    useEffect(() => () => {
        madHooksCacheSubscriptions.forEach(([subscription, opts]) => {
            subscription?.unsubscribe();
            cleanupSubscribers(collection, opts);
        });
        cleanupSubscribers(collection, { filters, sort });
    }, [madHooksCacheSubscriptions]);
    // cleans up all unobserved data objects belonging to collection on unmount
    useEffect(() => () => cleanupCollection(collection, new Set(madHooksCacheSubscriptions.keys())), []);
    /**
     * Function called when rxjs gets an update on a single piece of data that is in our list
     */
    const nextSingularData = useCallback((value) => {
        if (value.data) {
            let modified = false;
            let scratchData = [];
            const data = observer.value.data || [];
            if (value.isDeleted) {
                // purge it
                scratchData = data.filter((listItem) => handler.getIdentifier(listItem) !== handler.getIdentifier(value.data));
                modified = true;
            }
            else {
                scratchData = data.map((listItem) => {
                    if (value.data &&
                        handler.getIdentifier(value.data) === handler.getIdentifier(listItem)) {
                        if (!deepEqual(value.data, listItem)) {
                            modified = true;
                            return value.data;
                        }
                    }
                    return listItem;
                });
            }
            if (modified && !observer.value.isLoading) {
                observer.next({
                    ...observer.value,
                    data: scratchData
                });
            }
        }
    }, [observer]);
    /**
     * Adds a single piece of data to rxjs and creates a subscription to it for updates
     */
    const setSingularDataSubscription = useCallback((coll, data) => {
        const myFilters = {
            where: [
                {
                    field: handler.getIdentifierKey(),
                    type: FilterTypes.EQ,
                    value: handler.getIdentifier(data)
                }
            ]
        };
        const { observable, key } = getObservableFromData(collection, { filters: myFilters, key: `${handler.getIdentifierKey()}:${handler.getIdentifier(data)}` }, {
            data,
            ...getSingularDataDefaults()
        });
        if (observable && !madHooksCacheSubscriptions.has(key)) {
            const subscription = observable.subscribe({
                next: nextSingularData
            });
            madHooksCacheSubscriptions.set(key, [subscription, { filters: myFilters }]);
            setMadHooksCacheSubscriptions(madHooksCacheSubscriptions);
        }
    }, [madHooksCacheSubscriptions]);
    /**
     * loads data from the api when not already loading
     */
    const load = () => {
        const current = observer.value;
        if (current.isInitializing && !current.isLoading && !options.notReady) {
            observer.next({
                ...current,
                isLoading: true,
                isInitializing: false,
                isLoaded: false,
                data: []
            });
            // @ts-expect-error - TODO: determine if this is a ts issue or needs to be figured out
            const result = handler.find(filters || { where: [] }, sort);
            if (isObservable(result) || result instanceof ObservableBuilder) {
                // handle Observable stuff
                // resubscribe to not leave dangling subscriptions
                madSDKSubscription?.unsubscribe();
                madSDKSubscription = result.subscribe({
                    next(value) {
                        if (isPage(value)) {
                            for (const item of value.data) {
                                // create individual subscriptions to each data item
                                setSingularDataSubscription(collection, item);
                            }
                            // ensures that pagination filters are updated when new page metadata is received from the API
                            setPageMetadata(value.page);
                            observer.next({
                                data: value.data,
                                pageMetadata: value.page,
                                isLoading: true,
                                isLoaded: false,
                                isDeleted: false,
                                isInitializing: false,
                                refresh
                            });
                        }
                        else {
                            // create individual subscriptions to each data item
                            for (const item of value) {
                                setSingularDataSubscription(collection, item);
                            }
                            observer.next({
                                data: value,
                                isLoading: true,
                                isLoaded: false,
                                isDeleted: false,
                                isInitializing: false,
                                refresh
                            });
                        }
                    },
                    complete() {
                        observer.next({
                            ...observer.value,
                            isLoading: false,
                            isLoaded: true,
                            isInitializing: false,
                            isStale: false
                        });
                    },
                    error(error) {
                        observer.next({
                            data: [],
                            isLoading: false,
                            isLoaded: false,
                            isDeleted: false,
                            isInitializing: false,
                            error,
                            refresh
                        });
                    }
                });
            }
            else {
                // handle Promise stuff
                result
                    .then((data) => {
                    // create individual subscriptions to each data item
                    for (const item of data) {
                        setSingularDataSubscription(collection, item);
                    }
                    observer.next({
                        data: data,
                        isLoading: false,
                        isLoaded: true,
                        isDeleted: false,
                        isStale: false,
                        isInitializing: false,
                        refresh
                    });
                })
                    .catch((error) => observer.next({
                    data: [],
                    isLoading: false,
                    isLoaded: false,
                    isDeleted: false,
                    isInitializing: false,
                    refresh,
                    error
                }));
            }
        }
    };
    // triggers data load when not already loading or loaded
    useDeepCompareEffect(() => {
        const current = observer.value;
        // calls madsdk if first observer
        if (current.isInitializing) {
            load();
        }
        // clean up resources
        return () => {
            madSDKSubscription?.unsubscribe();
        };
    }, [observer]);
    // used to trigger a reload of data
    useEffect(() => {
        if (count > 0) {
            observer.next({
                ...observer.value,
                isInitializing: true
            });
            load();
        }
    }, [count]);
    return {
        ...listData,
        isLoading: listData.isInitializing || listData.isLoading,
        pagination,
        sort,
        setSort,
        refresh
    };
};
