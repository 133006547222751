import { css } from "@emotion/react";

export const small = css`
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.15),
    0px 2px 8px 0px rgba(0, 0, 0, 0.15);
`;

export const large = css`
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.25),
    0px 8px 24px 0px rgba(0, 0, 0, 0.25);
`;

const overflowShadowValue = "0px 0px 8px 0px rgba(0, 0, 0, 0.15)";

const clipPathValues = {
  bottom: "inset(0px 0px -8px 0px)",
  top: "inset(-8px 0px 0px 0px)",
  left: "inset(0px 0px 0px -8px)",
  right: "inset(0px -8px 0px 0px)"
};

export const overflow = {
  all: css`
    box-shadow: ${overflowShadowValue};
  `,
  bottom: css`
    box-shadow: ${overflowShadowValue};
    clip-path: ${clipPathValues.bottom};
  `,
  top: css`
    box-shadow: ${overflowShadowValue};
    clip-path: ${clipPathValues.top};
  `,
  left: css`
    box-shadow: ${overflowShadowValue};
    clip-path: ${clipPathValues.left};
  `,
  right: css`
    box-shadow: ${overflowShadowValue};
    clip-path: ${clipPathValues.right};
  `,
  values: {
    shadow: overflowShadowValue,
    clipPath: clipPathValues
  }
};
