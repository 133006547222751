/**
 * Credit to: https://stackoverflow.com/a/160583/7915435
 * @param str
 */
export const isValidUnitedStatesZip = (str: string) =>
  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(str);

export const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
/* eslint-disable no-useless-escape */
const advertiserDomainRegex = /^(?:[a-z0-9\-]+\.)+[a-z]{2,}$/;

export const isWholeNumber = (number: number) => Math.floor(number) === number;

export const isValidJson = (jsonBlob: string) => {
  try {
    return JSON.parse(JSON.stringify(jsonBlob));
  } catch (e) {
    console.error("Failed to read query string. param:", e);
    // Throwing the error here will block toast from showing
    return false;
  }
};

export const isValidObject = <T extends {}>(
  maybeObject: T | undefined | null
): maybeObject is T => !!maybeObject && typeof maybeObject === "object";

export const validateAdvertiserDomain = (
  advertiserDomain: string
): string | null => {
  if (advertiserDomain.match(advertiserDomainRegex)) {
    return null;
  }
  return 'Invalid advertiser domain format. Please enter the domain in the format "adomain.com".';
};
