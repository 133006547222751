export enum CardType {
  primary = "primary",
  secondary = "secondary"
}

export type CardProps = {
  /**
   * The `title` literal to specify the title of the card.
   * @type {string | undefined}
   */
  title?: string;

  /**
   * Any element element to append to the title. Multiple elements can be passed as a React Fragment.
   */
  titleSuffix?: React.ReactNode;

  /**
   * Any element to be placed on the right side of the action bar. Multiple elements can be passed as a React Fragment.
   */
  actions?: React.ReactNode;
  /**
   * React children prop
   */
  children: React.ReactNode | React.ReactNode[];
  /**
   * Makes paddings smaller
   * @default false
   */
  dense?: boolean;
  /**
   * Removes padding
   * @default false
   */
  noPadding?: boolean;
  /**
   * Styles the card as a nested card
   * @default false
   */
  type?: CardType;
};
