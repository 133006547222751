import { useEffect, useRef, DragEvent, FC } from "react";
import { DraggableItemProps } from "./types";
import { styles } from "./styles";
import Icon from "../Icon";
import { CLASS_NAME, DRAG_INDEX_NAME, DROP_POSITION } from "./constants";
import { DraggableItemDropTarget } from "./internal/DraggableItemDropTarget";

export const DraggableItem: FC<DraggableItemProps> = ({
  useHandle = true,
  index = 0,
  disabled = true,
  onDrop,
  children
}) => {
  const listItemRef = useRef<HTMLDivElement>(null);
  const ghostRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // If implementation already has a draggable element, its events will bubble up.
    // Otherwise, set the item itself to be draggable.
    if (
      !disabled &&
      !useHandle &&
      !listItemRef.current?.querySelector("[draggable=true")
    ) {
      listItemRef.current?.setAttribute("draggable", "true");
    }
  }, []);

  if (disabled) return <>{children}</>;

  const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData(DRAG_INDEX_NAME, index.toString());
    ghostRef.current && e.dataTransfer.setDragImage(ghostRef.current, 0, 0);

    // Manipulating DOM in the same tick as `dragstart` cancels it, so this is defered with setTimeout.
    setTimeout(() => {
      listItemRef.current?.parentElement?.classList.add(
        CLASS_NAME.CONTAINS_DRAGGING
      );
      listItemRef.current?.classList.add(CLASS_NAME.DRAGGABLE_DRAGGING);
    });
  };

  const handleDragEnd = () => {
    listItemRef.current?.parentElement?.classList.remove(
      CLASS_NAME.CONTAINS_DRAGGING
    );
    listItemRef.current?.classList.remove(CLASS_NAME.DRAGGABLE_DRAGGING);
  };

  const handleDrop = (
    indexOffset: DROP_POSITION,
    event: DragEvent<HTMLDivElement>
  ) => {
    const fromIndex = parseInt(event.dataTransfer.getData(DRAG_INDEX_NAME), 10);
    const toIndex = index + indexOffset;

    // Item was dropped on or after itself (same order).
    if (toIndex === fromIndex || toIndex === fromIndex + 1) {
      return;
    }

    onDrop && onDrop(fromIndex, toIndex);
    handleDragEnd();
  };

  return (
    <div
      css={styles.item}
      ref={listItemRef}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
      {useHandle && (
        <div draggable="true">
          <Icon name="DragHandle" />
        </div>
      )}
      <DraggableItemDropTarget onDrop={handleDrop} />
      <div css={styles.ghost} ref={ghostRef} />
    </div>
  );
};
