import { ComponentProps, ReactNode } from "react";
import ButtonGroup, { ButtonGroupProps } from "../Button/Group";
import PageHeader, { PageHeaderProps } from "../Page/Header";

/**
 * Use `Table.Heading` in `Table.children` to add a header section above table.
 * ### Use:
 * ```tsx
 * <Table {...}>
 *
 *   <Table.Heading
 *    key="header"
 *    titleSize="large"
 *    actions={<Button onClick={createNewThing}>New</Button>}
 *   >
 *    My Title
 *   </Table.Heading>
 *
 *   <Table.Heading
 *    key="subheader"
 *    actions={[
 *      <Input key="search" prefix="Search" {...} />,
 *      <Button key="filters" prefix="FilterList" {...} />
 *    ]}
 *   >
 *    <MyTabs />
 *   </Table.Heading>
 *
 * </Table>
 * ```
 */
export function TableHeading({
  children,
  actions,
  titleSize = "small",
  bordered = true
}: {
  /**
   * The title is rendered above the table — to the left
   */
  children?: ReactNode;
  /**
   * The header actions rendered above the table — to the right
   */
  actions?: ButtonGroupProps["children"];
  /**
   * The size of the title
   * @default "small"
   */
  titleSize?: "small" | "medium" | "large";
  /**
   * Whether a border should appear at the bottom of the header
   * @default true
   */
  bordered?: PageHeaderProps["bordered"];
}) {
  let subheadProp: PageHeaderProps["subhead"] = "small";
  if (titleSize === "medium") {
    subheadProp = true;
  }
  if (titleSize === "large") {
    subheadProp = undefined;
  }
  return (
    <PageHeader
      title={children}
      subhead={subheadProp}
      bordered={bordered}
      flush
      notSticky
    >
      {actions ? <ButtonGroup>{actions}</ButtonGroup> : null}
    </PageHeader>
  );
}

export type TableHeadingProps = ComponentProps<typeof TableHeading>;

TableHeading.displayName = "Table.Heading";
