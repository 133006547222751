import { css } from "@emotion/react";
import { kitText, kitShadow } from "../../styles";

export const styles = {
  container: css`
    margin: 0 auto;
    width: 100%;
  `,
  card: css`
    ${kitShadow.small}
    background: var(--color-background-secondary);
    border-radius: 0.625rem;
    padding: var(--spacing-32);
  `,
  dense: css`
    padding: var(--spacing-16) var(--spacing-32);
  `,
  noPadding: css`
    padding: 0;
  `,
  headerContainer: css`
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing-16);
  `,
  headerTitle: css`
    flex-grow: 1;
    gap: var(--spacing-8);
    display: flex;
    align-items: center;
  `,
  headerActions: css`
    gap: var(--spacing-8);
    display: flex;
    align-items: center;
  `,
  title: css`
    ${kitText.variant.heading4}
    margin: 0;
  `,
  spacer: css`
    margin: 1.5rem 0;
    height: 1px;
    border: 0px solid var(--color-shadow-primary);
    background-color: var(--color-shadow-primary);
  `,
  secondary: css`
    padding: var(--spacing-16);
    gap: var(--spacing-16);
    background: var(--color-background-tertiary);
    border: 1px solid var(--color-border-secondary);
    box-shadow: none;
    border-radius: var(--spacing-8);
  `
};
