import React, { FocusEvent, useState, useImperativeHandle } from "react";
import { css } from "@emotion/react";
import { DoNotCare } from "../../types";
import Chip from "../Chip";
import { ChipsProps } from "./types";
import { styles } from "./styles";

/** `Chips` is a container of `Chip` components that adds keyboard accessible actions for navigating and removing chips */
const Chips = <Item extends Record<string, DoNotCare> | string>({
  items = [],
  readonly,
  fill,
  disabled,
  emptyContent,
  gapSpacing = "4",
  setFocusRef,
  onChipsBlur,
  separator: separatorFn,
  lookupId,
  lookupIntent,
  lookupLabel,
  lookupIcon,
  lookupReadonly,
  onChange,
  onClick,
  onFocus,
  onBlur,
  onKeyUp,
  onKeyDown,
  intent
}: ChipsProps<Item>) => {
  const [focusedChip, setFocusedChip] = useState<number | undefined>();

  function getNextFocusIndex(
    currentIndex: number,
    direction: "next" | "prev"
  ): number {
    if (currentIndex < 0) {
      return -1;
    }
    if (currentIndex >= items.length) {
      return Infinity;
    }
    const directionValue = direction === "next" ? 1 : -1;
    const nextIndex = currentIndex + directionValue;
    const nextItem = items[nextIndex];
    if (nextItem && !lookupReadonly?.(nextItem)) {
      return nextIndex;
    }
    return getNextFocusIndex(nextIndex, direction);
  }

  const handleSetFocusedChip = (
    chipIndex: number | undefined,
    direction: "next" | "prev" = "next"
  ) => {
    if (chipIndex === undefined) {
      return setFocusedChip(undefined);
    }
    if (items[chipIndex] && !lookupReadonly?.(items[chipIndex])) {
      return setFocusedChip(chipIndex);
    }

    const nextFocusIndex = getNextFocusIndex(chipIndex, direction);

    if (nextFocusIndex === Infinity) {
      return onChipsBlur?.("next");
    }
    if (nextFocusIndex < 0) {
      return onChipsBlur?.("prev");
    }

    setFocusedChip(nextFocusIndex);
  };

  useImperativeHandle(
    setFocusRef,
    () => (focusedChip: number | undefined) => handleSetFocusedChip(focusedChip)
  );

  const separator = separatorFn?.();

  const removeChip = (index: number, maintainFocus = false) => {
    const newItems = [...items];
    const item = newItems.splice(index, 1);
    if (maintainFocus) {
      const nextFocusedIndex = index === 0 ? 0 : index - 1 || index + 1;
      handleSetFocusedChip(nextFocusedIndex, "prev");
    }
    onChange?.(newItems, item[0]);
  };

  const handleOnBlur = (e: FocusEvent<HTMLElement>, item: Item, i: number) => {
    if (i === focusedChip) {
      handleSetFocusedChip(undefined);
    }
    onBlur?.(e, item, i);
  };

  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    item: Item,
    index: number
  ) => {
    if (readonly || disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    switch (e.key) {
      case "ArrowLeft": {
        handleSetFocusedChip(index - 1, "prev");
        break;
      }
      case "ArrowRight": {
        handleSetFocusedChip(index + 1, "next");
        break;
      }
      case "Tab": {
        if (e.shiftKey && index !== 0) {
          e.preventDefault();
          e.stopPropagation();
          handleSetFocusedChip(index - 1, "prev");
          break;
        }
        if (!e.shiftKey && index !== items.length - 1) {
          e.preventDefault();
          e.stopPropagation();
          handleSetFocusedChip(index + 1, "next");
          break;
        }
        break;
      }
      case "Backspace":
      case "Delete":
        e.preventDefault();
        e.stopPropagation();
        removeChip(index, true);
        break;
      default:
        break;
    }
    onKeyDown?.(e, item, index);
  };

  return (
    <div
      className="kit-Chips"
      css={[
        styles.wrapper,
        disabled && styles.isDisabled,
        css`
          width: ${fill ? "100%" : "auto"};
          gap: var(--spacing-${gapSpacing});
        `
      ]}
    >
      {!items?.length
        ? emptyContent
        : items.map(
            (item, i) =>
              !!item && (
                <React.Fragment key={lookupId(item)}>
                  {i > 0 && !!separator && separator}
                  <Chip
                    isRemovable={!readonly}
                    readonly={readonly || lookupReadonly?.(item)}
                    disabled={disabled}
                    focus={i === focusedChip}
                    prefix={lookupIcon?.(item)}
                    onRemove={() => removeChip(i)}
                    onClick={e => onClick?.(e, item, i)}
                    onFocus={e => onFocus?.(e, item, i)}
                    onBlur={e => handleOnBlur?.(e, item, i)}
                    onKeyDown={e => handleOnKeyDown?.(e, item, i)}
                    onKeyUp={e => onKeyUp?.(e, item, i)}
                    intent={lookupIntent?.(item) || intent}
                  >
                    {lookupLabel(item) || null}
                  </Chip>
                </React.Fragment>
              )
          )}
    </div>
  );
};

export * from "./types";
export default Chips;
