import { FC, useMemo } from "react";
import { usePager } from "../../../hooks";
import { TableProps, ExternalPaginationProps, TableRowType } from "..";
import { Pager } from "../..";
import { TableRowActionDef } from "../types";
import { DoNotCare } from "../../types";
import { WrappedTableComponent } from "./types";

const getTableDefaultPageSize = (props: TableProps<DoNotCare>) => {
  const { variant, pagination = {} } = props;
  if (pagination && typeof pagination === "object" && pagination.pageSize) {
    return pagination.pageSize;
  }

  return variant === "card" ? 10 : 20;
};

/**
 * Higher Order Component that adds local pagination functionality to a table component.
 * By rendering the Pager component, and managing the data & pagination state.
 */
function tableWithLocalPagination<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: WrappedTableComponent<Row, Column, Action>) {
  return function Table(props: TableProps<Row, Column, Action>) {
    const { data, pagination } = props;
    const paginationObject = typeof pagination === "object" ? pagination : {};

    const count = data.length;

    const {
      Pager,
      data: pagedData = [],
      page,
      pageSize
    } = usePager<Row>({
      pageSize: getTableDefaultPageSize(props),
      ...paginationObject,
      data,
      count
    });

    return (
      <WrappedTable
        {...props}
        data={pagedData}
        pagination={{ ...paginationObject, page, pageSize }}
        INTERNAL_pager={!!count && Pager}
      />
    );
  };
}

/**
 * Higher Order Component that adds external pagination functionality to a table component.
 * Renders the Pager component, and passing up events,
 * and rendering controls from probided pagination props.
 * @param WrappedTable The kit Table component to wrap.
 * @returns A Table component that can handle external pagination, with the initial + additional props
 */
function tableWithExternalPagination<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: WrappedTableComponent<Row, Column, Action>) {
  /**
   * Table that handles external pagination.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const {
      pagination: {
        onChange,
        page,
        pageSize = getTableDefaultPageSize(props),
        count
      }
    } = props as TableProps<Row, Column, Action> & {
      pagination: ExternalPaginationProps;
    };

    return (
      <WrappedTable
        {...props}
        INTERNAL_pager={
          !!count && (
            <Pager
              onChange={onChange}
              page={page}
              pageSize={pageSize}
              count={count}
            />
          )
        }
      />
    );
  };
}

/**
 * Determines if Table should handle local pagination — based on props
 */
export const isTablePropsWithLocalPagination = (props: TableProps<DoNotCare>) =>
  !!props?.pagination &&
  (props.pagination === true ||
    typeof props.pagination.onChange !== "function");

/**
 * Determines if Table should handle external pagination — based on props
 */
export const isTablePropsWithExternalPagination = (
  props: TableProps<DoNotCare>
) =>
  typeof props.pagination === "object" &&
  typeof props.pagination.onChange === "function";

/**
 * Higher Order Component that adds pagination functionality to a table component.
 * @param WrappedTable The kit Table component to wrap.
 * @returns A Table component that can handle pagination, with the initial + additional props
 */
export function tableWithPagination<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: FC<TableProps<Row, Column, Action>>) {
  /**
   * Table that handles local or external pagination.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const Table = useMemo(() => {
      if (
        !isTablePropsWithLocalPagination(props) &&
        !isTablePropsWithExternalPagination(props)
      ) {
        return WrappedTable;
      }

      if (isTablePropsWithLocalPagination(props)) {
        return tableWithLocalPagination<Row, Column, Action>(WrappedTable);
      }
      if (isTablePropsWithExternalPagination(props)) {
        return tableWithExternalPagination<Row, Column, Action>(WrappedTable);
      }
      return WrappedTable;
    }, [
      isTablePropsWithLocalPagination(props),
      isTablePropsWithExternalPagination(props)
    ]);

    return <Table {...props} />;
  };
}
