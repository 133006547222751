import { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { omit } from "lodash";

import { Variant, ButtonProps, Color } from "./types";
import {
  danger,
  icon as iconStyle,
  loadingHiddenVisibility,
  primaryContained,
  primaryOutlined,
  primaryText,
  secondary,
  spinner
} from "./style";
import { Spinner, SpinnerSize } from "..";

const BaseComponent = (props: PropsWithChildren<ButtonProps>) => {
  const {
    loading,
    children,
    className,
    color,
    variant,
    icon,
    href,
    ...buttonProps
  } = props;

  /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
  const dataTestId = buttonProps["data-testid"];

  const ChildrenWithMaybeIcon = (
    <>
      {icon}
      {children && <span>{children}</span>}
    </>
  );

  const ButtonBase = (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...omit(buttonProps, ["ignoreBaseStyle"])}
      className={[
        className,
        `${color || Color.DEFAULT} ${variant || Variant.DEFAULT}`
      ].join(" ")}
      disabled={!!(buttonProps.disabled || loading)}
      data-testid={dataTestId}
    >
      {loading ? (
        <>
          <div
            css={loadingHiddenVisibility}
            data-testid="madhive-button-loading-hidden"
          >
            {ChildrenWithMaybeIcon}
          </div>
          <div css={spinner} data-testid="madhive-button-loading-spinner">
            <Spinner size={SpinnerSize.SMALL} />
          </div>
        </>
      ) : (
        ChildrenWithMaybeIcon
      )}
    </Button>
  );

  if (href && !buttonProps.disabled) {
    return <Link to={href}>{ButtonBase}</Link>;
  }

  return ButtonBase;
};

export const MadhiveButton = styled(BaseComponent)`
  ${props =>
    !props.className &&
    !props.ignoreBaseStyle &&
    css`
      &.bp4-button {
        background-image: none !important;
        webkit-box-shadow: none !important;
        box-shadow: none !important;
        position: relative;
        height: var(--spacing-32);

        border-bottom-left-radius: 4px !important;
        border-top-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;

        span.bp4-button-text {
          font-weight: 500;
          display: grid;
          grid-template-columns: repeat(
            ${props.icon && props.children ? "2" : "1"},
            auto
          );
          grid-column-gap: var(--spacing-8);

          span {
            display: flex;
            align-items: center;
          }
        }

        span.bp4-icon.bp4-icon:first-of-type:last-child {
          margin-left: var(--spacing-0);
          margin-right: var(--spacing-0);
        }

        &.bp4-button,
        .bp4-icon {
          color: unset;
        }

        &:hover,
        &:focus {
          webkit-box-shadow: none;
          box-shadow: none;
        }

        &.primary.contained {
          ${primaryContained}
        }

        &.primary.outlined {
          ${primaryOutlined}
        }

        &.primary.text {
          ${primaryText}
        }

        &.icon {
          ${iconStyle}
        }

        &.danger {
          ${danger}
        }

        &.secondary,
        &.save {
          ${secondary}
        }

        &.bp4-disabled,
        &.bp4-disabled:hover {
          cursor: not-allowed;
          background: transparent;
          border-color: var(--gray-2);

          span.bp4-button-text {
            color: var(--gray-4) !important;
          }
        }
      }
    `}
`;
