import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const GridLayout: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-grid-layout"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21.75C20.5192 21.75 21.75 20.5192 21.75 19V5C21.75 3.48079 20.5192 2.25 19 2.25H5C3.48079 2.25 2.25 3.48079 2.25 5V19C2.25 20.5192 3.48079 21.75 5 21.75H19ZM3.75 5C3.75 4.30921 4.30921 3.75 5 3.75H19C19.6908 3.75 20.25 4.30921 20.25 5V8.25H3.75V5ZM11.25 9.75H3.75V14.25H11.25V9.75ZM12.75 14.25V9.75H20.25V14.25H12.75ZM11.25 15.75H3.75V19C3.75 19.6908 4.30921 20.25 5 20.25H11.25V15.75ZM12.75 20.25V15.75H20.25V19C20.25 19.6908 19.6908 20.25 19 20.25H12.75Z"
      />
    </svg>
  );
};

export default GridLayout;
