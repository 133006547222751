import { ManageCampaignColumn } from "@madhive/mad-sdk";
import { CampaignStatus, CampaignStatusCode } from "lib/constants/campaign";

export enum ScreenViewMode {
  READONLY = "readonly",
  EDIT = "edit"
}

export enum CampaignScreenTabId {
  /** The members of this enum will be used as the value of the query string in URLs. */
  OVERVIEW = "overview",
  LINE_ITEMS = "line-items"
}

export const VIEW_MODE_PARAM = "mode";

export const TAB_PARAM = "tab";

export const ATTACHING_CREATIVES_PARAM = "attaching_creative";

export const REQUIRED_FIELD_MESSAGE = "This field is required";

export enum LineItemTab {
  OVERVIEW = "overview",
  TARGETING = "targeting",
  FLIGHTS = "flights",
  CREATIVES = "creatives",
  WEIGHTING = "weighting"
}

export enum InputIds {
  LINE_ITEM_NAME = "line-item-name",
  LINE_ITEM_START_DATE = "line-item-start-date",
  LINE_ITEM_END_DATE = "line-item-end-date",
  BOOKED_IMPRESSIONS_FIELD = "booked-impressions",
  BUDGET = "budget",
  ECPM = "ecpm",
  DAYPART_FIELD = "daypart",
  DEVICE_TYPE_FIELD = "device-type",
  WEIGHTING_FIELD = "weighting-field",
  CLICKTHROUGH_FIELD = "clickthrough-field",
  CREATIVE_DATE_FIELDS = "creative-date-fields",
  CREATIVE_STATUSES = "creative-statuses",
  CREATIVES = "creatives"
}

export const BRAND_LABEL = "Brand";
export const ESTIMATE_LABEL = "Estimate";

export const MAX_PERCENTAGE = 100;

export const MAX_DAILY_BUDGET = 50000;

export const MAX_LIFETIME_BUDGET = 250000;

export enum RawInstructionStatus {
  DRAFT = 100,
  DELIVERABLE = 200,
  PAUSED = 300,
  CANCELED = 400,
  ARCHIVED = 500
}

export enum TargetingSubTab {
  GEOGRAPHIC_SELECTION = "geographic-select",
  AUDIENCE_SEGMENT = "audience-segment",
  INVENTORY = "inventory",
  DEVICE_TYPE = "device-type"
}

export const TARGETING_SUB_PANEL_QUERY_PARAM = "targeting-sub-panel";

export const ERROR_STATUS_TO_LINE_ITEM_TAB_ID: Readonly<
  Partial<Record<CampaignStatus, LineItemTab>>
> = {
  [CampaignStatus.MISSING_CREATIVE]: LineItemTab.CREATIVES,
  [CampaignStatus.MISSING_WHITELIST]: LineItemTab.TARGETING,
  [CampaignStatus.MISSING_GEO]: LineItemTab.TARGETING,
  [CampaignStatus.MISSING_GOAL]: LineItemTab.OVERVIEW,
  [CampaignStatus.MISSING_PRODUCT]: LineItemTab.TARGETING,
  [CampaignStatus.MISSING_FREQ_CAP]: LineItemTab.TARGETING
};

export enum InstructionFilterCategory {
  /** The value of this enum's members appear in the UI as the category label for an applied filter pill. */
  BRAND = "Brand",
  ESTIMATE = "Estimate",
  STATION = "Station",
  AGENCY = "Agency",
  ADVERTISER = "Advertiser",
  CATEGORY = "Category",
  UPDATED_BY = "Updated By",
  CAMPAIGN_NAME = "Campaign Name",
  MEDIA_TYPE = "Media Type",
  INSTRUCTION_STATUS = "Status",
  PRODUCT_CODE = "Product Code",
  PREMION_CLIENT_CODE = "Client Code",
  CLIENT_ESTIMATE_CODE = "Client Estimate Code",
  PREMION_REVENUE_TYPE = "Revenue Type",
  PREMION_RFP_DETAIL_NAME = "RFP Detail Name",
  PREMION_RFP_NAME = "RFP Name",
  SCRIPPS_ADBOOK_STATUS = "AdBook Status",
  SCRIPPS_ADBOOK_MARKET = "AdBook Market",
  SCRIPPS_ADBOOK_CLIENT = "Adbook Client",
  SCRIPPS_ADBOOK_PACKAGE_NAME = "Adbook Package Name"
}

export enum InstructionPacingFilterCategory {
  PACE = "Pace",
  DELIVERY = "Delivery",
  TYPE = "Pacing Status"
}

/** Assign this statically since order matters for the <Checkbox> list. */
export const INSTRUCTION_STATUS_CHECKBOX_OPTIONS: Array<{
  id: string;
  label: CampaignStatus;
}> = [
  {
    id: CampaignStatusCode.MISSING_LINE_ITEM,
    label: CampaignStatus.MISSING_LINE_ITEM
  },
  {
    id: CampaignStatusCode.MISSING_CREATIVE,
    label: CampaignStatus.MISSING_CREATIVE
  },
  {
    id: CampaignStatusCode.MISSING_WHITELIST,
    label: CampaignStatus.MISSING_WHITELIST
  },
  {
    id: CampaignStatusCode.MISSING_PRODUCT,
    label: CampaignStatus.MISSING_PRODUCT
  },
  {
    id: CampaignStatusCode.MISSING_FREQ_CAP,
    label: CampaignStatus.MISSING_FREQ_CAP
  },
  {
    id: CampaignStatusCode.READY,
    label: CampaignStatus.READY
  },
  {
    id: CampaignStatusCode.WAITING,
    label: CampaignStatus.WAITING
  },
  {
    id: CampaignStatusCode.LIVE,
    label: CampaignStatus.LIVE
  },
  {
    id: CampaignStatusCode.LIVE_WARNING,
    label: CampaignStatus.LIVE_WARNING
  },
  {
    id: CampaignStatusCode.NO_ACTIVE_CREATIVE,
    label: CampaignStatus.NO_ACTIVE_CREATIVE
  },
  {
    id: CampaignStatusCode.PAUSED,
    label: CampaignStatus.PAUSED
  },
  {
    id: CampaignStatusCode.COMPLETED,
    label: CampaignStatus.COMPLETED
  },
  {
    id: CampaignStatusCode.CANCELLED,
    label: CampaignStatus.CANCELLED
  },
  {
    id: CampaignStatusCode.MISSING_GEO,
    label: CampaignStatus.MISSING_GEO
  },
  {
    id: CampaignStatusCode.MISSING_GOAL,
    label: CampaignStatus.MISSING_GOAL
  },
  {
    id: CampaignStatusCode.CANT_SET_LIVE,
    label: CampaignStatus.CANT_SET_LIVE
  }
];

export const adBookMarketList = [
  "Bakersfield",
  "Baltimore",
  "Billings",
  "Boise",
  "Buffalo",
  "Butte",
  "Cincinnati",
  "Cleveland",
  "Colorado Springs",
  "Corpus Christi",
  "Denver",
  "Detroit",
  "Fort Myers",
  "Grand Rapids",
  "Great Falls",
  "Green Bay",
  "Helena",
  "Indianapolis",
  "Kansas City",
  "Lafayette",
  "Lansing",
  "Las Vegas",
  "Lexington",
  "Miami",
  "Milwaukee",
  "Missoula",
  "Nashville",
  "New York",
  "Norfolk",
  "Norfolk - CW",
  "Omaha",
  "Phoenix",
  "Phoenix - CW",
  "Richmond",
  "Salt Lake City",
  "San Diego",
  "San Luis Obispo",
  "Tallahassee",
  "Tampa",
  "Tucson",
  "Tulsa",
  "Waco",
  "West Palm"
];

export const SORT_BY_ABBREV = {
  ascending: "asc",
  descending: "desc"
};

export const DEFAULT_PAGE_SIZE = 50;

export const manageCampaignColumnIdToCampaignBackendFilterName = (): Partial<
  Record<ManageCampaignColumn, string>
> => {
  return {
    [ManageCampaignColumn.START_DATE]: "start",
    [ManageCampaignColumn.END_DATE]: "end",
    [ManageCampaignColumn.SET_LIVE]: "set_live",
    [ManageCampaignColumn.LAST_UPDATED]: "updated_at",
    [ManageCampaignColumn.UPDATED_BY]: "updated_by",
    [ManageCampaignColumn.NAME]: "name",
    [ManageCampaignColumn.ASSOCIATED_CAMPAIGN]: "campaign_name",
    [ManageCampaignColumn.CATEGORY]: "iab_category_rtb_ids",
    [ManageCampaignColumn.ESTIMATE_CODE]: "ext_meta_client_estimate_code",
    [ManageCampaignColumn.MEDIA_TYPE]: "media_types",
    [ManageCampaignColumn.PREMION_CLIENT_CODE]: "ext_meta_client_code",
    [ManageCampaignColumn.PRODUCT_CODE]: "ext_metadata_product_code",
    [ManageCampaignColumn.PREMION_CLIENT_ESTIMATE_CODE]:
      "ext_meta_client_estimate_code",
    [ManageCampaignColumn.PREMION_RFP_DETAILS]: "ext_meta_rfp_detail_name",
    [ManageCampaignColumn.PREMION_RFP_NAME]: "ext_meta_rfp_name",
    [ManageCampaignColumn.PREMION_REVENUE_TYPE]: "ext_meta_revenue_type",
    [ManageCampaignColumn.PREMION_PLACEMENTS_IO_ID]:
      "ext_meta_placements_io_id",
    [ManageCampaignColumn.PREMION_PLACEMENTS_IO_GROUP_ID]:
      "ext_meta_placements_io_group_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_STATUS]: "ext_meta_adbook_status",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_MARKET]: "ext_meta_adbook_market",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_CLIENT_NAME]:
      "ext_meta_adbook_client_name",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_EXTERNAL_ADVERTISER_ID]:
      "ext_meta_adbook_external_advertiser_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_ADVERTISER]:
      "ext_meta_adbook_advertiser",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_ID]:
      "ext_meta_adbook_package_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_NAME]:
      "ext_meta_adbook_package_name",
    [ManageCampaignColumn.SCRIPPS_WIDE_ORBIT_ID]:
      "ext_meta_adbook_wide_orbit_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_SEGMENT_NOTE]:
      "ext_meta_adbook_segment_notes",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_AGENCY_ID]:
      "ext_meta_adbook_agency_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_CAMPAIGN_ID]:
      "ext_meta_adbook_campaign_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_STATION]: "ext_meta_adbook_station",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_STATION_ID]:
      "ext_meta_adbook_station_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_DROP_ID]: "ext_meta_adbook_drop_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_DROP_STATUS]:
      "ext_meta_adbook_line_item_status",
    [ManageCampaignColumn.SCRIPPS_ID]: "ext_meta_adbook_scripps_advertiser_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_ADVERTISER_ID]:
      "ext_meta_adbook_advertiser_id",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PRICE]: "ext_meta_adbook_price",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PRICE_TYPE]:
      "ext_meta_adbook_price_type",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_GROSS_PRICE]:
      "ext_meta_adbook_gross_price",
    [ManageCampaignColumn.SCRIPPS_OCTANE_PRODUCT]: "ext_meta_adbook_product",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_REVISION]: "ext_meta_adbook_revision",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_LAST_UPDATED]:
      "ext_meta_adbook_last_updated",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_WHITE_LIST]:
      "ext_meta_adbook_advertiser_whitelist",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_WHITE_LIST_NAME]:
      "ext_meta_adbook_whitelist_name",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_AGENCY_NAME]:
      "ext_meta_adbook_agency_name",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_POSITION_PATH]:
      "ext_meta_adbook_package_position_path",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_POSITION_NAME]:
      "ext_meta_adbook_position_name",
    [ManageCampaignColumn.SCRIPPS_OCTANE_ADVERTISER_DOMAIN]:
      "ext_meta_adbook_advertiser_domain",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_LEVEL]:
      "ext_meta_adbook_package_level",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_LAST_CHANGE_BY]:
      "ext_meta_adbook_last_changed_by",
    [ManageCampaignColumn.SCRIPPS_ADBOOK_CONTRACT_DATE]:
      "ext_meta_adbook_contract_date",
    [ManageCampaignColumn.FOX_WIDEORBIT_LINE_ID]:
      "ext_meta_wideorbit_fox_line_id",
    [ManageCampaignColumn.GROUNDTRUTH_BRANDS]: "ext_meta_brands",
    [ManageCampaignColumn.GROUNDTRUTH_LOCATION_GROUPS]:
      "ext_meta_location_groups",
    [ManageCampaignColumn.SALESFORCE_INSERTION_ORDER_ID]:
      "salesforce_insertion_order_id",
    [ManageCampaignColumn.WIDEORBIT_ESTIMATE_NUMBER]:
      "ext_meta_wideorbit_estimate_number",
    [ManageCampaignColumn.WIDEORBIT_ACCOUNT_EXECUTIVE]:
      "ext_meta_wideorbit_account_executive",
    [ManageCampaignColumn.WIDEORBIT_PRODUCT_DESCRIPTION]:
      "ext_meta_wideorbit_product_description",
    [ManageCampaignColumn.WIDEORBIT_IS_ORDER_DELETED]:
      "ext_meta_wideorbit_is_order_deleted",
    [ManageCampaignColumn.WIDEORBIT_UNIT_CODE_DISPLAY]:
      "ext_meta_wideorbit_unit_code_display",
    [ManageCampaignColumn.WIDEORBIT_NUMBER_OF_TIMES_PROCESSED]:
      "ext_meta_wideorbit_number_of_times_processed",
    [ManageCampaignColumn.WIDEORBIT_AGENCY_COMMISSION]:
      "ext_meta_wideorbit_agency_commission",
    [ManageCampaignColumn.WIDEORBIT_ORDER_IS_COOP]:
      "ext_meta_wideorbit_order_is_coop",
    [ManageCampaignColumn.WIDEORBIT_CLIENT_CODE]:
      "ext_meta_wideorbit_client_code",
    [ManageCampaignColumn.WIDEORBIT_AGENCY_PRODUCT_CODE]:
      "ext_meta_wideorbit_agency_product_code",
    [ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_STREET]:
      "ext_meta_wideorbit_billing_address_street",
    [ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_CITY]:
      "ext_meta_wideorbit_billing_address_city",
    [ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_STATE]:
      "ext_meta_wideorbit_billing_address_state",
    [ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_ZIP]:
      "ext_meta_wideorbit_billing_address_zip",
    [ManageCampaignColumn.WIDEORBIT_SALES_OFFICE]:
      "ext_meta_wideorbit_sales_office",
    [ManageCampaignColumn.WIDEORBIT_SALES_REGION]:
      "ext_meta_wideorbit_sales_region",
    [ManageCampaignColumn.WIDEORBIT_BILLING_CALENDAR]:
      "ext_meta_wideorbit_billing_calendar",
    [ManageCampaignColumn.WIDEORBIT_BILLING_CYCLE]:
      "ext_meta_wideorbit_billing_cycle"
  };
};
