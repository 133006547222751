import { css } from "@emotion/react";
import { CLASS_NAME, CSS_VAR } from "./constants";

/**
 * Note: The native `drag` state prevents the mouse from triggering CSS states (hover, etc.).
 * Removing pointer events cancels the drag and allows for those CSS features.
 */
export const styles = {
  wrapper: css`
    display: contents;
  `,
  item: css`
    display: flex;
    justify-content: space-between;
    position: relative;

    &.${CLASS_NAME.DRAGGABLE_DRAGGING} {
      pointer-events: none;
      opacity: 0.5;
    }

    [draggable="true"],
    &[draggable="true"] {
      cursor: grab;
    }
  `,
  draggableItemDropTarget: css`
    display: none;

    .${CLASS_NAME.CONTAINS_DRAGGING} & {
      display: block;
    }

    --kit-ddr-offset: calc((var(${CSS_VAR.GAP}, 0) / 2 + 1) * -1px);
    --kit-ddr-indicator-circle-size: 8px;
    --kit-ddr-indicator-border-width: 2px;
    --kit-ddr-indicator-circle-offset: calc(
      (
          var(--kit-ddr-indicator-border-width) +
            var(--kit-ddr-indicator-circle-size)
        ) /
        -2
    );
    position: absolute;
    inset: 0;
    height: calc(50% + 1px + 0.5px * var(${CSS_VAR.GAP}, 0));
    z-index: 1;
    border: var(--kit-ddr-indicator-border-width) solid transparent;
    border-width: var(--kit-ddr-indicator-border-width) 0;
    top: var(--kit-ddr-offset);

    &.${CLASS_NAME.DROP_ZONE_HOVER} {
      border-top-color: var(--color-splash-primary);
      &:after {
        content: "";
        top: var(--kit-ddr-indicator-circle-offset);
      }
    }

    &:after {
      position: absolute;
      left: var(--kit-ddr-indicator-circle-offset);
      height: var(--kit-ddr-indicator-circle-size);
      width: var(--kit-ddr-indicator-circle-size);
      border: var(--kit-ddr-indicator-border-width) solid
        var(--color-splash-primary);
      border-radius: 50%;
      background-color: var(--white);
    }

    + .${CLASS_NAME.DROP_ZONE} {
      top: auto;
      bottom: var(--kit-ddr-offset);

      &.${CLASS_NAME.DROP_ZONE_HOVER} {
        border-top-color: transparent;
        border-bottom-color: var(--color-splash-primary);

        &:after {
          top: auto;
          bottom: var(--kit-ddr-indicator-circle-offset);
        }
      }
    }
  `,
  ghost: css`
    position: absolute;
    width: 1px;
    height: 1px;
  `
};
