import { css } from "@emotion/react";
import { kitText } from "../../styles";

const getSvgChecked = (fill: string) => {
  return `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.6869 3.97976C13.8821 4.17502 13.8821 4.4916 13.6869 4.68687L6.35354 12.0202C6.15828 12.2155 5.8417 12.2155 5.64644 12.0202L2.3131 8.68687C2.11784 8.4916 2.11784 8.17502 2.3131 7.97976C2.50837 7.7845 2.82495 7.7845 3.02021 7.97976L5.99999 10.9595L12.9798 3.97976C13.175 3.7845 13.4916 3.7845 13.6869 3.97976Z' fill='${fill}'/%3e %3c/svg%3e")`;
};
const getSvgIndetermined = (fill: string) => {
  return `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.66666 7.83331C2.66666 7.55717 2.89051 7.33331 3.16666 7.33331H13.1667C13.4428 7.33331 13.6667 7.55717 13.6667 7.83331C13.6667 8.10945 13.4428 8.33331 13.1667 8.33331H3.16666C2.89051 8.33331 2.66666 8.10945 2.66666 7.83331Z' fill='${fill}'/%3e %3c/svg%3e")`;
};

export const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    &.bp4-control {
      padding: 0;
      margin: 0;
    }
    input[type="checkbox"] ~ .bp4-control-indicator {
      appearance: none;
      box-shadow: none;
      background-color: var(--color-background-primary);
      border: 0.063rem solid var(--color-border-primary);
      border-radius: var(--border-radii-sm);
      cursor: pointer;
      margin: 0;
      align-items: center;
      display: flex;
      flex: 0 0 1rem;
      justify-content: center;
    }

    input[type="checkbox"]:not(:disabled) {
      &:hover,
      &:focus {
        ~ .bp4-control-indicator {
          border-color: var(--color-splash-tertiary);
          outline: none;
        }
      }
    }

    input[type="checkbox"]:checked ~ .bp4-control-indicator {
      background-color: var(--color-splash-primary);
      border-style: none;

      &::before {
        background-image: ${getSvgChecked("white")};
      }
    }

    input[type="checkbox"]:checked:focus ~ .bp4-control-indicator {
      background-color: var(--color-splash-tertiary);
    }

    input[type="checkbox"]:checked:hover ~ .bp4-control-indicator {
      background-color: var(--color-splash-secondary);
    }

    input[type="checkbox"]:indeterminate ~ .bp4-control-indicator {
      background-color: var(--color-splash-primary);
      border-style: none;

      &::before {
        background-image: ${getSvgIndetermined("white")};
      }
    }

    input[type="checkbox"]:indeterminate:hover ~ .bp4-control-indicator {
      background-color: var(--color-splash-tertiary);
      border-color: var(--color-splash-tertiary);
    }

    input[type="checkbox"]:disabled ~ .bp4-control-indicator {
      background-color: var(--color-background-tertiary);
      cursor: not-allowed;
    }

    input[type="checkbox"]:checked:disabled ~ .bp4-control-indicator {
      background-color: var(--color-background-tertiary);

      &::before {
        background-image: ${getSvgChecked("%23B3BBCC")};
      }
    }

    input[type="checkbox"]:indeterminate:disabled ~ .bp4-control-indicator {
      background-color: var(--color-background-tertiary);

      &::before {
        background-image: ${getSvgIndetermined("%23B3BBCC")};
      }
    }

    input[type="checkbox"]:disabled:not(:indeterminate):not(:checked)
      ~ .bp4-control-indicator {
      border-color: var(--color-border-secondary);
    }
  `,
  label: css`
    padding-left: 0.5rem;
    max-width: 100%;
    overflow-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-primary);
    font-size: ${kitText.variant.body};
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
  `,
  ellipses: css`
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
  `
};
