import { PAGER_DEFAULT_PAGE_SIZES } from "./constants";

export const getMaxPage = (count: number, pageSize: number) =>
  Math.ceil(count / pageSize) - 1;

export const getInitialPageSize = (
  pageSize: number | undefined,
  pageSizes: number[] | null
) => {
  const propOrDefault = pageSize || PAGER_DEFAULT_PAGE_SIZES[0];
  return !pageSizes || pageSizes.includes(propOrDefault)
    ? propOrDefault
    : pageSizes[0];
};
