import { Icon, IconSize } from "@blueprintjs/core";
import MenuItem from "frontier/lib/components/Menu/Item";
import { FormGroup } from "frontier/lib/components";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { Button, ButtonVariant } from "madhive/components";
import { css } from "@emotion/react";
import { highlightText } from "lib/utils/filtering";
import { fuzzySortSearch } from "lib/utils/search";
import { FilterIdToEvent } from "lib/constants/events";
import { logEvent } from "lib/utils/analytics";
import { FC, MouseEvent } from "react";

export interface SelectOption {
  id: string | number;
  label: string;
  disabled?: boolean;
}

interface Props {
  id: string;
  label?: string;
  options: SelectOption[];
  selectedOptionId: string | number | undefined;
  onChange: (newValue: string | number, newLabel: string) => void;
  /**
   * Optional label to show when nothing is selected and there is no error & no errorOptionText prop.
   */
  defaultOptionText?: string;
  errorOptionText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  /**
   * Default: false
   */
  filterable?: boolean;
  hasError?: boolean;
  noResultsText?: string;
  fixedWidth?: string | number;
  fill?: boolean;
  onClearSelection?: () => void | undefined;
  customClass?: string | undefined;
  style?: Record<string, string | number>;
  isRightIconCaret?: boolean;
  hideNonFirstLabel?: boolean;
  hideLabel?: boolean;
  size?: "small" | "large";
}

export const renderMenuItem: ItemRenderer<SelectOption> = (
  item,
  { modifiers, handleClick, query }
) => (
  <MenuItem
    data-testid={item.id}
    active={modifiers.active}
    roleStructure="listoption"
    key={item.id}
    onClick={handleClick}
    shouldDismissPopover={false}
    // Avoid doing unnecessary work when query is blank.
    text={query ? highlightText(item.label, query) : item.label}
    disabled={item.disabled}
  />
);

const styles = {
  clearButtonWrapper: css`
    .bp4-button {
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%);
      z-index: 1000;
    }
  `,
  /**
   * Note @MaximeHeckel: After syncing with design we decided to make every SmitherSelect set to large=false
   * with a hardcoded height of 32px to match the height of `madhive/button`
   */
  adjustedHeight: css`
    .bp4-button {
      min-height: 32px;
    }
  }`,
  largeHeight: css`
    .bp4-button {
      min-height: 40px;
    }
  `,
  ellipsizedText: css`
    .bp4-button-text {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `,
  /** Slightly higher specificity than Blueprint's selector to make the background color a light gray. */
  whiteBackground: css`
    .bp4-button {
      background-color: var(--white);

      &:hover {
        background-color: var(--white);
      }
    }
  `,
  defaultText: css`
    > .bp4-button-text {
      color: var(--gray-4);
    }
  `,
  error: css`
    .bp4-button {
      border: solid 1px var(--red);
    }
  `,
  formGroupContainer: css`
    margin-bottom: 0;
  `,
  noLabelAfterFirstInput: css`
    > label.bp4-label {
      visibility: hidden;
    }
  `,
  noLabel: css`
    > label.bp4-label {
      display: none;
    }
  `,
  relativePosition: css`
    position: relative;
  `
};

const SmithersSelect: FC<Props> = props => {
  const selectedOption = props.options.find(
    option => option.id === props.selectedOptionId
  );

  const {
    noResultsText = "No results",
    defaultOptionText = "Select",
    filterable = false,
    fill = true
  } = props;

  const optionLabelIfNoneSelected =
    props.hasError && props.errorOptionText !== undefined
      ? props.errorOptionText
      : defaultOptionText;

  const handleClearSelection = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    if (props.onClearSelection) {
      e.stopPropagation();
      props.onClearSelection();
    }
  };
  const fixedWidthStyle = css`
    width: ${props.fixedWidth}px;
  `;
  return (
    <FormGroup
      label={props.label}
      labelFor={props.id}
      style={{
        ...props.style
      }}
      css={[
        styles.formGroupContainer,
        props.hideNonFirstLabel && styles.noLabelAfterFirstInput,
        props.hideLabel && styles.noLabel,
        props.fixedWidth && fixedWidthStyle
      ]}
    >
      <Select
        items={props.options}
        itemRenderer={renderMenuItem}
        onItemSelect={item => {
          if (props.disabled) {
            return;
          }

          props.onChange(item.id, item.label);
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          if (props.id && FilterIdToEvent[props.id]) {
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            logEvent(FilterIdToEvent[props.id]);
          }
        }}
        // changed undefined to null so that it does not highlights  the first value by default even if it is not selected
        activeItem={selectedOption || undefined}
        itemsEqual={(itemA, itemB) => itemA.id === itemB.id}
        noResults={
          <MenuItem disabled roleStructure="listoption" text={noResultsText} />
        }
        disabled={props.disabled}
        itemListPredicate={(query, options) =>
          fuzzySortSearch(options, "label", query)
        }
        filterable={filterable}
        popoverProps={{
          targetTagName: "div",
          usePortal: false
        }}
      >
        <div
          css={[
            props.size === "small" ? styles.adjustedHeight : styles.largeHeight,
            styles.ellipsizedText,
            !props.isLoading &&
              !props.hasError &&
              !props.disabled &&
              styles.whiteBackground,
            !props.selectedOptionId && styles.defaultText,
            props.hasError && styles.error,
            styles.relativePosition
          ]}
        >
          <Button
            className={props.className}
            data-testid={props.id}
            id={props.id}
            name={props.id}
            fill={fill}
            alignText="left"
            rightIcon={
              props.isRightIconCaret
                ? IconNames.CARET_DOWN
                : IconNames.CHEVRON_DOWN
            }
            disabled={props.disabled}
            loading={props.isLoading}
            onMouseOver={e => e.stopPropagation()}
            text={
              selectedOption ? selectedOption.label : optionLabelIfNoneSelected
            }
            ignoreBaseStyle
          />
          {selectedOption && !!props.onClearSelection ? (
            <div css={styles.clearButtonWrapper}>
              <Button
                variant={ButtonVariant.ICON}
                onClick={e => handleClearSelection(e)}
              >
                <Icon icon={IconNames.CROSS} iconSize={IconSize.LARGE} />
              </Button>
            </div>
          ) : null}
        </div>
      </Select>
    </FormGroup>
  );
};

export default SmithersSelect;
