import {
  GET_AVAILABLE_TEAMS_PENDING,
  GET_AVAILABLE_TEAMS_SUCCESS,
  GET_AVAILABLE_TEAMS_FAILURE,
  ABORT_TEAMS_REQUEST,
  TeamActionTypes,
  TeamsState
} from "./types";

export const initialState: TeamsState = {
  byId: {},
  isLoading: false,
  isSaving: false,
  /* boolean indicating if ever loaded -- useful for some loading screen logic */
  isLoaded: false,
  updatingIds: [],
  error: null
};

export const teamsReducer = (
  state = initialState,
  action: TeamActionTypes
): TeamsState => {
  switch (action.type) {
    case GET_AVAILABLE_TEAMS_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case GET_AVAILABLE_TEAMS_SUCCESS: {
      return {
        ...state,
        byId: action.payload.reduce((byId, team) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[team.id] = team;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        isLoading: false,
        isLoaded: true,
        error: null
      };
    }
    case GET_AVAILABLE_TEAMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error
      };
    case ABORT_TEAMS_REQUEST:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
