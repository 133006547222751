import { css } from "@emotion/react";
import { kitShadow, kitText } from "../../../styles";

export const styles = {
  wrapper: css`
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--kit-page-header-padding-y, var(--spacing-16))
      var(--kit-page-header-padding-x, var(--spacing-32));
    box-sizing: border-box;
    position: relative;
    gap: var(--spacing-8);
    flex-wrap: wrap;
    background-color: var(--color-background-secondary);

    @media (min-width: 600px) {
      flex-wrap: nowrap;
    }
  `,
  shadowed: kitShadow.overflow.bottom,
  bordered: css`
    border-bottom: 1px solid var(--color-border-secondary);
  `,
  title: css`
    ${kitText.variant.heading1}
    color: var(--color-primary);
    max-width: 100%;
    position: sticky;
    left: var(--spacing-32);
  `,
  subTitle: css`
    ${kitText.variant.heading2}
  `,
  smallSubhead: css`
    ${kitText.variant.heading4}
    line-height: var(--font-line-height-heading-sm);
  `,
  leftContent: css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: var(--spacing-4);
    width: auto;
    max-width: 100%;
    position: sticky;
    left: var(--spacing-32);
  `,
  rightContent: css`
    max-height: var(--spacing-32);
    position: sticky;
    right: var(--spacing-32);
  `,
  noWrap: css`
    flex-wrap: nowrap;
  `
};
