/* eslint-disable no-param-reassign */
import { omit } from "lodash";
import { combineReducers } from "redux";
import produce from "immer";
import { DEFAULT_PAGE_SIZE } from "features/ManageCampaigns/constants";
import { ManageCampaignColumn } from "@madhive/mad-sdk";
import { SortDirection } from "madhive/components";
import campaignsDashboardReducer from "./dashboard";
import campaignsQuickEditsReducer from "./quickEdits";
import {
  ABORT_GET_CAMPAIGN_REQUEST,
  CampaignsActionTypes,
  CampaignsState,
  CREATE_CAMPAIGN_PENDING,
  DELETE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_PENDING,
  DELETE_CAMPAIGN_SUCCESS,
  GET_ALL_CAMPAIGNS_FAILURE,
  GET_ALL_CAMPAIGNS_PENDING,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  GET_CAMPAIGN_PENDING,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_TEMPLATE_UPLOAD_FAILURE,
  GET_CAMPAIGN_TEMPLATE_UPLOAD_PENDING,
  GET_CAMPAIGN_TEMPLATE_UPLOAD_SUCCESS,
  SAVE_CAMPAIGN_FAILURE,
  SAVE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_PENDING,
  GET_ALL_LINEITEMS_FAILURE,
  GET_ALL_LINEITEMS_PENDING,
  GET_ALL_LINEITEMS_SUCCESS,
  CLEAR_CAMPAIGN_SORTBY,
  CLEAR_LINEITEM_SORTBY
} from "./types";

export const campaignsInitialState: CampaignsState = {
  byId: {},
  loadAllCancellationSource: null,
  loadSingleCancellationSource: {},
  receivedAllAt: null,
  isSaving: false,
  paging: {
    totalCount: 0,
    currentPageToken: "",
    currentPage: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    offset: 0
  },
  sortBy: {
    fieldId: ManageCampaignColumn.LAST_UPDATED,
    order: SortDirection.DESCENDING
  },
  lineItems: {
    cancellationSource: null,
    byId: {},
    campaignsById: {},
    sortBy: {
      fieldId: ManageCampaignColumn.LAST_UPDATED,
      order: SortDirection.DESCENDING
    },
    paging: {
      totalCount: 0,
      currentPageToken: "",
      currentPage: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      offset: 0
    }
  }
};

export const campaignsRootReducer = (
  state = campaignsInitialState,
  action: CampaignsActionTypes
): CampaignsState =>
  produce(state, draft => {
    switch (action.type) {
      case GET_ALL_CAMPAIGNS_PENDING:
        draft.loadAllCancellationSource = action.meta.cancellationSource;
        if (action.meta.sortInfo) {
          draft.sortBy = action.meta.sortInfo;
        }
        if (action.meta.pagingChanges?.newPageSize) {
          draft.paging.pageSize = action.meta.pagingChanges?.newPageSize;
        }
        if (!action.meta.isPreviousOrNextPage) {
          draft.paging.currentPage = 0;
          draft.paging.currentPageToken = "";
        }
        draft.paging.offset = action.meta.pagingChanges?.offset || 0;
        break;
      case GET_ALL_CAMPAIGNS_SUCCESS:
        draft.loadAllCancellationSource = null;
        draft.receivedAllAt = action.meta.timestamp;
        draft.byId = action.payload.reduce((byId, cmp) => {
          // eslint-disable-next-line no-param-reassign
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[cmp.id] = cmp;
          return byId;
        }, {});
        draft.paging.currentPage += draft.paging.offset;
        draft.paging.offset = 0;
        draft.paging.currentPageToken =
          action.meta.pagingInfoResponse?.token || "";
        draft.paging.totalCount = action.meta.pagingInfoResponse?.count || 0;
        break;
      case GET_ALL_CAMPAIGNS_FAILURE:
        draft.sortBy = null;
        draft.loadAllCancellationSource = null;
        break;
      case GET_ALL_LINEITEMS_PENDING:
        draft.lineItems.cancellationSource = action.meta.cancellationSource;
        if (action.meta.pagingChanges?.newPageSize) {
          draft.lineItems.paging.pageSize =
            action.meta.pagingChanges.newPageSize;
        }
        if (!action.meta.isPreviousOrNextPage) {
          draft.lineItems.paging.currentPage = 0;
          draft.lineItems.paging.currentPageToken = "";
        }

        draft.lineItems.paging.offset = action.meta.pagingChanges?.offset || 0;
        if (action.meta.pagingChanges?.newPageSize) {
          draft.lineItems.paging.pageSize =
            action.meta.pagingChanges?.newPageSize;
        }
        if (action.meta.sortInfo) {
          draft.lineItems.sortBy = action.meta.sortInfo;
        }
        break;
      case GET_ALL_LINEITEMS_SUCCESS:
        draft.lineItems.byId = action.payload.reduce((byId, cmp) => {
          // eslint-disable-next-line no-param-reassign
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[cmp.id] = cmp;
          return byId;
        }, {});
        draft.lineItems.campaignsById = action.meta.campaigns.reduce(
          (byId, cmp) => {
            // eslint-disable-next-line no-param-reassign
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            byId[cmp.id] = cmp;
            return byId;
          },
          {}
        );
        draft.lineItems.cancellationSource = null;
        draft.lineItems.paging.currentPage += draft.lineItems.paging.offset;
        draft.lineItems.paging.offset = 0;
        draft.lineItems.paging.currentPageToken =
          action.meta.pagingInfoResponse?.token || "";
        draft.lineItems.paging.totalCount =
          action.meta.pagingInfoResponse?.count || 0;
        break;
      case GET_ALL_LINEITEMS_FAILURE:
        draft.lineItems.sortBy = null;
        draft.lineItems.cancellationSource = null;
        break;

      case GET_CAMPAIGN_PENDING:
        draft.loadSingleCancellationSource[action.meta.campaignId] =
          action.meta.cancellationSource;
        break;
      case GET_CAMPAIGN_FAILURE:
      case ABORT_GET_CAMPAIGN_REQUEST: {
        delete draft.loadSingleCancellationSource[action.meta.campaignId];
        break;
      }
      case GET_CAMPAIGN_SUCCESS: {
        const campaign = action.payload;
        const { id, lineItems } = campaign;
        delete draft.loadSingleCancellationSource[id];
        draft.byId[id] = campaign;
        draft.lineItems.campaignsById[id] = campaign;
        lineItems.forEach(lineItem => {
          draft.lineItems.byId[lineItem.id] = lineItem;
        });
        break;
      }
      case UPDATE_CAMPAIGN_PENDING:
        draft.byId[action.meta.campaign.id].details.isLoading = true;
        draft.isSaving = true;
        break;

      case SAVE_CAMPAIGN_SUCCESS:
        draft.byId[action.payload.campaign.id] = action.payload.campaign;
        draft.isSaving = false;
        break;
      case DELETE_CAMPAIGN_SUCCESS:
        draft.isSaving = false;
        draft.byId = omit(draft.byId, action.payload.campaignId);
        break;
      case CREATE_CAMPAIGN_PENDING:
      case DELETE_CAMPAIGN_PENDING:
      case GET_CAMPAIGN_TEMPLATE_UPLOAD_PENDING:
        draft.isSaving = true;
        break;
      case GET_CAMPAIGN_TEMPLATE_UPLOAD_FAILURE:
      case GET_CAMPAIGN_TEMPLATE_UPLOAD_SUCCESS:
      case DELETE_CAMPAIGN_FAILURE:
      case SAVE_CAMPAIGN_FAILURE:
        draft.isSaving = false;
        break;
      case CLEAR_CAMPAIGN_SORTBY:
        draft.sortBy = null;
        break;
      case CLEAR_LINEITEM_SORTBY:
        draft.lineItems.sortBy = null;
        break;
      default:
    }
  });
export const campaignsReducer = combineReducers({
  root: campaignsRootReducer,
  dashboard: campaignsDashboardReducer,
  quickEdits: campaignsQuickEditsReducer
});
