import { omit } from "lodash";
import {
  AgenciesState,
  AgenciesActionTypes,
  Pending,
  GET_ALL_AGENCIES_PENDING,
  GET_ALL_AGENCIES_SUCCESS,
  GET_ALL_AGENCIES_FAILURE,
  GET_AGENCY_PENDING,
  GET_AGENCY_SUCCESS,
  GET_AGENCY_FAILURE,
  CREATE_AGENCY_PENDING,
  CREATE_AGENCY_SUCCESS,
  CREATE_AGENCY_FAILURE,
  UPDATE_AGENCY_PENDING,
  UPDATE_AGENCY_SUCCESS,
  UPDATE_AGENCY_FAILURE,
  DELETE_AGENCY_PENDING,
  DELETE_AGENCY_SUCCESS,
  DELETE_AGENCY_FAILURE,
  ABORT_GET_ALL_AGENCIES_REQUEST,
  ABORT_AGENCY_REQUEST
} from "./types";

export const initialState: AgenciesState = {
  byId: {},
  isAllLoading: false,
  pendingById: {},
  creatingByRequestId: {},
  receivedAllAt: null
};

export const agenciesReducer = (
  state = initialState,
  action: AgenciesActionTypes
): AgenciesState => {
  switch (action.type) {
    case GET_ALL_AGENCIES_PENDING:
      return {
        ...state,
        isAllLoading: true
      };
    case GET_ALL_AGENCIES_SUCCESS: {
      return {
        ...state,
        byId: action.payload.reduce((byId, agency) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[agency.id] = agency;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp,
        pendingById: {}
      };
    }
    case GET_ALL_AGENCIES_FAILURE:
      return {
        ...state,
        isAllLoading: false
      };
    case GET_AGENCY_PENDING:
      return {
        ...state,
        pendingById: {
          ...state.pendingById,
          [action.meta.id]: Pending.GET
        }
      };
    case GET_AGENCY_SUCCESS: {
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        pendingById: omit(state.pendingById, action.payload.id)
      };
    }
    case GET_AGENCY_FAILURE:
      return {
        ...state,
        pendingById: action.meta.id
          ? omit(state.pendingById, action.meta.id)
          : state.pendingById
      };
    case CREATE_AGENCY_PENDING: {
      return {
        ...state,
        creatingByRequestId: {
          ...state.creatingByRequestId,
          [action.meta.requestId]: action.meta.agency
        }
      };
    }
    case CREATE_AGENCY_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        creatingByRequestId: omit(
          state.creatingByRequestId,
          action.meta.requestId
        )
      };
    case CREATE_AGENCY_FAILURE:
      return {
        ...state,
        creatingByRequestId: omit(
          state.creatingByRequestId,
          action.meta.requestId
        )
      };
    case UPDATE_AGENCY_PENDING:
    case UPDATE_AGENCY_SUCCESS:
    case UPDATE_AGENCY_FAILURE:
      return {
        ...state
      };
    case DELETE_AGENCY_PENDING:
      return {
        ...state,
        pendingById: {
          ...state.pendingById,
          [action.meta.id]: Pending.DELETE
        }
      };
    case DELETE_AGENCY_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        byId: (({ [action.meta.id]: deleted, ...remaining }) => remaining)(
          state.byId
        ),
        pendingById: omit(state.pendingById, action.meta.id)
      };
    case DELETE_AGENCY_FAILURE:
      return {
        ...state,
        pendingById: action.meta.id
          ? omit(state.pendingById, action.meta.id)
          : state.pendingById
      };
    case ABORT_GET_ALL_AGENCIES_REQUEST:
      return {
        ...state,
        isAllLoading: false
      };
    case ABORT_AGENCY_REQUEST:
      return {
        ...state,
        pendingById: omit(state.pendingById, action.meta.id)
      };
    default:
      return state;
  }
};
