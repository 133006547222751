import { ReactNode, useState } from "react";
import { css } from "@emotion/react";
import Dropdown from "../../Dropdown";
import Button from "../../Button";
import Icon from "../../Icon";
import Flyout, { FlyoutProps } from "../../Flyout";
import { TableRowActionDefObject, TableRowType } from "../types";

const TableRowActionsFlyout = <
  Row extends TableRowType,
  Action extends TableRowActionDefObject<Row>
>({
  actions,
  row,
  flyoutProps = {},
  render,
  testId
}: {
  /**
   * The row to render actions for.
   */
  row: Row;
  /**
   * An array action definitions. {@link TableRowActionDefObjects}
   */
  actions?: Action[];
  /**
   * Props to pass to the Flyout component.
   */
  flyoutProps?: Omit<FlyoutProps, "target" | "children" | "isOpen">;
  /**
   * Custom render function for the flyout content.
   * - otherwise a Dropdown will be rendered
   * @param row - the current row
   */
  render?: (row: Row) => ReactNode;
  testId?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const filteredActions =
    actions?.filter(action => {
      if (typeof action?.hidden === "function") {
        return !action.hidden(row);
      }
      return action?.hidden !== true;
    }) || [];

  const hasActions = !!filteredActions.length || !!render;

  const handleAction = (actionId: string) => {
    if (render) {
      return;
    }
    const action = filteredActions.find(({ key: id }) => id === actionId);
    if (action) {
      action.onClick && action.onClick(row);
    }
    setIsOpen(false);
  };

  if (!hasActions) {
    return (
      <Button
        variant="ghost"
        prefix={<Icon name="Ellipsis" size="medium" />}
        onClick={() => {}}
        disabled
      />
    );
  }

  return (
    <Flyout
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      position="left"
      target={
        <Button
          variant="ghost"
          prefix={<Icon name="Ellipsis" size="medium" />}
          onClick={() => setIsOpen(true)}
        />
      }
      {...flyoutProps}
      lazy
    >
      <div
        css={css`
          display: contents;
        `}
        data-testid={`${testId}-flyout`}
      >
        {typeof render === "function" ? (
          <>{render(row)}</>
        ) : (
          <Dropdown
            items={[...filteredActions]}
            selected={undefined as any}
            onSelect={handleAction}
            lookupLabel={action => String(action?.label || action?.key)}
            lookupId={action => String(action!.key)}
            lookupIcon={action => action?.icon}
            cannotClear
          />
        )}
      </div>
    </Flyout>
  );
};

export default TableRowActionsFlyout;
