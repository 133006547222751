import {
  TableRowType,
  TableColumnProps,
  TableRowActionProps,
  TableRowActionDef,
  NestedRowsOnTable,
  TableProps
} from ".";

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

export function TableColumn<
  Row extends TableRowType,
  ColumnKey extends string = string
>(props: TableColumnProps<Row, ColumnKey>) {
  return <></>;
}
TableColumn.displayName = "Table.Column";

export function TableRowAction<Row extends TableRowType>(
  props: TableRowActionProps<Row>
) {
  return <></>;
}
TableRowAction.displayName = "Table.RowAction";

export function TableNestedRows<
  Row extends TableRowType = TableRowType,
  NestedRow extends TableRowType = TableRowType,
  Column extends string = string,
  Action extends TableRowActionDef<NestedRow> = TableRowActionDef<NestedRow>
>(props: NestedRowsOnTable<Row, NestedRow, Column, Action>) {
  return <></>;
}
TableNestedRows.displayName = "Table.NestedRows";

export function TableSearch<
  Row extends TableRowType,
  ColumnKey extends string = string
>(props: Partial<TableProps<Row, ColumnKey>["search"]>) {
  return <></>;
}
TableSearch.displayName = "Table.Search";
