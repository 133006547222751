import { FC } from "react";
import { CommonIconProps } from "./types";

const CalendarSchedule: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-calendar-schedule"
      width="100%"
      height="100%"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.748 2.996a.75.75 0 0 0-1.5 0v.25h-.25a3.751 3.751 0 0 0-3.751 3.751V16.001a3.751 3.751 0 0 0 3.75 3.751H9a.75.75 0 0 0 0-1.5H5.998a2.251 2.251 0 0 1-2.251-2.25V9.75h14.506V10a.75.75 0 0 0 1.5 0V6.997a3.751 3.751 0 0 0-3.751-3.75h-.25v-.25a.75.75 0 0 0-1.5 0v.25H7.747v-.25Zm-1.5 1.75v.25a.75.75 0 1 0 1.5 0v-.25h6.503v.25a.75.75 0 0 0 1.5 0v-.25h.251a2.251 2.251 0 0 1 2.251 2.251V8.25H3.747V6.997a2.251 2.251 0 0 1 2.25-2.25h.251ZM14.752 16.004a.75.75 0 0 1 .75-.75h2a.75.75 0 1 1 0 1.5h-2a.75.75 0 0 1-.75-.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.287 13.75a.537.537 0 0 0-.537.537v5.716c0 .287.25.537.537.537h6.43a.55.55 0 0 0 .537-.537v-5.716a.55.55 0 0 0-.537-.537h-6.43Zm-2.037.537c0-1.125.912-2.037 2.037-2.037h6.43a2.05 2.05 0 0 1 2.037 2.037v5.716a2.05 2.05 0 0 1-2.037 2.037h-6.43a2.05 2.05 0 0 1-2.037-2.037v-5.716Z"
      />
    </svg>
  );
};

export default CalendarSchedule;
