import { omit } from "lodash";
import {
  AudiencesActionTypes,
  AudiencesState,
  CREATE_AUDIENCE_FAILURE,
  CREATE_AUDIENCE_PENDING,
  CREATE_AUDIENCE_SUCCESS,
  GET_ALL_AUDIENCES_FAILURE,
  GET_ALL_AUDIENCES_PENDING,
  GET_ALL_AUDIENCES_SUCCESS,
  GET_AUDIENCE_FAILURE,
  GET_AUDIENCE_PENDING,
  GET_AUDIENCE_SUCCESS,
  CHECK_FOR_AUDIENCE_BY_EXPRESSION_PENDING,
  CHECK_FOR_AUDIENCE_BY_EXPRESSION_FAILURE,
  CHECK_FOR_AUDIENCE_BY_EXPRESSION_FOUND,
  CHECK_FOR_AUDIENCE_BY_EXPRESSION_NOT_FOUND,
  UPDATE_AUDIENCE_PENDING,
  UPDATE_AUDIENCE_FAILURE,
  UPDATE_AUDIENCE_SUCCESS,
  Pending,
  BULK_UPDATE_AUDIENCES_SUCCESS,
  GET_CACHED_AUDIENCES_SUCCESS
} from "./types";

export const initialState: AudiencesState = {
  byId: {},
  isAllLoading: false,
  creatingByRequestId: {},
  pendingById: {},
  receivedAllAt: null,
  checkingExpressionByRequestId: {},
  isCachedLoaded: false
};

export const audiencesReducer = (
  state = initialState,
  action: AudiencesActionTypes
): AudiencesState => {
  switch (action.type) {
    case GET_ALL_AUDIENCES_PENDING:
      return {
        ...state,
        isAllLoading: true
      };
    case GET_ALL_AUDIENCES_FAILURE:
      return {
        ...state,
        isAllLoading: false
      };
    case GET_ALL_AUDIENCES_SUCCESS:
      return {
        ...state,
        byId: action.payload.reduce((byId, audience) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[audience.id] = audience;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp
      };
    case GET_CACHED_AUDIENCES_SUCCESS:
      if (state.receivedAllAt) {
        return state;
      }
      return {
        ...state,
        isCachedLoaded: true,
        byId: action.payload.reduce((byId, audience) => {
          if (!state.byId[audience.id]) {
            // eslint-disable-next-line no-param-reassign
            byId[audience.id] = audience;
          }
          return byId;
        }, state.byId)
      };
    case GET_AUDIENCE_PENDING:
      return {
        ...state,
        pendingById: {
          ...state.pendingById,
          [action.meta.id]: Pending.GET
        }
      };
    case GET_AUDIENCE_FAILURE:
      return {
        ...state,
        pendingById: action.meta.id
          ? omit(state.pendingById, action.meta.id)
          : state.pendingById
      };
    case GET_AUDIENCE_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        pendingById: omit(state.pendingById, action.payload.id)
      };
    case CREATE_AUDIENCE_PENDING:
      return {
        ...state,
        creatingByRequestId: {
          ...state.creatingByRequestId,
          [action.meta.requestId]: action.meta.audience
        }
      };
    case CREATE_AUDIENCE_FAILURE:
      return {
        ...state,
        creatingByRequestId: omit(
          state.creatingByRequestId,
          action.meta.requestId
        )
      };
    case CREATE_AUDIENCE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        },
        creatingByRequestId: omit(
          state.creatingByRequestId,
          action.meta.requestId
        )
      };
    case CHECK_FOR_AUDIENCE_BY_EXPRESSION_PENDING:
      return {
        ...state,
        checkingExpressionByRequestId: {
          ...state.checkingExpressionByRequestId,
          [action.meta.requestId]: action.meta.expression
        }
      };
    case CHECK_FOR_AUDIENCE_BY_EXPRESSION_FAILURE:
      return {
        ...state,
        checkingExpressionByRequestId: action.meta.requestId
          ? omit(state.checkingExpressionByRequestId, action.meta.requestId)
          : state.checkingExpressionByRequestId
      };
    case CHECK_FOR_AUDIENCE_BY_EXPRESSION_FOUND:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        },
        checkingExpressionByRequestId: omit(
          state.checkingExpressionByRequestId,
          action.meta.requestId
        )
      };
    case CHECK_FOR_AUDIENCE_BY_EXPRESSION_NOT_FOUND:
      return {
        ...state,
        checkingExpressionByRequestId: omit(
          state.checkingExpressionByRequestId,
          action.meta.requestId
        )
      };
    case UPDATE_AUDIENCE_PENDING:
      return {
        ...state,
        pendingById: {
          ...state.pendingById,
          [action.meta.audience.id]: Pending.UPDATE
        }
      };
    case UPDATE_AUDIENCE_FAILURE:
      return {
        ...state,
        pendingById: omit(state.pendingById, action.meta.audienceId)
      };
    case UPDATE_AUDIENCE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        },
        pendingById: omit(state.pendingById, action.payload.id)
      };
    case BULK_UPDATE_AUDIENCES_SUCCESS:
      return {
        ...state,
        byId: action.payload.reduce((byId, audience) => {
          // eslint-disable-next-line no-param-reassign
          byId[audience.id] = audience;
          return byId;
        }, state.byId)
      };
    default:
      return state;
  }
};
