import {
  useMemo,
  useRef,
  useState,
  FocusEvent,
  MouseEvent,
  useEffect
} from "react";
import { v4 as uuid } from "uuid";
import { DoNotCare } from "../../types";
import {
  SelectProps,
  isPropsMultiSelect,
  isPropsSingleSelect,
  SelectTargetSetFocusRef
} from "./types";
import SelectTarget from "./Target";
import FormField from "../FormField";
import { styles } from "./styles";
import Dropdown from "../Dropdown";
import { useSizingProp } from "../../hooks/props/useSizingProp";
import ReadonlyFormField from "../FormField/Readonly";
import { useSelectedIds, useItemsMap } from "../../hooks";

/** `Select` allows users to select from a list of options  */
const Select = <
  Selected extends string[] | string | undefined,
  Item extends Record<string, DoNotCare> | string
>({
  fill,
  options,
  fixed: incomingFixed,
  width,
  minWidth,
  height: incomingHeight,
  lockedWidth = false,
  fillToWidestOption,
  flyoutProps = {},
  selected: incomingSelected,
  label,
  hideLabel,
  required,
  error,
  offsetError,
  description,
  labelSuffix,
  intent,
  labelSuffixAlign,
  cannotClear,
  pinned,
  searchable,
  search: incomingSearch,
  disabled,
  readonly = false,
  focusOnMount,
  readonlyParser,
  id = `kit-${uuid()}`,
  max,
  lookupId,
  lookupLabel,
  lookupIcon,
  lookupIntent,
  onSelect,
  onClick,
  onFocus,
  onBlur,
  selectedSeparator,
  hideSelected,
  isVirtualized = options.length > 100
}: SelectProps<Selected, Item>) => {
  const [isOpen, setIsOpen] = useState(!!flyoutProps?.isOpen);

  const selectProps = {
    selected: incomingSelected,
    onSelect: (selected: Selected) => {
      if ((Array.isArray(selected) && selected.length === 0) || !selected) {
        setIsOpen(true);
      }
      onSelect?.(selected);
    }
  };

  const baseRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [targetWidth, setTargetWidth] = useState<number>(0);

  let setTargetFocusRef: SelectTargetSetFocusRef | null = null;

  const height = useSizingProp(incomingHeight, "auto");

  const itemsMap = useItemsMap<Item>(options, lookupId);

  const { selectedIds, fixed } = useSelectedIds(
    incomingSelected,
    incomingFixed
  );

  useEffect(() => {
    setIsOpen(!!flyoutProps?.isOpen);
  }, [flyoutProps?.isOpen]);

  const focusLastChipOrTarget = () => {
    if (
      Array.isArray(incomingSelected) &&
      selectedIds.length !== (fixed?.size || 0)
    ) {
      return setTargetFocusRef?.chipIndex?.(selectedIds.length - 1);
    }
    setTargetFocusRef?.target();
  };

  const focusSearchOrFirstItem = () => {
    if (!isOpen) {
      return setIsOpen(true);
    }
    if (searchable) {
      return dropdownRef?.current
        ?.querySelector<HTMLInputElement>(".kit-Input input")
        ?.focus();
    }
    dropdownRef?.current
      ?.querySelector<HTMLOptionElement>(
        // first option that is not disabled
        "[aria-selected]:not([aria-disabled=true])"
      )
      ?.focus();
  };

  const onTargetClick = (e: MouseEvent<HTMLElement>) => {
    setIsOpen(prev => !prev);
    onClick?.(e);
  };

  const onTargetBlur = (
    direction: "next" | "prev" | undefined,
    event: FocusEvent<HTMLElement> | undefined
  ) => {
    if (direction === "next") {
      focusSearchOrFirstItem();
    }
    if (direction === "prev") {
      setIsOpen(false);
      setTargetFocusRef?.target();
    }
    if (event) {
      onBlur?.(event);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    flyoutProps?.onClose?.();
  };

  const search = (() => {
    const searchProps = {
      ...incomingSearch,
      focusOnMount: incomingSearch?.focusOnMount !== false
    };
    if (typeof options[0] === "string") {
      return searchProps as SelectProps<Selected, Item>["search"];
    }
    return {
      ...searchProps,
      keys: incomingSearch?.keys || []
    } as SelectProps<Selected, Item>["search"];
  })();

  const dropdownFlyoutProps: SelectProps<Selected, Item>["flyoutProps"] =
    useMemo(
      () => ({
        position: "bottom",
        offset: "0, 4px",
        keepOpen: Array.isArray(incomingSelected),
        ...flyoutProps,
        isOpen,
        usePortal: false,
        minimal: true,
        onClose
      }),
      [flyoutProps, isOpen, incomingSelected]
    );

  const handleOnDropdownBlur = (direction: "next" | "prev") => {
    switch (direction) {
      case "next":
        setIsOpen(false);
        break;
      case "prev": {
        focusLastChipOrTarget();
        break;
      }
      default:
        break;
    }
  };

  const targetAriaLabel = `${label}${required ? " *" : ""}`;

  if (readonly) {
    return (
      <ReadonlyFormField
        parser={readonlyParser}
        fill={fill}
        label={label}
        hideLabel={hideLabel}
        value={incomingSelected}
        minWidth={`${targetWidth}px`}
        description={description}
        labelSuffix={labelSuffix}
        labelSuffixAlign={labelSuffixAlign}
      />
    );
  }

  return (
    <div
      ref={baseRef}
      className="kit-Select"
      css={[!!fill && styles.fill]}
      id={id}
    >
      <FormField
        fieldset
        label={label}
        hideLabel={hideLabel}
        error={error}
        offsetError={offsetError}
        required={required}
        description={description}
        labelSuffix={labelSuffix}
        labelSuffixAlign={labelSuffixAlign}
      >
        {isPropsSingleSelect(selectProps) && (
          <Dropdown
            containerRef={ref => {
              dropdownRef.current = ref;
            }}
            flyoutProps={dropdownFlyoutProps}
            fill={fill}
            cannotClear={cannotClear}
            items={options}
            selected={selectProps.selected}
            hideSelected={hideSelected}
            width={targetWidth}
            minWidth={targetWidth}
            maxWidth={lockedWidth ? targetWidth : undefined}
            height={height}
            lookupId={lookupId}
            lookupLabel={lookupLabel}
            lookupIcon={lookupIcon}
            onSelect={selected => {
              selectProps.onSelect(selected);
              setIsOpen(!selected);
            }}
            searchable={searchable}
            search={search}
            onDropdownBlur={handleOnDropdownBlur}
            isVirtualized={isVirtualized}
          >
            <SelectTarget
              cannotClear={cannotClear}
              options={options}
              selected={selectProps.selected}
              onSelect={selectProps.onSelect}
              selectedIds={selectedIds}
              lookupLabel={lookupLabel}
              lookupId={lookupId}
              lookupIntent={lookupIntent}
              isOpen={isOpen}
              onBlur={onTargetBlur}
              focusOnMount={focusOnMount}
              width={width}
              minWidth={minWidth}
              fill={fill}
              fillToWidestOption={fillToWidestOption}
              label={targetAriaLabel}
              disabled={!!disabled}
              error={!!error}
              onFocus={onFocus}
              onClick={onTargetClick}
              setTargetWidth={setTargetWidth}
              itemsMap={itemsMap}
              setFocusRef={ref => (setTargetFocusRef = ref)}
            />
          </Dropdown>
        )}
        {isPropsMultiSelect(selectProps) && (
          <Dropdown
            containerRef={ref => {
              dropdownRef.current = ref;
            }}
            flyoutProps={dropdownFlyoutProps}
            fill={fill}
            pinned={pinned}
            items={options}
            selected={selectedIds}
            fixed={fixed}
            width={targetWidth}
            minWidth={targetWidth}
            maxWidth={lockedWidth ? targetWidth : undefined}
            height={height}
            lookupId={lookupId}
            lookupLabel={lookupLabel}
            lookupIcon={lookupIcon}
            onSelect={selectProps.onSelect}
            searchable={searchable}
            search={search}
            max={max}
            onDropdownBlur={handleOnDropdownBlur}
            isVirtualized={isVirtualized}
          >
            <SelectTarget
              options={options}
              selected={selectProps.selected}
              fixed={fixed}
              onSelect={selectProps.onSelect}
              selectedIds={selectedIds}
              lookupLabel={lookupLabel}
              lookupId={lookupId}
              lookupIntent={lookupIntent}
              intent={intent}
              lookupIcon={lookupIcon}
              isOpen={isOpen}
              onBlur={onTargetBlur}
              focusOnMount={focusOnMount}
              width={width}
              minWidth={minWidth}
              fill={fill}
              fillToWidestOption={fillToWidestOption}
              label={targetAriaLabel}
              disabled={!!disabled}
              error={!!error}
              onFocus={onFocus}
              onClick={onTargetClick}
              setTargetWidth={setTargetWidth}
              setFocusRef={ref => (setTargetFocusRef = ref)}
              itemsMap={itemsMap}
              selectedSeparator={selectedSeparator}
            />
          </Dropdown>
        )}
      </FormField>
    </div>
  );
};

export default Select;
export * from "./types";
