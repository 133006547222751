import { FormattedGeoFields } from "newnewyork";
import { LogicalOperator } from "lib/constants/misc";
import { GeoTargetingDetails, GeoTargets } from "campaign-types";

// The backend logic for geotargeting is as follows: Nationwide include targeting is denoted by all include fields being empty other than country. In most other cases, the country field being populated does not denote inclusion, but instead acts as a mandatory flag that tells Roger that an included/excluded state or zip code is within the US. The exceptions are metro (formerly DMA) and congressional district. If only metro (formerly DMAs) and/or districts are targeted, the country field must be left empty. So the easiest way to think of this is: The only time the country field should be empty is when the user wishes to target ONLY a DMA and/or district. Otherwise the targeting will be invalid and the lineitem update will fail.

// If geotargeting is comprised exclusively of metros (formerly DMAs)/Districts, return true
export const isExclusivelyDmasAndOrDistricts = (targets: GeoTargets) =>
  !targets.states.length &&
  !targets.zipCodes.length &&
  (!!targets.dmaCodes.length || !!targets.districts.length);

/**
 *
 * TB: you cannot exclude an entire country (backend logic), so exlude
 * targeting is effectively empty if all keys except country are empty
 */
export const isGeoTargetEmpty = (targets: GeoTargets) =>
  !targets.country &&
  !targets.states.length &&
  !targets.districts.length &&
  !targets.zipCodes.length &&
  !targets.dmaCodes.length;

export const formatLineItemGeoForNny = (
  geoTargeting: GeoTargetingDetails,
  logicalOperator: LogicalOperator
) => ({
  country: geoTargeting[logicalOperator].country,
  states: geoTargeting[logicalOperator].states,
  congressional_districts: geoTargeting[logicalOperator].districts,
  dmas: geoTargeting[logicalOperator].dmaCodes,
  zips: geoTargeting[logicalOperator].zipCodes
});

export const OPERATOR_TO_FINALIZE_GEO_FN: {
  [LogicalOperator.INCLUDE]: (
    geoTargeting: GeoTargetingDetails
  ) => FormattedGeoFields | null;
  [LogicalOperator.EXCLUDE]: (
    geoTargeting: GeoTargetingDetails
  ) => FormattedGeoFields | null;
} = {
  [LogicalOperator.INCLUDE]: (geoTargeting: GeoTargetingDetails) =>
    isGeoTargetEmpty(geoTargeting.include)
      ? null
      : formatLineItemGeoForNny(geoTargeting, LogicalOperator.INCLUDE),
  [LogicalOperator.EXCLUDE]: (geoTargeting: GeoTargetingDetails) =>
    isGeoTargetEmpty(geoTargeting.exclude)
      ? null
      : formatLineItemGeoForNny(geoTargeting, LogicalOperator.EXCLUDE)
};
