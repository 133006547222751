import { css } from "@emotion/react";
import { kitShadow, kitText } from "../../styles";

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    min-width: 100%;
    width: fit-content;
    flex-grow: 1;
  `,
  card: css`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    .kit-Table {
      max-width: 100%;
      overflow-x: auto;
      border-radius: var(--spacing-8);
    }
  `,
  table: css`
    ${kitText.variant.body};
    --table-cell-vertical-padding: var(--spacing-8);
    --table-cell-horizontal-padding: var(--spacing-24);
    --table-cell-padding: var(--spacing-24);
    display: grid;
    align-items: center;
    th,
    td {
      background-color: var(--color-background-primary);
      height: 100%;
      position: relative;
    }
    tr {
      th:first-of-type,
      td:first-of-type {
        padding-left: calc(
          var(--table-cell-horizontal-padding) +
            var(--kit-table-first-column-left-padding-offset)
        );
      }
    }
  `,
  tableHeader: css`
    display: contents;
  `,
  tableBody: css`
    display: contents;
    tr:first-of-type td {
      border-top: 0;
    }
  `,
  tr: css`
    display: contents;
  `,
  th: css`
    padding: var(--table-cell-vertical-padding) var(--table-cell-padding);
    display: flex;
    align-items: center;
    color: var(--color-text-primary);
    background-color: var(--color-background-primary);
    ${kitText.variant.body};
    ${kitText.style.bold};
    z-index: 2;
    border-bottom: 1px solid var(--color-border-secondary);
    &:after {
      content: "";
      position: absolute;
      right: -1px;
      left: -1px;
      bottom: 0;
      height: 10px;
    }
  `,
  thSortable: css`
    cursor: pointer;
    &:hover {
      color: var(--color-splash-primary);
      background-color: var(--color-background-tertiary);
    }
  `,
  thSorted: css`
    color: var(--color-splash-primary);
    // avoid layout shift
    padding-right: calc(
      var(--table-cell-horizontal-padding) - var(--spacing-16)
    );
  `,
  td: css`
    z-index: 1;
    display: flex;
    align-items: center;
    padding: var(--table-cell-vertical-padding)
      var(--table-cell-horizontal-padding);
    min-height: var(--kit-table-row-min-height);
    background-color: var(--color-background-primary);
    z-index: 1;
    border-top: 1px solid var(--color-border-secondary);
  `,
  stickyHeader: css`
    thead tr th {
      position: sticky;
      top: var(--kit-table-header-offset);
      z-index: 3;
      &::after {
        box-shadow:
          var(--scroll-shadow-top),
          0px 6px 2px 0px var(--color-background-primary);
        clip-path: ${kitShadow.overflow.values.clipPath.bottom};
      }
    }
  `,
  stickyFirstColumn: css`
    tbody tr td:first-of-type,
    thead tr th:first-of-type {
      position: sticky;
      left: 0;
      z-index: 2;
      box-shadow:
        var(--scroll-shadow-left),
        0px -6px 2px 0px var(--color-background-primary);
      clip-path: ${kitShadow.overflow.values.clipPath.right};
    }
    thead tr th:first-of-type {
      z-index: 4;
      clip-path: inset(0px -8px -8px 0px);
    }
  `,
  stickyLastColumn: css`
    tr td:last-of-type,
    tr th:last-of-type {
      position: sticky;
      right: 0;
      z-index: 2;
      background-color: var(--color-background-primary);
      box-shadow:
        var(--scroll-shadow-right, -1px 0 0 var(--color-border-secondary)),
        -1px 0 0 0 var(--color-background-primary);
      clip-path: ${kitShadow.overflow.values.clipPath.left};
    }
    tr th:last-of-type {
      z-index: 4;
      clip-path: inset(0px 0 -8px -8px);
    }
  `,
  actionsCell: css`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    > *,
    .bp4-popover-wrapper {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      height: 100%;
    }
    button,
    .kit-Display {
      width: auto;
    }
    button {
      width: 100%;
      --icon-fill-color: var(--color-secondary);
      &:hover {
        --icon-fill-color: var(--color-splash-primary);
      }
    }
  `,
  cellPrefix: css`
    margin-right: var(--spacing-8);
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
  `,
  pagerContainer: css`
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-background-primary);
    z-index: 2;
    box-shadow: 0 -1px 0 0 var(--color-border-secondary);
  `,
  pagerInCard: css`
    border-radius: 0 0 var(--spacing-8) var(--spacing-8);
  `,
  noViewContainer: css`
    display: flex;
    justify-content: center;
    margin: auto 0;
    padding: var(--spacing-32) 0;
  `,
  nestedToggle: css`
    -webkit-appearance: none;
    cursor: pointer;
    border: 0;
    background: transparent;
    outline: none;
    display: flex;
    margin: auto 0;
    width: var(--spacing-16);
  `
};
