import { Advertiser } from "@madhive/mad-sdk";

export const mapAdvertiserIdsToNames = (advertisers: Advertiser[]) => {
  const advMap = {};
  Object.values(advertisers).forEach(adv => {
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    advMap[adv.id || ""] = adv.name;
  });
  return advMap;
};
