// TODO: MAD-1708 - delete this whole file

import { SegmentType } from "lib/constants/forecast";
import { AudienceSegment } from "types";
import { SegmentsEndpointGetResponse } from "newnewyork";
import axios, { CancelTokenSource } from "axios";
import { parseNewNewYorkApiResponse } from "@madhive/mad-sdk";
import { BASE_API_URL } from "./constants";
import { logErrorToStackdriver } from "./logErrors";
import { KnownSegmentCategory } from "./types";

const CUSTOM_RECIPES_ENDPOINT = `${BASE_API_URL}/recipes`;

/**
 * The `category` property on the raw response needs to be converted to a member
 * of the `SegmentType` enum for use in the UI.
 */
export const CATEGORY_TYPE_MAP: Readonly<
  Record<KnownSegmentCategory, SegmentType | null>
> = {
  [KnownSegmentCategory.DEMOGRAPHICS]: SegmentType.DEMOGRAPHIC,
  [KnownSegmentCategory.AGE]: SegmentType.DEMOGRAPHIC, // "Age" segments are counted as Demographic for segment selection business logic.
  [KnownSegmentCategory.GENDER]: SegmentType.DEMOGRAPHIC, // "Gender" segments are counted as Demographic for segment selection business logic.
  [KnownSegmentCategory.CONSUMER]: SegmentType.CONSUMER,
  [KnownSegmentCategory.HOUSEHOLD]: SegmentType.HOUSEHOLD,
  [KnownSegmentCategory.CUSTOM]: SegmentType.CUSTOM
};

const getCustomSegments_REAL = async (
  cancellationSource: CancelTokenSource
): Promise<AudienceSegment[]> => {
  const data = await axios
    .get(CUSTOM_RECIPES_ENDPOINT, {
      headers: {
        "Content-Type": "application/json"
      },
      cancelToken: cancellationSource.token
    })
    .then(res => {
      try {
        const { data: rawRecipes } =
          parseNewNewYorkApiResponse<SegmentsEndpointGetResponse>(res);
        return rawRecipes;
      } catch (e) {
        throw Error(
          "Failed to retrieve segments. Please try again a later time."
        );
      }
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        logErrorToStackdriver(error);
      }
      throw error;
    });

  if (!data || !Array.isArray(data)) {
    const error = new Error(
      `No usable data present in the segments response. Data: ${data}`
    );

    logErrorToStackdriver(error);
    throw error;
  }

  const segmentsWithNormalizedType = data
    .filter(
      segment =>
        /** Backend now provides gender segments, but they were previously not available to users. Make sure to filter them out. */
        segment.category !== KnownSegmentCategory.GENDER
    )
    .map(segment => ({
      label: segment.name,
      id: segment.id,
      status: segment.status,
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      type: CATEGORY_TYPE_MAP[segment.category],
      price: segment.cpm
      // householdCount: Math.random() * 1000
      // PK TODO: Need to update ??? Elan says this is also a TBD (recipes won't have "household" for a while since it's a really hard problem)
      // householdCount: segment.
    }));

  const filteredNormalizedSegments = segmentsWithNormalizedType.filter(
    segment => !!segment.type
  );

  return filteredNormalizedSegments;
};

export const getAvailableSegments = getCustomSegments_REAL;
