import { FC, isValidElement } from "react";
import * as Icons from ".";
import {
  IconNames,
  IconComponentProps,
  DisplaySize,
  CommonIconProps
} from "./types";
import { IconProps } from "../Icon/types";
import Icon from "../Icon";

/**
 * Lookup icon component by its name
 * @param name: IconNames
 * @returns found icon component or null
 */
export const lookupIconComponent = <Path extends IconNames = IconNames>(
  name: Path
): FC<IconComponentProps<Path>> | null => {
  let found: FC<IconComponentProps<Path>> | undefined;
  name.split(".").forEach((namePart: string) => {
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    found = found ? found?.[namePart] : Icons[namePart];
  });
  return found || null;
};

/**
 * Method for returning icon wrapped in a Display component
 * @param icon: IconNames | JSX.Element | undefined | null
 * @param props: optional props to pass to Icon
 * @param props.size: DisplaySize | "fill" — if "fill" then the icon will be rendered without a Display wrapper
 * @param props.fill: `var(${string})` — CSS color value for the fill of the icon
 * @param props.stroke: `var(${string})` — CSS color value for the stroke of the icon
 * @param props: if icon is a string (IconName) then props will also include any specific props for that icon
 * @returns JSX.Element | null
 */
export const getIconFromStringOrElement = <
  Path extends IconNames | JSX.Element | undefined | null
>(
  icon: Path,
  props?: Path extends IconNames
    ? Omit<IconProps<Path>, "name">
    : CommonIconProps & { size?: DisplaySize | "fill" }
): JSX.Element | null => {
  const { size = "small", fill, stroke, ...rest } = props || {};
  if (!icon) {
    return null;
  }
  if (isValidElement(icon)) {
    return size === "fill" ? (
      icon
    ) : (
      <Icons.Display size={size} fill={fill} stroke={stroke}>
        {icon as JSX.Element}
      </Icons.Display>
    );
  }
  if (typeof icon === "string") {
    const IconAsElement = lookupIconComponent(icon as IconNames);
    if (!IconAsElement) {
      return null;
    }
    return (
      <Icon
        name={icon as IconNames}
        stroke={stroke}
        fill={fill}
        size={size}
        {...rest}
      />
    );
  }
  return null;
};
