const getHeap = () =>
  // heap has an issue where it is init'd as an array, before being turned into an object on load
  // to avoid having it break, we should just grab heap from the window each time we need it, instead of grabbing it once in the constructor
  // see: https://github.com/segmentio/analytics.js/issues/605#issuecomment-605464507
  // eslint-disable-next-line dot-notation
  /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
  window.heap;

// Measure page load time
// See: https://developers.heap.io/docs/executing-javascript-using-snapshots#capture-page-load-time
/** @deprecated: this should be hidden behind mad-tracker. */
export const trackPageLoadTime = () => {
  const heap = getHeap();

  if (!heap) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const interval = setInterval(sendTrack, 200);
  function sendTrack() {
    if (performance.timing.domComplete !== 0) {
      const loadTime =
        (performance.timing.domComplete - performance.timing.navigationStart) /
        1000;
      heap.track("Page Load", { "Load Time": loadTime });
      clearInterval(interval);
    }
  }
};
