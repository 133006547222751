import { FC, useMemo } from "react";
import { DoNotCare } from "../../types";
import { TableRowType, TableProps } from "..";
import { TableRowActionDef } from "../types";

export const isTablePropsWithCsvHeaderRow = ({
  columns: { orderedKeys, definitions } = {},
  data
}: TableProps<DoNotCare>) =>
  !orderedKeys?.length && !definitions && Array.isArray(data[0]);

/**
 * Higher Order Component that derives columns from the first row of the csv data.
 * @param WrappedTable The kit Table component to wrap.
 */
export const tableWithCsvHeaderRow = <
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(
  WrappedTable: FC<TableProps<Row, Column, Action>>
) => {
  /**
   * Table that derives columns from the first row of the csv data,
   * by slicing the first row from the data and passing it as columns.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const { columns, data } = useMemo(() => {
      let { data = [] } = props;
      const columns = { ...(props.columns || {}) };

      if (!isTablePropsWithCsvHeaderRow(props)) {
        return {
          columns,
          data
        };
      }

      if (!columns.orderedKeys && Array.isArray(data[0])) {
        columns.orderedKeys = data[0] as unknown as Column[];
        data = data.slice(1) as Row[];
      }

      return { columns, data };
    }, [isTablePropsWithCsvHeaderRow(props)]);

    return <WrappedTable {...props} columns={columns} data={data} />;
  };
};
