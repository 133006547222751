import { useState, DragEvent } from "react";
import classNames from "classnames";
import { styles } from "../styles";
import { CLASS_NAME, DROP_POSITION } from "../constants";

type DraggableItemDropTargetProps = {
  /**
   * Callback for when draggable item is dropped on this Drop Zone.
   * @param dropPosition - The position (top or bottom) of the zone where item is being dropped.
   * Note: This can be added to the index because the enum translates to 0 or 1.
   */
  onDrop: (
    dropPosition: DROP_POSITION,
    event: DragEvent<HTMLDivElement>
  ) => void;
};

const DropPosition = ({ onDrop }: DraggableItemDropTargetProps) => {
  const [dragHover, setDragHover] = useState(false);

  const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
    setDragHover(false);
    onDrop(0, e);
  };

  const handleOnDragOver = (e: DragEvent<HTMLDivElement>) => {
    // This prevents the browser from applying a default cursor effect.
    e.dataTransfer.dropEffect = "move";
    e.preventDefault();
  };

  return (
    <div
      className={classNames([
        CLASS_NAME.DROP_ZONE,
        dragHover && CLASS_NAME.DROP_ZONE_HOVER
      ])}
      css={[styles.draggableItemDropTarget]}
      onDragEnter={() => setDragHover(true)}
      onDragLeave={() => setDragHover(false)}
      onDragOver={handleOnDragOver}
      onDrop={handleOnDrop}
    />
  );
};

export const DraggableItemDropTarget = ({
  onDrop
}: DraggableItemDropTargetProps) => {
  return (
    <>
      <DropPosition onDrop={(_, e) => onDrop(0, e)} />
      <DropPosition onDrop={(_, e) => onDrop(1, e)} />
    </>
  );
};
