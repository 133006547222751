import { omit } from "lodash";
import { List as PublisherList, ServiceStatus } from "@madhive/mad-sdk";
import {
  PublisherListsState,
  PublisherListsActionTypes,
  GET_ALL_PUBLISHER_LISTS_PENDING,
  GET_ALL_PUBLISHER_LISTS_FAILURE,
  GET_ALL_PUBLISHER_LISTS_SUCCESS,
  ABORT_PUBLISHER_LISTS_REQUEST,
  SAVE_PUBLISHER_LIST_PENDING,
  SAVE_PUBLISHER_LIST_FAILURE,
  SAVE_PUBLISHER_LIST_SUCCESS,
  GET_PUBLISHER_LIST_BYID_PENDING,
  GET_PUBLISHER_LIST_BYID_FAILURE,
  GET_PUBLISHER_LIST_BYID_SUCCESS
} from "./publisherListsTypes";

export const initialState: PublisherListsState = {
  byId: {},
  archivedById: {},
  isAllLoading: false,
  receivedAllAt: null,
  savingById: {},
  getError: null,
  saveError: null,
  isGetByIdLoading: false
};

export const publisherListsReducer = (
  state = initialState,
  action: PublisherListsActionTypes
): PublisherListsState => {
  switch (action.type) {
    case GET_ALL_PUBLISHER_LISTS_PENDING:
      return {
        ...state,
        isAllLoading: true,
        getError: null
      };
    case GET_ALL_PUBLISHER_LISTS_FAILURE:
      return {
        ...state,
        isAllLoading: false,
        getError: action.meta.error.message
      };
    case GET_ALL_PUBLISHER_LISTS_SUCCESS: {
      return {
        ...state,
        ...action.payload.reduce(
          (acc, publisherList) => {
            if (publisherList.status === ServiceStatus.READY) {
              /* eslint-disable no-param-reassign */
              /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
              acc.byId[publisherList.id] = publisherList;
              /* eslint-enable no-param-reassign */
            } else if (publisherList.status === ServiceStatus.ARCHIVED) {
              /* eslint-disable no-param-reassign */
              /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
              acc.archivedById[publisherList.id] = publisherList;
              /* eslint-enable no-param-reassign */
            }
            return acc;
          },
          { byId: {}, archivedById: {} }
        ),
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp,
        getError: null
      };
    }
    case GET_PUBLISHER_LIST_BYID_PENDING: {
      return {
        ...state,
        isGetByIdLoading: true,
        getError: null
      };
    }
    case GET_PUBLISHER_LIST_BYID_FAILURE: {
      return {
        ...state,
        isGetByIdLoading: false,
        getError: action.meta.error.message
      };
    }
    case GET_PUBLISHER_LIST_BYID_SUCCESS: {
      return {
        ...state,
        isGetByIdLoading: false,
        getError: null,
        byId: { ...state.byId, [action.payload.id]: action.payload }
      };
    }
    case ABORT_PUBLISHER_LISTS_REQUEST:
      return {
        ...state,
        isAllLoading: false,
        getError: null,
        saveError: null
      };
    case SAVE_PUBLISHER_LIST_PENDING:
      if (action.meta.publisherList.id) {
        return {
          ...state,
          savingById: {
            ...state.savingById,
            [action.meta.publisherList.id]: action.meta
              .publisherList as PublisherList
          },
          saveError: null
        };
      }
      return {
        ...state,
        saveError: null
      };
    case SAVE_PUBLISHER_LIST_SUCCESS:
      return {
        ...state,
        savingById: omit(state.savingById, action.payload.publisherListId),
        saveError: null
      };
    case SAVE_PUBLISHER_LIST_FAILURE:
      if (action.meta.publisherListId) {
        return {
          ...state,
          savingById: omit(state.savingById, action.meta.publisherListId),
          saveError: action.meta.error.message
        };
      }
      return {
        ...state,
        saveError: action.meta.error.message
      };
    default:
      return state;
  }
};
