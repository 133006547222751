import { keyBy } from "lodash";

export enum AdvertiserFilterCategory {
  NAME = "name",
  ADVERTISER_DOMAIN = "advertiserDomain",
  ADVERTISER_ID = "id",
  EXTERNAL_ID = "externalId",
  CATEGORY = "iabCategoryId"
}

export const ADVERTISER_CATEGORIES = [
  {
    label: "Name",
    id: AdvertiserFilterCategory.NAME
  },
  {
    label: "Advertiser Domain",
    id: AdvertiserFilterCategory.ADVERTISER_DOMAIN
  },

  {
    label: "External ID",
    id: AdvertiserFilterCategory.EXTERNAL_ID
  },
  {
    label: "Category",
    id: AdvertiserFilterCategory.CATEGORY
  }
];

export enum AdvertiserTableHeading {
  NAME = "name",
  ADVERTISER_DOMAIN = "advertiser-domain",
  ADVERTISER_ID = "advertiser-id",
  EXTERNAL_ID = "external-id",
  CATEGORY = "category",
  ASSOCIATED_AGENCY = "associated-agency"
}

export const ADVERTISER_TABLE_HEADINGS = [
  { id: AdvertiserTableHeading.NAME, label: "Name" },
  { id: AdvertiserTableHeading.ASSOCIATED_AGENCY, label: "Associated Agency" },
  { id: AdvertiserTableHeading.ADVERTISER_DOMAIN, label: "Advertiser Domain" },
  { id: AdvertiserTableHeading.CATEGORY, label: "Category" },
  { id: AdvertiserTableHeading.ADVERTISER_ID, label: "Advertiser ID" },
  { id: AdvertiserTableHeading.EXTERNAL_ID, label: "External ID" }
];

export const CATEGORY_ID_TO_LABEL = keyBy(ADVERTISER_CATEGORIES, "id");

// this creates an object where the key is the label, and the value is the id
export const CATEGORY_LABEL_TO_ID = ADVERTISER_CATEGORIES.reduce(
  (mapping, category) => {
    const map = { ...mapping };
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
    map[category.label] = category.id;
    return map;
  },
  {}
);

export const CATEGORY_OPTIONS = ["Political"];
