import {
  cloneElement,
  FC,
  PropsWithChildren,
  ReactElement,
  useMemo
} from "react";
import { TableRowType, TableProps, TableHeadingProps } from "..";
import { TableRowActionDef } from "../types";
import { WrappedTableComponent, WrappedTableProps } from "./types";
import { lookupElementsByDisplayName } from "../../utils";
import PageSticky from "../../Page/Sticky";
import { DoNotCare } from "../../types";
import {
  isTablePropsWithExternalSearch,
  isTablePropsWithLocalSearch
} from "./withSearch";

/**
 * Determines if the TableProps includes heading(s)
 * @param props The TableProps to check.
 */
export const isTablePropsWithHeadingSection = (props: TableProps<DoNotCare>) =>
  lookupElementsByDisplayName("Table.Heading", props.children).length > 0 ||
  isTablePropsWithLocalSearch(props) ||
  isTablePropsWithExternalSearch(props);

const PassThroughWrapper: FC<PropsWithChildren<DoNotCare>> = ({ children }) =>
  children;

/**
 * Higher Order Component that adds a heading section above the table.
 * @param WrappedTable The kit Table component to wrap.
 * @returns A Table component that includes a heading section with all
 * `Table.Heading` components founding in `children`
 */
export function tableWithHeadingSection<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: WrappedTableComponent<Row, Column, Action>) {
  /**
   * Table with a header section above the table.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const {
      stickyHeader,
      children = [],
      variant
    } = props as WrappedTableProps<Row, Column, Action>;

    const headings = useMemo(() => {
      const headings = lookupElementsByDisplayName(
        "Table.Heading",
        children
      ) as Array<ReactElement<TableHeadingProps>>;
      if (variant !== "card") {
        return headings;
      }
      return headings.map(heading =>
        cloneElement(heading, {
          ...heading.props,
          bordered: false
        })
      );
    }, [children]);

    const hasStickyHeader = !!stickyHeader || typeof stickyHeader === "number";

    const HeadingWrapper = useMemo(
      () => (hasStickyHeader ? PageSticky : PassThroughWrapper),
      [hasStickyHeader]
    );

    return (
      <WrappedTable
        {...props}
        INTERNAL_heading={<HeadingWrapper>{headings}</HeadingWrapper>}
      />
    );
  };
}
