/* eslint-disable camelcase */
import { FC, PropsWithChildren, ReactNode, useMemo, useRef } from "react";
import { css } from "@emotion/react";
import { TableRowType, TableProps } from "..";
import { TableRowActionDef } from "../types";
import { WrappedTableComponent, WrappedTableProps } from "./types";
import { styles } from "../styles";
import OverflowShadows, { OverflowShadowsProps } from "../../OverflowShadows";
import { DoNotCare } from "../../types";
import Card from "../../Card";

type TableWrapperProps_INTERNAL = PropsWithChildren<{
  containerRef?: Required<TableProps<DoNotCare>>["containerRef"];
  header?: ReactNode;
}>;

const PassThroughWrapper: FC<TableWrapperProps_INTERNAL> = ({ children }) => (
  <>{children}</>
);

const CardTableWrapper: FC<TableWrapperProps_INTERNAL> = ({
  children,
  containerRef,
  header
}) => (
  <div
    className="kit-TableCard"
    css={[
      styles.card,
      css`
        --kit-page-header-padding-y: 8px;
        --kit-page-header-padding-x: 0px;
      `
    ]}
    ref={containerRef}
  >
    {header}
    <Card noPadding>{children}</Card>
  </div>
);

/**
 * The base container around `<table />` that applies styling and css variables
 */
const BaseTableWrapper: FC<
  Omit<TableProps<DoNotCare>, "children"> & { children: DoNotCare }
> = props => {
  const {
    stickyHeader,
    stickyFirstColumn,
    stickyLastColumn,
    variant = "page",
    containerRef: _containerRef,
    children
  } = props as WrappedTableProps<DoNotCare>;
  const hasStickyHeader = !!stickyHeader || typeof stickyHeader === "number";
  const containerRef = useRef<HTMLDivElement | null>(null);
  const headerOffset =
    variant === "card"
      ? 0
      : containerRef.current?.querySelector("table")?.offsetTop || 0;
  return (
    <div
      className="kit-Table"
      css={[
        styles.wrapper,
        css`
          --kit-table-header-offset: ${headerOffset}px;
          --kit-table-first-column-left-padding-offset: ${variant === "card"
            ? "0px"
            : "var(--spacing-8)"};
        `,
        stickyFirstColumn && styles.stickyFirstColumn,
        stickyLastColumn && styles.stickyLastColumn,
        hasStickyHeader && styles.stickyHeader
      ]}
      ref={(current: HTMLDivElement) => {
        containerRef.current = current;
        _containerRef?.(current);
      }}
    >
      {children}
    </div>
  );
};

/**
 * Higher Order Component that adds the container element around `Table`
 * @param WrappedTable The kit Table component to wrap.
 */
export function tableWithWrapper<
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(WrappedTable: WrappedTableComponent<Row, Column, Action>) {
  /**
   * Table component that wraps `<table />` in the base container
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const {
      stickyHeader,
      stickyFirstColumn,
      stickyLastColumn,
      variant = "page",
      isLoading,
      data,
      INTERNAL_heading,
      INTERNAL_loadingState,
      INTERNAL_emptyState,
      INTERNAL_pager
    } = props as WrappedTableProps<Row, Column, Action>;

    const hasStickyHeader = !!stickyHeader || typeof stickyHeader === "number";

    const Outer = useMemo(
      () => (variant === "card" ? CardTableWrapper : PassThroughWrapper),
      [variant]
    );
    const isDataViewable = !isLoading && !!data?.length;

    const overflowShadowProps = useMemo(() => {
      const overflowShadowProps: OverflowShadowsProps = {
        overflow: "visible",
        overflowShadows: [],
        fillHeight: true,
        children: undefined
      };
      if (hasStickyHeader) {
        overflowShadowProps.overflowShadows!.push("top");
      }
      if (stickyFirstColumn) {
        overflowShadowProps.overflowShadows!.push("left");
      }
      if (stickyLastColumn) {
        overflowShadowProps.overflowShadows!.push("right");
      }
      return overflowShadowProps;
    }, [variant, hasStickyHeader, stickyFirstColumn, stickyLastColumn]);

    const pager = INTERNAL_pager && (
      <div
        className="kit-TablePager"
        css={[styles.pagerContainer, variant === "card" && styles.pagerInCard]}
      >
        {INTERNAL_pager}
      </div>
    );

    return (
      <Outer containerRef={props.containerRef} header={INTERNAL_heading}>
        <BaseTableWrapper {...props}>
          {variant !== "card" && INTERNAL_heading}

          {!isDataViewable && (
            <div css={styles.noViewContainer}>
              {isLoading ? INTERNAL_loadingState : INTERNAL_emptyState}
            </div>
          )}
          {isDataViewable && (
            <>
              <OverflowShadows {...overflowShadowProps}>
                <WrappedTable {...props} />
              </OverflowShadows>
              {variant !== "card" && pager}
            </>
          )}
        </BaseTableWrapper>
        {variant === "card" && pager}
      </Outer>
    );
  };
}
