import { useEffect, useState } from "react";
import { peerChangesHandler } from "../../../hooks/modules";
import { useSave } from "../useSave";
import { useDelete } from "../useDelete";
import { useMadHooksCacheSubscription } from "../useMadHooksCacheSubscription";
import { useChange } from "../useChange";
import { useSingularMadHooksUtils } from "../singularUtils";
import { useLoadSingleData } from "./useLoadData";
/**
 * Initializes a single piece of data by id
 * @param collection Collection the data belongs to
 * @param id the id of the data
 * @param noData Flag used to skip requesting data via handler.
 * @returns BaseSingleData
 */
const useInitializeSingleData = (collection, id, noData) => {
    const { observer, handler } = useSingularMadHooksUtils(collection, id);
    const loadData = useLoadSingleData(collection, id, noData);
    const [singularData, setSingularData] = useState(observer.value);
    useMadHooksCacheSubscription(observer, (value) => {
        setSingularData({ ...value });
    }, [id]);
    useEffect(() => {
        const current = observer.value;
        // calls madsdk if first observer
        if (!noData && id && current.isInitializing) {
            loadData();
        }
        else {
            observer.next({
                ...observer.value,
                isInitializing: false
            });
        }
    }, [id, observer, handler]);
    const idKey = handler.getIdentifierKey();
    useEffect(() => {
        if (!observer.value.data?.[idKey])
            return undefined;
        const resourceId = observer.value.data?.[idKey];
        let subscription;
        peerChangesHandler
            .startListeningSingular(resourceId, handler.id, (saved) => {
            observer.next({
                ...observer.value,
                peerChanges: {
                    saved
                }
            });
        })
            .then((sub) => {
            subscription = sub;
        });
        return () => {
            subscription?.unsubscribe();
            if (!observer.observed) {
                peerChangesHandler.stopListening(resourceId);
            }
        };
    }, [observer.value.data?.[idKey]]);
    return singularData;
};
/**
 * Hook to retrieve a single object from MadSDK using Observer pattern in MadSDK
 * @param collection name used to key into MadSDK. Should match the Type you want it to return.
 * @param id the id of the object
 * @param options
 * @returns {SingularData<T>} for your object
 * @template T
 */
export const useMadSDKSingular = (collection, id, 
// this is placeholder for now but we anticipate adding some options later on
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
options) => {
    // This allows the implementation to define a string to use as the identifier in the cache. This
    // is useful for tracking new data instances that don't have an initial id.
    const cacheId = id || options?.key;
    // If the options.key is defined, we don't want to load data from the handler.
    const noData = !!options?.key;
    const baseSingleData = useInitializeSingleData(collection, cacheId, noData);
    const dependentCollections = options?.dependentCollections || [];
    // 'delete' is a reserved word in js so we'll use 'destroy' instead
    const destroy = useDelete(collection, cacheId, { dependentCollections });
    const { changeFunction, validateFunction } = useChange(collection, cacheId);
    const save = useSave(collection, cacheId, { dependentCollections, noData });
    const load = useLoadSingleData(collection, cacheId, noData);
    const reload = (resetChanges = false) => load({ resetChanges, clearCache: true });
    const reset = (clearCache = false) => load({ resetChanges: true, clearCache });
    return {
        ...baseSingleData,
        isLoading: baseSingleData.isLoading || baseSingleData.isInitializing,
        save,
        change: changeFunction,
        validate: validateFunction,
        destroy,
        reload,
        reset
    };
};
