import { css } from "@emotion/react";
import { DragReorderProps } from "./types";
import { styles } from "./styles";
import { DraggableItem } from "./DraggableItem";

/**
 * `DragReorder` is a meta component that adds drag and drop reordering functionality
 * to existing sets of sibling items (lists).
 */
const DragReorder = <T,>({
  items,
  gap = 0,
  useHandle = true,
  disabled = false,
  onReorder,
  children
}: DragReorderProps<T>) => {
  if (disabled) return <>{children}</>;

  const handleDrop = (fromIndex: number, toIndex: number) => {
    if (!onReorder) return;

    if (items) {
      // Offset the index when moving down (higher index) because the items above will be shifted.
      const offset = fromIndex > toIndex ? 0 : 1;
      const reordered = [...items];
      reordered.splice(toIndex - offset, 0, reordered.splice(fromIndex, 1)[0]);
      onReorder(reordered, fromIndex, toIndex);
    } else {
      onReorder(fromIndex, toIndex);
    }
  };

  return (
    <div
      className="kit-DragDropReorder"
      css={[
        css`
          --kit-ddr-gap: ${gap};
        `,
        styles.wrapper
      ]}
    >
      {children.map((child, i) =>
        child.type === DraggableItem ? (
          <DraggableItem
            {...child.props}
            key={child.key || i}
            index={i}
            disabled={false}
            useHandle={
              typeof child.props.useHandle === "undefined"
                ? useHandle
                : child.props.useHandle
            }
            onDrop={handleDrop}
          />
        ) : (
          child
        )
      )}
    </div>
  );
};

DragReorder.DraggableItem = DraggableItem;
export default DragReorder;
export * from "./types";
