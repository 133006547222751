import { useMemo } from "react";
import { TableProps, TableRowActionDef, TableRowType } from "./types";
import {
  tableWithCsvHeaderRow,
  tableWithPagination,
  isTablePropsWithCsvHeaderRow,
  tableWithLocalSort,
  isTablePropsWithLocalSort,
  isTablePropsWithExternalPagination,
  isTablePropsWithLocalPagination,
  isTablePropsWithHeadingSection,
  tableWithHeadingSection,
  tableWithEmptyState,
  tableWithLoadingState,
  isTablePropsWithLocalSearch,
  isTablePropsWithExternalSearch,
  tableWithSearch
} from "./HOC";
import TableBase from "./TableBase";
import { TableHeading } from "./Heading";
import {
  TableColumn,
  TableNestedRows,
  TableRowAction,
  TableSearch
} from "./CompositionalProps";
import { tableWithWrapper } from "./HOC/withWrapper";

let WrappedTable = tableWithWrapper(TableBase);
WrappedTable = tableWithLoadingState(WrappedTable);
WrappedTable = tableWithEmptyState(WrappedTable);

/**
 * `Table` is a flexible component for rendering tabular data.
 */
export const Table = <
  Row extends TableRowType,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(
  props: TableProps<Row, Column, Action>
) => {
  const Table = useMemo(() => {
    let Table = WrappedTable;

    // Heading section — above the table
    if (isTablePropsWithHeadingSection(props)) {
      Table = tableWithHeadingSection(Table);
    }

    // Pagination
    if (
      isTablePropsWithLocalPagination(props) ||
      isTablePropsWithExternalPagination(props)
    ) {
      Table = tableWithPagination(Table);
    }

    // Local Sorting
    if (isTablePropsWithLocalSort(props)) {
      Table = tableWithLocalSort(Table);
    }

    // Search
    if (
      isTablePropsWithLocalSearch(props) ||
      isTablePropsWithExternalSearch(props)
    ) {
      Table = tableWithSearch(Table);
    }

    // CSV Data With First Row As Columns
    if (isTablePropsWithCsvHeaderRow(props)) {
      Table = tableWithCsvHeaderRow(Table);
    }

    return Table;
  }, [
    isTablePropsWithHeadingSection(props),
    isTablePropsWithLocalPagination(props),
    isTablePropsWithExternalPagination(props),
    isTablePropsWithLocalSearch(props),
    isTablePropsWithExternalSearch(props),
    isTablePropsWithLocalSort(props)
  ]);

  return <Table {...props} />;
};

Table.Column = TableColumn;
Table.RowAction = TableRowAction;
Table.Heading = TableHeading;
Table.NestedRows = TableNestedRows;
Table.Search = TableSearch;
