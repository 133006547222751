import { useMemo, useState } from "react";
import { objectLookup } from "../utils";
import { IdType, KeyPaths } from "../types";

const isUndefined = <V extends undefined | null | "">(
  val: any,
  undefinedValues = [undefined, null, ""] as V[]
): val is V => undefinedValues.includes(val);

export type ValidSortType = string | number | Date | boolean | undefined | null;

type SortTypeName = "string" | "number" | "boolean" | "date";

/**
 * Default sort logic for sorting values of different types.
 * @param a - the first value
 * @param b - the second value
 * @param sortType - option type of the values
 */
const defaultSortFn = <T extends ValidSortType>(a: T, b: T): number => {
  if (isUndefined(a) && isUndefined(b)) {
    return 0;
  }

  let sortTypeName: SortTypeName | undefined;
  if (typeof a === "string" || typeof b === "string") {
    sortTypeName = "string";
  }
  if (typeof a === "number" || typeof b === "number") {
    sortTypeName = "number";
  }
  if (typeof a === "boolean" || typeof b === "boolean") {
    sortTypeName = "boolean";
  }
  if (a instanceof Date || b instanceof Date) {
    sortTypeName = "date";
  }

  // if one of the values is undefined
  if (isUndefined(a) || isUndefined(b)) {
    // undefined is treated as the smallest value
    if (sortTypeName === "number") {
      return isUndefined(a) ? -1 : 1;
    }
    // push to the end
    return isUndefined(a) ? 1 : -1;
  }

  // sort the values based on the type
  switch (sortTypeName) {
    case "date": {
      const aTime = (a as Date)?.getTime();
      const bTime = (b as Date)?.getTime();
      if (aTime === bTime) {
        return 0;
      }
      return aTime < bTime ? -1 : 1;
    }
    case "number": {
      const numA = Number(a);
      const numB = Number(b);
      if (numA === numB || (Number.isNaN(numA) && Number.isNaN(numB))) {
        return 0;
      }
      if (Number.isNaN(numA) || Number.isNaN(numB)) {
        return Number.isNaN(numA) ? -1 : 1;
      }
      return numA < numB ? -1 : 1;
    }
    case "boolean": {
      if (a === b) {
        return 0;
      }
      return a ? -1 : 1;
    }
    default: {
      return (
        a.toLocaleString().localeCompare(b.toLocaleString() || "", "en", {
          numeric: true,
          sensitivity: "case"
        }) || 0
      );
    }
  }
};

export type UseSortedProps<
  T,
  Field extends IdType = KeyPaths<T, ValidSortType>,
  ValueMapKey extends string = string
> = {
  /** Array of data to be sorted. */
  data: T[];
  /** Current field key being sorted. */
  key?: Field;
  /**
   * The current sort direction.
   * - @default "desc"
   */
  direction?: "asc" | "desc";
  /**
   * Object of lookup methods to override the value for a provided key.
   */
  lookupValues?: Record<ValueMapKey, (item: T) => ValidSortType>;
  disabled?: boolean;
} & (T extends ValidSortType
  ? { sortFn?: <Item extends T>(a: Item, b: Item) => number }
  : {
      sortFn?: <F extends Field | ValueMapKey | KeyPaths<T, ValidSortType>>(
        key: F
      ) => <Item extends T>(a: Item, b: Item) => number;
    });

/**
 * Hook for sorting data.
 * @param data - the data to sort - can be an array of any type
 * @param direction - the direction to sort the key
 * @param key - if data is array of records: the key to sort by
 * @param disabled - if true, sorting is disabled (data is returned as is)
 * @param value - optional map of to value lookup methods ((item: T) => ValidSortType)
 * @param sortFn - optional custom sort function
 */
export const useSorted = <
  T,
  Field extends IdType = KeyPaths<T, ValidSortType>,
  ValueMapKey extends string = string
>(
  {
    data,
    disabled,
    key,
    direction,
    lookupValues,
    sortFn
  } = {} as UseSortedProps<T, Field, ValueMapKey>
) => {
  const [sort, setSort] = useState<{
    key?: Field | KeyPaths<T, ValidSortType> | ValueMapKey | undefined;
    direction?: "asc" | "desc";
  }>({
    key,
    direction: direction || "asc"
  });

  const sortedData = useMemo<T[]>(
    () => {
      if (!data) {
        return [];
      }
      const { key: sortField, direction: sortDirection } = sort;
      if (disabled || (!sortField && !sortDirection)) {
        return data;
      }
      let sortFunc = defaultSortFn;
      const dataSample = data?.find(Boolean);
      if (sortFn) {
        if (
          ["string", "number", "boolean"].includes(typeof dataSample) ||
          dataSample instanceof Date
        ) {
          // if sortFn is defined and data is not an array of records, use it
          sortFunc = sortFn as <Item = T>(a: Item, b: Item) => number;
        } else {
          // if sortFn is defined and data is an array of records,
          // call it with the key to get the custom sort function
          const maybeCustomSortFunc = (sortFn as any)(sortField as Field);
          if (typeof maybeCustomSortFunc === "function") {
            sortFunc = maybeCustomSortFunc;
          }
        }
      }

      const lookupValue =
        lookupValues?.[sortField as ValueMapKey] ||
        // default lookup
        ((item: T) => {
          const value = !sortField
            ? item
            : objectLookup(item, String(sortField));
          return Array.isArray(value) ? value.join(",") : value;
        });

      return [...data].sort((a, b) => {
        const aItem = sort.direction === "asc" ? a : b;
        const bItem = sort.direction === "asc" ? b : a;
        // if default sort function, lookup the value
        const aVal = sortFunc === defaultSortFn ? lookupValue(aItem) : aItem;
        const bVal = sortFunc === defaultSortFn ? lookupValue(bItem) : bItem;
        return sortFunc(aVal, bVal);
      });
    },
    disabled ? [] : [data, sort.key, sort.direction]
  );

  return { data: disabled ? data : sortedData, sort, setSort };
};
