import { FC } from "react";
import { useSorted, UseSortedProps } from "../../../hooks";
import { TableProps } from "..";
import { TableRowActionDef } from "../types";
import { DoNotCare } from "../../types";

/**
 * Determines if the TableProps has local sorting enabled.
 * @param props The TableProps to check.
 */
export const isTablePropsWithLocalSort = ({
  sorting,
  columns
}: TableProps<DoNotCare>) =>
  typeof sorting === "object" &&
  typeof sorting?.onSort !== "function" &&
  !columns?.orderedKeys?.some(key => {
    const column = columns?.definitions?.[key];
    return (
      typeof column === "object" &&
      "onSort" in column &&
      typeof column?.onSort === "function"
    );
  });

/**
 * Higher Order Component that adds local sorting functionality via useSorted hook.
 * @param WrappedTable The kit Table component to wrap.
 * @returns A Table component that can handle local sorting
 */
export const tableWithLocalSort = <
  Row extends Record<string, any>,
  Column extends string,
  Action extends TableRowActionDef<Row>
>(
  WrappedTable: FC<TableProps<Row, Column, Action>>
) => {
  /**
   * Table that handles local sorting.
   */
  return function Table(props: TableProps<Row, Column, Action>) {
    const { data, sorting: _sorting = {} } = props;
    const sorting = { ..._sorting };

    const {
      data: sortedData,
      sort: { key, direction },
      setSort: onSort
    } = useSorted({
      data,
      key: sorting?.key,
      direction: sorting?.direction,
      lookupValues: sorting?.lookupValues
    } as UseSortedProps<Row>);

    sorting.onSort = (key, direction) => {
      sorting?.onSorted?.(key, direction);
      onSort({ key: String(key), direction });
    };

    sorting.key = key as Column;
    sorting.direction = direction;

    return <WrappedTable {...props} sorting={sorting} data={sortedData} />;
  };
};
