import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Filter: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-filter"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.75C3.03379 2.75 2.25 3.53379 2.25 4.5V7.032C2.25 7.55076 2.48102 8.0438 2.87988 8.37618L9.25 13.6843V19.691C9.25 20.6211 10.2283 21.2239 11.0584 20.8088L13.782 19.447C14.3755 19.151 14.75 18.545 14.75 17.882V13.6843L21.1201 8.37617C21.5187 8.04404 21.75 7.55217 21.75 7.032V4.5C21.75 3.53379 20.9662 2.75 20 2.75H4ZM3.75 4.5C3.75 4.36221 3.86221 4.25 4 4.25H20C20.1378 4.25 20.25 4.36221 20.25 4.5V6.75H3.75V4.5ZM5.07161 8.25L10.4801 12.7568C10.6511 12.8993 10.75 13.1104 10.75 13.333V19.286L13.1116 18.1052C13.1958 18.0632 13.25 17.9769 13.25 17.882V13.333C13.25 13.1104 13.3489 12.8993 13.5199 12.7568L18.9284 8.25H5.07161Z"
      />
    </svg>
  );
};

export default Filter;
