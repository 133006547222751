import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";
import { show } from "appReducers/toasterReducer";
import { Intent } from "@blueprintjs/core";

interface PossibleError {
  message: string | { message: string };
  uid: number;
}
// PK: Messy, but serializing errors is nontrivial, and it seems like the errors caught were
// network errors which would be nested as such: {message: {message: "Network Error"}}
// We could leverage a lib or utilize something like this: JSON.stringify(err,  Object.getOwnPropertyNames(err));
export const deriveErrorMessage = (possibleError: PossibleError) => {
  const { message, ...rest } = possibleError;
  let validErrorMsg = "";

  // PK: Error message as bubbled up from action/reducer, this will catch most errors.
  if (message && typeof message === "string") {
    validErrorMsg = message;
  }
  // PK: For those Error objects that would be nested within {message: {message: err}}. If there is a more graceful way to handle this I'd love to learn about it.
  else if (possibleError.message && typeof possibleError.message !== "string") {
    validErrorMsg = possibleError.message.message;
  }
  // PK: I know generic messages aren't the best, and can probably be done at the action level per component/view reducer.
  // However, given we don't have fallback error messages for each action file, this will suffice until we refactor.
  else {
    validErrorMsg = "There was an error, please try again.";
  }
  return {
    validErrorMsg,
    ...rest
  };
};

const toasterMiddleware: Middleware<Dispatch> =
  ({ dispatch }: MiddlewareAPI) =>
  (next: (arg0: AnyAction) => void) =>
  (action: AnyAction) => {
    if (action.meta) {
      // Success
      if (action.meta.success && action.meta.success.message) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { message, ...rest } = action.meta.success;

        dispatch(
          show({
            message: action.meta.success.message,
            icon: "endorsed",
            intent: Intent.SUCCESS,
            ...rest
          })
        );
      }

      // Failure
      if (action.meta.error) {
        const { validErrorMsg, ...rest } = deriveErrorMessage(
          action.meta.error
        );
        dispatch(
          show({
            message: validErrorMsg,
            icon: "error",
            intent: Intent.DANGER,
            ...rest
          })
        );
      }

      // Warning
      if (action.meta.warning && action.meta.warning.message) {
        const { message, ...rest } = action.meta.warning;

        dispatch(
          show({
            message,
            icon: "warning-sign",
            intent: Intent.WARNING,
            ...rest
          })
        );
      }

      // Info
      if (action.meta.info && action.meta.info.message) {
        const { message, ...rest } = action.meta.info;

        dispatch(
          show({
            message,
            icon: "info-sign",
            intent: Intent.PRIMARY,
            ...rest
          })
        );
      }
    }

    next(action);
  };

export default toasterMiddleware;
