import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const SquareWithZigZag: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-square-with-zig-zag"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25006 14.9912C2.24998 14.9975 2.24998 15.0038 2.25006 15.0101V17C2.25006 19.6232 4.37685 21.75 7.00006 21.75H17.0001C19.6233 21.75 21.7501 19.6232 21.7501 17V9.00871C21.7501 9.00248 21.7501 8.99625 21.7501 8.99001V7C21.7501 4.37679 19.6233 2.25 17.0001 2.25H7.00006C4.37685 2.25 2.25006 4.37679 2.25006 7V14.9912ZM3.75006 7C3.75006 5.20521 5.20527 3.75 7.00006 3.75H17.0001C18.7948 3.75 20.2501 5.20521 20.2501 7V8.61403L14.0811 13.0204L10.5304 9.46967C10.2711 9.21042 9.86247 9.1766 9.56413 9.3897L3.75006 13.5426V7ZM20.2501 10.4574V17C20.2501 18.7948 18.7948 20.25 17.0001 20.25H7.00006C5.20527 20.25 3.75006 18.7948 3.75006 17V15.386L9.91898 10.9796L13.4697 14.5303C13.729 14.7896 14.1376 14.8234 14.436 14.6103L20.2501 10.4574Z"
      />
    </svg>
  );
};

export default SquareWithZigZag;
