import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import * as Icons from "../Icons";
import { BreadcrumbsProps, Breadcrumb, BreadcrumbWithLink } from "./types";
import { styles } from "./styles";

/**
 * `Breadcrumbs` are a navigational aid that helps users keep track of their location, and navigate back to a parent page.
 * They are typically placed, in horizontal form, under the masthead or navigation of an experience, above the primary content area.
 */
const Breadcrumbs: FC<BreadcrumbsProps> = ({ crumbs, onClick }) => {
  const navigate = useNavigate();

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    crumb: BreadcrumbWithLink
  ) => {
    event.preventDefault();

    if (onClick) {
      onClick(crumb);
    } else if (crumb.link) {
      navigate(crumb.link);
    }
  };

  return (
    <nav
      aria-label="breadcrumbs"
      css={styles.wrapper}
      data-testid="kit-breadcrumb"
    >
      {crumbs.map((crumb: Breadcrumb, index: number) => (
        <React.Fragment key={crumb.title}>
          <a
            href="#"
            css={[
              styles.crumb,
              !crumb.link && !onClick && styles.unclickableCrumb,
              index === crumbs.length - 1 && styles.currentCrumb
            ]}
            onClick={event => handleClick(event, crumb as BreadcrumbWithLink)}
          >
            {crumb.title}
          </a>
          {index !== crumbs.length - 1 && (
            <Icons.Display size="small">
              <Icons.Chevron direction="right" fill="var(--color-tertiary)" />
            </Icons.Display>
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
export * from "./types";
