import { FC, PropsWithChildren } from "react";
import { css } from "@emotion/react";
import { styles } from "./styles";
import { GridProps } from "./types";

export const Grid: FC<PropsWithChildren<GridProps>> = ({
  columns = 3,
  gap = 24,
  children
}) => {
  const gridTemplateStyle = css`
    grid-template-columns: repeat(${columns}, minmax(100px, 1fr));
    grid-gap: var(--spacing-${gap});

    & > .kit-GridHR {
      grid-column: span ${columns};
    }
  `;
  return (
    <div css={[styles.grid, styles.hideEmptyCell, gridTemplateStyle]}>
      {children}
    </div>
  );
};
