import { DoNotCare } from "../types";

/**
 * @param object: an object to get a value from.
 * @param path: a path within the object, leading to the value you want. It is dot-separated. Example: "user.meta.nickname".
 * @return: the value from the object at that path, or undefined if nothing exists within the object at that path.
 */
export const objectLookup = (
  object: DoNotCare,
  path: string
): DoNotCare | undefined => {
  if (!path || typeof object !== "object" || !object) {
    return undefined;
  }

  const depth = path.split(".");
  let result = object[depth[0]];
  if (result !== undefined) {
    for (let i = 1; i < depth.length; i++) {
      result = typeof result === "object" ? result[depth[i]] : undefined;
      if (result === undefined) {
        break;
      }
    }
  }
  return result;
};

/**
 * Convert any value to a strong typed array.
 * If value is already an array, value will be returned as-is.
 */
export function toArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

/**
 * Type guard for determining whether a value is defined,
 * with the option of overriding "undefined" values.
 * @param value - the value to check
 * @param undefinedValues - set of undefined values - default: Set([undefined, null])
 */
export function hasValue<T, NoVal = undefined | null>(
  value: T,
  undefinedValues?: Set<NoVal>
): value is Exclude<T, NoVal> {
  const noVals: Set<DoNotCare> = undefinedValues || new Set([undefined, null]);
  return !noVals.has(value);
}
