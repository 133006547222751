import { Intent } from "@blueprintjs/core";
import { PeerSave, PeerSaveHeardAs } from "@madhive/mad-sdk";
import { useToast } from "frontier/lib/hooks/stateful/useToast";
import { Button } from "frontier/lib/kit";
import { RemoteConfigProperties, useFeatureFlag } from "hooks/useFeatureFlag";
import { dateDisplayTime, displayDate } from "lib/utils/date";
import { useEffect } from "react";

export const useDataChangedToast = (
  saved: PeerSave | undefined,
  dataType: string = "data"
) => {
  const addToast = useToast();
  const isSaveAlertsToastsFeatureEnabled = useFeatureFlag(
    RemoteConfigProperties.PEER_CHANGES_SAVE_ALERTS_TOASTS
  );
  const isSaveAlertsListsToastsFeatureEnabled = useFeatureFlag(
    RemoteConfigProperties.PEER_CHANGES_SAVE_LISTS_ALERTS_TOASTS
  );

  useEffect(() => {
    if (!isSaveAlertsToastsFeatureEnabled) {
      return;
    }

    if (saved) {
      const { user, timestamp, heardAs } = saved;
      const date = new Date(timestamp);
      const type = saved.dataType || dataType;

      if (
        !isSaveAlertsListsToastsFeatureEnabled &&
        (heardAs === "list" || heardAs === "new")
      ) {
        return;
      }

      const DATA_CHANGED_TOASTS: Record<
        PeerSaveHeardAs,
        { title: string; message: string }
      > = {
        new: {
          title: "New Item Created",
          message: `A new ${type} has been created by
            ${user?.email} on ${displayDate(date)} at ${dateDisplayTime(date)}.`
        },
        singular: {
          title: "New Version Published",
          message: `${user?.email} published a new version of this
            ${type} on ${displayDate(date)} at
            ${dateDisplayTime(date)}.`
        },
        list: {
          title: "Related Data Changed",
          message: `A new version of a related ${type} has been published by
            ${user?.email} on ${displayDate(date)} at ${dateDisplayTime(date)}.`
        }
      };

      const { title, message } = DATA_CHANGED_TOASTS[heardAs];

      addToast({
        title,
        intent: Intent.WARNING,
        message: (
          <>
            {message}{" "}
            <Button variant="ghost" onClick={() => window.location.reload()}>
              Refresh Page
            </Button>
          </>
        ),
        timeout: 0
      });
    }
  }, [saved]);
};
