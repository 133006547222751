import { css } from "@emotion/react";
import { kitText } from "../../styles";

export const styles = {
  layout: css`
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-16) var(--spacing-32);
  `,
  pageSize: css`
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
    position: sticky;
    left: var(--spacing-32);
  `,
  paging: css`
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-8);
    position: sticky;
    right: var(--spacing-32);
    > span {
      ${kitText.style.bold}
    }
  `
};
