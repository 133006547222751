import { css } from "@emotion/react";
import { kitText } from "../../styles";
import { styles } from "./styles";
import { ToggleProps } from "./types";
import FormField from "../FormField";
import ReadonlyFormField from "../FormField/Readonly";

const Toggle = ({
  disabled,
  onClick,
  id,
  label,
  labelPosition = "left",
  labelSuffix,
  labelSuffixAlign,
  readonly,
  readonlyParser,
  checked,
  fill,
  error,
  offsetError,
  required,
  description
}: ToggleProps) => {
  const labelId = `${id || "switch-input"}-label`;

  if (readonly) {
    return (
      <ReadonlyFormField
        parser={readonlyParser}
        fill={fill}
        label={label}
        value={checked}
        description={description}
        labelSuffix={labelSuffix}
        labelSuffixAlign={labelSuffixAlign}
      />
    );
  }

  return (
    <FormField
      fill={fill}
      label={labelPosition === "top" ? label : undefined}
      error={error}
      offsetError={offsetError}
      required={required}
      description={description}
      labelSuffix={labelPosition === "top" ? labelSuffix : undefined}
      labelSuffixAlign={labelSuffixAlign}
      fieldset
    >
      <div
        className="kit-Toggle"
        css={[
          styles.wrapper,
          disabled && styles.disabled,
          css`
            display: flex;
            align-items: center;
            gap: var(--spacing-8);
            ${labelPosition === "right" && "flex-direction: row-reverse;"}
          `
        ]}
        role="button"
        data-testid={id}
        aria-label={label}
        onClick={() => {
          onClick && !disabled && onClick();
        }}
        onKeyUp={e => {
          if (e.key === "Enter" && onClick) {
            onClick();
          }
        }}
        tabIndex={disabled ? -1 : 0}
      >
        {label && labelPosition !== "top" && (
          <span css={[kitText.style.bold, kitText.variant.body]}>{label}</span>
        )}

        <div css={[styles.inputWrapper, disabled && styles.disabled]}>
          <input
            css={[styles.input, disabled && styles.disabled]}
            data-testid={`${id}-switch-input`}
            id={id}
            aria-label={id || labelId}
            aria-labelledby={labelId}
            aria-checked={checked}
            checked={checked}
            type="checkbox"
            disabled={disabled}
            tabIndex={checked ? 1 : 0}
            aria-disabled={disabled}
            readOnly
          />
          <label
            htmlFor={id}
            css={[
              styles.handle,
              checked && styles.checked,
              disabled && styles.disabled,
              disabled && checked && styles.disabledChecked,
              disabled && !checked && styles.disabledUnchecked
            ]}
            aria-disabled={disabled}
          />
        </div>
      </div>
    </FormField>
  );
};

export default Toggle;
export * from "./types";
