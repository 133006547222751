/* eslint-disable camelcase */
import {
  Product,
  List,
  User,
  MediaType,
  KnownOrganizationIds,
  ManageCampaignColumn,
  ServiceStatus,
  removeWhitespace,
  IABCategory,
  FilterField,
  FilterTypes,
  LineItem,
  CreativeFlight,
  satoshisToDollars
} from "@madhive/mad-sdk";
import { css } from "@emotion/react";
import {
  getCampaignAudit,
  getLineItemAudit,
  getQAReport,
  transformDaypartFromEndpoint
} from "api/manageCampaigns";
import {
  CampaignEditRecords,
  LineItemEditRecords
} from "appReducers/campaignsReducer/quickEdits";
import { requestLineItemStatusUpdate } from "appReducers/lineItemsReducer/actions";
import {
  CampaignAudit,
  DraftCampaign,
  GeoTargets,
  LineItemAudit,
  LineItemCreativeMaybeWithoutId,
  LineItemFormatted,
  LineItemFormattedForAttachingCreatives,
  PossibleQuickEditStatuses,
  QuickEditLineItemPayload,
  RootCampaign,
  ShallowLineItem
} from "campaign-types";
import { EMPTY_DAYPARTS } from "components/SmithersDayparter/SmithersDayparter";
import {
  CampaignStatus,
  PRE_SET_LIVE_CAMPAIGN_STATUSES,
  UNEDITABLE_LINE_ITEM_STATUSES
} from "lib/constants/campaign";
import { BASE_APP_NAME } from "lib/constants/config";
import { ManageCampaignsEvents } from "lib/constants/events";
import { LogicalOperator } from "lib/constants/misc";
import {
  compareOrgRules,
  RULE_WHITE_LABEL_ORGS_IDS
} from "lib/constants/orgRules";
import { logEvent } from "lib/utils/analytics";
import { CSVContent, downloadCSV } from "lib/utils/csv";
import {
  addMonthsToDate,
  addOneDayToDate,
  addOneMonthToDate,
  addYearsToDate,
  dateFullDisplayWithTime,
  displayDate,
  getEarliestDate,
  getLatestDate,
  getTenMinutesFromNow,
  isDatePassed,
  prettifyDateWithTimezone,
  setDateToLastMillisecondOfDay,
  setDateToMidnight
} from "lib/utils/date";
import { GeographicEntityTypes } from "lib/utils/geography";
import {
  isExclusivelyDmasAndOrDistricts,
  isGeoTargetEmpty
} from "lib/utils/lineItemGeography";
import { formatAsPercent } from "lib/utils/number";
import {
  compareDates,
  sortAlphabetically,
  sortNullableValueAscending
} from "lib/utils/sorting";
import { buildMediaQuery } from "lib/utils/styles";
import { downloadBlob } from "frontier/lib/utils/files";
import { camelCase, cloneDeep } from "lodash";
import { ReactText } from "react";
import { madSDK } from "lib/sdk";
import { AppThunk } from "types";
import { formatAsUsd } from "lib/utils/currency";
import { BulkEditFormType } from "./types";
import { RawInstructionStatus } from "../constants";
import { LIST_OF_STATES_WITH_ABBREVIATIONS_MAP } from "../LineItemScreen/constants";
import {
  EditModeLineItemWithCreatives,
  LineItemScreenStateReducer
} from "../LineItemScreen/reducer";
import {
  CAMPAIGN_AUDIT_COLUMN_HEADERS,
  ColumnNamesForCSV,
  LINEITEM_AUDIT_COLUMN_HEADERS
} from "../MainScreen/constants";

export const useScreenStyles = {
  tabsList: css`
    display: flex;
    flex-direction: column;
    & > .bp4-tab-list {
      & > .bp4-tab {
        &:not(last-child) {
          margin-right: 50px;
        }
      }
      padding: 20px;
      border-bottom: 1px solid var(--border-light);
    }

    & > [role="tablist"] {
      padding: 20px;
      border-bottom: 1px solid var(--border-light);
    }

    & > .bp4-tab-panel {
      margin-top: 0px;
    }
  `,
  PanelContainer: css`
    display: flex;
    flex-grow: 1;
  `,
  invalidDate: css`
    & > input {
      border-color: red;
      &:focus {
        box-shadow:
          0 0 0 0 rgba(219, 55, 55, 0),
          0 0 0 0 rgba(219, 55, 55, 0),
          inset 0 0 0 1px #db3737,
          inset 0 0 0 1px rgba(16, 22, 26, 0.15),
          inset 0 1px 1px rgba(16, 22, 26, 0.2);
      }
    }
  `,
  leftIcon: css`
    margin-right: 7px;
  `,
  categoryLabel: css`
    font-weight: bold;
    margin-bottom: 10px;
  `,
  categoryLabelLarge: css`
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: var(--font-weight-bold);
  `,
  categoryValue: css`
    color: var(--semi-dark);
    line-height: 21px;
    word-break: break-word;
    white-space: normal;
  `,
  categoryValueError: css`
    color: var(--danger-color);
    line-height: 21px;
    word-break: break-word;
    white-space: normal;
  `,
  twoColumnLayout: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--spacing-24);
    grid-row-gap: var(--spacing-32);
  `,
  twoColumnLayoutNoRowGap: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--spacing-24);
  `,
  threeColumnLayout: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: var(--spacing-24);
    grid-row-gap: var(--spacing-32);
  `,
  fourColumnLayout: css`
    display: grid;
    grid-template-columns: repeat(4; 1fr);
    grid-column-gap: var(--spacing-24);
    grid-row-gap: var(--spacing-32);
  `,
  constrainedCard: css`
    max-width: 100em;
    padding: 10px 0px;
  `,
  subheader: css`
    font-weight: bold;
  `,
  subheaderDark: css`
    font-weight: bold;
    color: var(--black);
  `,
  noMargin: css`
    margin: 0px;
  `,
  requiredChip: css`
    color: #fff;
    font-size: 10px;
    background-color: var(--red);
    height: 20px;
    margin-left: 1rem;
  `,
  subheaderContainer: css`
    margin-bottom: 30px;
  `,
  navTab: css`
    position: relative;
    padding: 20px 28px 19px 30px;
    margin: 0px 30px 0px 0px;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    &:not(.selected) {
      &:hover {
        background-color: var(--primary-color);
        opacity: 0.5;
        color: #fff;
        border-radius: 0px 30px 30px 0px;
      }
    }
    &.selected {
      background-color: var(--primary-color);
      border-radius: 0px 30px 30px 0px;
      color: #fff;
    }
  `,
  verticalNavWrapper: css`
    padding-top: var(--spacing-16);
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eeeff2;
    height: 100%;
  `,
  emptyStateContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  emptyStateHeader: css`
    color: var(--text-gray);
    font-weight: 300;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
  `,
  emptyStateSubheader: css`
    color: var(--text-gray);
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
  `,
  interstitialCalloutCard: css`
    margin: var(--spacing-16) 0px;
  `,
  largeMarginBottom: css`
    margin-bottom: 40px;
  `,
  smallMarginBottom: css`
    margin-bottom: 10px;
  `,
  mediumMarginBottom: css`
    margin-bottom: 16px;
  `,
  mediumMarginTop: css`
    margin-top: 16px;
  `,
  tableHeader: css`
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  `,
  targetingTwoColumnLayout: css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
  `,
  weightToggleText: css`
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  `,
  /**
   * inputProps only styles the wrapping div around a Suggest input.
   */
  advertiserInput: css`
    & > input {
      &::placeholder {
        color: rgba(0; 0; 0; 0.5);
        font-size: 14;
      }
      font-size: 16;
      padding: 28.5px 10px 28.5px 14px;
      border-radius: 4px;
      border-color: rgba(0; 0; 0; 0.23);
      &:hover {
        border: 0.5px solid rgba(0; 0; 0; 0.87);
        transition: border-color 200ms cubic-bezier(0.0; 0; 0.2; 1) 0ms;
      }
    }
    margin-top: 18;
    margin-bottom: 8;
  `,
  statusIconContainer: css`
    display: flex;
  `,
  statusText: css`
    margin: 0 5px;
    display: inline;
  `,
  normalInputWidth: css`
    width: 195px;
  `,
  flexWithCenteredChildren: css`
    display: flex;
    align-items: center;
  `,
  flexGrow: css`
    flex-grow: 1;
  `,
  setLiveTitleText: css`
    font-size: 18;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.01em;
  `,
  EmptyStateButton: css`
    padding: var(--spacing-16) var(--spacing-32);
  `,
  PrimaryEmptyStateButton: css`
    margin: var(--spacing-16) 0;
  `,
  drawerPaper: css`
    ${buildMediaQuery.min("lg")} {
      width: 1600px;
    }
    ${buildMediaQuery.between("md", "xl")} {
      width: 1200px;
    }
    ${buildMediaQuery.between("sm", "lg")} {
      width: 800px;
    }
  `,
  blueprintLabelContainer: css`
    & > label.bp4-label {
      font-weight: bold;
      margin-bottom: 10px;
      & > span.bp4-text-muted {
        font-weight: normal;
        color: var(--indicator-bad);
      }
    }
  `,

  blueprintLabelContainerNoMargin: css`
    & > label.bp4-label {
      margin-bottom: 10px;
    }
  `,

  creationFormTwoColumnLayout: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--spacing-24);
    grid-row-gap: var(--spacing-32);
  `,
  creationFormThreeColumnLayout: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: var(--spacing-24);
    grid-row-gap: var(--spacing-32);
  `,
  creationFormFourColumnLayout: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: var(--spacing-24);
    grid-row-gap: var(--spacing-32);
  `,
  grow: css`
    flex-grow: 1;
  `,
  alignedToFirstGridColumn: css`
    grid-column-start: 1;
  `,
  alignedToThirdAndFourthGridColumn: css`
    grid-column-start: 2;
    grid-column-end: 4;
  `,
  spanTwoGridColumns: css`
    grid-column-start: 1;
    grid-column-end: 3;
  `,
  smallGridRowGap: css`
    grid-row-gap: var(--spacing-16);
  `,
  disabledButton: css`
    color: #34bc6e !important;
  `,
  switchTrack: css`
    background-color: #34bc6e !important;
  `,
  timeDataFetched: css`
    color: var(--semi-dark);
    word-break: break-word;
    white-space: normal;
    font-size: 12px;
  `,
  flexWithNoMarginBottom: css`
    display: flex;
    justify-content: right;
    margin-bottom: 0px !important;
  `,
  tableWrapper: css`
    margin: 10px 30px;
    border: 1px solid var(--border-light);
    border-radius: 10px;
    box-shadow: var(--shadow-1);
    &:last-child {
      marginbottom: 30px;
    }
  `,
  calloutWrap: css`
    padding: 10px 30px;
  `,
  loadingState: css`
    margin: auto;
    width: 100%;
  `,
  creativesPanelLoader: css`
    margin-bottom: 30px;
  `,
  pageBackground: css`
    background: #fff;
  `,
  dialog: css`
    background: white;
    margin-bottom: 0px;
    padding-bottom: 0;
    padding-top: 0px;
    margin-top: 0px;
    border-radius: 0;
  `,
  fileName: css`
    font-weight: bold;
    margin-left: 10px;
  `,
  progressWrap: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  uploadMessageWrapSuccess: css`
    width: 100%;
    margin-bottom: 15px;
    background: #f8f9fb;
    & > .bp4-callout {
      background: #f8f9fb;
    }
    & > .bp4-callout.bp4-callout-icon > .bp4-icon-error {
      color: var(--red);
    }
    & > .bp4-callout.bp4-callout-icon > .bp4-icon-tick {
      color: var(--secondary-color);
    }
  `,
  uploadedFileAction: css`
    color: var(--primary-color);
    cursor: pointer;
    margin-right: 10px;
  `,
  uploadedFileWrapper: css`
    margin-bottom: 30px;
    background: #fff;
    padding: 20px 20px 10px 20px;
    border-radius: 0;
    border-top: 1px solid var(--border-light);
  `,
  actionClass: css`
    background: var(--gray-light);
  `,
  titleClass: css`
    padding-bottom: 0px;
    padding-left: 10px;
  `,
  contentClass: css`
    margin-bottom: 40px;
  `,
  loadingLeftMargin: css`
    margin-left: 10px;
  `,
  deviceHeader: css`
    font-weight: bold;
    margin-bottom: 10px;
  `,
  deviceWrapper: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 20px;
  `,
  sectionHeader: css`
    margin: 10px 20px 10px 20px;
  `,
  separator: css`
    border-bottom: 1px solid var(--border-light);
    margin-top: 30px;
  `,
  blueprintLabelHeader: css`
    margin-left: 20px;
    letter-spacing: 1px;
    font-size: 20px;
    & > label.bp4-label {
      font-weight: 500;
      margin-bottom: 10;
      line-height: 0.8;
      letter-spacing: 1px;
      font-family: RedHatText;
      & > span.bp4-text-muted {
        font-weight: normal;
        color: var(--indicator-bad);
      }
    }
  `,
  publisherHeader: css`
    margin-bottom: 10px;
  `,
  maxDistroHeader: css`
    margin-bottom: 0;
    margin-top: 30px;
  `,
  sectionWrapper: css`
    margin-left: 10px;
  `,
  sectionWrapperProductAndPacing: css`
    margin-left: 10px;
    padding-bottom: 0px;
  `,
  publisherSelect: css`
    &.bp4-button {
      width: 350px;
    }
  `,
  productHeader: css`
    font-size: 20px;
  `,
  productHeaderMargin: css`
    margin-top: 30px;
    display: flex;
  `,
  errorDot: css`
    position: relative;
    left: 9px;
    top: 5px;
  `,
  builder: css`
    margin: 0 24px;
  `
};

const getUserEmailById = (id: string, allUsers: User[]) => {
  return allUsers.find(el => el.id === id)?.email || id;
};

const getCategoryList = async (iab_category_rtb_ids?: Array<string>) => {
  let categories: Array<string> = [];

  if (iab_category_rtb_ids) {
    const query: Array<FilterField<IABCategory>> = [];
    for (let i = 0; i < iab_category_rtb_ids.length; i++) {
      query.push({
        field: "rtb_id",
        type: FilterTypes.EQ,
        value: iab_category_rtb_ids[i]
      });
    }

    const cat = await madSDK.categories.find({ where: query });
    categories = cat.map((cat: IABCategory) => cat.description);
  }

  return categories;
};

const getLineItemAuditDetail = async (
  audit: LineItemAudit,
  allUsers: User[],
  allProducts: Record<string, Product>,
  allPublishers: Record<string, List>
): Promise<Array<ReactText>> => {
  const categories: Array<string> = await getCategoryList(
    audit.iab_category_rtb_ids
  );

  return new Promise(resolve => {
    const {
      name,
      id,
      version,
      updated,
      start_date,
      end_date,
      product_id,
      external_id,
      ext_metas,
      status,
      frequencies,
      device_caps,
      whitelist_id,
      geo_include,
      geo_exclude,
      segments,
      creatives,
      dayparts,
      booked
    } = audit;

    const potentialProductByName = product_id
      ? allProducts[product_id]?.name
      : product_id || "";
    const potentialPublisherByName =
      allPublishers[whitelist_id]?.name || whitelist_id;
    const potentialDeviceCaps = device_caps
      ? {
          // Ugly, but given how we type this data we need to access via bracket notation in order to suppress "type" doesn't exist on Never
          Desktop: device_caps!.desktop
            ? formatAsPercent(device_caps!.desktop / 100)
            : "",
          Mobile: device_caps!.mobile
            ? formatAsPercent(device_caps!.mobile / 100)
            : "",
          TV: device_caps!.tv ? formatAsPercent(device_caps!.tv / 100) : "",
          Tablet: device_caps!.tablet
            ? formatAsPercent(device_caps!.tablet / 100)
            : ""
        }
      : "";

    const convertedDayparts = transformDaypartFromEndpoint(dayparts!).map(
      el => ({
        ...el,
        hours: el.hours.join(", ")
      })
    );

    resolve([
      name,
      id,
      version,
      getUserEmailById(audit.updated_by, allUsers),
      dateFullDisplayWithTime(new Date(updated!)),
      start_date,
      end_date,
      booked || 0,
      JSON.stringify(convertedDayparts),
      JSON.stringify(potentialDeviceCaps),
      potentialProductByName,
      potentialPublisherByName,
      JSON.stringify(geo_include),
      JSON.stringify(geo_exclude),
      (frequencies || [])
        .map(({ max, duration: { time_unit } }) => `${time_unit}: ${max}`)
        .join(", "),
      JSON.stringify(segments),
      JSON.stringify(creatives),
      JSON.stringify(ext_metas),
      external_id,
      Object.keys(RawInstructionStatus).find(
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        key => RawInstructionStatus[key] === status
      ) as string,
      JSON.stringify(categories)
    ]);
  });
};

export const getLineItemAuditCSV = async (
  lineItem: LineItemFormatted | ShallowLineItem,
  allUsers: User[],
  allProducts: Record<string, Product>,
  allPublishers: Record<string, List>
) => {
  const lineItemId = lineItem.id;

  const lineItemAuditData = await getLineItemAudit(lineItemId);
  const csvFileName = `${BASE_APP_NAME} - LineItem - ${
    lineItem.name
  } - ${dateFullDisplayWithTime(new Date())}`;

  let rows: CSVContent = [];

  if (lineItemAuditData) {
    const dataToBeFetched = [];
    const sorted = lineItemAuditData.sort(
      (a: LineItemAudit, b: LineItemAudit) => b.version - a.version
    );
    for (let i = 0; i < sorted.length; i++) {
      dataToBeFetched.push(
        getLineItemAuditDetail(sorted[i], allUsers, allProducts, allPublishers)
      );
    }
    rows = await Promise.all(dataToBeFetched);
  }

  const headers = LINEITEM_AUDIT_COLUMN_HEADERS;

  downloadCSV(rows, headers, csvFileName);
};

const getCampaignAuditDetail = async (
  audit: CampaignAudit,
  allUsers: User[],
  advertiserName: string | ""
): Promise<Array<ReactText>> => {
  const categories: Array<string> = await getCategoryList(
    audit.iab_category_rtb_ids
  );
  return new Promise(resolve => {
    const {
      name,
      id,
      updated_by,
      version,
      updated,
      start_date,
      end_date,
      descendants,
      external_id,
      ext_metas,
      status
    } = audit;

    const descendantIds = descendants
      ? Object.values(descendants).map(el => el.id)
      : "";

    resolve([
      name,
      id,
      version,
      getUserEmailById(updated_by, allUsers),
      dateFullDisplayWithTime(new Date(updated!)),
      start_date,
      end_date,
      descendantIds ? descendantIds.join(", ") : "",
      JSON.stringify(ext_metas),
      external_id,
      Object.keys(RawInstructionStatus).find(
        /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
        key => RawInstructionStatus[key] === status
      ) as string,
      advertiserName,
      JSON.stringify(categories)
    ]);
  });
};

export const getCampaignAuditCSV = async (
  campaign: RootCampaign,
  allUsers: User[],
  advertiserName: string | ""
) => {
  const campaignId = campaign.id;

  const campaignAuditData = await getCampaignAudit(campaignId);
  const csvFileName = `${BASE_APP_NAME} - Campaigns - ${
    campaign.name
  } - ${dateFullDisplayWithTime(new Date())}`;

  let rows: CSVContent = [];

  if (campaignAuditData) {
    const dataToBeFetched = [];
    const sorted = campaignAuditData.sort(
      (a: CampaignAudit, b: CampaignAudit) => b.version - a.version
    );

    for (let i = 0; i < sorted.length; i++) {
      dataToBeFetched.push(
        getCampaignAuditDetail(sorted[i], allUsers, advertiserName)
      );
    }

    rows = await Promise.all(dataToBeFetched);
  }

  const headers = CAMPAIGN_AUDIT_COLUMN_HEADERS;

  downloadCSV(rows, headers, csvFileName);
};

export const createInitialCampaignState = (
  organizationId: KnownOrganizationIds | null,
  overloadedCampaignProps?: { endDate?: Date }
): DraftCampaign => ({
  name: "",
  organizationId: organizationId || "",
  daypartOperator: LogicalOperator.INCLUDE,
  dayparts: cloneDeep(EMPTY_DAYPARTS),
  endDate:
    overloadedCampaignProps?.endDate ||
    setDateToLastMillisecondOfDay(
      addOneMonthToDate(addOneDayToDate(new Date()))
    ),
  startDate: setDateToMidnight(addOneDayToDate(new Date())),
  startASAP: false,
  frequencies: [],
  hasFrequencyCap: false,
  bookedImpressions: 0,
  parent: organizationId || "",
  advertiserId: ""
});

// Used to derive start date for a creative about to be attached on line item
export const getAppropriateStartDate = (
  foundCreative: LineItemCreativeMaybeWithoutId | CreativeFlight | undefined,
  editModeLineItem:
    | LineItemFormattedForAttachingCreatives
    | ShallowLineItem
    | EditModeLineItemWithCreatives
    | LineItem
) => {
  // If not live, check to see if this creative already exists within line item and belongs to line item so that user defined start date can be set on creative being attached
  if (foundCreative) {
    return foundCreative.startDate;
  }
  // If line item is live, return current start date
  if (editModeLineItem.status === CampaignStatus.LIVE) {
    // Need to strip milliseconds
    const datePlusDayWithoutMilliseconds = new Date().setMilliseconds(0);
    return new Date(datePlusDayWithoutMilliseconds);
  }
  // Default to line item's start date if all else fails

  return editModeLineItem.startDate;
};

export const filterExact =
  (
    filterValue: RootCampaign[keyof RootCampaign],
    propertyName: keyof RootCampaign
  ) =>
  (item: RootCampaign) => {
    const propertyValue = item[propertyName];

    return propertyValue === filterValue;
  };

export const filterWithinSet =
  (
    setOfFilters: Set<RootCampaign[keyof RootCampaign]>,
    propertyName: keyof RootCampaign
  ) =>
  (item: RootCampaign) => {
    if (setOfFilters.size === 0) {
      return true;
    }

    const propertyValue: RootCampaign[keyof RootCampaign] = item[propertyName];

    return setOfFilters.has(propertyValue);
  };

export const handleReportDownload = async (organizationId?: string) => {
  const { data: QAReportData } = await getQAReport();

  const blob = new Blob([QAReportData], {
    type: "application/xml"
  });

  const now = new Date().toISOString();
  let fileName = `${BASE_APP_NAME}-QA-Report${now}.xlsx`;
  if (
    organizationId &&
    compareOrgRules(organizationId, RULE_WHITE_LABEL_ORGS_IDS)
  ) {
    fileName = `QA-Report${now}.xlsx`;
  }

  downloadBlob(blob, fileName);
};

export const isCampaignDescendentEditable = (
  organizationIds: KnownOrganizationIds[],
  derivedStatus: CampaignStatus
) => {
  /**
   * Premion requires ability to edit 'COMPLETED' and 'ARCHIVED' campaigns specifically for modifying actualized
   * impressions for billing.
   */
  if (
    Array.isArray(organizationIds) &&
    organizationIds.length &&
    organizationIds.includes(KnownOrganizationIds.PREMION)
  ) {
    return derivedStatus !== CampaignStatus.CANCELLED;
  }

  return !UNEDITABLE_LINE_ITEM_STATUSES.has(derivedStatus);
};

export const checkIfStartDateShouldBeDisabled = (
  inst: RootCampaign | LineItemFormatted
): boolean =>
  !(
    inst.rawStatus === RawInstructionStatus.DRAFT ||
    inst.rawStatus === RawInstructionStatus.PAUSED ||
    (inst.rawStatus === RawInstructionStatus.DELIVERABLE &&
      inst.startDate &&
      Date.now() < inst.startDate.valueOf())
  );

export const deriveCreativeStartMinDate = (
  creative: LineItemCreativeMaybeWithoutId,
  lineItem:
    | LineItemFormattedForAttachingCreatives
    | EditModeLineItemWithCreatives,
  currentTimeInMilliseconds: number
) => {
  // When line item is in draft, it should default to lineItem's start date
  if (
    creative.status === RawInstructionStatus.DRAFT &&
    lineItem.rawStatus === RawInstructionStatus.DRAFT
  ) {
    return lineItem.startDate;
  }
  if (creative.status === RawInstructionStatus.DRAFT) {
    return getLatestDate([
      lineItem.startDate,
      new Date(currentTimeInMilliseconds)
    ]);
  }
  return lineItem.startDate;
};

export const shouldCreativeStartDateBeDisabled = (props: {
  isLoading: boolean;
  creativeIsNewlyAttached: boolean;
  associatedCampaignIsUserEditable: boolean;
  lineItemRawStatus: RawInstructionStatus;
  creativeFlightStartDateInMilliseconds: number;
  currentTimeInMilliseconds: number;
  creative: LineItemCreativeMaybeWithoutId;
}) => {
  if (props.isLoading || !props.associatedCampaignIsUserEditable) {
    return true;
  }

  if (props.creativeIsNewlyAttached) {
    return false;
  }

  // If archived, do not let field be editable
  if (props.creative.status === RawInstructionStatus.ARCHIVED) {
    return true;
  }

  /**
   * Disable edits to creative flight start date if line item status is either LIVE or PAUSED
   * and creative flight start date has past. We do this to prevent users from rewriting line item history.
   */
  if (
    (props.lineItemRawStatus === RawInstructionStatus.DELIVERABLE ||
      props.lineItemRawStatus === RawInstructionStatus.PAUSED) &&
    props.creativeFlightStartDateInMilliseconds <
      props.currentTimeInMilliseconds
  ) {
    return true;
  }

  return false;
};

export const shouldCreativeEndDateBeDisabled = (
  screenState: LineItemScreenStateReducer,
  creative: LineItemCreativeMaybeWithoutId,
  associatedCampaign: RootCampaign
) =>
  // If creative already exists on line item
  (creative.status !== RawInstructionStatus.DRAFT &&
    // More than one creative flight exists on line item
    screenState.editModeLineItem.attachedCreatives.length > 1 &&
    // End date is less than current date
    creative.endDate &&
    creative.endDate.valueOf() < new Date().valueOf() &&
    // And is live status, disable end date input since they shouldn't be able to edit creatives that already finished running
    (screenState.editModeLineItem.rawStatus ===
      RawInstructionStatus.DELIVERABLE ||
      screenState.editModeLineItem.rawStatus ===
        RawInstructionStatus.PAUSED)) ||
  creative.status === RawInstructionStatus.ARCHIVED ||
  !associatedCampaign.isUserEditable ||
  screenState.isLoading;

export const hasAnyReadOnlyLineItems = (campaign: RootCampaign) => {
  const { lineItems } = campaign;
  if (!Array.isArray(lineItems) || !lineItems.length) {
    return false;
  }

  return lineItems.some(li => !li.isUserEditableIgnoreArchived);
};

/**
 * Users should only be able to add line items to campaigns if editable and end dates are some time in the future.
 * This is due to the campaign shell acting as a guardrail for any of it's descendent instructions. User should not
 * be able to create a line item with dates that escape the bounds of its parent campaign.
 * If campaign uses line item dates, we will ignore this setting as the campaign dates will be updated per line item dates
 */
export const isUserAbleToAddDescendantToParentCampaign = (
  campaign: RootCampaign,
  campaignUsesLineItemDates: boolean,
  canManage: boolean
) =>
  canManage &&
  campaign.isUserEditable &&
  (campaignUsesLineItemDates || campaign.endDate > new Date());

/**
 * Users should only be able to edit line item start date if both of the following conditions are met:
 *    1) Campaign status = "DRAFT" or start date is in the future
 *    2) Campaign has no read-only line items
 */
export const isUserAbleToEditParentCampaignStartDate = (
  campaign: RootCampaign
) =>
  campaign.isUserEditable &&
  !hasAnyReadOnlyLineItems(campaign) &&
  (campaign.rawStatus === RawInstructionStatus.DRAFT ||
    campaign.startDate > new Date());
export const validateInstructionDateRangeAgainstStatusDeliverable = (
  startDateinMilliseconds: number,
  endDateInMilliseconds: number,
  currentTimeInMilliseconds: number,
  status: RawInstructionStatus,
  isStartASAPEnabled: boolean
) => {
  const endDateIsInTheFuture =
    endDateInMilliseconds >= currentTimeInMilliseconds;
  const startDateIsInTheFuture =
    startDateinMilliseconds >= currentTimeInMilliseconds;
  // if current status is DRAFT or PAUSED
  // can set LIVE only if the start date and the end date are in the future
  if (status === RawInstructionStatus.DRAFT) {
    return (
      endDateIsInTheFuture && (startDateIsInTheFuture || isStartASAPEnabled)
    );
  }
  if (status === RawInstructionStatus.PAUSED) {
    return (
      (endDateIsInTheFuture && startDateIsInTheFuture) ||
      (!startDateIsInTheFuture && endDateIsInTheFuture)
    );
  }
  // if current status is  CANCELED or ARCHIVED, /can't set LIVE
  if (
    status === RawInstructionStatus.CANCELED ||
    status === RawInstructionStatus.ARCHIVED
  ) {
    return false;
  }
  return true;
};

export const validateInstructionDateRangeAgainstStatusArchive = (
  startDateinMilliseconds: number,
  endDateInMilliseconds: number,
  currentTimeInMilliseconds: number,
  status: RawInstructionStatus
) => {
  // can archive LIVE campaign if it is in the past or the future
  if (status === RawInstructionStatus.DELIVERABLE) {
    return (
      (startDateinMilliseconds < currentTimeInMilliseconds &&
        endDateInMilliseconds < currentTimeInMilliseconds) ||
      (startDateinMilliseconds > currentTimeInMilliseconds &&
        endDateInMilliseconds > currentTimeInMilliseconds)
    );
  }
  // can archive 100, 300, 400
  return true;
};

export const validateInstructionDateRangeAgainstStatusPause = (
  startDateinMilliseconds: number,
  endDateInMilliseconds: number,
  currentTimeInMilliseconds: number,
  status: RawInstructionStatus
) => {
  // can pause LIVE campaign if the start date is in the future
  if (status === RawInstructionStatus.DELIVERABLE) {
    return (
      (startDateinMilliseconds >= currentTimeInMilliseconds &&
        endDateInMilliseconds >= currentTimeInMilliseconds) ||
      (startDateinMilliseconds <= currentTimeInMilliseconds &&
        endDateInMilliseconds >= currentTimeInMilliseconds)
    );
  }
  // can't pause 100, 400, 500
  return false;
};

export const validateInstructionDateRangeAgainstStatus = (
  startDateinMilliseconds: number | undefined,
  endDateInMilliseconds: number | undefined,
  currentTimeInMilliseconds: number,
  currentStatus: RawInstructionStatus,
  newStatus: RawInstructionStatus,
  isStartASAPEnabled: boolean
) => {
  if (!(startDateinMilliseconds && endDateInMilliseconds)) {
    return false;
  }
  if (startDateinMilliseconds > endDateInMilliseconds) {
    return false;
  }

  // change status to PAUSED
  if (newStatus === RawInstructionStatus.PAUSED) {
    return validateInstructionDateRangeAgainstStatusPause(
      startDateinMilliseconds,
      endDateInMilliseconds,
      currentTimeInMilliseconds,
      currentStatus
    );
  }
  // change status to LIVE
  if (newStatus === RawInstructionStatus.DELIVERABLE) {
    return validateInstructionDateRangeAgainstStatusDeliverable(
      startDateinMilliseconds,
      endDateInMilliseconds,
      currentTimeInMilliseconds,
      currentStatus,
      isStartASAPEnabled
    );
  }
  // change status to ARCHIVED
  if (newStatus === RawInstructionStatus.ARCHIVED) {
    return validateInstructionDateRangeAgainstStatusArchive(
      startDateinMilliseconds,
      endDateInMilliseconds,
      currentTimeInMilliseconds,
      currentStatus
    );
  }
  return true;
};

export const getErrorMessage = (startDate?: Date, endDate?: Date): string => {
  if (startDate && endDate && startDate.valueOf() > endDate.valueOf()) {
    return "";
  }
  const currentDateInMilSec = new Date().valueOf();

  const isStartDateInthePast =
    startDate && startDate.valueOf() < currentDateInMilSec;
  const isEndDateInThePast = endDate && endDate.valueOf() < currentDateInMilSec;

  if (!isStartDateInthePast && !isEndDateInThePast) {
    return "";
  }

  if (isStartDateInthePast && isEndDateInThePast) {
    return ` Can't set live. Start and end dates are in the past.`;
  }
  return ` Can't set live. ${
    isStartDateInthePast ? `Start` : `End`
  } date is in the past.`;
};

const getErrorMessageForChangingStatusToPaused = (
  currentStatus: RawInstructionStatus,
  isStartDateInthePast: boolean,
  isEndDateInThePast: boolean
) => {
  if (currentStatus === RawInstructionStatus.DELIVERABLE) {
    if (isStartDateInthePast && isEndDateInThePast) {
      return ` Can't pause. Start and end dates are in the past.`;
    }
  } else {
    return ` Can't pause canceled, archived and draft campaigns.`;
  }
  return undefined;
};

const draftErrorMsg = (
  isStartDateInthePast: boolean,
  isEndDateInThePast: boolean,
  isStartASAPEnabled: boolean
) => {
  if (!isStartDateInthePast && !isEndDateInThePast) {
    return undefined;
  }

  if (isStartDateInthePast && isEndDateInThePast) {
    return ` Can't set live. Start and end dates are in the past.`;
  }

  if (isStartDateInthePast && !isStartASAPEnabled) {
    return ` Can't set live. Start date is in the past.`;
  }
  if (isEndDateInThePast) {
    return ` Can't set live. End date is in the past.`;
  }
  return undefined;
};

const archivedOrCanceledErrorMsg = () =>
  ` Can't set live archived or cancelled campaigns.`;

const pausedErrMsg = (
  isStartDateInthePast: boolean,
  isEndDateInThePast: boolean
) => {
  if (isStartDateInthePast && isEndDateInThePast) {
    return ` Can't set live. Start and end dates are in the past.`;
  }
  return undefined;
};

const getErrorMessageForChangingStatusToLive = (
  currentStatus: RawInstructionStatus,
  isStartDateInthePast: boolean,
  isEndDateInThePast: boolean,
  isStartASAPEnabled: boolean
) => {
  // changing from DRAFT to LIVE
  if (currentStatus === RawInstructionStatus.DRAFT) {
    return draftErrorMsg(
      isStartDateInthePast,
      isEndDateInThePast,
      isStartASAPEnabled
    );
    // chaning from ARCHIVED/CANCELLED to LIVE
  }
  if (
    currentStatus === RawInstructionStatus.ARCHIVED ||
    currentStatus === RawInstructionStatus.CANCELED
  ) {
    return archivedOrCanceledErrorMsg();
    // chaning from PAUSED to LIVE
  }
  if (currentStatus === RawInstructionStatus.PAUSED) {
    return pausedErrMsg(isStartDateInthePast, isEndDateInThePast);
  }
  return undefined;
};

export const getStatusTooltipMessage = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  currentDateInMilSec: Date,
  currentStatus: RawInstructionStatus,
  newStatus: RawInstructionStatus,
  isStartASAP: boolean = false
): string | undefined => {
  if (!(startDate && endDate)) {
    return;
  }
  const isStartDateInthePast =
    startDate.valueOf() < currentDateInMilSec.valueOf();
  const isEndDateInThePast = endDate.valueOf() < currentDateInMilSec.valueOf();

  if (startDate.valueOf() > endDate.valueOf()) {
    return undefined;
  }

  // changing status to new 200 LIVE status
  if (newStatus === RawInstructionStatus.DELIVERABLE) {
    return getErrorMessageForChangingStatusToLive(
      currentStatus,
      isStartDateInthePast,
      isEndDateInThePast,
      isStartASAP
    );
    // changing status to new 300 PAUSED status
  }
  if (newStatus === RawInstructionStatus.PAUSED) {
    return getErrorMessageForChangingStatusToPaused(
      currentStatus,
      isStartDateInthePast,
      isEndDateInThePast
    );
  }
  return undefined;
};

/**
 * Users need to be able to set creative flights live when setting campaign/line item live, this fn checks for flights that can be set live/paused
 */
export const isValidCreativeFlightForLive = (
  flight: LineItemCreativeMaybeWithoutId
): boolean =>
  Boolean(
    flight.status !== RawInstructionStatus.ARCHIVED &&
      flight.status !== RawInstructionStatus.CANCELED &&
      flight.endDate &&
      flight.endDate.valueOf() > new Date().valueOf()
  );

// backend returns error if we send DMA and country, all other combinations work
// so we check if only DMA exists and use the check to avoid sending country
export const geoOnlyHasDMA = (
  values: BulkEditFormType,
  operator: LogicalOperator
) => {
  if (
    values.geo.state.logicalOperator === operator &&
    values.geo.state.values.length > 0
  ) {
    return false;
  }

  if (
    values.geo.district.logicalOperator === operator &&
    values.geo.district.values.length > 0
  ) {
    return false;
  }

  if (
    values.geo.zipCode.logicalOperator === operator &&
    values.geo.zipCode.values.length > 0
  ) {
    return false;
  }

  if (
    values.geo.dma.logicalOperator === operator &&
    values.geo.dma.values.length > 0
  ) {
    return true;
  }

  return false;
};

// backend returns error if we send DMA and District and country, all other combinations work
// so we check if only DMA exists and use the check to avoid sending country
export const geoOnlyHasDMAandDistrict = (
  values: BulkEditFormType,
  operator: LogicalOperator
) => {
  if (
    values.geo.state.logicalOperator === operator &&
    values.geo.state.values.length > 0
  ) {
    return false;
  }

  if (
    values.geo.zipCode.logicalOperator === operator &&
    values.geo.zipCode.values.length > 0
  ) {
    return false;
  }

  if (
    values.geo.dma.logicalOperator === operator &&
    values.geo.dma.values.length > 0 &&
    values.geo.district.logicalOperator === operator &&
    values.geo.district.values.length > 0
  ) {
    return true;
  }

  return false;
};

// backend returns error if we send District and country, all other combinations work
// so we check if only District exists and use the check to avoid sending country
export const geoOnlyHasDistrict = (
  values: BulkEditFormType,
  operator: LogicalOperator
) => {
  if (
    values.geo.state.logicalOperator === operator &&
    values.geo.state.values.length > 0
  ) {
    return false;
  }

  if (
    values.geo.dma.logicalOperator === operator &&
    values.geo.dma.values.length > 0
  ) {
    return false;
  }

  if (
    values.geo.zipCode.logicalOperator === operator &&
    values.geo.zipCode.values.length > 0
  ) {
    return false;
  }

  if (
    values.geo.district.logicalOperator === operator &&
    values.geo.district.values.length > 0
  ) {
    return true;
  }

  return false;
};

export const determineBulkEditInstRawStatus = (
  editBulkCampaignData: QuickEditLineItemPayload | undefined,
  campaign: RootCampaign | ShallowLineItem
) =>
  editBulkCampaignData
    ? editBulkCampaignData.status === RawInstructionStatus.DELIVERABLE
      ? RawInstructionStatus.PAUSED
      : RawInstructionStatus.DELIVERABLE
    : campaign.rawStatus === RawInstructionStatus.DELIVERABLE
      ? RawInstructionStatus.PAUSED
      : RawInstructionStatus.DELIVERABLE;

export const toggleStatus = (
  status: RawInstructionStatus
): PossibleQuickEditStatuses => {
  if (
    status === RawInstructionStatus.DRAFT ||
    status === RawInstructionStatus.PAUSED
  ) {
    return RawInstructionStatus.DELIVERABLE;
  }
  return RawInstructionStatus.PAUSED;
};

export const compareLineItemsForExpandOrder = (
  a: ShallowLineItem,
  b: ShallowLineItem
): number => {
  const startDateDiff = sortNullableValueAscending(
    a.startDate,
    b.startDate,
    compareDates
  );
  if (startDateDiff) {
    return startDateDiff;
  }
  const endDateDiff = sortNullableValueAscending(
    a.endDate,
    b.endDate,
    compareDates
  );
  if (endDateDiff) {
    return endDateDiff;
  }
  const nameDiff = sortNullableValueAscending(
    a.name,
    b.name,
    sortAlphabetically
  );
  if (nameDiff) {
    return nameDiff;
  }
  return sortNullableValueAscending(a.lastUpdated, b.lastUpdated, compareDates);
};

/**
 * takes grouped creatives nested array , the index of the array and an array with a modified creative.
 * The 'modifyGroupedCreatives' function assigns 'newAttachedCreatives' to the grouped creatives array at a given index.
 * creative Ids will get deprecated, so indices are used instead for keeping track of where the changes occur.
 * groupedCreatives is derived from all attached creatives array. Here, we modify/edit creatives, put them back together in the array and return it in its original format
 */
export const modifyGroupedCreatives = (
  groupedCreatives: LineItemCreativeMaybeWithoutId[][],
  groupedCreativeIndex: number,
  newAttachedCreatives: LineItemCreativeMaybeWithoutId[]
) => {
  // eslint-disable-next-line no-param-reassign
  groupedCreatives[groupedCreativeIndex] = [...newAttachedCreatives];
  return groupedCreatives.flat();
};

export const getAllLineItems = (rootCampaigns: RootCampaign[]) =>
  rootCampaigns.flatMap(item => item.lineItems);

export const evenWeightCreativeFlights = (lineItems: ShallowLineItem[]) =>
  lineItems.map(item => ({
    ...item,
    attachedCreatives: item.attachedCreatives.map((cr, idx, arr) => ({
      ...cr,
      weight: 100 / arr.length
    }))
  }));

//

export const inheritDatesForNewCreatives = (lineItems: ShallowLineItem[]) => {
  const updatedLineItems = lineItems.map(item => ({
    ...item,
    attachedCreatives: item.attachedCreatives
      // Creative flights at end of attached creatives will be new, so start from end and assign dates to newly attached only
      // Better implementation would be to assign a newlyAssigned property to the creative type and detect for the boolean
      .map(cr => {
        if (cr.isNewlyAttached) {
          return {
            ...cr,
            // If date is in past, give current date else inherit line item's start date
            startDate:
              item.startDate.valueOf() < new Date().valueOf()
                ? new Date()
                : item.startDate,
            // User won't be able to bulk assign completed line items so end date can just be passed down
            endDate: item.endDate
          };
        }
        return cr;
      })
  }));
  return updatedLineItems;
};

// validate and format date
export const formatDateForCSV = (date: Date) => displayDate(date);

// csv expects a string
export const formatNumberForCSV = (num: number) => num.toString();

/**
 * formatting values
 */
export const getFormattedColumnValue = (
  columnValue: string | number,
  formattedColumnName: string
): string | number => {
  // format numbers
  if (typeof columnValue === "number") {
    formatNumberForCSV(columnValue);
  } else if (
    // format and validate dates
    formattedColumnName === ColumnNamesForCSV.START_DATE ||
    formattedColumnName === ColumnNamesForCSV.END_DATE
  ) {
    return formatDateForCSV(new Date(columnValue));
  }

  return columnValue;
};

/**
 *
 * some column names are not the exact match to campaign properties.
 * returning appropriate column name
 */
export const getPropertyName = (columnName: string, isLineItem = false) => {
  switch (columnName) {
    case ManageCampaignColumn.MADHIVE_ID:
      return isLineItem ? ColumnNamesForCSV.ID : camelCase(columnName);

    case ManageCampaignColumn.GOAL:
      return ColumnNamesForCSV.GOAL;
    case ManageCampaignColumn.BOOKED_IMPRESSIONS:
      return !isLineItem
        ? ColumnNamesForCSV.BOOKED_IMPRESSIONS
        : camelCase(columnName);

    case ManageCampaignColumn.OMS_ID:
      return ColumnNamesForCSV.ORDER_MANAGEMENT_SYSTEM_ID;

    // n.b. these are the same field in the BE
    // ... and are only being split into two columns due to subtle differences in the UI
    case ManageCampaignColumn.ESTIMATE_CODE:
    case ManageCampaignColumn.PREMION_CLIENT_ESTIMATE_CODE:
      return ColumnNamesForCSV.ESTIMATE_CODE;

    case ManageCampaignColumn.PREMION_CLIENT_CODE:
      return ColumnNamesForCSV.PREMION_CLIENT_CODE;
    case ManageCampaignColumn.PREMION_RFP_NAME:
      return ColumnNamesForCSV.PREMION_RFP_NAME;
    case ManageCampaignColumn.PREMION_RFP_DETAILS:
      return ColumnNamesForCSV.PREMION_RFP_DETAILS;
    case ManageCampaignColumn.PREMION_REVENUE_TYPE:
      return ColumnNamesForCSV.PREMION_REVENUE_TYPE;
    case ManageCampaignColumn.PREMION_PLACEMENTS_IO_ID:
      return ColumnNamesForCSV.PREMION_PLACEMENTS_IO_ID;
    case ManageCampaignColumn.PREMION_PLACEMENTS_IO_GROUP_ID:
      return ColumnNamesForCSV.PREMION_PLACEMENTS_IO_GROUP_ID;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_STATUS:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_STATUS;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_MARKET:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_MARKET;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_CLIENT_NAME:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_CLIENT_NAME;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_EXTERNAL_ADVERTISER_ID:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_EXTERNAL_ADVERTISER_ID;

    case ManageCampaignColumn.EXTERNAL_ID:
      return ColumnNamesForCSV.EXTERNAL_ID;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_ADVERTISER:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_ADVERTISER;
    case ManageCampaignColumn.SCRIPPS_ID:
      return ColumnNamesForCSV.SCRIPPS_ID;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_ADVERTISER_ID:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_ADVERTISER_ID;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_ID:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_PACKAGE_ID;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_NAME:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_PACKAGE_NAME;

    case ManageCampaignColumn.SCRIPPS_WIDE_ORBIT_ID:
      return ColumnNamesForCSV.SCRIPPS_WIDE_ORBIT_ID;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_SEGMENT_NOTE:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_SEGMENT_NOTE;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_GEO:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_GEO;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_GEO_IDS:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_GEO_IDS;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_STATION:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_STATION;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_STATION_ID:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_STATION_ID;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_DROP_ID:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_DROP_ID;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_DROP_STATUS:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_DROP_STATUS;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_PRICE:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_PRICE;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_PRICE_TYPE:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_PRICE_TYPE;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_GROSS_PRICE:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_GROSS_PRICE;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_STN_DEMO_AUDIENCE:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_STN_DEMO_AUDIENCE;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_STN_CONSUMER_AUDIENCE:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_STN_CONSUMER_AUDIENCE;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_CUSTOM_SEGMENT:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_CUSTOM_SEGMENT;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_CUSTOM_SEGMENT_NOTES:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_CUSTOM_SEGMENT_NOTES;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_CAMPAIGN_ID:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_CAMPAIGN_ID;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_AGENCY_ID:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_AGENCY_ID;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_POSTAL_CODES:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_POSTAL_CODES;

    case ManageCampaignColumn.SCRIPPS_OCTANE_PRODUCT:
      return ColumnNamesForCSV.SCRIPPS_OCTANE_PRODUCT;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_LAST_UPDATED:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_LAST_UPDATED;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_REVISION:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_REVISION;

    case ManageCampaignColumn.SCRIPPS_ADBOOK_WHITE_LIST:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_WHITE_LIST;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_WHITE_LIST_NAME:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_WHITE_LIST_NAME;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_AGENCY_NAME:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_AGENCY_NAME;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_POSITION_PATH:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_PACKAGE_POSITION_PATH;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_POSITION_NAME:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_POSITION_NAME;
    case ManageCampaignColumn.SCRIPPS_OCTANE_ADVERTISER_DOMAIN:
      return ColumnNamesForCSV.SCRIPPS_OCTANE_ADVERTISER_DOMAIN;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_LEVEL:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_PACKAGE_LEVEL;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_LAST_CHANGE_BY:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_LAST_CHANGE_BY;
    case ManageCampaignColumn.SCRIPPS_ADBOOK_CONTRACT_DATE:
      return ColumnNamesForCSV.SCRIPPS_ADBOOK_CONTRACT_DATE;
    case ManageCampaignColumn.GROUNDTRUTH_BRANDS:
      return ColumnNamesForCSV.GROUNDTRUTH_BRANDS;
    case ManageCampaignColumn.GROUNDTRUTH_LOCATION_GROUPS:
      return ColumnNamesForCSV.GROUNDTRUTH_LOCATION_GROUPS;
    case ManageCampaignColumn.ASSOCIATED_CAMPAIGN:
      return !isLineItem
        ? ColumnNamesForCSV.NAME
        : ColumnNamesForCSV.ASSOCIATED_CAMPAIGN;
    default:
      return camelCase(columnName);
  }
};

export const extractString = (regxArr: RegExpMatchArray | null) =>
  regxArr && regxArr.length ? regxArr.pop() : "";

export const delimitByComma = (id: string | undefined) =>
  id ? removeWhitespace(id).split(",") : [];

export const getRegexMatch = (
  lineItemGeoIds: string | undefined,
  regex: RegExp
) => (lineItemGeoIds ? lineItemGeoIds.match(regex) : []);

/**
 * Extracts state and dma ids.
 * As strings come in as "[DMA Region IDs=...] and [State IDs=...]", we need to pull out ids
 * and delimit with commas
 */
export const displayIds = (lineItemGeoIds: string | undefined) => {
  const dmaRegex = /\[DMA Region IDs=(.*?)\]/;
  const stateRegex = /\[State IDs=(.*?)\]/;

  // Gets regex  match
  const dmaIdRegexArray = getRegexMatch(lineItemGeoIds, dmaRegex);
  const stateIdRegexArray = getRegexMatch(lineItemGeoIds, stateRegex);

  // Extracts string and separates with commas
  /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts2345 */
  const dmaIds = delimitByComma(extractString(dmaIdRegexArray));

  /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts2345 */
  const stateIds = delimitByComma(extractString(stateIdRegexArray));

  const allIds = [...dmaIds, ...stateIds].join(", ");

  return allIds.length ? allIds : "";
};

/**
 * Looks up a dma name from dma code to dma name map as well as a state name
 * from state abbreviation to state name
 */
export const displayGeo = (
  dmasMap: Map<string, string>,
  lineItem: ShallowLineItem
) => {
  // get dma name
  const lineItmeWithGeo = lineItem.geoTargeting.include.dmaCodes
    .map((dma: number) =>
      dmasMap.has(dma.toString()) ? dmasMap.get(dma.toString()) : ""
    )
    .filter(Boolean);

  // get state name
  const states = lineItem.geoTargeting.include.states
    .map((state: string) =>
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      LIST_OF_STATES_WITH_ABBREVIATIONS_MAP[state]
        ? /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          LIST_OF_STATES_WITH_ABBREVIATIONS_MAP[state]
        : ""
    )
    .filter(Boolean);

  return lineItmeWithGeo.concat(states).join(", ");
};

/**
 *
 * Formats zip codes, dmas and states for csv. These properties are arrays and csv requires values to be strings
 */
export const getColumnValue = (
  formattedColumnName: string,
  dmasMap: Map<string, string>,
  lineItem: ShallowLineItem
) => {
  switch (formattedColumnName) {
    case ColumnNamesForCSV.SCRIPPS_ADBOOK_POSTAL_CODES:
      return lineItem.geoTargeting.include.zipCodes.join(",");
    case ColumnNamesForCSV.GOAL:
      return !lineItem?.budget
        ? lineItem.bookedImpressions
        : formatAsUsd(satoshisToDollars(lineItem?.budget || 0));
    case ColumnNamesForCSV.SCRIPPS_ADBOOK_GEO_IDS:
      return displayIds(lineItem.scrippsFieldAdbookGeoIds);
    case ColumnNamesForCSV.SCRIPPS_ADBOOK_GEO:
      return displayGeo(dmasMap, lineItem);
    case ColumnNamesForCSV.SCRIPPS_ADBOOK_CUSTOM_SEGMENT:
      return !!lineItem.scrippsFieldAdbookCustomSegment;
    case ColumnNamesForCSV.EXTERNAL_ID:
      return lineItem.isAdbookOrder
        ? lineItem.scrippsFieldAdbookExternalAdvertiserId
        : lineItem.externalId;

    case ColumnNamesForCSV.SCRIPPS_ADBOOK_WHITE_LIST:
      return !!lineItem.scrippsFieldAdbookAdvertiserWhitelistName;
    case ColumnNamesForCSV.MEDIA_TYPE:
      return (lineItem.mediaType && MediaType[lineItem.mediaType]) || undefined;
    case ColumnNamesForCSV.CATEGORY:
      return lineItem.iab_category_rtb_ids?.length ?? 0;
    default:
      /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
      return lineItem[formattedColumnName] || "";
  }
};

const isLineItemIncludeGeoTargetingNational = (geoTargeting: GeoTargets) =>
  !Object.values(GeographicEntityTypes)
    .filter(geoType => geoType !== GeographicEntityTypes.COUNTRY)
    /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts2551 */
    .some(geoType => geoTargeting[geoType] && geoTargeting[geoType].length);

export const stripCountryFlagFromLineItemGeoTargeting = (
  lineItem: LineItemFormatted
): LineItemFormatted => ({
  ...lineItem,
  geoTargeting: {
    include: isLineItemIncludeGeoTargetingNational(
      lineItem.geoTargeting.include
    )
      ? lineItem.geoTargeting.include
      : {
          ...lineItem.geoTargeting.include,
          country: ""
        },
    exclude: {
      ...lineItem.geoTargeting.exclude,
      country:
        isExclusivelyDmasAndOrDistricts(lineItem.geoTargeting.exclude) ||
        isGeoTargetEmpty(lineItem.geoTargeting.exclude)
          ? ""
          : lineItem.geoTargeting.exclude.country
    }
  }
});

// TB: The naming convention for congressional districts is '{state ABV} District {DistrictNumber}'. When we sort districts, we want them sorted alphabetically and then by their number. string.prototype.localeCompare only goes so far, as it will sort a "10" in front of a "2". sortDistrictNames checks if a district has the same `{state ABV} District`, and if it does, uses the parsed district number to sort the name numerically

export const sortDistrictNames = (a: string, b: string) => {
  const [aNum, bNum] = [a, b].map(name => {
    const numMatchArray = name.match(/\d+/);
    return numMatchArray ? parseInt(numMatchArray[0], 10) : null;
  });

  const [aName, bName] = [a, b].map(name => name.replace(/[0-9]/g, "").trim());

  if (aNum && bNum && aName.localeCompare(bName) === 0) {
    return aNum === bNum ? 0 : aNum > bNum ? 1 : -1;
  }

  return a.localeCompare(b);
};

// In table cells, a district will often be formatted like "CA District 1, CA District 2", if a table is sorted by zip code (bc multiple districts often correspond to the same zip). This function takes two such a cell split on ", " and sorts each district within the origal a string against its corresponding index in the b string.

export const sortDistrictNamesRecursive = (
  a: string[],
  b: string[]
): number => {
  const [aName, bName] = [a[0], b[0]];

  if (aName && bName) {
    const result = sortDistrictNames(aName, bName);

    // If a[0] === b[0], try sorting them on the next array index
    if (result === 0) {
      return sortDistrictNamesRecursive(
        a.slice(1, a.length),
        b.slice(1, b.length)
      );
    }

    return result;
  }

  // if a[] has more districts than b[], sort b ahead of a
  return !aName && !bName ? 0 : aName ? 1 : -1;
};

export const disabledTargetingFieldsForOrg = {
  [KnownOrganizationIds.SCRIPPS]: true,
  [KnownOrganizationIds.HEARST]: true
};

export const areTargetingFieldsDisabledPerOrg = (
  lineItem: LineItemFormatted | EditModeLineItemWithCreatives,
  orgId: KnownOrganizationIds
) => {
  switch (orgId) {
    case KnownOrganizationIds.SCRIPPS:
      return lineItem.isAdbookOrder && disabledTargetingFieldsForOrg[orgId];
    case KnownOrganizationIds.HEARST:
      return disabledTargetingFieldsForOrg[orgId];
    default:
      return false;
  }
};

export const dispatchLineItemStatusUpdate =
  (
    lineItem: LineItemFormatted | ShallowLineItem,
    status: ServiceStatus,
    logFailedEvent: ManageCampaignsEvents,
    options: {
      shouldAlsoUpdateCampaign?: boolean;
    }
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    dispatch(
      requestLineItemStatusUpdate(
        lineItem,
        lineItem.parentCampaignId,
        status,
        options
      )
    ).catch(err => {
      console.error(err);
      logEvent(logFailedEvent, {
        params: { error: err.message }
      });
    });
  };

export const dispatchArchiveLineItem =
  (
    lineItem: LineItemFormatted | ShallowLineItem,
    options: {
      shouldAlsoUpdateCampaign?: boolean;
    }
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    dispatch(
      dispatchLineItemStatusUpdate(
        lineItem,
        ServiceStatus.ARCHIVED,
        ManageCampaignsEvents.FAILED_TO_ARCHIVE_LINE_ITEM_TABLE,
        options
      )
    );
  };

export const dispatchUnarchiveLineItem =
  (
    lineItem: LineItemFormatted | ShallowLineItem,
    options: {
      shouldAlsoUpdateCampaign?: boolean;
    }
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    dispatch(
      dispatchLineItemStatusUpdate(
        lineItem,
        ServiceStatus.DRAFT,
        ManageCampaignsEvents.FAILED_TO_UNARCHIVE_LINE_ITEM_TABLE,
        options
      )
    );
  };

export const getCampaignArchiveStatus = (campaign: RootCampaign) => {
  const isArchived = campaign.status === CampaignStatus.ARCHIVED;

  const isUnArchived = !isArchived;

  const isReadyToArchive = campaign.rawStatus === ServiceStatus.DRAFT;

  return { isArchived, isUnArchived, isReadyToArchive };
};

export const getIsCampaignEndDateDisabled = (campaign: RootCampaign) =>
  campaign.isAdbookOrder ||
  campaign.isWideOrbitOrder ||
  campaign.status === CampaignStatus.ARCHIVED;

export const toggleSelectedCampaigns = (
  campaign: RootCampaign,
  campaignList: RootCampaign[],
  shouldAdd?: boolean | undefined
) => {
  if (campaignList.some(sc => sc.id === campaign.id)) {
    if (shouldAdd === true) return campaignList;
    return campaignList.filter(sc => sc.id !== campaign.id);
  }
  if (shouldAdd === false) return campaignList;
  return [...campaignList.filter(c => c.id !== campaign.id), campaign];
};

export const toggleSelectedLineitems = (
  lineItem: ShallowLineItem,
  lineItemList: ShallowLineItem[]
) => {
  if (lineItemList.some(sli => sli.id === lineItem.id)) {
    return lineItemList.filter(sli => sli.id !== lineItem.id);
  }
  return [...lineItemList.filter(li => li.id !== lineItem.id), lineItem];
};

interface NumberOfInstructionsInThePast {
  campaignsInPast: number;
  lineItemsInPast: number;
  creativesInPast: number;
}

export const getNumberOfInstructionsInThePast = (
  quickEditsCampaign: CampaignEditRecords,
  quickEditsLineItem: LineItemEditRecords,
  campaignsRecord: Record<string, RootCampaign>,
  lineItemsRecord: Record<string, ShallowLineItem>
): NumberOfInstructionsInThePast => {
  const campaignsInPast = Object.entries(quickEditsCampaign).filter(
    ([id, campaign]) => {
      const originalCampaign = campaignsRecord[id];
      return (
        campaign.status === RawInstructionStatus.DELIVERABLE &&
        originalCampaign?.rawStatus === RawInstructionStatus.DRAFT &&
        (campaign.startDate
          ? isDatePassed(campaign.startDate)
          : isDatePassed(originalCampaign.startDate))
      );
    }
  ).length;
  const lineItemsInPast = Object.entries(quickEditsLineItem).filter(
    ([id, lineItem]) => {
      const originalLineItem = lineItemsRecord[id];
      if (
        lineItem.status === RawInstructionStatus.DELIVERABLE &&
        originalLineItem?.rawStatus === RawInstructionStatus.DRAFT &&
        (lineItem.startDate
          ? isDatePassed(lineItem.startDate)
          : isDatePassed(originalLineItem.startDate))
      ) {
        return true;
      }
      return false;
    }
  ).length;
  const creativesInPast = Object.entries(quickEditsLineItem).filter(
    ([id, lineItem]) => {
      const originalLineItem = lineItemsRecord[id];
      return (
        lineItem.creatives &&
        lineItem.creatives.filter(creative => {
          const originalCreative = originalLineItem?.attachedCreatives.find(
            cr => cr.id === creative.id
          );
          if (
            originalCreative &&
            originalCreative.status === RawInstructionStatus.DRAFT &&
            creative.status === RawInstructionStatus.DELIVERABLE &&
            (creative.startDate
              ? isDatePassed(creative.startDate)
              : originalCreative?.startDate &&
                isDatePassed(originalCreative.startDate))
          ) {
            return true;
          }
          return false;
        }).length
      );
    }
  ).length;
  return { campaignsInPast, lineItemsInPast, creativesInPast };
};

export const getInstructionsInThePastMessage = (
  instructionsInThePastNumber: NumberOfInstructionsInThePast
): string => {
  const instructions = [];
  if (instructionsInThePastNumber.campaignsInPast) {
    instructions.push(
      `${instructionsInThePastNumber.campaignsInPast} campaign(s)`
    );
  }
  if (instructionsInThePastNumber.lineItemsInPast) {
    instructions.push(
      `${instructionsInThePastNumber.lineItemsInPast} line item(s)`
    );
  }
  if (instructionsInThePastNumber.creativesInPast) {
    instructions.push(
      `${instructionsInThePastNumber.creativesInPast} creative flight(s)`
    );
  }
  const instructionsWithPunctuation = instructions.reduce(
    (previous, current, index) => {
      if (!previous) return current;
      if (index === instructions.length - 1) {
        return `${previous} and ${current}`;
      }

      return `${previous}, ${current}`;
    },
    ""
  );
  const totalNumberOfInstructions = Object.values(
    instructionsInThePastNumber
  ).reduce((prev, curr) => prev + curr, 0);
  return `There ${
    totalNumberOfInstructions > 1 ? "are" : "is"
  } ${instructionsWithPunctuation} with a start date in the past. They will get updated to the earliest possible time.`;
};

export const getDatesInRange = (
  startDate: Date,
  endDate: Date,
  currentStartDate: Date,
  currentEndDate: Date
): Date[] => {
  const currentStartTime = currentStartDate.valueOf();
  const currentEndTime = currentEndDate.valueOf();
  const startTime = startDate.valueOf();
  const endTime = endDate.valueOf();
  let newStartDate = currentStartDate;
  let newEndDate = currentEndDate;

  if (startTime >= endTime) {
    return [currentStartDate, currentEndDate];
  }

  if (currentStartTime < startTime) {
    newStartDate = startDate;
  }

  if (currentEndTime > endTime) {
    newEndDate = endDate;
  }

  // addional validation to catch possible trade off
  if (newStartDate.valueOf() >= newEndDate.valueOf()) {
    newEndDate = endDate;
    newStartDate = startDate;
  }

  return [newStartDate, newEndDate];
};

export const getLineItemMinAndMaxDates = (
  campaign: RootCampaign,
  startDate: Date | undefined,
  endDate: Date | undefined,
  campaignUsesLineItemDates?: boolean
) => {
  const minStartDate = campaignUsesLineItemDates
    ? getTenMinutesFromNow(new Date())
    : getLatestDate([campaign.startDate, new Date()]);
  const eighteenMonthsFromNow = addMonthsToDate(new Date(), 18);
  let maxStartDate = campaignUsesLineItemDates
    ? endDate || eighteenMonthsFromNow
    : getEarliestDate([campaign?.endDate, endDate]);
  if (maxStartDate && maxStartDate < minStartDate) maxStartDate = minStartDate;

  const minEndDate = getLatestDate([startDate, new Date()]);
  let maxEndDate = campaignUsesLineItemDates
    ? addYearsToDate(new Date(), 3)
    : campaign.endDate;
  if (maxEndDate && maxEndDate < minEndDate) maxEndDate = minEndDate;
  return { minStartDate, maxStartDate, minEndDate, maxEndDate };
};

export const getCampaignStartAndEndDates = (
  campaign: RootCampaign,
  isStartASAPEnabled: boolean
) => {
  const getStartDate = () => {
    if (
      isStartASAPEnabled &&
      campaign.startASAP &&
      PRE_SET_LIVE_CAMPAIGN_STATUSES.has(campaign.status)
    ) {
      return "ASAP";
    }
    return prettifyDateWithTimezone(campaign.startDate);
  };

  const getEndDate = () => prettifyDateWithTimezone(campaign.endDate);

  return { startDate: getStartDate(), endDate: getEndDate() };
};

export const deriveOMSIdToFieldName = (user: User | undefined): string => {
  if (!user) {
    return "OMS ID";
  }
  return user.settings.extMeta.omsIdFieldName;
};

export const deriveOMSLabel = (user: User | undefined): string => {
  if (!user) {
    return "OMS";
  }
  return user.settings.extMeta.omsLabel;
};
