import {
  GET_LINEAR_REPORT_WHITE_LIST_FAILURE,
  GET_LINEAR_REPORT_WHITE_LIST_PENDING,
  GET_LINEAR_REPORT_WHITE_LIST_SUCCESS
} from ".";
import {
  CustomReportingState,
  CustomReportsActionTypes,
  DELETE_REPORT_SUCCESS,
  GET_REPORTS_FAILURE,
  GET_REPORTS_PENDING,
  GET_REPORTS_SUCCESS,
  GET_REPORT_DETAILS_FAILURE,
  GET_REPORT_DETAILS_PENDING,
  GET_REPORT_DETAILS_SUCCESS,
  REFRESH_REPORTS,
  GET_REPORT_CSV_PENDING,
  GET_REPORT_CSV_SUCCESS,
  GET_REPORT_CSV_FAILURE,
  SET_REPORT_CSV_METADATA,
  CLEAR_REPORT_CSV_METADATA,
  SET_REPORT_CSV_METADATA_AVAILABLE_DIMENSIONS
} from "./types";

export const initialState: CustomReportingState = {
  byId: {},
  reportCSVMetadata: {
    report: undefined,
    availableDimensions: undefined,
    reportHasOttData: undefined,
    shouldShowLinearData: undefined
  },
  resultsById: null,
  isLoading: false,
  isLoaded: false,
  isReportDetailsLoading: false,
  isNewDimensionLoading: false,
  error: null,
  isWhiteListLoading: false,
  whiteListError: false,
  whitelistedUsers: null
};

export const reportsReducer = (
  state = initialState,
  action: CustomReportsActionTypes
) => {
  switch (action.type) {
    case GET_REPORTS_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case REFRESH_REPORTS: {
      return {
        ...state,
        // @ts-ignore To Type once reports are defined
        byId: {
          ...state.byId,
          ...action.payload.reduce((byId, report) => {
            /* eslint-disable no-param-reassign */
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            byId[report.resultKey] = report;
            /* eslint-enable no-param-reassign */
            return byId;
          }, {})
        }
      };
    }
    case GET_REPORTS_SUCCESS: {
      return {
        ...state,
        // @ts-ignore To Type once reports are defined
        byId: action.payload.reduce((byId, report) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[report.resultKey] = report;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        isLoading: false,
        isLoaded: true,
        error: null
      };
    }
    case GET_REPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error
      };
    case GET_REPORT_DETAILS_PENDING:
      return {
        ...state,
        isNewDimensionLoading: true,
        isReportDetailsLoading: action.payload || false
      };
    case GET_REPORT_DETAILS_SUCCESS:
      // we append newly fetched data (instead of overwriting) to the dimensions that have already been fetched.
      // before we fetched everything at once, now we fetch individual dimensions

      // get all results by id
      const resultsById = state.resultsById || {};

      // get all dimensions by report id
      const currentData = resultsById[action.payload.reportId] || {};

      // combine new dimensions and dimensions already fetched
      const results = { ...currentData, ...action.payload.results };

      return {
        ...state,
        isReportDetailsLoading: false,
        isNewDimensionLoading: false,
        resultsById: {
          ...state.resultsById,
          [action.payload.reportId]: results
        }
      };
    case GET_REPORT_DETAILS_FAILURE:
      return {
        ...state,
        isReportDetailsLoading: false,
        isNewDimensionLoading: false
      };

    case GET_REPORT_CSV_PENDING:
      return {
        ...state,
        isReportCSVLoading: true
      };
    case GET_REPORT_CSV_SUCCESS:
      return {
        ...state,
        isReportCSVLoading: false
      };
    case SET_REPORT_CSV_METADATA:
      return {
        ...state,
        reportCSVMetadata: {
          report: action.payload.report
            ? action.payload.report
            : state.reportCSVMetadata.report,
          availableDimensions: []
        }
      };
    case SET_REPORT_CSV_METADATA_AVAILABLE_DIMENSIONS:
      return {
        ...state,
        reportCSVMetadata: {
          ...state.reportCSVMetadata,
          availableDimensions: action.payload.availableDimensions,
          reportHasOttData: action.payload.reportHasOttData,
          shouldShowLinearData: action.payload.shouldShowLinearData,
          shouldShowDisplayData: action.payload.shouldShowDisplayData
        }
      };
    case CLEAR_REPORT_CSV_METADATA:
      return {
        ...state,
        reportCSVMetadata: null
      };
    case GET_REPORT_CSV_FAILURE:
      return {
        ...state,
        isReportCSVLoading: false
      };
    case DELETE_REPORT_SUCCESS: {
      const {
        // eslint-disable-next-line no-empty-pattern
        [action.meta.id]: {},
        ...rest
      } = state.byId;
      return {
        ...state,
        byId: {
          ...rest
        }
      };
    }
    case GET_LINEAR_REPORT_WHITE_LIST_PENDING: {
      return {
        ...state,
        isWhiteListLoading: true,
        whiteListError: false
      };
    }

    case GET_LINEAR_REPORT_WHITE_LIST_SUCCESS: {
      return {
        ...state,
        isWhiteListLoading: false,
        whitelistedUsers: action.payload,
        whiteListError: false
      };
    }
    case GET_LINEAR_REPORT_WHITE_LIST_FAILURE: {
      return {
        ...state,
        isWhiteListLoading: false,
        whiteListError: true
      };
    }
    default:
      return state;
  }
};
