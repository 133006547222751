import {
  DmpSegmentsActionTypes,
  DmpSegmentsState,
  GET_ALL_DMP_SEGMENTS_PENDING,
  GET_ALL_DMP_SEGMENTS_SUCCESS,
  GET_ALL_DMP_SEGMENTS_FAILURE,
  RETARGETING_SEGMENT_CREATION_SUCCESS,
  RETARGETING_SEGMENT_ARCHIVED,
  GET_ARCHIVED_RETARGETING_SEGMENTS_SUCCESS,
  GET_DMP_SEGMENT_PENDING,
  GET_DMP_SEGMENT_SUCCESS,
  GET_DMP_SEGMENT_FAILURE,
  BASE_SEGMENT_CREATION_SUCCESS
} from "./types";

export const initialState: DmpSegmentsState = {
  byId: {},
  isOneLoading: false,
  isAllLoading: false,
  isArchivedLoaded: false,
  receivedAllAt: null
};

export const dmpSegmentsReducer = (
  state = initialState,
  action: DmpSegmentsActionTypes
): DmpSegmentsState => {
  switch (action.type) {
    case GET_ALL_DMP_SEGMENTS_PENDING:
      return {
        ...state,
        isAllLoading: true
      };
    case GET_ALL_DMP_SEGMENTS_FAILURE:
      return {
        ...state,
        isAllLoading: false
      };
    case GET_ALL_DMP_SEGMENTS_SUCCESS:
      return {
        ...state,
        byId: action.payload.reduce((byId, segment) => {
          /* eslint-disable no-param-reassign */
          /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
          byId[segment.id] = segment;
          /* eslint-enable no-param-reassign */
          return byId;
        }, {}),
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp
      };
    case GET_DMP_SEGMENT_PENDING:
      return {
        ...state,
        isOneLoading: true
      };
    case GET_DMP_SEGMENT_FAILURE:
      return {
        ...state,
        isOneLoading: false
      };
    case GET_DMP_SEGMENT_SUCCESS:
      const updatedById = { ...state.byId };
      updatedById[action.payload.id] = action.payload;
      return {
        ...state,
        isOneLoading: false,
        byId: updatedById
      };
    case RETARGETING_SEGMENT_CREATION_SUCCESS:
    case BASE_SEGMENT_CREATION_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload }
      };
    case RETARGETING_SEGMENT_ARCHIVED:
      const { [action.payload]: _deleted, ...rest } = state.byId;
      return {
        ...state,
        byId: rest
      };
    case GET_ARCHIVED_RETARGETING_SEGMENTS_SUCCESS:
      return {
        ...state,
        isArchivedLoaded: true,
        byId: {
          ...state.byId,
          ...action.payload.reduce((byId, segment) => {
            /* @ts-expect-error - (TS Upgrade: 5.7.3) - https://typescript.tv/errors/#ts7053 */
            byId[segment.id] = segment;
            return byId;
          }, {})
        }
      };

    default:
      return state;
  }
};
