import { css } from "@emotion/react";
import { FC, PropsWithChildren, useRef } from "react";
import { kitText } from "../../styles";
import { styles } from "./styles";
import { FormFieldProps } from "./types";
import Truncated from "../Truncated";

const FormField: FC<PropsWithChildren<FormFieldProps>> = ({
  label,
  truncateLabel = false,
  hideLabel,
  description,
  error,
  offsetError,
  fieldset = false,
  required = false,
  fill,
  labelSuffix,
  labelSuffixAlign = "space-between",
  id,
  children
}) => {
  /** The capital variable names here make the JSX parse into the value instead of the literal
   * variable name (createElement("fieldset") vs createElement("wrapperElement")). */
  const WrapperElement = fieldset ? "fieldset" : "label";
  const LabelElement = fieldset ? "legend" : "div";

  const fieldRef = useRef<HTMLDivElement>(null);

  if (!label && !required && !description && !error) return <>{children}</>;
  return (
    <div
      className="kit-FormField"
      css={[styles.base, fill && styles.fill]}
      ref={fieldRef}
    >
      {label || required || description ? (
        <WrapperElement htmlFor={id} data-testid="form-field-label-wrapper">
          {(!!label || !!required) && (
            <LabelElement
              data-testid="form-field-label"
              css={[
                kitText.style.bold,
                kitText.variant.body,
                styles.label,
                truncateLabel && styles.labelTruncate,
                hideLabel && styles.hidden
              ]}
            >
              {truncateLabel && label ? <Truncated text={label} /> : label}
              {required && <span css={styles.requiredAsterisk}> *</span>}
              {labelSuffix && (
                <div
                  data-testid="form-field-label-suffix"
                  css={[
                    styles.labelSuffix,
                    css`
                      justify-content: ${labelSuffixAlign};
                    `
                  ]}
                >
                  {labelSuffix}
                </div>
              )}
            </LabelElement>
          )}
          {description && (
            <div
              data-testid="form-field-description"
              css={[
                kitText.color.secondary,
                styles.description,
                !label &&
                  css`
                    margin-top: 0;
                  `
              ]}
            >
              {description}
            </div>
          )}
          {children}
        </WrapperElement>
      ) : (
        children
      )}
      {error && (
        <div
          data-testid="form-field-error"
          css={[kitText.variant.micro, kitText.color.error, styles.error]}
          ref={field => {
            if (field && offsetError && fieldRef.current) {
              fieldRef.current.style.marginTop = `${field.clientHeight + 4}px`;
            }
            if (!field && fieldRef.current) {
              fieldRef.current.style.marginTop = "0";
            }
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default FormField;
export * from "./types";
