import {
  ClientDeviceNames,
  DeviceCaps,
  DeviceNames,
  FrequencyCap,
  GeographicEntityTypes,
  GeoTargetsKeys,
  LineItem,
  LogicalOperator
} from "@madhive/mad-sdk";
import { ShallowLineItem } from "campaign-types";
import { GeoSelectionProps } from "components/Reusable/GeoSelection/GeoSelection";
import { SelectOption } from "components/Reusable/SmithersSelect/SmithersSelect";
import { SortDirection } from "lib/utils/search";
import { sortAlphabetically } from "lib/utils/sorting";

export interface BulkEditOption {
  id: string;
  name: string | undefined;
}

export type BulkEditFields = Partial<Record<keyof LineItem, string>>;
export const BULK_EDIT_FIELDS_STATE_KEY = "lineItemBulkEditFields";

export type ReviewItem = {
  fieldChanged: string;
  currentValue: string;
  currentTooltip?: string;
  newValue: string;
  newTooltip?: string;
  lineItemId: string;
  id: string;
} & Partial<ShallowLineItem>;

export enum BulkEditReviewColumn {
  LINE_ITEM_NAME = "line-item-name",
  FIELD_CHANGED = "field-changed",
  CURRENT_VALUE = "current-value",
  NEW_VALUE = "new-value",
  ASSOCIATED_CAMPAIGN = "associated-campaign",
  ID = "id"
}

type SortFnLookup = Record<
  BulkEditReviewColumn,
  (a: ReviewItem, b: ReviewItem) => number
>;

export const SORT_FN_LOOKUP: Readonly<SortFnLookup> = {
  [BulkEditReviewColumn.LINE_ITEM_NAME]: (a, b) =>
    sortAlphabetically(a.name || "", b.name || ""),
  [BulkEditReviewColumn.FIELD_CHANGED]: (a, b) =>
    sortAlphabetically(a.fieldChanged, b.fieldChanged),
  [BulkEditReviewColumn.CURRENT_VALUE]: (a, b) =>
    sortAlphabetically(a.currentValue, b.currentValue),
  [BulkEditReviewColumn.NEW_VALUE]: (a, b) =>
    sortAlphabetically(a.newValue, b.newValue),
  [BulkEditReviewColumn.ASSOCIATED_CAMPAIGN]: (a, b) =>
    sortAlphabetically(a.parentName || "", b.parentName || ""),
  [BulkEditReviewColumn.ID]: (a, b) => sortAlphabetically(a.id, b.id)
};

export interface BulkEditReviewTableColumn {
  id: BulkEditReviewColumn;
  label: string;
}

export const REVIEW_CHANGES_ID_TO_NAME: Record<BulkEditReviewColumn, string> = {
  [BulkEditReviewColumn.LINE_ITEM_NAME]: "Line Item Name",
  [BulkEditReviewColumn.FIELD_CHANGED]: "What Changed",
  [BulkEditReviewColumn.CURRENT_VALUE]: "Current",
  [BulkEditReviewColumn.NEW_VALUE]: "New",
  [BulkEditReviewColumn.ASSOCIATED_CAMPAIGN]: "Associated Campaign",
  [BulkEditReviewColumn.ID]: "Id"
};

export const HEADINGS: BulkEditReviewTableColumn[] = Object.values(
  BulkEditReviewColumn
).map(id => ({
  id,
  label: REVIEW_CHANGES_ID_TO_NAME[id]
}));

export const INITIAL_SORT_COLUMN = BulkEditReviewColumn.LINE_ITEM_NAME;
export const INITIAL_SORT_DIRECTION = SortDirection.ASCENDING;
export const INITIAL_SORT_COLUMN_INDEX = HEADINGS.findIndex(
  c => c.id === INITIAL_SORT_COLUMN
);

export const DEFAULT_REVIEW_CHANGE_OPTION: Readonly<SelectOption> = {
  id: "showAll",
  label: "Show All",
  disabled: false
};

export const PAGES = [
  "Line Item Attributes",
  "Bulk Edit Line Items",
  "Review Changes"
];

export const LAST_PAGE = PAGES.length - 1;

export const ROW_ID_DELIMITER = "__";

export interface FieldValidationError {
  validated: boolean;
  errorMessage: string | undefined;
}

export const INITIAL_FIELD_VALIDATION: Readonly<FieldValidationError> = {
  validated: false,
  errorMessage: undefined
};

// Keep country undefined initially to gray out other geo edits
export const DEFAULT_GEO_SELECTION_PROPS: GeoSelectionProps = {
  country: undefined,
  state: {
    logicalOperator: LogicalOperator.INCLUDE,
    values: []
  },
  dma: {
    logicalOperator: LogicalOperator.INCLUDE,
    values: []
  },
  district: {
    logicalOperator: LogicalOperator.INCLUDE,
    values: []
  },
  zipCode: {
    logicalOperator: LogicalOperator.INCLUDE,
    values: []
  }
};

export const DEFAULT_FREQUENCY_CAP_PROPS: FrequencyCap[] = [
  { duration: { time_unit: "DAY" }, max: "5" }
];

export const BLANK_DEVICE_CAPS = {
  tv: 0,
  desktop: 0,
  mobile: 0,
  tablet: 0
} as Readonly<DeviceCaps>;

export const DEVICE_CAPS_FIELD_TO_NAMES: Record<string, string> = {
  [ClientDeviceNames.CTV]: DeviceNames.CTV,
  [ClientDeviceNames.MOBILE]: DeviceNames.MOBILE,
  [ClientDeviceNames.DESKTOP]: DeviceNames.DESKTOP,
  [ClientDeviceNames.TABLET]: DeviceNames.TABLET
};

export const GEO_SELECTION_PROPS_FIELD_TO_NAMES: Record<string, string> = {
  [GeographicEntityTypes.COUNTRY]: "Country",
  [GeographicEntityTypes.STATE]: "State",
  [GeographicEntityTypes.DMA]: "Metro",
  [GeographicEntityTypes.DISTRICT]: "District",
  [GeographicEntityTypes.ZIP_CODE]: "Zip Code"
};

export const GEO_TARGETING_FIELD_TO_NAMES: Record<string, string> = {
  [GeoTargetsKeys.COUNTRY]: "Country",
  [GeoTargetsKeys.STATE]: "States",
  [GeoTargetsKeys.DMA]: "Metros",
  [GeoTargetsKeys.DISTRICT]: "Districts",
  [GeoTargetsKeys.ZIP_CODE]: "Zip Codes"
};

export const GEO_TARGETING_DETAILS_FIELD_TO_NAMES: Record<string, string> = {
  country: "Country",
  regions: "States",
  dmas: "Metros",
  districts: "Districts",
  postalCodes: "Zip Codes"
};
